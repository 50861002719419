import React, { FC, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import SectionOuPartir from "components/SectionOuPartir/SectionOuPartir";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';

export interface PageOuPartirProps {
  className?: string;
}


const PageOuPartir: FC<PageOuPartirProps> = ({ className = "" }) => {

  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);

  useEffect( () => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []);
 
  return (
    <div
      className={`nc-PageOuPartir overflow-hidden ${className}`}
      data-nc-id="PageOuPartir"
    >
      <Helmet>
        <title>{t('ouPartir')}</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 mt-10 lg:mt-12 px-10">
        <SectionOuPartir />
      </div>
</div>
  );
};

export default PageOuPartir;
