import { CalendarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import { useTranslation } from 'react-i18next';

export interface NbDaysProps {
  className?: string;
  nbJours?: number;
}

const NbDays: FC<NbDaysProps> = ({
  className = "",
  nbJours = 0
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nc-NbDays flex items-center space-x-1 text-xs text-base font-semibold ${className}`}
      data-nc-id="NbDays"
    >
      <div className="pb-[2px]">
        <CalendarIcon className="w-[16px] h-[16px] text-rose-500" />
      </div>
      <span className="text-neutral-500 dark:text-neutral-400">
      {nbJours} {t('jours')}
      </span>
    </div>
  );
};

export default NbDays;
