import { GuestsObject } from "components/HeroSearchForm/type";
import NcSelectMonnaie from "./NcSelectMonnaie/NcSelectMonnaie";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from 'react-i18next';

export interface SelectMonnaieProps {
  defaultValue?: string;
  onChange?: (data: string) => void;
  className?: string;
}

const SelectMonnaie: FC<SelectMonnaieProps> = ({
  defaultValue,
  onChange,
  className = ""
}) => {
  const { t } = useTranslation();

  const [monnaie, setMonnaie] = useState(
    "euro"
  );

  useEffect(() => {
    setMonnaie(defaultValue || "euro");
  }, [defaultValue]);

  const handleChangeData = (value: string) => {
    onChange && onChange(value);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <NcSelectMonnaie
        className="w-full"
        defaultValue={monnaie}
        onChange={(value) => handleChangeData(value)}
        max={10}
        min={1}
        label={t('monnaie')}
      />
    </div>
  );
};

export default SelectMonnaie;
