import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import { autocompleteService } from '_services/autocomplete.service';
import ModalIncontournables from '../ModalIncontournables';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

export interface PaysInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onSelectDestination?: (updatedDestinations: Pays[]) => void;
  isModifiable?: boolean;
  idPays: string;
  libellesPays: string;
  onRemoveDestination?: (updatedDestinations: Pays[]) => void;
  onClickPays?: (codePays: string) => void; 
  nbJours?: number;
}

export type Destination = {
  code_pays: string;
  pays: string;
  ville: string;
  place_id: string,
  latitude: string,
  longitude: string,
};

type Pays = {
  code_pays: string;
  nom: string;
};

const PaysInput: FC<PaysInputProps> = ({
  autoFocus = false,
  placeHolder = "Pays",
  desc = "Où allez-vous ?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onSelectDestination,
  isModifiable,
  idPays,
  libellesPays,
  onRemoveDestination,
  onClickPays,
  nbJours
}) => {
  const { t } = useTranslation();
  
  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState("");
  const [selectedDestinations, setSelectedDestinations] = useState<Pays[]>([]);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [searchResults, setSearchResults] = useState<Pays[]>([]);

  const [codePaysClicked, setCodePaysClicked] = useState<string>("");
  const [isModalIncontournablesOpen, setIsModalIncontournablesOpen] = useState(false);

  useEffect(() => {
    // Fonction pour convertir la chaîne d'ID de pays en une liste d'objets Pays
    const parseIdPays = (idPays: string): Pays[] => {
      const codesPays = idPays.split('_');
      const destinations: Pays[] = codesPays.map((codePays) => {
        // Vous devez obtenir le libellé du pays correspondant à partir de libellesPays.
        // Je suppose que les libellés sont également séparés par '_'.
        const libelles = libellesPays.split('_');
        const index = codesPays.indexOf(codePays);
        const nom = index !== -1 ? libelles[index] : ''; // Utilisez le bon index pour obtenir le libellé

        return {
          code_pays: codePays,
          nom: nom,
        };
      });
      return destinations;
    };

    setSelectedDestinations(parseIdPays(idPays));
  }, []);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && value && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    if (value) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [value]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    setShowPopover(false);
  };

  const handleSelectLocation = (item: Pays) => {
    const isAlreadySelected = selectedDestinations.some(
      (destination) => destination.code_pays === item.code_pays
    );
  
    if (isAlreadySelected) {
      alert(
        "Vous avez déjà saisi ce pays, veuillez saisir le pays suivant ou passer à la saisie des autres critères !"
      );
      return;
    }
    
    if (onSelectDestination) {
      const updatedDestinations = [...selectedDestinations, item];
     
      onSelectDestination(updatedDestinations);
    }
    setSelectedDestinations([...selectedDestinations, item]);
    setValue("");
    setSearchResults([]);
    setShowPopover(false);
  };
  

  const handleRemoveDestination = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  
    const updatedDestinations = [...selectedDestinations];
    updatedDestinations.splice(index, 1);

    if (onRemoveDestination) {
      onRemoveDestination(updatedDestinations);
    }

    setSelectedDestinations(updatedDestinations);
  };
  

  const fetchSearchResults = async () => {
    try {
      const response = await autocompleteService.getPays(value, langue);
      const data = await response.data;

      if (data && data.rows) {
        const formattedResults: Pays[] = data.rows.map((row: any) => ({
          code_pays: row.code_pays,
          nom: row.nom,
        }));
        setSearchResults(formattedResults);
      }

    } catch (error) {
      console.error("Erreur lors de la récupération des résultats de recherche :", error);
    }
  };

  const renderSearchResults = () => {
    return (
      <>
        {searchResults.map((item) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item.code_pays}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.nom}
            </span>
          </span>
        ))}
      </>
    );
  };

  const handleClickIncontournables = (index: number) => {
    setCodePaysClicked(selectedDestinations[index].code_pays);
    setIsModalIncontournablesOpen(true);
  }

  const closeModalIncontournables = () => {
    setIsModalIncontournablesOpen(false);
  }

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
      onClick={() => isModifiable && setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          { isModifiable && (
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={selectedDestinations.length > 0 ? t('autrePays') : t('pays')}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          )}
          {selectedDestinations.length > 0 && (
            <div className="mt-2 text-base font-medium text-neutral-700 dark:text-neutral-200">
              {isModifiable ? (
                selectedDestinations.map((country, index) => (
                  <span key={index} className="inline-block mr-1">
                    <a
                      href={`${t('lienGuide')}${country.code_pays}`}
                      className="mr-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <u>{country.nom}</u>
                    </a>
                    <button
                      type="button"
                      onClick={() => handleClickIncontournables(index)}
                      className="ml-1 text-red-500 focus:outline-none"
                    >
                      <i className="loupe las la-search"></i>
                    </button>
                    <button
                      type="button"
                      onClick={(e) => handleRemoveDestination(index, e)}
                      className="ml-1 text-red-500 focus:outline-none"
                    >
                      <i className="pointeurRose las la-trash"></i>
                    </button>
                    {index < selectedDestinations.length - 1 && " - "}
                  </span>
                ))
              ) : (
                selectedDestinations.map((country, index) => (
                  <span key={index} className="inline-block mr-1">
                    <a
                      href={`${t('lienGuide')}/${country.code_pays}`}
                      className="mr-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <u>{country.nom}</u>
                    </a>
                    <button
                    type="button"
                      onClick={() => handleClickIncontournables(index)}
                      className="ml-1 text-red-500 focus:outline-none"
                    >
                      <i className="loupe las la-search"></i>
                    </button>
                  </span>
                ))
              )}
            </div>
          )}

          {!value && selectedDestinations.length === 0 && (
            <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
              <span className="line-clamp-1">{t('ouAllezVous')}</span>
            </span>
          )}
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
              }}
            />
          )}
        </div>
      </div>

      {showPopover && value && (
        <div className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}></div>
      )}

      {showPopover && value && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value && renderSearchResults()}
        </div>
      )}

      
    {isModalIncontournablesOpen && (
       <ModalIncontournables
       onIncontournablesCloseModal={closeModalIncontournables} 
       codePays={codePaysClicked}
       isModif={isModifiable}
       nbJours={nbJours || 0}
       />
       )}


    </div>
  );
};

export default PaysInput;