import Button2, { Button2Props } from "shared/Button/Button2";
import React from "react";

export interface ButtonSquareProps extends Button2Props {}

const ButtonSquare: React.FC<ButtonSquareProps> = ({
  className = " ",
  ...args
}) => {
  return (
    <Button2
      className={`w-full mb-1 ttnc-ButtonSquare border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 ${className}`}
      {...args}
    />
  );
};

export default ButtonSquare;
