import axios from 'axios';
import { accountService } from './account.service'
import {urlAPI} from '../_config/constantes'; 

const Axios = axios.create({
    baseURL: urlAPI,
    withCredentials: true,
});

const apiKey = '4f9d3d3f6c4b6e87hgqb83a5f9d3d3f6c4b6e3a';

Axios.interceptors.request.use(request => {

    if(accountService.isLogged())
    {
        request.headers.Authorization = 'Bearer '+accountService.getToken();
    }
    request.headers['x-api-key'] = apiKey;
    return request;
})

export default Axios;