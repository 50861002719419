import Axios from './caller.service';
  
let getGuides = (langue) => {
  return Axios.get('/guides/'+langue);
  //return Axios.get('/guides/');
}

let getGuidesAnnee = (langue) => {
  return Axios.get('/guides/annee/'+langue);
}

let getGuidesMois = (numMois, langue) => {
  return Axios.get('/guides/mois/'+langue+'/'+numMois);
}

let getGuidePays = (codePays, langue) => {
  return Axios.get('/guides/pays/'+langue+'/'+codePays);
}

let getMonnaie = (codeMonnaie) => {
  return Axios.get('/guides/monnaie/'+codeMonnaie);
}

let getIncontournables = (codePays, langue) => {
  return Axios.get('/guides/incontournables/'+langue+'/'+codePays);
}

export const guidesService = {
    getGuides,
    getGuidesMois,
    getGuidesAnnee,
    getGuidePays,
    getMonnaie,
    getIncontournables
}