import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import React, { Fragment, useEffect, useState } from "react";
import { autocompleteService } from '_services/autocomplete.service';
import { useTranslation } from 'react-i18next';

const SearchDropdown = () => {
  const { t } = useTranslation();
  
  const inputRef = React.createRef<HTMLInputElement>();

  const [searchResults, setSearchResults] = useState<string[]>([]);

  const fetchSearchResults = async (valeur: string) => {

    if(valeur !== "")
    {
      try {

        const response = await autocompleteService.getRecherche(valeur);
        const data = await response.data;
  
        if(data && data.liste.length > 0)
        {
          setSearchResults(data.liste);
        }
        else
        {
          setSearchResults([]); 
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des résultats de recherche :", error);
      }
    }
    else
    {
      setSearchResults([]); 
    }

  };

  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open }) => {
          if (open) {
            setTimeout(() => {
              inputRef.current?.focus();
            }, 100);
          }

          return (
            <>
              <Popover.Button className="text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
                <i className="las la-search" onClick={() => setSearchResults([])} ></i>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute right-0 z-10 w-screen max-w-sm mt-3"
                >
                  <form action="" method="POST">
                    <Input
                      ref={inputRef}
                      type="search"
                      placeholder={t('rechercheTripkygo')}
                      onChange={(e) => {
                        fetchSearchResults(e.currentTarget.value);
                      }}
                    />
                    <input type="submit" hidden value="" />
                  </form>
                  <ul className="max-h-80 overflow-y-auto bg-white dark:bg-neutral-900 shadow-lg rounded-lg overflow-hidden">
                  {searchResults.map((result: any, index) => (
                    <li key={index} className="cursor-pointer border-b border-neutral-200 dark:border-neutral-700 last:border-b-0">
                      <a href={result.url} target="_blank" className="block py-2 px-4 text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800">
                        {result.libelle}
                      </a>
                    </li>
                  ))}
                </ul>

                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

export default SearchDropdown;
