import React, { useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { useNavigate } from "react-router-dom";
import { compteService } from "_services/compte.service";
import { useTranslation } from 'react-i18next';

interface RadioValues {
  [key: string]: boolean;
}


const CompteConfidentialite = () => {
  const { t } = useTranslation();

  const [radioValues, setRadioValues] = useState<RadioValues>({
    Ouiaffichage_hotels: false,
    Ouiaffichage_prix: false,
    Ouiaffichage_budget: false,
    Ouiaffichage_photos: false,
    Ouiaffichage_dates: false,
    Ouiaffichage_liens: false,
    Nonaffichage_hotels: false,
    Nonaffichage_prix: false,
    Nonaffichage_budget: false,
    Nonaffichage_photos: false,
    Nonaffichage_dates: false,
    Nonaffichage_liens: false,
  });
  let navigate=useNavigate();

  useEffect(() => {

    // Appeler l'API pour obtenir les informations sur le voyageur
    compteService.getProfil()
      .then((response) => {
        setRadioValues({
          Ouiaffichage_hotels: response.data[0]?.affichage_hotels === 'O',
          Ouiaffichage_prix: response.data[0]?.affichage_prix === 'O',
          Ouiaffichage_budget: response.data[0]?.affichage_budget === 'O',
          Ouiaffichage_photos: response.data[0]?.affichage_photos === 'O',
          Ouiaffichage_dates: response.data[0]?.affichage_dates === 'O',
          Ouiaffichage_liens: response.data[0]?.affichage_liens === 'O',
          Nonaffichage_hotels: response.data[0]?.affichage_hotels !== 'O',
          Nonaffichage_prix: response.data[0]?.affichage_prix !== 'O',
          Nonaffichage_budget: response.data[0]?.affichage_budget !== 'O',
          Nonaffichage_photos: response.data[0]?.affichage_photos !== 'O',
          Nonaffichage_dates: response.data[0]?.affichage_dates !== 'O',
          Nonaffichage_liens: response.data[0]?.affichage_liens !== 'O',
        });
      })
      .catch((error: any) => {

        console.error("Error fetching data:", error);
        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
          navigate(t('lienLogin'));
        }
        else
        {
          alert(t('alertRecupProfil'));
          navigate(t('lienHome'));
        }
      });
  }, []);


  const handleRadioChange = (option: keyof RadioValues) => {
    // Mise à jour de l'état local
    //setUpdatedProfile({ ...updatedProfile, [option]: value });

    let champModif='';
    let valeur='';
    let champInv='';
    if(String(option).startsWith("Oui"))
    {
      champModif=String(option).split("Oui")[1];
      valeur="O";
      champInv="Non"+champModif;
    }
    else
    {
      champModif=String(option).split("Non")[1];
      champInv="Oui"+champModif;
    }

    // Mettre à jour l'état local
    setRadioValues((prevRadioValues) => {
      const updatedRadioValues: RadioValues = { ...prevRadioValues, [option]: !prevRadioValues[option] } as RadioValues;
      return updatedRadioValues;
    });
  
    // Mettre à jour l'état local
    setRadioValues((prevRadioValues) => {
      const updatedRadioValues: RadioValues = { ...prevRadioValues, [champInv]: !prevRadioValues[champInv] } as RadioValues;
      return updatedRadioValues;
    });

    // Appel de l'API pour mettre à jour le profil
    compteService.updateProfil({ [champModif]: valeur })
      .then((response) => {
      })
      .catch((error: any) => {
        if (error.response?.status === 401) {
          alert(t('alertMajConnecte'));
          localStorage.removeItem('xsrfToken');
          navigate(t('lienLogin'));
        }
        else
        {
          alert(t('alertMajConnecte'));
        }
      });
  };

  const renderRadio = (
    name: keyof RadioValues,
    id: string,
    label: string,

  ) => {
    return (
      <div className="flex items-center">
        <input
          checked={radioValues[name]}
          id={id + name}
          name={name as string}
          type="radio"
          onChange={() => handleRadioChange(name)}
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t('confidentialiteVoyagesPublics')}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
           <div>
           <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
           {t('infoConfidentialiteVoyagesPublics')}
           </span>
          <br/>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <br/>
          <h2 className="text-xl font-semibold">
          {t('info2ConfidentialiteVoyagesPublics')}
          </h2>
          <br/>
        </div>
        {/* FORM */}
          <div className="space-y-8">
            {/* ITEM */}
              <div>
                <label className="text-lg font-semibold" htmlFor="">
                {t('affichageHebergements')}
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {renderRadio("Ouiaffichage_hotels", t('oui'), t('oui'))}
                {renderRadio("Nonaffichage_hotels", t('non'), t('non'))} </div>
              </div>
              <div>
                <label className="text-lg font-semibold" htmlFor="">
                {t('affichagePrix')}
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {renderRadio("Ouiaffichage_prix", t('oui'), t('oui'))}
                {renderRadio("Nonaffichage_prix", t('non'), t('non'))}
                </div>
              </div>
              <div>
                <label className="text-lg font-semibold" htmlFor="">
                {t('affichageBudget')}
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {renderRadio("Ouiaffichage_budget", t('oui'), t('oui'))}
                {renderRadio("Nonaffichage_budget", t('non'), t('non'))}
                </div>
              </div>
              <div>
                <label className="text-lg font-semibold" htmlFor="">
                {t('affichagePhotos')}
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {renderRadio("Ouiaffichage_photos", t('oui'), t('oui'))}
                {renderRadio("Nonaffichage_photos", t('non'), t('non'))}
                </div>
              </div>
              <div>
                <label className="text-lg font-semibold" htmlFor="">
                {t('affichageDates')}
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {renderRadio("Ouiaffichage_dates", t('oui'), t('oui'))}
                {renderRadio("Nonaffichage_dates", t('non'), t('non'))}
                </div>
              </div>
              <div>
                <label className="text-lg font-semibold" htmlFor="">
                {t('affichageLiens')}
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {renderRadio("Ouiaffichage_liens", t('oui'), t('oui'))}
                {renderRadio("Nonaffichage_liens", t('non'), t('non'))}
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default CompteConfidentialite;
