import React, { FC, useEffect, useRef, useState } from 'react';
import Datamap from 'datamaps';

interface CarteMondeProps {
    data: any;
    responsivite: any;
  }

const CarteMonde: FC<CarteMondeProps> =({ data, responsivite }) => {
  const mapContainer = useRef(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
 
  useEffect(() => {

    const loadScript = (src: any) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = src;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
      };

    const loadScripts = async () => {

      try {
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.3/d3.min.js');
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/topojson/1.6.9/topojson.min.js');
        
        if (mapContainer.current) {
          new Datamap({
            element: mapContainer.current,
            projection: 'mercator',
            fills: { defaultFill: '#FFFFFF' },
            data: data,
            geographyConfig: {
              borderColor: '#DEDEDE',
              highlightBorderWidth: 2,
              highlightFillColor: function(geo: any) {
                return geo['fillColor'] || '#f0cdcf';
              },
              highlightBorderColor: '#B7B7B7',
              popupTemplate: function(geo: any, data: any) {
                if (!data) {
                  return;
                }
                return `<div class="hoverinfo"><strong>${data.nom}</strong></div>`;
              },
            },
          });
          setIsLoaded(true);
        }
      } catch (error) {
        console.error('Failed to load scripts', error);
      }
    };
    
    if(data && !isLoaded)
    {
        loadScripts();
    }
    return () => {
        setIsLoaded(false);
        if (mapContainer.current) {
            const mapContainerElement = mapContainer.current as HTMLDivElement;
            if (mapContainerElement) {
                mapContainerElement.innerHTML = ''; // Supprimer le contenu de la div
            }
        }
    };
  }, [data, responsivite]);

  return <div ref={mapContainer} className="carteMonde"/>;
};

export default CarteMonde;
