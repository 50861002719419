import React, { FC, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import SectionStatistiques from "components/SectionStatistiques/SectionStatistiques";
import AppContext from 'AppContext';
import { compteService } from "../../_services/compte.service";
import { useTranslation } from 'react-i18next';

export interface PageStatistiquesProps {
  className?: string;
}

const PageStatistiques: FC<PageStatistiquesProps> = ({ className = "" }) => {

  const { setVoyageInfo,  setAfficheMenuMobile } = useContext(AppContext);
  const [stats, setStats] = useState<any>();
  
  const { t } = useTranslation();

  let navigate = useNavigate();

  useEffect( () => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []);


  useEffect( () => {
      compteService
        .getStatistiques()
        .then((response) => {

          setStats(response.data);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des stats:", error);
          navigate('/');
        });
  }, []);
 
  return (
    <div
      className={`nc-PageStatistiques ${className}`}
      data-nc-id="PageStatistiques"
    >
      <Helmet>
        <title>{t('statistiques')}</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 mt-10 lg:mt-12 px-3 md:px-6">
        <SectionStatistiques stats={stats} />
      </div>
</div>
  );
};

export default PageStatistiques;
