import React, { FC, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";


export interface ConvertisseurMonnaieProps {
  onConvertisseurCloseModal: () => void;
}

const ConvertisseurMonnaie: FC<ConvertisseurMonnaieProps> = ({
  onConvertisseurCloseModal
}) => {

  const handleCloseModalConvertisseur = () => {
      onConvertisseurCloseModal();

  };
    return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalConvertisseur}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
              <div
                className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
              >

<span className="absolute left-3 top-3">
                      <ButtonClose onClick={handleCloseModalConvertisseur} 
                      />
                    </span>

                <div className="px-8 pt-12 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                <iframe loading="lazy" title="Currency Converter Widget" src="https://currencyrate.today/load-converter?&amp;lg=fr&amp;tz=1&amp;fm=EUR&amp;to=USD&amp;st=primary&amp;lr=0&amp;rd=0" width="100%" height="289" frameBorder="0" scrolling="no"></iframe>
                  <div style={{ textAlign: "right" }}>
                    <a className="text-xs" href="https://currencyrate.today">Fourni par CurrencyRate</a>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConvertisseurMonnaie;
