import React, { FC, useState, useEffect } from "react";
import DetailPagetLayout from "./Layout";
import Photos from "./Photos";
import { JourneeType } from "data/types";
import type { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import Axios from '../../_services/caller.service';

interface PhotosPageProps {
  eventCategoriesPage: JourneeType[];
  numDroit: number;
}

const PhotosPage: React.FC<PhotosPageProps> = ({ eventCategoriesPage, numDroit }) => {

  const [images, setImages] = useState<ListingGalleryImage[] | null>(null);

  useEffect(() => {
    const fetchImages = async () => {
      const imagePromises = eventCategoriesPage.flatMap((day, dayIndex) => 
        day.evenements.map(async (event, eventIndex) => {
          if (event.image && event.image !== "") {
            try {
              const response = await Axios.get(event.image, { responseType: 'blob' });
              const blob = new Blob([response.data], { type: 'image/jpeg' });
              const url = URL.createObjectURL(blob);

              let libelle="";

              if(event.typeEv === "N")
              {
                libelle="Nuit "+day.num_jour;
                if(event.libelle1 !== null && event.libelle1 !== "")
                {
                  libelle+=" · "+event.libelle1
                }
                if(event.libelle2 !== null && event.libelle2 !== "")
                {
                  if(event.libelle1 !== null && event.libelle1 !== "")
                  {
                    libelle+=" - "+event.libelle2
                  }
                  else
                  {
                    libelle+=" · "+event.libelle2
                  }
                }
              }
              else
              {
                libelle="Jour "+day.num_jour;
                if(event.libelle1 !== null && event.libelle1 !== "")
                {
                  libelle+=" · "+event.libelle1
                }
              }



               // Crée l'objet image
              const image = {
                url,
                libelle: libelle,
              };

              return image; // Retourne l'image
            } catch (error) {
              console.error('Erreur lors du chargement de l\'image', error);
              return null; 
            }
          }
          return null; 
        })
      );

      const resolvedImages = await Promise.all(imagePromises);
      // Filtre les images nulles et attribue les ID
      const imagesWithId = resolvedImages
        .filter((image): image is { url: string; libelle: string } => image !== null)
        .map((image, index) => ({
          id: index, // Attribue l'ID en fonction de l'ordre dans le tableau
          ...image,
        }));
  
      setImages(imagesWithId);
    };

    fetchImages();

    // Nettoyage : Révoque les URL lorsque le composant est démonté
    return () => {
      if(images)
      {
        images.forEach(image => {
          if (image) {
            URL.revokeObjectURL(image.url);
          }
        });
      }

    };
  }, [eventCategoriesPage]);

  return (
    <DetailPagetLayout images={images} >
      <Photos images={images} numDroit={numDroit}/>
    </DetailPagetLayout>
  );
};

export default PhotosPage;