import React, { FC, Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useTranslation } from 'react-i18next';

export interface TabFiltersProps {
  onCountryChange: (country: string | null) => void;
  onUserChange: (user: string | null) => void;
  onTriChange: (user: string | null) => void;
  tabs?: string[];
  tabsUsers?: string[];
  pays?: string;
}

const typeOfTris = [
  {
    name: "triPubCroi",
    translationKey: "triPubCroi",
  },
  {
    name: "triPubDecroi",
    translationKey: "triPubDecroi",
  },
  {
    name: "triCreaCroi",
    translationKey: "triCreaCroi",
  },
  {
    name: "triCreaDecroi",
    translationKey: "triCreaDecroi",
  },
  {
    name: "triNbJCroi",
    translationKey: "triNbJCroi",
  },
  {
    name: "triNbJDecroi",
    translationKey: "triNbJDecroi",
  },
  {
    name: "triNbLCroi",
    translationKey: "triNbLCroi",
  },
  {
    name: "triNbLDecroi",
    translationKey: "triNbLDecroi",
  },
];



const TabFilters: FC<TabFiltersProps> = ({ onCountryChange = () => {}, tabs = [], onUserChange = () => {}, tabsUsers = [], onTriChange = () => {}, pays  }) => {

  const [isCountrySelected, setIsCountrySelected] = useState(false);

  const [isUserSelected, setIsUserSelected] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if(pays && pays !== '')
    {
      setIsCountrySelected(true);
    }
   }, [pays])

  const renderTabsTris = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              } border-neutral-300 dark:border-neutral-700"`}
            >
              <span >{t('tri')}</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    {typeOfTris.map((tri) => (
                      <div
                        key={tri.name}
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={() => { 
                          onTriChange(tri.name === "all" ? null : tri.name);
                          close(); // Close the popover after selecting a user
                        }}
                      >
                           {t(tri.translationKey)}
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsUsers = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }
              ${
                isUserSelected
                  ? "border-primary-500 bg-primary-50 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              }`}
            >
              <span>{t('utilisateur')}</span>
              <i className="las la-angle-down ml-2"></i>
              {isUserSelected && renderXClearUser(close)}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-y-auto max-h-screen rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    {tabsUsers.map((user) => (
                      <div
                        key={user}
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={() => { 
                          onUserChange(user === "all" ? null : user);
                        setIsUserSelected(true);
                          close(); // Close the popover after selecting a user
                        }}
                      >
                        {user}
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  

  const renderCountryDropdown = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              } ${
                isCountrySelected
                  ? "border-primary-500 bg-primary-50 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              }`}
            >
              <span>{t('pays')}</span>
              <i className="las la-angle-down ml-2"></i>
              {isCountrySelected && renderXClearCountry(close)}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-y-auto max-h-screen rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {/* Country checkboxes */}
                    {tabs.map((country) => (
                      <div key={country} className="" style={{ cursor: "pointer" }} onClick={() => {
                        onCountryChange(country === "all" ? null : country);
                        setIsCountrySelected(true);
                        close(); // Close the popover after selecting a country
                      }}>{country}
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderXClearCountry = (close: () => void) => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer" 
      onClick={(e) => {
        e.stopPropagation();
        onCountryChange(null);
        setIsCountrySelected(false);
       close();
      }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderXClearUser = (close: () => void) => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer" 
      onClick={(e) => {
        e.stopPropagation();
        onUserChange(null);
        setIsUserSelected(false);
       close();
      }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabsTris()}
        {renderCountryDropdown()}
        {renderTabsUsers()}
      </div>
      <div className="flex lg:hidden space-x-4">
        {renderTabsTris()}
        {renderCountryDropdown()}
        {renderTabsUsers()}
      </div>
    </div>
  );
};

export default TabFilters;
