import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { accountService } from "_services/account.service"

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {

    if (!accountService.isLogged()) {
        return <Navigate to="../login" />;
    }

    return <>{children}</>;
};

export default AuthGuard;
