import React, { FC, Fragment , useState} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { voyageService } from '_services/voyage.service';
import Axios from '_services/caller.service';
import { useNavigate } from "react-router-dom";

interface ModalPhotoModifProps {
  editedPhoto: string;
  editedPhotoLien: string;
  nomEvenement: string;
  typeEvenement: string;
  idVoyage: string;
  idJournee: number;
  index: string;
  id: string;
  majPhoto: (nomPhoto: string) => void;
  majPhotoLien: (nomPhotoLien: string) => void;
  onPhotoModifClose: () => void;
  onDisplayElementChange?: (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement:string) => void;
}

const ModalPhotoModif: React.FC<ModalPhotoModifProps> = ({ editedPhoto, editedPhotoLien, nomEvenement, typeEvenement, idVoyage, idJournee, index, id, majPhoto, majPhotoLien, onPhotoModifClose, onDisplayElementChange }) => {

  let navigate=useNavigate();

  const handleCloseModalPhotoModif = () => {
    onPhotoModifClose();

};

const handlePhotoDelete = async () => {
  
  const fileName = editedPhotoLien.split("/photo/")[1];

  // Handle file upload to the server or save it locally
  try {

    voyageService.supprimerPhoto(idVoyage, fileName)
  .then((response: any) => {

    majPhoto("");
    majPhotoLien("");
    if(onDisplayElementChange)
    {
      // Appeler la fonction de rappel pour propager les changements
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'image');
    }

 })
  .catch((error: any) => {
    if (error.response?.status === 401) {
      alert("Erreur lors de la suppression de l'image, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de la suppression de l'image");
    }
    console.error('Error maj image:', error);
  });

    // Optionally, update the user's profile data with the new avatar filename
    //await saveChanges("avatar", fileName);
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Erreur lors du téléchargement du fichier.");
  }
};


const handlePhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const fileInput = event.target;
  const file = fileInput.files?.[0];

  let imageURL: string | undefined;

  if (file) {
    // Check file type
    if (!/\/(jpeg|jpg)$/.test(file.type)) {
      alert("Veuillez sélectionner un fichier JPEG ou JPG.");
      fileInput.value = ""; // Clear the file input
      return;
    }

    // Check file size
    const maxSize = 8 * 1024 * 1024; // 7MB
    if (file.size > maxSize) {
      alert("La taille du fichier ne doit pas dépasser 8 Mo.");
      fileInput.value = ""; // Clear the file input
      return;
    }

    // Assuming you have some way to get the user's identifiant
    const fileName = `photo_${index?.split('-')[0]}_${index?.split('-')[1]}.${file.type.split("/")[1]}`;

    // Handle file upload to the server or save it locally
    try {
      // Example using the Fetch API to upload the file (modify as needed)
      const formData = new FormData();
      formData.append("photo", file, fileName);

      voyageService.uploadPhoto(idVoyage, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
    .then((response: any) => {
      majPhotoLien(response.data.photoInfo.url);

      Axios.get(response.data.photoInfo.url, { responseType: 'blob' })
      .then((response: any) => {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        imageURL = URL.createObjectURL(blob);
        majPhoto(imageURL);
      })
      .catch((error: any) => console.error('Erreur lors du chargement de l\'image', error));

      if(onDisplayElementChange)
      {
        // Appeler la fonction de rappel pour propager les changements
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], response.data.photoInfo.url, 'image');
      }
   })
    .catch((error: any) => {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de l'image, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de l'image");
      }
      console.error('Error maj image:', error);
    });

      // Optionally, update the user's profile data with the new avatar filename
      //await saveChanges("avatar", fileName);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Erreur lors du téléchargement du fichier.");
    }
  }
  
  return () => {
    if (imageURL) {
      URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
    }
  };
};

  return (
    <Transition appear as={Fragment} show={true}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={handleCloseModalPhotoModif}
    >
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
            <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                  id="headlessui-dialog-title-70"
                >
                  Ajout/modification d'une Photo
                </h3>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={handleCloseModalPhotoModif} />
                </span>
              </div>
              <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="mt-5 w-full text-center">
                    
                  { !editedPhoto && (
                  <>
                    <form encType="multipart/form-data">
                    <input
                      name='photo'
                      type="file"
                      className="cursor-pointer"
                      accept="image/jpeg, image/jpg"
                      onChange={handlePhotoChange}
                    />
                    </form>

                    <div className="text-xxs text-center mt-3">
                      .jpeg et jpg acceptés, 8 Mo max
                    </div>
                  </>
                    )}

                    {editedPhoto && (
                      <>
                            <img
                              //className={`pointeur absolute inset-0 w-full h-full object-cover ${radius}`}
                              src={`${editedPhoto}`}
                              alt={nomEvenement}
                              //onClick={handleAvatarClick}
                            />
                            <ButtonPrimary className="mt-3 mb-5" onClick={handlePhotoDelete}
                            >Supprimer</ButtonPrimary>
                      </>
                    )}
                    
                  </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
  );
};

export default ModalPhotoModif;
