import React, { FC, useState, useEffect } from "react";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import BadgeGuides from "shared/BadgeGuides/BadgeGuides";
import convertNumbThousand from "utils/convertNumbThousand";
import { useTranslation } from 'react-i18next';

export interface CardGuidesProps {
  className?: string;
  taxonomy: any;
  categories?:any
}

const CardGuides: FC<CardGuidesProps> = ({
  className = "",
  taxonomy,
  categories
}) => {
  const { t } = useTranslation();
  
  const { count, nom, thumbnail, href = "/", code } = taxonomy;
  
  return (
    <Link
      to={`${t('lienGuide')}/${code}`}
      className={`nc-CardGuides pointeurRose relative flex items-center p-3 sm:p-3 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardGuides"
    >
     { categories && (
        <BadgeGuides 
        className="absolute right-2 top-2"
        color="gray"
        name={categories}
        />
     )}

      <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
        <NcImage src={require(`../../images/countries/${code}.webp`)} containerClassName="absolute inset-0" />
      </div>
      <div className="ml-4 flex-grow overflow-hidden text-center">
        <h2 className="text-base font-medium">
          <span className="">{nom}</span>
        </h2>
        <span
          className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}
        >
        </span>
      </div>
    </Link>
  );
};

export default CardGuides;
