import React, { Fragment, useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ClearDataButton from "./ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);
setDefaultLocale("fr");

export interface DateInputProps {
  className?: string;
  fieldClassName?: string;
  onSelectDate?: (date: Date | null) => void;
  date?: Date | null,
  libelle: string;
}


const DateInput: FC<DateInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding ]",
  onSelectDate,
  date,
  libelle
}) => {
  const [internalStartDate, setInternalStartDate] = useState<Date | null>(date ?? null);

  //


  const onChangeDate = (date: Date | null, closePopover: () => void) => {

    setInternalStartDate(date);
    if(onSelectDate)
    {
      onSelectDate(date);  
    }

    closePopover();
  }

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-400 dark:text-neutral-500">
          <CalendarIcon className="w-3 h-3 lg:w-4 lg:h-4" />
        </div>
        <div className="flex-grow text-left">
          <span className="block text-xs">
            {internalStartDate?.toLocaleDateString("fr-FR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }) || ""}
          </span>
          <span className="block mt-1 text-xs text-neutral-400 leading-none font-light">
            {libelle}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`DateInput z-10 relative flex ${className}`}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex-1 z-10 flex relative  items-center space-x-3 focus:outline-none`}
            onClickCapture={() => document.querySelector("html")?.click()}
          >
            {renderInput()}
            {internalStartDate && open && (
              <ClearDataButton onClick={() => onChangeDate(null, close)} />
            )}
          </Popover.Button>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={internalStartDate}
                  onChange={(date) => onChangeDate(date, close)}
                  selectsRange={false} 
                  monthsShown={2}
                  showPopperArrow={false}
                  inline
                  shouldCloseOnSelect={true}
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                  locale="fr"
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default DateInput;
