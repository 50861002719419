import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { autocompleteService } from '_services/autocomplete.service';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string; 
  autoFocus?: boolean;
  onSelectDestination?: (codePays: string, pays: string, ville: string, place_id: string, latitude: string, longitude: string) => void;
  onRemoveDestination?: (index: number) => void;
  destinationsEnregistrees: Destination[];
  isPlanif: boolean;
  onClickPays?: (codePays: string) => void;
}

export type Destination = {
  code_pays: string;
  pays: string;
  ville: string;
  place_id: string,
  latitude: string,
  longitude: string,
};

type Pays = {
  code_pays: string;
  nom: string;
};

const PaysInputMobile: FC<Props> = ({
  onChange = () => {},
  autoFocus = false,
  className = "",
  defaultValue = "United States",
  headingText = "Où allez-vous ?",
  onSelectDestination,
  onRemoveDestination,
  destinationsEnregistrees,
  isPlanif,
  onClickPays

}) => {
  const { t } = useTranslation();

  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }


  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [selectedDestinations, setSelectedDestinations] = useState<Pays[]>([]);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [searchResults, setSearchResults] = useState<Pays[]>([]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && value && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
     // inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    if (value) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [value]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
   /*if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }*/
    setShowPopover(false);
  };

  const handleSelectLocation = (item: Pays) => {
    const isAlreadySelected = selectedDestinations.some(
      (destination) => destination.code_pays === item.code_pays
    );
  
    if (isAlreadySelected) {
      alert(
        "Vous avez déjà saisi ce pays, veuillez saisir le pays suivant ou passer à la saisie des autres critères !"
      );
      return;
    }
    
    if (onSelectDestination) {
      onSelectDestination(item.code_pays,item.nom, '', '', '', '')
    }
    setSelectedDestinations([...selectedDestinations, item]);
    setValue("");
    setSearchResults([]);
    setShowPopover(false);
  };
  

  const handleRemoveDestination = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {

    event.preventDefault();
  
    if (onRemoveDestination) {
      onRemoveDestination(index);
    }

    const updatedDestinations = [...selectedDestinations];
    updatedDestinations.splice(index, 1);
    setSelectedDestinations(updatedDestinations);
  };

  const fetchSearchResults = async () => {
    try {
      const response = await autocompleteService.getPays(value, langue);
      const data = await response.data;

      if (data && data.rows) {
        const formattedResults: Pays[] = data.rows.map((row: any) => ({
          code_pays: row.code_pays,
          nom: row.nom,
        }));
        setSearchResults(formattedResults);
      }

    } catch (error) {
      console.error("Erreur lors de la récupération des résultats de recherche :", error);
    }
  };

  const handleClickIncontournables = (index: number) => {
    if(onClickPays)
    {
      onClickPays(destinationsEnregistrees[index].code_pays);
    }
  }


  const renderSearchResults = () => {
    return (
      <>
        {searchResults.map((item: Pays) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item.code_pays}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.nom}
            </span>
          </span>
        ))}
      </>
    );
  };

 /* useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);*/

  /*const handleSelectLocation = (item: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item);
      onChange && onChange(item);
    }, 0);
  };*/

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: Pays[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(item)}
                key={item.code_pays}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item.nom}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderDestSaved = ({
    heading,
    items,
  }: {
    heading: string;
    items: Destination[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item, index: number) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                key={item.code_pays}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                
                <span className="">
                <a
                href={`${t('lienGuide')}/${item.code_pays}`}
                className="mr-1"
                target="_blank"
                rel="noreferrer"
              >
                <u>{item.pays}</u>
              </a>
              { isPlanif && (<button
                        onClick={() => handleClickIncontournables(index)}
                        className="ml-1 text-red-500 focus:outline-none"
                      >
                        <i className="loupe las la-search"></i>
                      </button>)}
              <button
                onClick={(e) => handleRemoveDestination(index, e)}
                 className="ml-1 text-red-500 focus:outline-none"
              >
                <i className="pointeurRose las la-trash"></i>
              </button>
                {index < selectedDestinations.length - 1 && " - "}
              </span>
              </div>
            );
          })}
        </div>
      </>
    );
  };


  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
        {t('ouAllezVous')}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={destinationsEnregistrees.length > 0 ? t('autrePays') : t('saisirPays')}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: searchResults.length === 0 ? t('aucunResultat') : searchResults.length === 1 ? t('resultat') :t('resultat')+"s",
                items: searchResults,
              })
            : null }
            {/*renderSearchValues({
                heading: "Popular destinations",
                items: [
                  "Australia",
                  "Canada",
                  "Germany",
                  "United Kingdom",
                  "United Arab Emirates",
                ],
              })*/}
              {destinationsEnregistrees.length > 0
            ? renderDestSaved({
                heading: destinationsEnregistrees.length === 1 ? t('destinationEnregistree') : t('destinationsEnregistrees'),
                items: destinationsEnregistrees,
              })
            : null }
            {/*renderSearchValues({
                heading: "Popular destinations",
                items: [
                  "Australia",
                  "Canada",
                  "Germany",
                  "United Kingdom",
                  "United Arab Emirates",
                ],
              })*/}
        </div>
      </div>
      
    </div>
  );
};

export default PaysInputMobile;
