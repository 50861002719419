import React from "react";
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Page } from "./types";
import { AppProvider } from "../AppContext";
import ScrollToHash from "./ScrollToHash";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PagePlanificateur from "containers/PagePlanificateur/PagePlanificateur";
import PagePlanificateurIntegre from "containers/PagePlanificateurIntegre/PagePlanificateurIntegre";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import CompteGestion from "containers/AccountPage/CompteGestion";
import ValidationLiens from "containers/AccountPage/ValidationLiens";
import CompteConfidentialite from "containers/AccountPage/CompteConfidentialite";
import PageContact from "containers/PageContact/PageContact";
import PageMentionsLegales from "containers/PageMentionsLegales/PageMentionsLegales";
import PageGuides from "containers/PageGuides/PageGuides";
import PageGuide from "containers/PageGuide/PageGuide";
import PageStatistiques from "containers/PageStatistiques/PageStatistiques";
import PageOuPartir from "containers/PageOuPartir/PageOuPartir";
import PageOuPartirMois from "containers/PageOuPartirMois/PageOuPartirMois";
import PageAbout from "containers/PageAbout/PageAbout";
import PageAide from "containers/PageAide/PageAide";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import VoyageurPage from "containers/VoyageurPage/VoyageurPage";
import MesVoyages from "containers/MesVoyages/MesVoyages";
import VoyagesPartages from "containers/VoyagesPartages/VoyagesPartages";
import ReservationTransport from "containers/ReservationPage/ReservationTransport";
import ReservationHebergement from "containers/ReservationPage/ReservationHebergement";
import LocationVoiture from "containers/ReservationPage/LocationVoiture";
import ReservationActivite from "containers/ReservationPage/ReservationActivite";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import AuthGuard from "components/AuthGard";
import './i18n'; 
import { useTranslation } from 'react-i18next';

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home", exact: true, component: PageHome },
  { path: "/voyages/:ancre", exact: true, component: PageHome },
  { path: "/voyages/pays/:pays", exact: true, component: PageHome },

  { path: "/planificateur-voyage/:voyageKey", component: PagePlanificateur },
  { path: "/planificateur-voyage", component: PagePlanificateur },
  { path: "/planificateur-voyage/:voyageKey/email/:emailPartage/code/:codePartage", component: PagePlanificateur },
  { path: "/itineraire-voyage-integre/:voyageKey/:vue", component: PagePlanificateurIntegre },

  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
 
  { path: "/gestion-compte", component: CompteGestion },
  { path: "/compte-confidentialite", component: CompteConfidentialite },

  { path: "/validation-liens", component: ValidationLiens },

  { path: "/aide", component: PageAide },
  { path: "/contact", component: PageContact },
  { path: "/mentions-legales", component: PageMentionsLegales },
  { path: "/a-propos", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/login/:emailReinit/:codeReinit", component: PageLogin },

  { path: "/voyageur/:id", component: VoyageurPage },
  { path: "/mes-voyages", component: MesVoyages },
  { path: "/voyages-partages", component: VoyagesPartages },
  { path: "/guides-pays", component: PageGuides },
  { path: "/guide-pays/:codePays", component: PageGuide },
  { path: "/ou-partir", component: PageOuPartir },
  { path: "/ou-partir-en-janvier/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-fevrier/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-mars/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-avril/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-mai/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-juin/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-juillet/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-aout/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-septembre/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-octobre/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-novembre/:numMois", component: PageOuPartirMois },
  { path: "/ou-partir-en-decembre/:numMois", component: PageOuPartirMois },
  { path: "/reservation", component: ReservationHebergement },
  { path: "/reservation-transport", component: ReservationTransport },
  { path: "/reservation-hebergement", component: ReservationHebergement },
  { path: "/location-voiture", component: LocationVoiture },
  { path: "/reservation-activite", component: ReservationActivite },
  { path: "/mes-statistiques", component: PageStatistiques },

  { path: "/en", exact: true, component: PageHome },
  { path: "/en#", exact: true, component: PageHome },
  { path: "/en/travels/:ancre", exact: true, component: PageHome },
  { path: "/en/travels/country/:pays", exact: true, component: PageHome },

  { path: "/en/travel-planning/:voyageKey", component: PagePlanificateur },
  { path: "/en/travel-planning", component: PagePlanificateur },
  { path: "/en/travel-itinerary-embed/:voyageKey/:vue", component: PagePlanificateurIntegre },

  { path: "/en/account", component: AccountPage },
  { path: "/en/account-password", component: AccountPass },
 
  { path: "/en/account-management", component: CompteGestion },
  { path: "/en/account-confidentiality", component: CompteConfidentialite },

  { path: "/en/help", component: PageAide },
  { path: "/en/contact", component: PageContact },
  { path: "/en/legal-notice", component: PageMentionsLegales },
  { path: "/en/about-us", component: PageAbout },
  { path: "/en/signup", component: PageSignUp },
  { path: "/en/login", component: PageLogin },
  { path: "/en/login/:emailReinit/:codeReinit", component: PageLogin },

  { path: "/en/traveler/:id", component: VoyageurPage },
  { path: "/en/my-travels", component: MesVoyages },
  { path: "/en/shared-travels", component: VoyagesPartages },
  { path: "/en/country-guides", component: PageGuides },
  { path: "/en/country-guide/:codePays", component: PageGuide },
  { path: "/en/where-to-go", component: PageOuPartir },
  { path: "/en/where-to-go-in-january/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-february/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-march/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-april/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-may/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-june/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-july/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-august/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-september/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-october/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-november/:numMois", component: PageOuPartirMois },
  { path: "/en/where-to-go-in-december/:numMois", component: PageOuPartirMois },
  { path: "/en/book", component: ReservationHebergement },
  { path: "/en/book-transport", component: ReservationTransport },
  { path: "/en/book-accomodation", component: ReservationHebergement },
  { path: "/en/rent-car", component: LocationVoiture },
  { path: "/en/book-activity", component: ReservationActivite },
  { path: "/en/my-statistics", component: PageStatistiques },
];

  const RoutesComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n } = useTranslation();

    let WIN_WIDTH = useWindowSize().width;
    if (typeof window !== "undefined") {
      WIN_WIDTH = WIN_WIDTH || window.innerWidth;
    }
  const isPlanificateurPage = location.pathname.startsWith('/itineraire-voyage-integre') || location.pathname.startsWith('/en/travel-itinerary-embed');
  
  React.useEffect(() => {
    //redirection des url de tripkygo v1
    if(location.pathname.endsWith('.html'))
    {
      if (location.pathname.match(/^\/planificateur-voyage-([0-9]+)\.html$/)) {
        const id = location.pathname.split('-')[2]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/planificateur-voyage/${id}`, { replace: true });
      }
      else if(location.pathname.match(/^\/itineraire-integre-consult-([0-9]+)\.html$/)) {
        const id = location.pathname.split('-')[3]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/itineraire-voyage-integre/${id}/planning`, { replace: true });
      }
      else if(location.pathname.match(/^\/itineraire-integre-photos-([0-9]+)\.html$/)) {
        const id = location.pathname.split('-')[3]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/itineraire-voyage-integre/${id}/planning`, { replace: true });
      }
      else if(location.pathname.match(/^\/itineraire-integre-budget-([0-9]+)\.html$/)) {
        const id = location.pathname.split('-')[3]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/itineraire-voyage-integre/${id}/budget`, { replace: true });
      }
      else if(location.pathname.match(/^\/itineraire-integre-carte-([0-9]+)\.html$/)) {
        const id = location.pathname.split('-')[3]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/itineraire-voyage-integre/${id}/carte`, { replace: true });
      }
      else if(location.pathname.match(/^\/ou-partir\.html$/)) {
        navigate(`/ou-partir`, { replace: true });
      }
      else if(location.pathname.match(/^\/guides-pays\.html$/)) {
        navigate(`/guides-pays`, { replace: true });
      }
      else if(location.pathname.match(/^\/guide-([a-zA-Z]+)-([a-zA-Z]+)\.html$/)) {
        const id = location.pathname.split('-')[2]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/guide-pays/${id}`, { replace: true });
      }
      else if (location.pathname.match(/^\/en\/travel-planning-([0-9]+)\.html$/)) {
        const id = location.pathname.split('-')[2]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/en/travel-planning/${id}`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/where-to-go\.html$/)) {
        navigate(`/en/where-to-go`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/country-guides\.html$/)) {
        navigate(`/en/country-guides`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/guide-([a-zA-Z]+)-([a-zA-Z]+)\.html$/)) {
        const id = location.pathname.split('-')[2]?.split('.')[0]; // Extraire l'id de l'ancienne URL
        navigate(`/en/country-guide/${id}`, { replace: true });
      }
      else if(location.pathname.match(/^\/reservation-transport\.html$/)) {
        navigate(`/reservation-transport`, { replace: true });
      }
      else if(location.pathname.match(/^\/reservation-hebergement\.html$/)) {
        navigate(`/reservation-hebergement`, { replace: true });
      }
      else if(location.pathname.match(/^\/reservation-activite\.html$/)) {
        navigate(`/reservation-activite`, { replace: true });
      }
      else if(location.pathname.match(/^\/location-voiture\.html$/)) {
        navigate(`/location-voiture`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/booking-transport\.html$/)) {
        navigate(`/book-transport`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/booking-accomodation\.html$/)) {
        navigate(`/book-accomodation`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/booking-activity\.html$/)) {
        navigate(`/book-activity`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/rent-car\.html$/)) {
        navigate(`/rent-car`, { replace: true });
      }
    } 
    else if(location.pathname.includes('.php'))
    {
      if(location.pathname.match(/^\/tutoriels\.php$/)) {
        navigate(`/aide`, { replace: true });
      }
      else if(location.pathname.match(/^\/contact\.php$/)) {
        navigate(`/contact`, { replace: true });
      }
      else if(location.pathname.match(/^\/index\.php$/)) {
        const params = new URLSearchParams(location.search);
        if (params.has('idPays') && params.get('idPays') === 'IN') {
          navigate(`/voyages/pays/Inde`, { replace: true });
        }
        else if (params.has('idPays') && params.get('idPays') === 'US') {
          navigate(`/voyages/pays/Etats-Unis%20d'Amérique`, { replace: true });
        }
        else
        {
          navigate(`/`, { replace: true });
        }
      }
      else if(location.pathname.match(/^\/planificateur_embed\.php$/)) {
        navigate(`/`, { replace: true });
      }
      else if(location.pathname.match(/^\/en\/index\.php$/)) {
        navigate(`/`, { replace: true });
      }
    }
  }, [location.pathname, navigate]);

  React.useEffect(() => {
    if (location.pathname.startsWith('/en')) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('fr');
    }
  }, [location, i18n]);

    return (
      <>
        <ScrollToTop />
        <ScrollToHash />
        {!isPlanificateurPage && <SiteHeader />}
  
        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            if(path === '/account' || path === '/compte-confidentialite' || path === '/account-password' || path === '/gestion-compte' || path === '/mes-voyages' || path === '/voyages-partages' || path === '/mes-statistiques' 
            || path === '/en/account' || path === '/en/account-confidentiality' || path === '/en/account-password' || path === '/en/account-management' || path === '/en/my-travels' || path === '/en/shared-travels' || path === '/en/my-statistics')
            {
              return <Route key={path} element={<AuthGuard><Component /></AuthGuard>} path={path} />;
            }
            else {
              return <Route key={path} element={<Component />} path={path} />;
            }
  
          })}
          
          <Route path="*" element={<Page404 />} />
        </Routes>
        <br/>
        <br/>
        {WIN_WIDTH < 768 && !isPlanificateurPage && <FooterNav />}
        {!isPlanificateurPage && <Footer />}
      </>
    );
  };

const MyRoutes = () => {
  return (
    <AppProvider>
      <BrowserRouter basename="/">
        <RoutesComponent />
      </BrowserRouter>
    </AppProvider>
  );
};

export default MyRoutes;
