import React, { useState, useEffect } from "react";
import CommonLayout from "./CommonLayout";
import { compteService } from "_services/compte.service";
import { voyageService } from "_services/voyage.service";
import { useNavigate } from "react-router-dom";
import Textarea from "shared/Textarea/Textarea";
import SelectPaiementHebergement from "shared/SelectPaiementHebergement/SelectPaiementHebergement";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const ValidationLiens = () => {
  const [loading, setLoading] = useState(false);
  const [liens, setLiens] = useState<any[]>([]);
  const [motifs, setMotifs] = useState<{ [key: string]: string }>({});
  const [selectValues, setSelectValues] = useState<{ [key: string]: string }>({});
  const [secondTextareas, setSecondTextareas] = useState<{ [key: string]: string }>({});
  const [validatedLinks, setValidatedLinks] = useState<Set<string>>(new Set());
  const navigate = useNavigate();

  useEffect(() => {
    // Appeler l'API pour obtenir les informations sur le voyageur
    compteService.getProfil()
      .then((response) => {
        if (response.data[0].identifiant !== 15) {
          navigate('/');
        } else {
          voyageService.getLiens()
            .then((response) => {

              setLiens(response.data.rows);
            })
            .catch((error) => {
              console.error("Erreur lors de la récupération des données:", error);
              navigate('/');
            });
        }
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        } else {
          alert("Erreur lors de la récupération du profil");
          localStorage.removeItem('xsrfToken');
          navigate('/');
        }
      });
  }, [navigate]);

  const handleSelectChange = (id: string, value: string, lien: any) => {
    setSelectValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    if (value === "V") {
      setMotifs((prevMotifs) => ({
        ...prevMotifs,
        [id]: "",
      }));
      setSecondTextareas((prevTextareas) => ({
        ...prevTextareas,
        [id]: "",
      }));
    } else if (value === "M") {
      setMotifs((prevMotifs) => ({
        ...prevMotifs,
        [id]: "Remplacement ou ajout d'un identifiant d'affiliation.",
      }));
      setSecondTextareas((prevTextareas) => ({
        ...prevTextareas,
        [id]: lien.lien,
      }));
    } else if (value === "R") {
      setMotifs((prevMotifs) => ({
        ...prevMotifs,
        [id]: "Lien pouvant afficher des données personnelles. Il restera invisible aux autres utilisateurs.",
      }));
      setSecondTextareas((prevTextareas) => ({
        ...prevTextareas,
        [id]: "",
      }));
    }
  };

  const handleMotifChange = (id: string, value: string) => {
    setMotifs((prevMotifs) => ({
      ...prevMotifs,
      [id]: value,
    }));
  };

  const handleSecondTextareaChange = (id: string, value: string) => {
    setSecondTextareas((prevTextareas) => ({
      ...prevTextareas,
      [id]: value,
    }));
  };

  const handleValiderClick = (lien: any) => {
    const idVoyage = lien.idVoyage;
    const typeEvenement = lien.type;
    const idEvenement = lien.id;
    const lienTexte = lien.lien;
    const etatLien = selectValues[idEvenement] || "V";
    const lienModifie = secondTextareas[idEvenement] || "";
    const motifLien = motifs[idEvenement] || "";

    voyageService.validerLien(idVoyage, {
      typeEvenement,
      idEvenement,
      lien: lienTexte,
      etatLien,
      lienModifie,
      motifLien
    })
    .then((response: any) => {
      setValidatedLinks(prev => new Set(prev).add(idEvenement));
    })
    .catch((error: any) => {
      if (error.response?.status === 401) {
        alert("Erreur lors de la validation du lien.");
        //navigate('/');
      } else if (error.response?.status === 410 && error.response.data.message !== undefined) {
        alert(error.response.data.message);
      } else {
        alert("Erreur lors de la validation du lien");
      }
    });
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Validation des liens</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
            <div className="pt-0">
              Booking : aid=2040377
            </div>
            <div className="pt-0">
              Kiwi : affilid=tripkygolink
            </div>
            <div className="pt-0">
              GetYourGuide : partner_id=SX74NSV
            </div>
            <div className="pt-0">
              Discovercars : a_aid=tripkygo
            </div>
            <br />
            <br />
            <>
              {liens.map((lien: any) => (
                <div key={lien.id}>
                  <span>{lien.idVoyage} - {lien.type} {lien.id} - {lien.langue}</span>
                  <SelectPaiementHebergement
                    value={selectValues[lien.id] || ""}
                    onChange={(event) => handleSelectChange(lien.id, event.target.value, lien)}
                    className="pointeur"
                  >
                    <option key="choixV" value="V">V</option>
                    <option key="choixM" value="M">M</option>
                    <option key="choixR" value="R">R</option>
                  </SelectPaiementHebergement>
                  <Textarea
                    maxLength={2000}
                    className="mt-1.5 text-md"
                    defaultValue={lien.lien}
                    //onBlur={(event) => handleBlurTextarea(event, "activites")}
                    //onFocus={(event) => handleFocusTextarea(event)}
                  />
                  <Textarea
                    maxLength={2000}
                    className="mt-1.5 mb-2 text-md"
                    value={secondTextareas[lien.id] || ""}
                    onChange={(event) => handleSecondTextareaChange(lien.id, event.target.value)}
                    //onBlur={(event) => handleBlurTextarea(event, "activites")}
                    //onFocus={(event) => handleFocusTextarea(event)}
                  />
                  <input
                    className="block w-full border-b border-neutral-200 mb-2"
                    value={motifs[lien.id] || ""}
                    placeholder="Motif"
                    onChange={(event) => handleMotifChange(lien.id, event.target.value)}
                    //onBlur={(event) => handleBlurTextarea(event, "activites")}
                    //onFocus={(event) => handleFocusTextarea(event)}
                  />
                  <a href={lien.lien} target="_blank" rel="noreferrer">Tester</a>
                  {!validatedLinks.has(lien.id) && (
                    <ButtonPrimary
                      sizeClass="px-2 py-2 sm:px-2 ml-2"
                      fontSize="text-sm sm:text-sm font-medium"
                      onClick={() => handleValiderClick(lien)}>
                      <i className="las la-tools text-md"></i>
                      <span className="ml-1">Valider</span>
                    </ButtonPrimary>
                  )}
                  <br />
                  <br />
                </div>
              ))}
            </>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default ValidationLiens;
