import React from "react";
import { FC, useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface CommonLayoutProps {
  children?: React.ReactNode;
  onClickMenu?: (nomMenu: string) => void;
  affichageBudget?: string;
  affichagePhotos?: string;
  numDroit?: number;
  menuDefaut: string;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, onClickMenu, affichageBudget, affichagePhotos, numDroit, menuDefaut }) => {
  const { t } = useTranslation();
  
  const [menuClicked, setMenuClicked] = useState<string>(menuDefaut || "planning");

  const onMenuClicked = (menu: string) => {
    setMenuClicked(menu);
    if(onClickMenu)
    {
      onClickMenu(menu);
    }
  }

  return (
    <div className="nc-CommonLayoutProps">
      <div className="">
        <div className="container">
          <div className="flex space-x-4 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <NavLink
               to={'#'}
               onClick={onClickMenu ? () => onMenuClicked("planning") : undefined}
              className={`block py-5 md:py-6 border-b-2 flex-shrink-0 ${
                menuClicked === "planning" ? "border-primary-500" : "border-transparent"
              }`}
            >
              Planning
            </NavLink>
            <NavLink
               to={'#'}
               onClick={onClickMenu ? () => onMenuClicked("resume") : undefined}
              className={`block py-5 md:py-6 border-b-2 flex-shrink-0 ${
                menuClicked === "resume" ? "border-primary-500" : "border-transparent"
              }`}
            >
              {t('resume')}
            </NavLink>
            <NavLink
              to={'#'}
              onClick={onClickMenu ? () => onMenuClicked("carte") : undefined}
              className={`block py-5 md:py-6 border-b-2 flex-shrink-0 ${
                menuClicked === "carte" ? "border-primary-500" : "border-transparent"
              }`}
            >
              {t('carte')}
            </NavLink>
            { (affichageBudget === "O" || numDroit === 0 || numDroit === 1) && (
              <NavLink
              to={'#'}
              onClick={onClickMenu ? () => onMenuClicked("budget") : undefined}
              className={`block py-5 md:py-6 border-b-2 flex-shrink-0 ${
                menuClicked === "budget" ? "border-primary-500" : "border-transparent"
              }`}
              >
              Budget
              </NavLink>
            )}
             { (affichagePhotos === "O" || numDroit === 0 || numDroit === 1) && (
             <NavLink
             to={'#'}
             onClick={onClickMenu ? () => onMenuClicked("photos") : undefined}
             className={`block py-5 md:py-6 border-b-2 flex-shrink-0 ${
               menuClicked === "photos" ? "border-primary-500" : "border-transparent"
             }`}
             >
             Photos <sup className="text-xxs couleurRose texteGras">NOUVEAU</sup>
             </NavLink>
            )}
           
          </div>
        </div>
      </div>
      <div className="container-planif">{children}</div>
    </div>
  );
};

export default CommonLayout;
