import React, { FC, useState, MouseEvent } from "react";

export interface BtnOptionsIconProps {
  className?: string;
  colorClass?: string;
  onMouseOver?: () => void;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>; // Change the type here
}

const BtnOptionsIcon: FC<BtnOptionsIconProps> = ({
  className = "right-6 top-3 w-5 h-5",
  colorClass = "text-white bg-black bg-opacity-20 hover:bg-opacity-50",
  onMouseOver,
  onMouseLeave,
}) => {
  return (
    <div
      className={`nc-BtnOptionsIcon -mx-3 absolute flex items-center justify-center rounded-full cursor-pointer "nc-BtnOptionsIcon--liked" ${colorClass} ${className}`}
      data-nc-id="BtnOptionsIcon"
      title="Save"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <i className={`las la-ellipsis-h`}></i>
    </div>
  );
};

export default BtnOptionsIcon;
