import React from "react";
import { FC, useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import logoPDFImg from "images/logoPDF.png";
import { useTranslation } from 'react-i18next';
import Axios from '../../_services/caller.service';
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import AvatarMenu from "shared/AvatarMenu/AvatarMenu";

export interface CommonLayoutProps {
  children?: React.ReactNode;
  onClickMenu?: (nomMenu: string) => void;
  affichageBudget?: string;
  numDroit?: number;
  classLayout?: string;
  username: string;
  avatar: string;
  vue: string;
  idVoyage: number;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, onClickMenu, affichageBudget,
  numDroit, classLayout="container", username, avatar, vue, idVoyage }) => {
    const { t } = useTranslation();

  const [menuClicked, setMenuClicked] = useState<string>(vue);
  const [imagesAvatars, setImagesAvatars] = useState<string[]>([]);

  
  useEffect(() => {
   
    let imageURL: string | undefined;
  
     if(avatar && avatar !== "")
     {

      const avatarList = avatar.split(";").map(av => av.trim());

      avatarList.forEach(av => {
          Axios.get(av, { responseType: 'blob' })
              .then((response) => {
                  const blob = new Blob([response.data], { type: 'image/jpeg' });
                  const imageURL = URL.createObjectURL(blob);
                  
                  setImagesAvatars(prevImages => [...prevImages, imageURL]);
              })
              .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
            });
     }
  
     return () => {
       if (imageURL) {
         URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
       }
     };
     
   }, [avatar]);

  const onMenuClicked = (menu: string) => {
    setMenuClicked(menu);
    if(onClickMenu)
    {
      onClickMenu(menu);
    }
  }

  return (
    <div className="nc-CommonLayoutProps">
      <div className="">
        <div className="container">
          <div className="flex text-xs md:text-sm space-x-3 md:space-x-10 overflow-x-auto hiddenScrollbar">
              <NavLink
                  to={`/planificateur-voyage/${idVoyage}`}
                  className={`block py-1 md:py-2 border-b-2 flex-shrink-0 border-transparent flex items-center `}
                  target={"_blank"}
                  > 
                  <img
                  className={`block max-h-12 dark:hidden w-14 md:w-20`}
                  src={logoPDFImg}
                  alt="Logo"
                  /> 
                   <>
          <i className="text-lg sm:text-xl las la-user ml-2.5 text-neutral-500 dark:text-neutral-400"></i>
          {username.split(';').map((name, index) => (
           <span
           key={index}
           data-tooltip-id={`myTooltip${index}`} 
           data-tooltip-content={name}
           data-tooltip-place="top"
           style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
         >
              <AvatarMenu sizeClass="w-7 h-7 sm:w-9 sm:h-9 mr-2" imgUrl={imagesAvatars[index] || ''} />
              <Tooltip id={`myTooltip${index}`} style={{ zIndex: 9999 }}></Tooltip>
            </span>
          ))}
        </>
              </NavLink>
              <NavLink
               to={'#'}
               onClick={onClickMenu ? () => onMenuClicked("planning") : undefined}
              className={`block py-2 md:py-3 border-b-2 flex-shrink-0 ${
                menuClicked === "planning" ? "border-primary-500" : "border-transparent"
              }`}
            >
              Planning
            </NavLink>
            <NavLink
               to={'#'}
               onClick={onClickMenu ? () => onMenuClicked("resume") : undefined}
              className={`block py-2 md:py-3 border-b-2 flex-shrink-0 ${
                menuClicked === "resume" ? "border-primary-500" : "border-transparent"
              }`}
            >
              {t('resume')}
            </NavLink>
            <NavLink
              to={'#'}
              onClick={onClickMenu ? () => onMenuClicked("carte") : undefined}
              className={`block py-2 md:py-3 border-b-2 flex-shrink-0 ${
                menuClicked === "carte" ? "border-primary-500" : "border-transparent"
              }`}
            >
              {t('carte')}
            </NavLink>
            { (affichageBudget === "O" || numDroit === 0 || numDroit === 1) && (
              <NavLink
              to={'#'}
              onClick={onClickMenu ? () => onMenuClicked("budget") : undefined}
              className={`block py-2 md:py-3 border-b-2 flex-shrink-0 ${
                menuClicked === "budget" ? "border-primary-500" : "border-transparent"
              }`}
              >
              Budget
              </NavLink>
            )}
           
           
          </div> 
        </div>
      </div>
      <div className={`${classLayout}`}>{children}</div>
    </div>
  );
};

export default CommonLayout;
