import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { guidesService } from "../../_services/guides.service";
import { Tab } from "@headlessui/react";
import { GuidePaysDataType, IncontournablesType, SectionGuidesType, VoyageDataType } from "data/types";
import TabFiltersGuide from "../../containers/TabFiltersGuide";
import VoyageCard from "components/VoyageCard/VoyageCard";
import { voyageService } from '_services/voyage.service';
import { useTranslation } from 'react-i18next';
import { useLocation} from "react-router-dom";

export interface InformationsPaysProps {
  codePays?: string;
  pays?: GuidePaysDataType;
}

const InformationsPays: React.FC<InformationsPaysProps> = ({ 
  codePays,
  pays
}) => {
  const { t } = useTranslation();

  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  const tabMois = [
    { 
      name: t('janvier'),
      nameShort: t('janvier'),
      paysRef: "TH" 
    },
    { 
      name: t('fevrier'),
      nameShort: t('fevrier'),
      paysRef: "PH" 
    },
    { 
      name: t('mars'),
      nameShort: t('mars'),
      paysRef: "LK" 
    },
    { 
      name: t('avril'),
      nameShort: t('avril'),
      paysRef: "JP" 
    },
    { 
      name: t('mai'),
      nameShort: t('mai'),
      paysRef: "MA" 
    },
    { 
      name: t('juin'),
      nameShort: t('juin'),
      paysRef: "CV" 
    },
    { 
      name: t('juillet'),
      nameShort: t('juillet'),
      paysRef: "GR" 
    },
    { 
      name: t('aout'),
      nameShort: t('aout'),
      paysRef: "MT" 
    },
    { 
      name: t('septembre'),
      nameShort: "Sept.",
      paysRef: "KE" 
    },
    { 
      name: t('octobre'),
      nameShort: "Oct.",
      paysRef: "EG" 
    },
    { 
      name: t('novembre'),
      nameShort: "Nov.",
      paysRef: "MX" 
    },
    { 
      name: t('decembre'),
      nameShort: "Dec.",
      paysRef: "NZ" 
    },
  ];

  const [monnaie, setMonnaie] = useState("");
  const [symboleMonnaie, setSymboleMonnaie] = useState("");
  const [langues, setLangues] = useState<string[]>([]);
  const [capitale, setCapitale] = useState("");
  const [drapeau, setDrapeau] = useState("");
  const [incontournables, setIncontournables] = useState<IncontournablesType[]>([]);
  const [sections, setSections] = useState<SectionGuidesType[]>([]);
  const [chargementSections, setChargementSections] = useState<boolean>(false);
  const [numSectionSelected, setNumSectionSelected] = useState(0);
  const [voyagesListings, setVoyagesListings] = useState<VoyageDataType[]>([]);
  const [chargementInfos, setChargementInfos] = useState<boolean>(false);

  let navigate = useNavigate();

  useEffect(() => {
    if(codePays)
    {
      const fetchVoyages = async () => {
        try {

          const response = await voyageService.getVoyagesPublicsPays(codePays);
          const voyagesData = response.data.rows.map((voyage: VoyageDataType) => ({
            ...voyage,
            nb_likes: voyage.nb_likes ?? null,
            visibilite: 'public'
          }));

          setVoyagesListings(voyagesData);
        } catch (error: any) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      };

      guidesService.getIncontournables(codePays, langue)
      .then((response) => {

        setIncontournables(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des mois:", error);
      });

      recupinfosGenerales(codePays);
      fetchVoyages();
    }
  }, [codePays])


  useEffect(() => {

   if(pays)
   {
    recupWikivoyage();
   }
  }, [pays])

  useEffect(() => {

    if(sections && sections.length > 0)
    {
      setChargementSections(true);
    }
   }, [sections])

  function apiWikiData(idWikidata: any) {
    return new Promise(function(resolve, reject) {
        var url='https://www.wikidata.org/w/api.php?action=wbgetentities&ids='+idWikidata+'&format=json&origin=*';
      fetch(url)
      .then(response => {
        // check if the call was successful
        if (response.ok) {
          response.json().then(data => resolve(data));
        } else {
          response.json().then(data => reject(data));
        }
      });
    });
  }

  function apiWikivoyageSections(titreWikivoyage: any) {
    return new Promise(function(resolve, reject) {
        var url='https://fr.wikivoyage.org/w/api.php?action=parse&format=json&page='+titreWikivoyage+'&prop=sections&origin=*';
        fetch(url)
      .then(response => {
        // check if the call was successful
        if (response.ok) {
          response.json().then(data => resolve(data));
        } else {
          response.json().then(data => reject(data));
        }
      });
    });
  }

  function apiWikivoyage(titreWikivoyage: any, numSection: number) {
    return new Promise(function(resolve, reject) {
        var url='https://fr.wikivoyage.org/w/api.php?action=parse&format=json&page='+titreWikivoyage+'&section='+numSection+'&origin=*';
        fetch(url)
      .then(response => {
        // check if the call was successful
        if (response.ok) {
          response.json().then(data => resolve(data));
        } else {
          response.json().then(data => reject(data));
        }
      });
    });
  }

  async function recupWikivoyage (){
    var wikiTrouve=false;
    var idWikidata=pays?.id_wikidata || '';
    var titreWiki="";
    var tabSections: SectionGuidesType[]=[];

    var section1: SectionGuidesType = {
      numSection: 1,
      nomSection: "Informations",
      detailSection: ""
    };

    var section2: SectionGuidesType = {
      numSection: 2,
      nomSection: t('quandPartir')+" ?",
      detailSection: ""
    };

    var section3: SectionGuidesType = {
      numSection: 3,
      nomSection: t('nosIncontournables'),
      detailSection: ""
    };

    var section4: SectionGuidesType = {
      numSection: 15,
      nomSection: t('itinerairesPublics'),
      detailSection: ""
    };

    tabSections.push(section1);
    tabSections.push(section2);
    tabSections.push(section3);
    tabSections.push(section4);

    if(idWikidata !== '')
    {
        const data: any = await apiWikiData(idWikidata);

            if(data.entities[idWikidata].sitelinks.frwikivoyage !== undefined && data.entities[idWikidata].sitelinks.frwikivoyage !== null)
            {
                const titre=data.entities[idWikidata].sitelinks.frwikivoyage.title.split('/');
                titreWiki=titre[0];
                wikiTrouve=true;
                
            }
            if(wikiTrouve)
            {
              const dataWikivoyageSections: any = await apiWikivoyageSections(titreWiki);
                 
                  for(var i=0;i<dataWikivoyageSections.parse.sections.length;i++)
                    {
                      var numSection=0;
                      var nomSection="";
                      var detailSection="";

                        if(dataWikivoyageSections.parse.sections[i].anchor === 'Climat' || dataWikivoyageSections.parse.sections[i].anchor === 'Santé' || dataWikivoyageSections.parse.sections[i].anchor === 'Régions' || dataWikivoyageSections.parse.sections[i].anchor === 'Villes'  || dataWikivoyageSections.parse.sections[i].anchor === 'Autres_destinations' || dataWikivoyageSections.parse.sections[i].anchor.indexOf('Partir_') !== -1 || dataWikivoyageSections.parse.sections[i].anchor === 'Formalités'  || dataWikivoyageSections.parse.sections[i].anchor === 'Monnaie' || dataWikivoyageSections.parse.sections[i].anchor === 'Conseils_gouvernementaux_aux_voyageurs' || dataWikivoyageSections.parse.sections[i].anchor === 'Visa' || dataWikivoyageSections.parse.sections[i].anchor === 'Acheter')
                        {
                      
                            nomSection=dataWikivoyageSections.parse.sections[i].anchor;

                            switch (nomSection) {
                              case 'Climat':
                                  numSection=6;
                              break;
                              case 'Santé':
                                  numSection=11;
                              break;
                              case 'Régions':
                                  numSection=7;
                              break;
                              case 'Villes':
                                  numSection=8;
                              break;
                              case 'Autres_destinations':
                                  numSection=9;
                              break;
                              case 'Partir_':
                                  numSection=10;
                              break;
                              case 'Formalités':
                                  numSection=4;
                              break;
                              case 'Monnaie':
                                  numSection=12;
                              break;
                              case 'Conseils':
                                  numSection=10;
                              break;
                              case 'Visa':
                                  numSection=5;
                              break;
                              case 'Acheter':
                                  numSection=13;
                              break;
                              default: 
                              numSection=14;
                          }

                            const dataWikivoyage: any = await apiWikivoyage(titreWiki, dataWikivoyageSections.parse.sections[i].index);

                            const regex = /<!--.*-->/gis;
                                detailSection=dataWikivoyage.parse.text['*'].replace(regex, '');

                           // const regex0 = /^<div class\="mw\-parser\-output"><h[123]><span id\="[^"]*"><\/span><span class\="mw\-headline" id\="[^"]*">.*<\/span><\/h[123]>\s*<\/div>\s*$/gis;
                            const regex0 = /^<div class="mw-parser-output"><h[123]>.*<span class="mw-headline" id="[^"]*">.*<\/span><\/h[123]>\s*<\/div>\s*$/gis;                            
                            
                            const regex1 = / href="\//gi;
                            detailSection=detailSection.replace(regex1, ' target="_blank" href="https://fr.wikivoyage.org/');

                            const regex2 = /<span class="mw-editsection".*<span class="mw-editsection-bracket">\]<\/span><\/span>/gi;
                            detailSection=detailSection.replace(regex2,'');

                            const regex3 = /<div id="toc" class="toc" role="navigation".*<\/ul>\n<"\/div>/gis;
                            detailSection=detailSection.replace(regex3,'');

                            const regex4 = /margin: 0 0 0 -1.5em/gis;
                            detailSection=detailSection.replace(regex4,'margin: 0 0 0 0.5em');

                            const regex5 = new RegExp(`<h[23]><span class="mw-headline" id="[^<]*">[^<]*</span></h[23]>`, 'gis');
                            detailSection=detailSection.replace(regex5,'');

                            const regex6 = new RegExp(`<h[23]><span id="[^<]*">[^<]*</span><span class="mw-headline" id="[^<]*">[^<]*</span></h[23]>`, 'gis');
                            detailSection=detailSection.replace(regex6,'');

                            detailSection=detailSection.replace('h2','h3');
                            
                            nomSection=dataWikivoyage.parse.sections[0].anchor;

                            if(dataWikivoyage.parse.sections[0].anchor === 'Autres_destinations')
                            {
                                detailSection=detailSection.replace('Autres destinations','Autres lieux');
                                nomSection="Autres lieux";
                            }
                            else if(dataWikivoyage.parse.sections[0].anchor === 'Visa')
                            {
                              nomSection="Formalités";
                            }
                            else if(dataWikivoyage.parse.sections[0].anchor === 'Acheter')
                            {
                              nomSection="Argent";
        
                            }
                            if(detailSection.match(regex0) === null)
                            {
                              var section: SectionGuidesType = {
                                numSection: numSection,
                                nomSection: nomSection.replaceAll("_", " "),
                                detailSection: detailSection.replaceAll("<h3>","<h3 class='mt-2'><b>").replaceAll("<h4>","<h4 class='mt-2'><b>").replaceAll("</h3>","</b></h3>").replaceAll("</h4>","</b></h4>")                      
                              };
                             
                              if(detailSection.replaceAll("<h3>","<h3 class='mt-2'><b>").replaceAll("<h4>","<h4 class='mt-2'><b>").replaceAll("</h3>","</b></h3>").replaceAll("</h4>","</b></h4>").replace(/\s+/g, '') 
                              !== '<divclass="mw-content-ltrmw-parser-output"lang="fr"dir="ltr"></div>')
                              {
                                tabSections.push(section);
                              }
                            }
                        }
                    }
                    tabSections.sort(comparerSections);
                    setSections(tabSections);   
            }
    }
  }

  function comparerSections(sectionA: any, sectionB: any) {
    return sectionA.numSection - sectionB.numSection;
}

  function apiRestcountries(idPays: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `https://restcountries.com/v3.1/alpha/${idPays}`;
      fetch(url)
        .then(response => {
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(error => reject(error));
          }
        })
        .catch(error => reject(error));
    });
  }

  function recupinfosGenerales (idPays: string){

     var codePaysGB=idPays;
     var codeMonnaieAPI='';
     var symboleMonnaieAPI='';

     if(idPays.toLowerCase() === 'ww' || idPays.toLowerCase()  === 'xx' || idPays.toLowerCase()  === 'yy' || idPays.toLowerCase()  === 'zz')
     {
         codePaysGB='gb';
     }
 
      if(codePaysGB === 'an')
      {
        codeMonnaieAPI='ANG';
        symboleMonnaieAPI='NAƒ';
        langues.push("Néerlandais");
        setCapitale("Willemstad");
      }

      apiRestcountries(codePaysGB)
          .then(data => {

            var tabLangues=[];
            if(codePaysGB !== 'an')
            {
              for(var i in data[0].languages)
              {
                switch (data[0].languages[i]) {
                    case 'English':
                        langues.push('Anglais');
                    break;
                    case 'French':
                        tabLangues.push('Français');
                    break;
                    case 'Arabian':
                        tabLangues.push('Arabe');
                    break;
                    case 'Arabic':
                        tabLangues.push('Arabe');
                    break;
                    case 'Spanish':
                        tabLangues.push('Espagnol');
                    break;
                    case 'Portuguese':
                        tabLangues.push('Portugais');
                    break;
                    case 'Chinese':
                        tabLangues.push('Chinois');
                    break;
                    case 'Armenian':
                        tabLangues.push('Arménien');
                    break;
                    case 'Hebrew':
                        tabLangues.push('Hébreux');
                    break;
                    case 'Hebrew (modern)':
                        tabLangues.push('Hébreux');
                    break;
                    case 'Dutch':
                        tabLangues.push('Hollandais');
                    break;
                    case 'German':
                        tabLangues.push('Allemand');
                    break;
                    case 'Russian':
                        tabLangues.push('Russe');
                    break;
                    case 'Croatian':
                        tabLangues.push('Croate');
                    break;
                    case 'Serbian':
                        tabLangues.push('Serbe');
                    break;
                    case 'Hungarian':
                        tabLangues.push('Hongrois');
                    break;
                    case 'Greek':
                        tabLangues.push('Grec');
                    break;
                    case 'Greek (modern)':
                        tabLangues.push('Grec');
                    break;
                    case 'Turkish':
                        tabLangues.push('Turc');
                    break;
                    case 'Danish':
                        tabLangues.push('Danois');
                    break;
                    case 'Estonian':
                        tabLangues.push('Estonien');
                    break;
                    case 'Italian':
                        tabLangues.push('Italien');
                    break;
                    case 'Swedish':
                        tabLangues.push('Suédois');
                    break;
                    case 'Maltese':
                        tabLangues.push('Maltais');
                    break;
                    case 'Finnish':
                        tabLangues.push('Finnois');
                    break;
                    case 'Bulgarian':
                        tabLangues.push('Bulgare');
                    break;
                    case 'Georgian':
                        tabLangues.push('Géorgien');
                    break;
                    case 'Belarusian':
                        tabLangues.push('Biélorusse');
                    break;
                    case 'Latvian':
                        tabLangues.push('Letton');
                    break;
                    case 'Romanian':
                        tabLangues.push('Roumain');
                    break;
                    case 'Albanian':
                        tabLangues.push('Albanais');
                    break;
                    case 'Irish':
                        tabLangues.push('Irlandais');
                    break;
                    case 'Icelandic':
                        tabLangues.push('Islandais');
                    break;
                    case 'Lithuanian':
                        tabLangues.push('Lituanien');
                    break;
                    case 'Luxembourgish':
                        tabLangues.push('Luxembourgeois');
                    break;
                    case 'Indonesian':
                        tabLangues.push('Idonésien');
                    break;
                    case 'Korean':
                        tabLangues.push('Coréen');
                    break;
                    case 'Kurdish':
                        tabLangues.push('Kurde');
                    break;
                    case 'Persian (Farsi)':
                        tabLangues.push('Persan (Farsi)');
                    break;
                    case 'Kyrgyz':
                        tabLangues.push('Kirghize');
                    break;
                    case 'Malaysian':
                        tabLangues.push('Malaisien');
                    break;
                    case 'Mongolian':
                        tabLangues.push('Mongol');
                    break;
                    case 'Nepali':
                        tabLangues.push('Népalais');
                    break;
                    case 'Uzbek':
                        tabLangues.push('Ouzbek');
                    break;
                    case 'Sinhalese':
                        tabLangues.push('Cingalais');
                    break;
                    case 'Tamil':
                        tabLangues.push('Tamoul');
                    break;
                    case 'Tajik':
                        tabLangues.push('Tadjik');
                    break;
                    case 'Thai':
                        tabLangues.push('Thaï');
                    break;
                    case 'Turkmen':
                        tabLangues.push('Turkmène');
                    break;
                    case 'Vietnamese':
                        tabLangues.push('Vietnamien');
                    break;
                    case 'Fijian':
                        tabLangues.push('Fidjien');
                    break;
                    case 'Faroese':
                        tabLangues.push('Féroïen');
                    break;
                    case 'Japanese':
                        tabLangues.push('Japonais');
                    break;
                    default: 
                    tabLangues.push(data[0].languages[i]);
                }
              }
              setLangues(tabLangues);
            }

            if(codeMonnaieAPI === "")
            {
              for(var j in data[0].currencies)
              {
                if(codeMonnaieAPI==='')
                {
                  codeMonnaieAPI=j;
                  symboleMonnaieAPI=data[0].currencies[j].symbol;
                }
              }
            }
            guidesService.getMonnaie(codeMonnaieAPI)
            .then((response) => {

              setMonnaie(response.data[0].libelle_monnaie);
              setSymboleMonnaie(symboleMonnaieAPI);
            })
            .catch((error) => {
              console.error("Erreur lors de la récupération des mois:", error);
            });

            setCapitale(data[0].capital[0]);
            setDrapeau(data[0]?.flag || '');

            setChargementInfos(true);

          })
          .catch(error => {
            console.error(`Error fetching flag for ${codePaysGB}:`, error);
            setChargementInfos(false);
            
          });
  }
  

  const renderSection = (sec: SectionGuidesType) => {
    return (
      <div key={`section-${sec.numSection}`} className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{sec.nomSection}</h2>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <span dangerouslySetInnerHTML={{ __html: sec.detailSection }}></span>
          </div>
          <p className="infoWikivoyage text-xxs text-neutral-6000 dark:text-neutral-300">Ces informations sont issues du projet collaboratif <a target="_blank" rel="noreferrer" href="https://fr.wikivoyage.org/"><u>Wikivoyage</u></a> sous licence <a target="_blank" rel="noreferrer" href="https://creativecommons.org/licenses/by-sa/3.0/deed.fr"><u>Creative Commons Attribution-ShareAlike 3.0</u></a>. N'hésitez pas à les mettre à jour ou à les compléter pour aider la communauté de voyageurs.</p>
      </div>
    );
  };
 
  const renderQuandPartir = () => {
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{t('quandPartir')} {pays?.quand_partir ? pays?.quand_partir : "en" } {pays?.nom} ?</h2>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="text-sm text-neutral-6000 dark:text-neutral-300">{t('conseilsVoyage')}</div>
     
        <div className="flex justify-between text-neutral-800 dark:text-neutral-300 grid grid-cols-2 gap-5">
        {tabMois.map((item: any, i: any) => (
          <div key={`mois-${i}`}>
            <span className="hidden md:block">
              {item.name} :&nbsp;
              {pays?.ou_partir && (
                <>
                  {pays?.plage && pays?.plage.substr(i, 1) === "O" && (
                    <i className="las la-umbrella-beach" style={{ color: '#E7A919' }}></i>
                  )}
                  {pays?.nature && pays?.nature.substr(i, 1) === "O" && (
                    <i className="las la-tree" style={{ color: '#145A32' }}></i>
                  )}
                  {pays?.culture && pays?.culture.substr(i, 1) === "O" && (
                    <i className="las la-gopuram" style={{ color: '#6E2C00' }}></i>
                  )}
                  {pays?.ski && pays?.ski.substr(i, 1) === "O" && (
                    <i className="las la-skiing" style={{ color: '#020563' }}></i>
                  )}
                  {(!pays?.plage || pays?.plage.substr(i, 1) !== "O") &&
                  (!pays?.nature || pays?.nature.substr(i, 1) !== "O") &&
                  (!pays?.culture || pays?.culture.substr(i, 1) !== "O") &&
                  (!pays?.ski || pays?.ski.substr(i, 1) !== "O") && (
                    "-"
                  )}
                </>
              )}
              {!pays?.ou_partir && pays?.mois && (
                pays?.mois.substr(i, 1) === "O" ? "+" : "-"
              )}
            </span>
            <span className="block md:hidden">
              {item.nameShort} :&nbsp;
              {pays?.ou_partir && (
                <>
                  {pays?.plage && pays?.plage.substr(i, 1) === "O" && (
                    <i className="las la-umbrella-beach" style={{ color: '#E7A919' }}></i>
                  )}
                  {pays?.nature && pays?.nature.substr(i, 1) === "O" && (
                    <i className="las la-tree" style={{ color: '#145A32' }}></i>
                  )}
                  {pays?.culture && pays?.culture.substr(i, 1) === "O" && (
                    <i className="las la-gopuram" style={{ color: '#6E2C00' }}></i>
                  )}
                  {pays?.ski && pays?.ski.substr(i, 1) === "O" && (
                    <i className="las la-skiing" style={{ color: '#020563' }}></i>
                  )}
                  {(!pays?.plage || pays?.plage.substr(i, 1) !== "O") &&
                  (!pays?.nature || pays?.nature.substr(i, 1) !== "O") &&
                  (!pays?.culture || pays?.culture.substr(i, 1) !== "O") &&
                  (!pays?.ski || pays?.ski.substr(i, 1) !== "O") && (
                    "-"
                  )}
                </>
              )}
              {!pays?.ou_partir && pays?.mois && (
                pays?.mois.substr(i, 1) === "O" ? "+" : "-"
              )}
            </span>
          </div>
        ))}
      </div>

      {pays?.ou_partir && (
      <div className="hidden md:flex justify-between text-neutral-6000 dark:text-neutral-300 items-center text-sm hidden">
        <table>
          <tbody>
            <tr>
              <td><i className="las la-umbrella-beach" style={{ color: '#E7A919' }}></i> {t('plage')}&nbsp;&nbsp;&nbsp;</td>
              <td><i className="las la-tree" style={{ color: '#145A32' }}></i> Nature&nbsp;&nbsp;&nbsp;</td>
              <td><i className="las la-gopuram" style={{ color: '#6E2C00' }}></i> Culture&nbsp;&nbsp;&nbsp;</td>
              <td><i className="las la-skiing" style={{ color: '#020563' }}></i> Ski&nbsp;&nbsp;&nbsp;</td>
              <td>- {t('nonConseille')}</td>
            </tr>
          </tbody>
        </table>
      </div>
      )}
      {pays?.ou_partir && (
      <div className="flex md:hidden justify-between text-neutral-6000 dark:text-neutral-300 items-center text-sm">
        <table>
          <tbody>
            <tr>
              <td><i className="las la-umbrella-beach" style={{ color: '#E7A919' }}></i> {t('plage')} </td>
              <td><i className="las la-tree" style={{ color: '#145A32' }}></i> Nature</td>
            </tr>
            <tr>
              <td><i className="las la-gopuram" style={{ color: '#6E2C00' }}></i> Culture</td>
              <td><i className="las la-skiing" style={{ color: '#020563' }}></i> Ski</td>
            </tr>
            <tr>
              <td>- {t('nonConseille')}</td>
            </tr>
          </tbody>
        </table>
      </div>
      )}
      {!pays?.ou_partir && pays?.mois && (
      <div className="flex justify-start text-neutral-6000 dark:text-neutral-300 items-center text-sm">
        <table>
          <tbody>
            <tr>
              <td>+ {t('conseille')}&nbsp;&nbsp;&nbsp;</td>
              <td>- {t('nonConseille')}</td>
            </tr>
          </tbody>
        </table>
      </div>
      )}
      </div>
    );
  };

  
  const renderIncontournables = () => {
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] lg:hidden">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{t('nosIncontournables')}</h2>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div key={`titreInc-${0}`} className="text-sm text-neutral-6000 dark:text-neutral-300">{t('notreSelection')} {pays?.quand_partir ? pays?.quand_partir : "en" } {pays?.nom}</div>
     
        {incontournables.length >0 ? (
          <div className="flex justify-between text-neutral-800 dark:text-neutral-300 grid grid-cols-2 gap-5">
          {incontournables.map((incontournable: IncontournablesType, index: number) => (
           incontournable.titre_wiki !== '' ?
           (
            <div key={`incontournable-${index}`} >
              <a className= "pointeurRose" target="_blank" rel="noreferrer" href={`https://${incontournable.titre_wiki.split("!")[0]}.wikipedia.org/wiki/${incontournable.titre_wiki.split("!")[1]}`}><u>{incontournable.libelle_incontournable}</u></a>
            </div>
           )
           :
           (
            <div key={`incontournable-${index}`}>{incontournable.libelle_incontournable}</div>
           )
            ))}
          </div>
        ) : (
          <div className="flex justify-between text-neutral-800 dark:text-neutral-300 ">         
            <div>{t('pasRecommandation')}</div>
          </div>
        )}

    </div>
    );
  };

  const renderInformations = () => {
    return (
      <div className="listingSection__wrap shadow-xl  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] block ">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-2xl font-semibold">
         Informations
          </span>
        </div>

        {/* FORM */}

        {/* SUM */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        { monnaie && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300"> 
          <span>{t('monnaie')} : <span className="text-neutral-800 dark:text-neutral-300">{monnaie} {symboleMonnaie}</span></span>
        </div>
        )}
        { langues && langues.length > 0 && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t('languesOfficielles')}  : <span className="text-neutral-800 dark:text-neutral-300">{langues.map((langue: string, index: number) => (
              <Fragment key={`langue-${index}`}>
                {langue}
                {index < langues.length - 1 && ", "}
              </Fragment>
              ))}
              </span>
            </span>
         </div>
        )}
        { drapeau && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t('drapeau')} : <span className="text-xl">{drapeau}</span></span>
         </div>
        )}
        { capitale && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t('capitale')}  : <span className="text-neutral-800 dark:text-neutral-300">{capitale}</span></span>
        </div>
        )}
        
      </div>
    );
  };

  const renderItineraires= () => {
    return (
      <div>
      <div className="listingSection__wrap [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        <div>
          <h2 className="text-2xl font-semibold">{t('itinerairesPublics')}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-2 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                {voyagesListings && voyagesListings.length > 0 ? (
                voyagesListings.map((voyage : VoyageDataType) => (
                  <VoyageCard key={voyage.id_voyage} voyageData={voyage} voyageKey={voyage.id_voyage}/>
                ))
              ) : null }
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
       <div className="sticky top-28 text-center mt-8 lg:hidden">
       <ButtonPrimary
           sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
           fontSize="text-sm sm:text-base lg:text-lg font-medium"
           onClick={() => navigate("/")}
         >
           {t('creerItineraire')}
       </ButtonPrimary>
     </div>
     </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div>
      <div className="listingSectionSidebar__wrap shadow-xl  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
         {t('nosIncontournables')}
          </span>
        </div>

        {/* FORM */}

        {/* SUM */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div key={`titreInc-${2}`} className="text-sm text-neutral-6000 dark:text-neutral-300">{t('notreSelection')} {pays?.quand_partir ? pays?.quand_partir : "en" } {pays?.nom}</div>
     
        {incontournables.length >0 ? (
          <div className="flex justify-between text-neutral-800 dark:text-neutral-300 grid grid-cols-2 gap-5">
          {incontournables.map((incontournable: IncontournablesType, index:number) => (
            incontournable.titre_wiki !== '' ?
            (
            <div  key={`incontournable-${index}`}>
              <a className= "pointeurRose" target="_blank" rel="noreferrer" href={`https://${incontournable.titre_wiki.split("!")[0]}.wikipedia.org/wiki/${incontournable.titre_wiki.split("!")[1]}`}><u>{incontournable.libelle_incontournable}</u></a>
            </div>
            )
            :
            (
            <div  key={`incontournable-${index}`}>{incontournable.libelle_incontournable}</div>
            )
            ))}
          </div>
        ) : (
          <div className="flex justify-between text-neutral-800 dark:text-neutral-300 ">         
            <div>{t('pasRecommandation')}</div>
          </div>
        )}
       
        
      </div>
       <div className="sticky top-28 text-center mt-8">
        <ButtonPrimary
            sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
            fontSize="text-sm sm:text-base lg:text-lg font-medium"
            onClick={() => navigate("/")}
          >
            {t('creerItineraire')}
        </ButtonPrimary>
      </div>
      </div>
    );
  };

  const handleSectionChange = ( numSec: number) => {
    setNumSectionSelected(numSec);
  }

  return (
    
    <DndProvider backend={HTML5Backend}>
  

        <div  className="nc-ListingStayDetailPage">

      {/* MAIN     <BackgroundSection /> */}
      <div className=" relative mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        

        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          { chargementSections && (
            <TabFiltersGuide presenceItineraires={voyagesListings && voyagesListings.length > 0 ? true : false} tabSections={sections} onSectionChange={handleSectionChange} chargementInfos={chargementInfos}/>
          )}
          {chargementInfos && (numSectionSelected === 0 || numSectionSelected === 1) && (
                renderInformations()
          )}
           {!chargementSections &&  (
            <p className="text-center texteGras">{t('chargementGuide')}</p>
            )}
          { (pays?.ou_partir || pays?.mois) && (numSectionSelected === 0 || numSectionSelected === 2) && (renderQuandPartir())}
          {(numSectionSelected === 0 || numSectionSelected === 3) &&  (
            renderIncontournables()
          )}
          {chargementSections && sections.map((sec: SectionGuidesType) => (
              (numSectionSelected === 0 || numSectionSelected === sec.numSection) && sec.numSection>3 && sec.numSection<15 && (
                renderSection(sec)
              )
          ))}
          {voyagesListings && voyagesListings.length > 0 && (numSectionSelected === 0 || numSectionSelected === 15) &&( 
            renderItineraires()
          )}
        </div>

        {/* SIDEBAR */}
          <div className="hidden lg:block flex-grow mt-14 lg:mt-0 sideBarGuide">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </div>
    </div>
   {/* <MobileFooterSticky />*/}
          
</DndProvider>
)};

export default InformationsPays;