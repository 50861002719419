import React from "react";
import { GeoapifyType, AeroportType } from "data/types";

interface AutocompleteListProps {
  suggestionsAeroports?: AeroportType[] | null ;
  suggestions: GeoapifyType[];
  onSelect: (suggestion: GeoapifyType) => void;
  onSelectAeroport?: (suggestion: AeroportType) => void | null;
  classe?: string;
}

const AutocompleteList: React.FC<AutocompleteListProps> = ({
  suggestions,
  suggestionsAeroports,
  onSelect,
  onSelectAeroport,
  classe
}) => {

  return (
    <ul className={`autocomplete-list bg-white dark:bg-neutral-800 text-left ${classe}`}>
      {suggestionsAeroports && suggestionsAeroports.map((suggestion) => (
        <li
          key={suggestion.code_aeroport_3c}
          onClick={() => (onSelectAeroport !== undefined && onSelectAeroport !== null) ? onSelectAeroport(suggestion) : {}}
          style={{ cursor: "pointer" }}
          className="hover:bg-neutral-100 dark:hover:bg-neutral-700"
        >
         <i className={`text-neutral-500 dark:text-neutral-400 las la-map-marker `}></i> {suggestion.nom_aeroport_fr}
            <span className=" text-neutral-500 dark:text-neutral-400"> {suggestion.ville_aeroport_fr}, {suggestion.pays_aeroport_fr}</span>
        </li>
      ))}
      {suggestions.map((suggestion) => (
        <li
          key={suggestion.properties.place_id}
          onClick={() => onSelect(suggestion)}
          style={{ cursor: "pointer" }}
          className="hover:bg-neutral-100 dark:hover:bg-neutral-700"
        >
         <i className={`text-neutral-500 dark:text-neutral-400 las la-map-marker `}></i> {suggestion.properties.address_line1}
          {suggestion.properties.address_line2 !== null &&
          suggestion.properties.address_line2 !== "" ? (
            <span className=" text-neutral-500 dark:text-neutral-400"> {suggestion.properties.address_line2}</span>
          ) : null}
        </li>
      ))}
      <div className="labelGeoapify">Powered by <a target="_blank" rel="noreferrer" href="https://www.geoapify.com/">Geoapify</a></div>
    </ul>
  );
};

export default AutocompleteList;
