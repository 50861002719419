import React, { Fragment, useState, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { voyageService } from '_services/voyage.service';
import Axios from '_services/caller.service';
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';
import { PDFDocument} from 'pdf-lib';
import { saveAs } from 'file-saver';
import logoPDFImg from "images/logoPDF.png";
import logoImg from "images/logo.png";
import couvertureCarnetImg from "images/PDF/couvertureCarnet.png";
import markerImg from "images/PDF/marker.png";
import repasImg from "images/PDF/repas.png";
import bedImg from "images/PDF/bed.png";
import blocImg from "images/PDF/bloc.png";
import payeImg from "images/PDF/paye.jpg";
import barreImg from "images/PDF/barre.png";
import barre1Img from "images/PDF/barre1.png";
import barre2Img from "images/PDF/barre2.png";
import planeImg from "images/PDF/plane.png";
import shipImg from "images/PDF/ship.png";
import trainImg from "images/PDF/train.png";
import busImg from "images/PDF/bus.png";
import taxiImg from "images/PDF/taxi.png";
import carImg from "images/PDF/car.png";
import motorcycleImg from "images/PDF/motorcycle.png";
import bicycleImg from "images/PDF/bicycle.png";
import walkingImg from "images/PDF/walking.png";
import carLocImg from "images/PDF/car_loc.png";
import motorcycleLocImg from "images/PDF/motorcycle_loc.png";
import bicycleLocImg from "images/PDF/bicycle_loc.png";
import { useTranslation } from 'react-i18next';
import { useLocation} from "react-router-dom";

export interface ModalEditionPDFProps {
  onClose: () => void;
  keyVoyage: number;
  isCreateur: boolean;
  isCoCreateur: boolean;
  partageConfirmations?: string;
  partagePrix?: string;
  partageBlocNotes?: string;
}

const ModalEditionPDF: FC<ModalEditionPDFProps> = ({ onClose, keyVoyage, isCreateur, isCoCreateur,
  partageConfirmations, partagePrix, partageBlocNotes }) => {

  const { t } = useTranslation(); 
  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isAffichePrix, setIsAffichePrix] = useState<boolean>(false);
  const [isAfficheConfirmations, setIsAfficheConfirmations] = useState<boolean>(false);
  const [isAfficheGPS, setIsAfficheGPS] = useState<boolean>(false);
  const [isAfficheBlocNotes, setIsAfficheBlocNotes] = useState<boolean>(false);
  const [typeEdition, setTypeEdition] = useState<string>("pdf");

  const togglePrix = () => {
    setIsAffichePrix(prevState => !prevState);
  };
  
  const toggleConfirmations = () => {
    setIsAfficheConfirmations(prevState => !prevState);
  };

  const toggleGPS= () => {
    setIsAfficheGPS(prevState => !prevState);
  };

  const toggleBlocNotes = () => {
    setIsAfficheBlocNotes(prevState => !prevState);
  };

  let navigate = useNavigate();

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  // Convertir la Data URI en Uint8Array
function dataURIToUint8Array(dataURI: any) {
  const byteString = atob(dataURI.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return intArray;
}

function getCurrentDate() {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois sont de 0 à 11
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
}

const ajouterPageNotes = (doc: jsPDF) => {
        doc.setFontSize(22);
        doc.setFont("Helvetica", "italic");
        doc.text("NOTES", 20, 20);
    
        for(var i=0;i<21;i++)
        {
            doc.line(20, 40+(i*12), 190, 40+(i*12));
        }

        doc.addPage();
}

const fetchImage = async (url: string): Promise<HTMLImageElement> => {
  try {
    const response = await Axios.get(url, { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'image/jpeg' });
    const imageURL = URL.createObjectURL(blob);

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
        // Nous révoquerons l'URL blob après l'ajout de l'image au PDF
      };
      img.onerror = (event) => {
        console.error('Erreur lors du chargement de l\'image', event);
        reject(event);
      };
      img.src = imageURL;
    });
  } catch (error) {
    console.error('Erreur lors de la requête pour récupérer l\'image', error);
    throw error;
  }
};

const addEventImage = async (doc: jsPDF, imageUrl: string, currentY: number, columnWidth: number, lineHeight: number, pageHeight:number) => {
  const img = await fetchImage(imageUrl);
  const isLandscape = img.width > img.height;
  let width = isLandscape ? 90 : 60 * (img.width / img.height);
  let height = isLandscape ? 90 * (img.height / img.width) : 60;

  var changePage=false;

  var resultChgtCol=controleChgtColonnePhoto(height, currentY, lineHeight, columnWidth, pageHeight);

  if(resultChgtCol)
  {
    changePage=true;
    doc.addPage();
    currentY=10;
  }

  const positionX = (columnWidth/2-width)/2+5;
  const positionY = currentY + lineHeight +1;

  doc.addImage(img.src, 'JPEG', positionX, positionY, width, height);
  URL.revokeObjectURL(img.src);

  return { height, changePage};

};

const controleChgtColonne = (evenement:any, type: string, nouvElement: boolean, currentY: number, lineHeight: number, currentColumn: number, columnWidth: number, currentX: number, xOffset: number, heightDoc: number) => {
   var newPage=false;
   var newColumn=false;
   
   // Passer à la colonne suivante si la colonne actuelle est pleine
   if ((currentY + lineHeight > heightDoc) || (nouvElement && (currentY + lineHeight * 5 > heightDoc-5)))
     {
      currentColumn++;
      newColumn=true;
      if (currentColumn > 4) {
          // Si toutes les colonnes sont remplies, créer une nouvelle page
          //doc.addPage();
          newPage=true;
        
          currentColumn = 1; // Revenir à la première colonne sur la nouvelle page
      }
      if(type === "feuille")
      {
        currentX = (currentColumn - 1) * (columnWidth + 6);
        xOffset = (columnWidth / 2) + currentX;
        currentY = 20; // Réinitialiser la position verticale
      }
      else
      {
        currentX = 3;
        if(evenement.typeEv !== "T")
        {
          xOffset = (columnWidth / 2)+ (columnWidth / 4) + currentX;
        }
        else
        {      
          xOffset = (columnWidth / 2)+ currentX;
        }
      }
  } 
  else if(type === "carnet")
  {
    if(evenement.typeEv !== "T")
    {
      xOffset = (columnWidth / 2)+ (columnWidth / 4) + currentX;
    }
    else
    {      
      xOffset = (columnWidth / 2)+ currentX;
    }
  }

  return { nouvCurrentY: currentY, nouvCurrentX: currentX, nouvXOffset: xOffset, nouvCurrentColumn: currentColumn, newPage, newColumn};
}

const controleChgtColonnePhoto = (hauteurPhoto: number, currentY: number, lineHeight: number, columnWidth: number, heightDoc: number) => {

    // Passer à la page suivante si la page actuelle est pleine
    if (currentY + hauteurPhoto + 8 > heightDoc)
    {
     return true;
    }
    else
    {
      return false;
    }
}

const decoupeLibelle = (type: string, evenement:any, xOffset: number, currentY: number, libelle: string, doc: any, lineHeight: number, nbLignes: number, isChgtLigne: boolean, currentColumn: number, columnWidth: number, currentX: number, pageHeight: number, currentYInit:number) : any => {
                    
                    var OldNbLignes=nbLignes;
                    var tabLib=libelle.split("\n");
                    var nbCarMax=28;
                    var chgtColonneDec=false;

                    if(type === "carnet")
                    {
                      if(evenement.type === "T")
                      {
                        nbCarMax=83;
                      }
                      else
                      {
                        nbCarMax=40;
                      }
                    }

                    if(lineHeight < 4)
                    {
                      nbCarMax=48;
                      if(type === "carnet")
                      {
                        if(evenement.type === "T")
                        {
                          nbCarMax=140;
                        }
                        else
                        {
                          nbCarMax=70;
                        }
                      }
                    }

                    if(tabLib.length > 1)
                    {
                      for(var i=0; i<tabLib.length; i++)
                      {

                        var resultDecoup=decoupeLibelle(type, evenement, xOffset, currentY, tabLib[i], doc, lineHeight, nbLignes, true, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                        currentY=resultDecoup.nouvCurrentY;
                        nbLignes=resultDecoup.nouvNbLignes;
                        currentYInit=resultDecoup.nouvCurrentYInit;
                        currentX=resultDecoup.nouvCurrentX;
                        xOffset=resultDecoup.nouvXOffset; 
                        currentColumn=resultDecoup.nouvCurrentColumn;
                        if(resultDecoup.nouvChgtColonne)
                        {
                          chgtColonneDec=resultDecoup.nouvChgtColonne;
                        }
                      }
                    }
                    else
                    {
                      if(libelle.length > nbCarMax)
                      {
                        var tabLib=libelle.split(" ");

                        if(tabLib.length > 1)
                        {   
                          var libAff=tabLib[0];
                          var libAffTmp="";
                          for(var i=1; i<tabLib.length; i++)
                          {
                            libAffTmp=libAff+" "+tabLib[i];
                            if(libAffTmp.length <= nbCarMax)
                            {
                              libAff=libAffTmp;
                              
                            }
                            else
                            {
                              var resultChgtCol=controleChgtColonne(evenement, type, false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                              if(resultChgtCol.newColumn)
                              {
                                if(evenement.typeEv === 'L')
                                {
                                  if(evenement.type !== null && evenement.type === "O")
                                  {
                                    doc.setDrawColor(240, 226, 68);
                                  }
                                  else
                                  {
                                    doc.setDrawColor(198, 198, 198);
                                  }
                                } 
                                else if(evenement.typeEv === 'T')
                                {
                                  if(evenement.location !== null && evenement.location === "O")
                                  {
                                    doc.setDrawColor(107, 33, 168);
                                  }
                                  else
                                  { 
                                    doc.setDrawColor(34, 197, 95);
                                  }
                                } 
                                else if(evenement.typeEv === 'N')
                                {
                                  doc.setDrawColor(46, 77, 176);
                                }
                    
                                doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                                if(lineHeight < 4)
                                {
                                  currentYInit=resultChgtCol.nouvCurrentY-lineHeight*2;
                                }
                                else
                                {
                                  currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                                }

                                nbLignes=1;

                                chgtColonneDec=true;
                                if(type === "carnet")
                                {
                                  doc.addPage();
                                  currentColumn=1;
                                  currentX=3;
                                  if(evenement.typeEv !== "T")
                                  {
                                    xOffset=(columnWidth / 2)+ (columnWidth / 4)+ currentX;
                                  }
                                  else
                                  {
                                    xOffset=columnWidth/2+ currentX;
                                  }
                                  currentY=10;
                                  currentYInit=5;      
                                }
          
                              }
          
                              if(type !== "carnet")
                              {
                                  currentY=resultChgtCol.nouvCurrentY;
                                  currentX=resultChgtCol.nouvCurrentX;
                                  xOffset=resultChgtCol.nouvXOffset; 
                                  currentColumn=resultChgtCol.nouvCurrentColumn;
              
                                  if(resultChgtCol.newPage)
                                  {
                                    doc.addPage();
                                  }
                                  
                              }

                              doc.text(libAff, xOffset, currentY, {align: 'center'});
                              libAff=tabLib[i];
                              currentY+=lineHeight;
                              nbLignes++;

                            }

                            if(i === tabLib.length-1)
                              { 
                                var resultChgtCol=controleChgtColonne(evenement, type, false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                                if(resultChgtCol.newColumn)
                              {
                                if(evenement.typeEv === 'L')
                                {
                                  if(evenement.type !== null && evenement.type === "O")
                                  {
                                    doc.setDrawColor(240, 226, 68);
                                  }
                                  else
                                  {
                                    doc.setDrawColor(198, 198, 198);
                                  }
                                } 
                                else if(evenement.typeEv === 'T')
                                {
                                  if(evenement.location !== null && evenement.location === "O")
                                  {
                                    doc.setDrawColor(107, 33, 168);
                                  }
                                  else
                                  { 
                                    doc.setDrawColor(34, 197, 95);
                                  }
                                } 
                                else if(evenement.typeEv === 'N')
                                {
                                  doc.setDrawColor(46, 77, 176);
                                }
                      
                                  doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);
            
                                  if(lineHeight < 4)
                                  {
                                    currentYInit=resultChgtCol.nouvCurrentY-lineHeight*2;
                                  }
                                  else
                                  {
                                    currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                                  }
                                  nbLignes=1;

                                  chgtColonneDec=true;
            
                                  if(type === "carnet")
                                  {
                                    doc.addPage();
                                    currentColumn=1;
                                    currentX=3;
                                    if(evenement.typeEv !== "T")
                                  {
                                    xOffset=(columnWidth / 2)+ (columnWidth / 4)+ currentX;
                                  }
                                  else
                                  {
                                    xOffset=columnWidth/2+ currentX;
                                  }
                                    currentY=10;
                                    currentYInit=5;      
                                  }
            
                                }
            
                                if(type !== "carnet")
                                {
                                    currentY=resultChgtCol.nouvCurrentY;
                                    currentX=resultChgtCol.nouvCurrentX;
                                    xOffset=resultChgtCol.nouvXOffset; 
                                    currentColumn=resultChgtCol.nouvCurrentColumn;
                
                                    if(resultChgtCol.newPage)
                                    {
                                      doc.addPage();
                                    }
                                    
                                }

                                doc.text(libAff, xOffset, currentY, {align: 'center'});
                                currentY+=lineHeight;
                                nbLignes++;
                              }

                          }
                        }
                        else
                        {
                          var resultChgtCol=controleChgtColonne(evenement, type, false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                          
                          if(resultChgtCol.newColumn)
                          {
                            if(evenement.typeEv === 'L')
                            {
                              if(evenement.type !== null && evenement.type === "O")
                              {
                                doc.setDrawColor(240, 226, 68);
                              }
                              else
                              {
                                doc.setDrawColor(198, 198, 198);
                              }
                            } 
                            else if(evenement.typeEv === 'T')
                            {
                              if(evenement.location !== null && evenement.location === "O")
                              {
                                doc.setDrawColor(107, 33, 168);
                              }
                              else
                              { 
                                doc.setDrawColor(34, 197, 95);
                              }
                            } 
                            else if(evenement.typeEv === 'N')
                            {
                              doc.setDrawColor(46, 77, 176);
                            }
                  
                              doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);
        
                              if(lineHeight < 4)
                              {
                                currentYInit=resultChgtCol.nouvCurrentY-lineHeight*2;
                              }
                              else
                              {
                                currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                              }

                              nbLignes=1;
        
                              chgtColonneDec=true;
                              if(type === "carnet")
                              {
                                doc.addPage();
                                currentColumn=1;
                                currentX=3;
                                if(evenement.typeEv !== "T")
                                  {
                                    xOffset=(columnWidth / 2)+ (columnWidth / 4)+ currentX;
                                  }
                                  else
                                  {
                                    xOffset=columnWidth/2+ currentX;
                                  }
                                currentY=10;
                                currentYInit=5;      
                              }
        
                            }
        
                            if(type !== "carnet")
                            {
                                currentY=resultChgtCol.nouvCurrentY;
                                currentX=resultChgtCol.nouvCurrentX;
                                xOffset=resultChgtCol.nouvXOffset; 
                                currentColumn=resultChgtCol.nouvCurrentColumn;
            
                                if(resultChgtCol.newPage)
                                {
                                  doc.addPage();
                                }
                                
                            }
                          doc.text(libelle.substring(0,nbCarMax), xOffset, currentY, {align: 'center'});
                          currentY+=lineHeight;
                          nbLignes++;

                          var resultChgtCol=controleChgtColonne(evenement, type, false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                          
                                if(resultChgtCol.newColumn)
                              {
                                if(evenement.typeEv === 'L')
                                {
                                  if(evenement.type !== null && evenement.type === "O")
                                  {
                                    doc.setDrawColor(240, 226, 68);
                                  }
                                  else
                                  {
                                    doc.setDrawColor(198, 198, 198);
                                  }
                                } 
                                else if(evenement.typeEv === 'T')
                                {
                                  if(evenement.location !== null && evenement.location === "O")
                                  {
                                    doc.setDrawColor(107, 33, 168);
                                  }
                                  else
                                  { 
                                    doc.setDrawColor(34, 197, 95);
                                  }
                                } 
                                else if(evenement.typeEv === 'N')
                                {
                                  doc.setDrawColor(46, 77, 176);
                                }
                      
                                  doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);
            
                                  if(lineHeight < 4)
                                  {
                                    currentYInit=resultChgtCol.nouvCurrentY-lineHeight*2;
                                  }
                                  else
                                  {
                                    currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                                  }
                                  nbLignes=1;

                                  chgtColonneDec=true;
            
                                  if(type === "carnet")
                                  {
                                    doc.addPage();
                                    currentColumn=1;
                                    currentX=3;
                                    if(evenement.typeEv !== "T")
                                  {
                                    xOffset=(columnWidth / 2)+ (columnWidth / 4)+ currentX;
                                  }
                                  else
                                  {
                                    xOffset=columnWidth/2+ currentX;
                                  }
                                    currentY=10;
                                    currentYInit=5;      
                                  }
            
                                }
            
                                if(type !== "carnet")
                                {
                                    currentY=resultChgtCol.nouvCurrentY;
                                    currentX=resultChgtCol.nouvCurrentX;
                                    xOffset=resultChgtCol.nouvXOffset; 
                                    currentColumn=resultChgtCol.nouvCurrentColumn;
                
                                    if(resultChgtCol.newPage)
                                    {
                                      doc.addPage();
                                    }
                                    
                                }

                          doc.text(libelle.substring(nbCarMax), xOffset, currentY, {align: 'center'});
                          currentY+=lineHeight;
                          nbLignes++;
                        }
                      }
                      else
                      {
                        var resultChgtCol=controleChgtColonne(evenement, type, false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                        
                        if(resultChgtCol.newColumn)
                        {
                          if(evenement.typeEv === 'L')
                          {
                            if(evenement.type !== null && evenement.type === "O")
                            {
                              doc.setDrawColor(240, 226, 68);
                            }
                            else
                            {
                              doc.setDrawColor(198, 198, 198);
                            }
                          } 
                          else if(evenement.typeEv === 'T')
                          {
                            if(evenement.location !== null && evenement.location === "O")
                            {
                              doc.setDrawColor(107, 33, 168);
                            }
                            else
                            { 
                              doc.setDrawColor(34, 197, 95);
                            }
                          } 
                          else if(evenement.typeEv === 'N')
                          {
                            doc.setDrawColor(46, 77, 176);
                          }
                
                            doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);
      
                            if(lineHeight < 4)
                                {
                                  currentYInit=resultChgtCol.nouvCurrentY-lineHeight*2;
                                }
                                else
                                {
                                  currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                                }

                            nbLignes=1;

                            chgtColonneDec=true;

                            if(type === "carnet")
                            {
                              doc.addPage();
                              currentColumn=1;
                              currentX=3;
                              if(evenement.typeEv !== "T")
                              {
                                xOffset=(columnWidth / 2)+ (columnWidth / 4)+ currentX;
                              }
                              else
                              {
                                xOffset=columnWidth/2+ currentX;
                              }
                              currentY=10;
                              currentYInit=5;      
                            }
      
                          }
      
                          if(type !== "carnet")
                          {
                              currentY=resultChgtCol.nouvCurrentY;
                              currentX=resultChgtCol.nouvCurrentX;
                              xOffset=resultChgtCol.nouvXOffset; 
                              currentColumn=resultChgtCol.nouvCurrentColumn;
          
                              if(resultChgtCol.newPage)
                              {
                                doc.addPage();
                              }
                              
                          }

                        doc.text(libelle, xOffset, currentY, {align: 'center'});
                        currentY+=lineHeight;
                        nbLignes++;
                      }
                    }

                    if(!chgtColonneDec)
                    {
                      if(lineHeight < 4 && !isChgtLigne)
                      {
                        nbLignes=OldNbLignes+((nbLignes-OldNbLignes)/2);
                      }
                    }
                    else
                    {
                      if(lineHeight < 4 && !isChgtLigne)
                      {
                        nbLignes=nbLignes/2;
                      }
                    }

                    return { nouvCurrentY: currentY, nouvNbLignes: nbLignes, nouvCurrentYInit: currentYInit, nouvCurrentX: currentX, nouvXOffset: xOffset, nouvCurrentColumn: currentColumn, nouvChgtColonne: chgtColonneDec};
}

const convertImageToBase64 = (url: string): Promise<{ base64: string; width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/webp');
        resolve({ base64: dataURL, width: img.width, height: img.height });
      } else {
        reject(new Error('Could not get canvas context'));
      }
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
};


// Fonction pour ajouter un cadre rouge autour du texte
const addRedFrame = (doc: any, x: number, y: number, width: number, height: number) => {
  doc.setDrawColor(255, 0, 0); // Rouge
  doc.rect(x, y, width, height, 'D'); // Dessiner le cadre
};

  const handleEdition =  async () => {
      setIsLoading(true);

      if(typeEdition === "pdf")
      {

         try {

          const response = await voyageService.getVoyage(keyVoyage, "", "", langue);

            const tabJournees = response.data.voyage.groupedResults || [];


            var dateDeb=response.data.voyage.date_deb_formate;
            var dateFin=response.data.voyage.date_fin_formate;
            var titreVoyage=response.data.voyage.titre_voyage;

            var affichagePrix=isAffichePrix ? "O" : "N";
            var affichageBlocs=isAfficheBlocNotes ? "O" : "N";
            var affichageConfirmations=isAfficheConfirmations ? "O" : "N";
            var affichageCoordonnees=isAfficheGPS ? "O" : "N";
            var numDroit=response.data.voyage.num_droit;

            if(numDroit === 2 && affichagePrix === "O" && response.data.voyage.affichage_prix === "N")
            {
              affichagePrix="N";
            }
            if(numDroit === 2 && affichageBlocs === "O" && response.data.voyage.affichage_blocs === "N")
            {
              affichageBlocs="N";
            }
            if(numDroit === 2 && affichageConfirmations === "O" && response.data.voyage.affichage_confirmations === "N")
            {
              affichageConfirmations="N";
            }

            if(titreVoyage !== '')
            {
              titreVoyage = titreVoyage+" - "+t('du')+" "+dateDeb+" "+t('au')+" "+dateFin;
            }
            else
            {
              titreVoyage = t('itineraireVoyage')+" "+t('du')+" "+dateDeb+" "+t('au')+" "+dateFin;
            }

            var monnaie=response.data.voyage.monnaie;

            let monnaieSymb="";
   
     switch(monnaie) {
       case 'euro':
         monnaieSymb='€';
         break;
       case 'dollar':
        monnaieSymb='$';
         break;
       case 'pound':
        monnaieSymb='£';
           break;
       case 'yen':
        monnaieSymb='¥';
             break;
       case 'won':
        monnaieSymb='₩';
               break;
       case 'rupee':
        monnaieSymb='₹';
             break;
             case 'indian-rupee':
              monnaieSymb='₹';
                 break;
             case 'baht':
              monnaieSymb='฿';
                 break;
             case 'brazilian-real':
              monnaieSymb='R$';
                 break;
             case 'dong':
              monnaieSymb='₫';
                 break;
             case 'peso':
              monnaieSymb='₱';
                 break;
             case 'ruble':
              monnaieSymb='₽';
                 break;
                 case 'chf':
                  monnaieSymb='CHF';
                     break;
       default:
        monnaieSymb='€';
         
       }

            var doc = new jsPDF({  
              orientation: 'landscape', 
              unit: 'mm',         
              format: 'a4',   
          });
          const fontSize = 14; // Taille de police
          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight()
          const columnWidth = (pageWidth / 4)-6; // Largeur de chaque colonne
          const lineHeight = 4; // Hauteur de ligne

          let currentColumn = 1; // Colonnes actuelle (commence à la première colonne)
          let currentX = (currentColumn - 1) * columnWidth + 4; // Position horizontale de départ pour la première colonne
          let currentY = 20; // Position verticale de départ
      
          let currentYInit=0;
          let nbLignes=0;

          // Calculer la largeur du texte
          const textWidth = doc.getTextWidth(titreVoyage);
      
          // Calculer la position horizontale centrée
          const titreX = (pageWidth - textWidth) / 2;
      
          // Ajouter le titre centré
          doc.setFontSize(fontSize);
          var xOffset = doc.internal.pageSize.width / 2

            var index=0;

           for (const journee of tabJournees) {
            
            if(currentColumn === 1)
              {

                doc.setFontSize(7);
                doc.setFont("Helvetica", "italic");
                doc.text("Edité le "+getCurrentDate(), 272, 207);
                doc.setFont("Helvetica", "normal");
                doc.setFontSize(10);

                doc.setFontSize(fontSize);
                xOffset = doc.internal.pageSize.width / 2
                doc.text(titreVoyage, xOffset, 10, {align: 'center'}); // Ajustez la position verticale selon vos besoins
        
                // Calculer la hauteur du texte
                const titleHeight = fontSize;
            
                // Ajouter le cadre rouge autour du texte
                addRedFrame(doc, titreX - 2, 3, textWidth + 2, titleHeight - 4);
    
                doc.addImage(logoPDFImg, 'JPEG', 270, 4, 20, 8);
              }

            if(index > 0)
            {
              currentColumn++;
              if (currentColumn > 4) {
                  // Si toutes les colonnes sont remplies, créer une nouvelle page
                  doc.addPage();
                  currentColumn = 1; // Revenir à la première colonne sur la nouvelle page
                  currentX = (currentColumn - 1) * columnWidth + 4;
              }
              else
              {
                //currentX = (currentColumn - 1) * columnWidth + 10; // Définir la position horizontale pour la nouvelle colonne
                currentX = currentX + columnWidth + 4; // Définir la position horizontale pour la nouvelle colonne
              }

              currentY = 20; // Réinitialiser la position verticale
            }    

            // Ajouter un rectangle avec un fond gris derrière le texte
            doc.setFillColor("#f0f0f0"); // Gris clair
            doc.rect(currentX, currentY  - lineHeight, columnWidth, lineHeight+2, 'F');

            // Ajouter le texte à la colonne actuelle
            doc.setTextColor("#000000"); // Couleur de texte noir
            doc.setFontSize(11);

            const textWidth2 = doc.getTextWidth("Jour " + journee.num_jour + " - "+ journee.date_journee);
            const textX = currentX + textWidth2 / 2; // Position horizontale centrée pour le texte

            // Ajouter le texte à la colonne actuelle
            //doc.text("Jour " + journee.num_jour + " - "+ journee.date_journee, textX, currentY);
            
            //xOffset = (columnWidth / 2)+ (columnWidth * (currentColumn - 1));
            xOffset = (columnWidth / 2) + currentX;
            doc.text("Jour " + journee.num_jour + " - "+ journee.date_journee, xOffset, currentY, {align: 'center'});

            doc.setFontSize(10);

            currentY += lineHeight;

            journee.evenements.forEach((evenement: any) => {

              currentYInit=currentY;
              nbLignes=0;
              var chgtColonne=false;
              var elementVide=true;

              if(evenement.typeEv === "L")
              {

                if((evenement.libelle1 !== null && evenement.libelle1 !== "") 
                || (evenement.libelle2 !== null && evenement.libelle2 !== "")
                )
                {
                  elementVide=false;
                  var resultChgtCol=controleChgtColonne(evenement, "feuille", true, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                     currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;
                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }


                  if(evenement.type !== null && evenement.type === "O")
                  {
                    doc.addImage(repasImg, 'JPEG', currentX+1, currentY+1, 5, 5);
                  }
                  else
                  {
                    doc.addImage(markerImg, 'JPEG', currentX+1, currentY+1, 5, 5);
                  }
                  nbLignes++;

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.0" && evenement.paiement !== null && evenement.paiement === "paye")
                  {
                    doc.addImage(payeImg, 'JPEG', currentX+columnWidth-7, currentY+1, 5, 5);
                  }

                  currentY+=4;


                  currentY+=lineHeight;
  
                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                      doc.setFont("Helvetica", "bold");

                      var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.libelle1, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                    

                    if(affichageCoordonnees === "O" 
                    && evenement.lat !== null && evenement.lat !== ""
                    && evenement.longi !== null && evenement.longi !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      if(evenement.type !== null && evenement.type === "O")
                      {
                        doc.setDrawColor(240, 226, 68);
                      }
                      else
                      {
                        doc.setDrawColor(198, 198, 198);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;
                      nbLignes++;
                      doc.setFontSize(10);
                    }
 
                  }
                  
                  if(evenement.libelle2 !== null && evenement.libelle2 !== "")
                  {
                    if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                    {
                      currentY+=3;
                      nbLignes++;
                    } 

                    var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.libelle2, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                    currentY=resultDecoup.nouvCurrentY;
                    nbLignes=resultDecoup.nouvNbLignes;
                    currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      currentY+=lineHeight/2;

                  }

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.00")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      if(evenement.type !== null && evenement.type === "O")
                      {
                        doc.setDrawColor(240, 226, 68);
                      }
                      else
                      {
                        doc.setDrawColor(198, 198, 198);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                    currentY+=lineHeight/2;
                    nbLignes++;

                    doc.text(evenement.prix+" "+monnaieSymb, xOffset, currentY, {align: 'center'});
                    currentY+=lineHeight;

                    nbLignes=nbLignes+0.5;
                  }

                  if(affichageBlocs === "O" 
                    && evenement.bloc !== null && evenement.bloc !== "")
                    {

                      doc.addImage(blocImg, 'JPEG', xOffset-2, currentY, 4, 4);

                      currentY+=lineHeight/2;
                      currentY+=lineHeight;
                      nbLignes++;

                      doc.setFont("Helvetica", "italic");
                      doc.setFontSize(6);

                      var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.bloc, doc, lineHeight-2, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                      doc.setFontSize(10);

                      currentY+=lineHeight/2;
                      nbLignes++;
                    }
                  if(evenement.type !== null && evenement.type === "O")
                  {
                    doc.setDrawColor(240, 226, 68);
                  }
                  else
                  {
                    doc.setDrawColor(198, 198, 198);
                  }
                  // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                  doc.roundedRect(currentX, currentYInit, columnWidth, nbLignes*lineHeight+3, 5, 5);

                  if(chgtColonne)
                  {
                    doc.setFillColor(255, 255, 255);
                    doc.rect(currentX, currentYInit-1, columnWidth, 2, 'F');
                    doc.rect(currentX, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX, currentYInit+2, 2, 1, 'F');
                    doc.rect(currentX+columnWidth-2, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX+columnWidth-1, currentYInit+2, 2, 1, 'F');
                  }
                
                }

              }
              else if(evenement.typeEv === "T")
              {
                if((evenement.debut !== null && evenement.debut !== "")
                || (evenement.libelle1 !== null && evenement.libelle1 !== "") 
                || (evenement.fin !== null && evenement.fin !== "")
                || (evenement.libelle2 !== null && evenement.libelle2 !== "")
                )
                {
                  elementVide=false;
                  var resultChgtCol=controleChgtColonne(evenement, "feuille", true, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {                  
                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;
                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }


                  var decalageBarre=0;

                  if(affichageCoordonnees === "O" 
                  && evenement.lat !== null && evenement.lat !== ""
                  && evenement.longi !== null && evenement.longi !== "")
                  {
                    decalageBarre=4;
                  }

                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                    if(evenement.libelle1.length > 30)
                    {
                      decalageBarre+=lineHeight;
                    }
                  }  

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.0" && evenement.paiement !== null && evenement.paiement === "paye")
                  {
                    doc.addImage(payeImg, 'JPEG', currentX+columnWidth-7, currentY+1, 5, 5);
                  }

                  if(evenement.location === null || evenement.location !== "O")
                  {
                    if(((evenement.libelle1 != null && evenement.libelle1 !== "")
                    || (evenement.debut != null && evenement.debut !== ""))
                     && ((evenement.libelle2 != null && evenement.libelle2 !== "")
                    || (evenement.fin != null && evenement.fin !== "")))
                    {
                      if(((evenement.libelle1 != null && evenement.libelle1 !== "")
                      && (evenement.debut != null && evenement.debut !== ""))
                      && ((evenement.libelle2 != null && evenement.libelle2 !== "")
                      && (evenement.fin != null && evenement.fin !== "")))
                      {
                        decalageBarre+=lineHeight;
                      }
                      doc.addImage(barreImg, 'JPEG', currentX+16, currentY+1+lineHeight+decalageBarre, 4, 20);
                      
                    }
                    else if((evenement.libelle2 != null && evenement.libelle2 !== "")
                    || (evenement.fin != null && evenement.fin !== ""))
                    {
                      doc.addImage(barre2Img, 'JPEG', currentX+16, currentY+2+decalageBarre, 4, 18);
                    }
                    else
                    {       
                      decalageBarre+=lineHeight;
                      decalageBarre+=lineHeight;
                      doc.addImage(barre1Img, 'JPEG', currentX+16, currentY+2+decalageBarre, 4, 18);
                    }
  
                  }
                  
                  if(evenement.type !== null && evenement.type !== "")
                      {
                      switch (evenement.type) {
                        case 'plane':
                          doc.addImage(planeImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          break;
                        case 'ship':
                          doc.addImage(shipImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          break;
                        case 'train':
                          doc.addImage(trainImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          break;
                        case 'bus':
                          doc.addImage(busImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                           break;
                        case 'taxi':
                          doc.addImage(taxiImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          break;
                        case 'car':
                          if(evenement.location !== null && evenement.location === "O")
                          {
                            doc.addImage(carLocImg, 'JPEG', currentX+4, currentY+7+lineHeight+lineHeight+decalageBarre, 9, 9);
                          }
                          else
                          {
                            doc.addImage(carImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          }
                          break;
                        case 'motorcycle':
                          if(evenement.location !== null && evenement.location === "O")
                          {
                            doc.addImage(motorcycleLocImg, 'JPEG', currentX+4, currentY+7+lineHeight+lineHeight+decalageBarre, 9, 9);
                          }
                          else
                          {
                            doc.addImage(motorcycleImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          }
                          break; 
                        case 'bicycle':
                          if(evenement.location !== null && evenement.location === "O")
                          {
                            doc.addImage(bicycleLocImg, 'JPEG', currentX+4, currentY+7+lineHeight+lineHeight+decalageBarre, 9, 9);
                          }
                          else
                          {
                            doc.addImage(bicycleImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          }
                          break;
                        case 'walking':
                          doc.addImage(walkingImg, 'JPEG', currentX+4, currentY+2+lineHeight+lineHeight+decalageBarre, 9, 9);
                          break;
                        default:
                          break;
                      }
                      if(evenement.distance !== null && evenement.distance != "")
                      {
                        doc.setFontSize(8);
                        doc.text(evenement.distance+" km", currentX+8, currentY+2+lineHeight+lineHeight+decalageBarre+12, {align: 'center'});

                        if(evenement.duree !== null && evenement.duree != "")
                        {
                          doc.text(evenement.duree, currentX+8, currentY+2+lineHeight+lineHeight+decalageBarre+13+lineHeight/2, {align: 'center'});
                        }
                        doc.setFontSize(10);
                      }
                    } 
                  

                  nbLignes++;
                  currentY+=lineHeight;

                  if(evenement.location !== null && evenement.location === "O" && evenement.moment === "P")
                  {
                    doc.setFont("Helvetica", "italic");
                    doc.setFontSize(9);
                    doc.text("Prise en charge", xOffset-(columnWidth/2)+2, currentY, {align: 'left'});
                    doc.setFont("Helvetica", "normal");
                    doc.setFontSize(10);
                    currentY+=lineHeight;
                    nbLignes++;
                  }

                  if(evenement.location !== null && evenement.location === "O" && evenement.moment === "R")
                  {
                    doc.setFont("Helvetica", "italic");
                    doc.setFontSize(9);
                    doc.text("Restitution", xOffset-(columnWidth/2)+2, currentY, {align: 'left'});
                    doc.setFont("Helvetica", "normal");
                    doc.setFontSize(10);
                    currentY+=lineHeight;
                    nbLignes++;
                  }
    
                  if(evenement.debut !== null && evenement.debut !== "")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                    if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                    doc.text(evenement.debut, xOffset, currentY, {align: 'center'});
                    currentY+=lineHeight;
                    nbLignes++;
                  }
                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                    doc.setFont("Helvetica", "bold");

                    var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.libelle1, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                    currentY=resultDecoup.nouvCurrentY;
                    nbLignes=resultDecoup.nouvNbLignes;
                    currentYInit=resultDecoup.nouvCurrentYInit;
                    currentX=resultDecoup.nouvCurrentX;
                    xOffset=resultDecoup.nouvXOffset; 
                    currentColumn=resultDecoup.nouvCurrentColumn;
                    if(!chgtColonne) {
                     chgtColonne=resultDecoup.nouvChgtColonne;
                    }

                    doc.setFont("Helvetica", "normal");

                    if(affichageCoordonnees === "O" 
                    && evenement.lat !== null && evenement.lat !== ""
                    && evenement.longi !== null && evenement.longi !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                      if(resultChgtCol.newColumn)
                      {
                        if(evenement.location !== null && evenement.location === "O")
                        {
                         doc.setDrawColor(107, 33, 168);
                        }
                        else
                        { 
                          doc.setDrawColor(34, 197, 95);
                        }
            
                        doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);
  
                        currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                        nbLignes=1;

                        if(!chgtColonne) {
                         chgtColonne=true;
                        }
  
                      }
  
                      currentY=resultChgtCol.nouvCurrentY;
                      currentX=resultChgtCol.nouvCurrentX;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
  
                      if(resultChgtCol.newPage)
                      {
                        doc.addPage();
                      }
  
                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;
                      nbLignes++;
                      doc.setFontSize(10);
                    }
                  }

                  if(evenement.location === null || evenement.location !== "O" || evenement.moment !== "R")
                  {
                    currentY+=lineHeight;
                    nbLignes++;
                  }

                  if(evenement.compagnie !== null && evenement.compagnie !== "")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);
  
                    if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }


                    doc.text(evenement.compagnie, xOffset + (columnWidth / 2) -2, currentY, {align: 'right'});
                  }
                  currentY+=lineHeight;
                  nbLignes++;
                  if(evenement.ref !== null && evenement.ref !== "")
                  {
                    doc.text(evenement.ref, xOffset + (columnWidth / 2) -2, currentY, {align: 'right'});
                  }

                  
                  if(evenement.location === null || evenement.location !== "O")
                  {
                    currentY+=lineHeight;
                    nbLignes++;

                    currentY+=lineHeight;
                    nbLignes++;
                  }

                  if(evenement.fin !== null && evenement.fin !== "")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                    if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                       chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                    doc.text(evenement.fin, xOffset, currentY, {align: 'center'});
                  }
                  currentY+=lineHeight;
                  nbLignes++;
                  if(evenement.libelle2 !== null && evenement.libelle2 !== "")
                  {
                     doc.setFont("Helvetica", "bold");

                      var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.libelle2, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      doc.setFont("Helvetica", "normal");

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                    if(affichageCoordonnees === "O" 
                    && evenement.lat2 !== null && evenement.lat2 !== ""
                    && evenement.longi2 !== null && evenement.longi2 !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                      if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat2, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi2, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight;
                      nbLignes++;
                      nbLignes++;
                      doc.setFontSize(10);

                    }
                  }

                  if(affichagePrix === "O" 
                    && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.00")
                    {
                      currentY+=lineHeight/2;
                      var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                     if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                      doc.text(evenement.prix+" "+monnaieSymb, xOffset, currentY, {align: 'center'});
                        currentY+=lineHeight/2;
                        nbLignes++;
                    }

                    if(affichageBlocs === "O" 
                    && evenement.bloc !== null && evenement.bloc !== "")
                    {

                      doc.addImage(blocImg, 'JPEG', xOffset-2, currentY, 4, 4);

                      currentY+=lineHeight/2;
                      currentY+=lineHeight;
                      nbLignes++;

                      doc.setFont("Helvetica", "italic");
                      doc.setFontSize(6);

                      var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.bloc, doc, lineHeight-2, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                      doc.setFontSize(10);

                      currentY+=lineHeight/2;
                      nbLignes++;
                    }

                  if(evenement.location !== null && evenement.location === "O")
                  {
                    doc.setDrawColor(107, 33, 168);
                  }
                  else
                  {
                    doc.setDrawColor(34, 197, 95);
                  }
                  // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                  doc.roundedRect(currentX, currentYInit, columnWidth, nbLignes*lineHeight, 5, 5);

                  if(chgtColonne)
                  {
                    doc.setFillColor(255, 255, 255);
                    doc.rect(currentX, currentYInit-1, columnWidth, 2, 'F');
                    doc.rect(currentX, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX, currentYInit+2, 2, 1, 'F');
                    doc.rect(currentX+columnWidth-2, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX+columnWidth-1, currentYInit+2, 2, 1, 'F');
                  }
  
                }          
              }
              else if(evenement.typeEv === "N")
              {
                if((evenement.libelle1 !== null && evenement.libelle1 !== "") 
                || (evenement.libelle2 !== null && evenement.libelle2 !== "")
                )
                {
                  elementVide=false;
                  var resultChgtCol=controleChgtColonne(evenement, "feuille", true, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;
                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                  doc.addImage(bedImg, 'JPEG', currentX+1, currentY+1, 5, 5);
                  
                  nbLignes++;

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.0" && evenement.paiement !== null && evenement.paiement === "paye")
                  {
                    doc.addImage(payeImg, 'JPEG', currentX+columnWidth-7, currentY+1, 5, 5);
                  }

                  currentY+=4;


                  currentY+=lineHeight;
  
                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                      if(evenement.libelle2 === null || evenement.libelle2 === "")
                      {
                        doc.setFont("Helvetica", "bold");
                      }

                      var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.libelle1, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");

                    if(affichageCoordonnees === "O" 
                    && evenement.lat !== null && evenement.lat !== ""
                    && evenement.longi !== null && evenement.longi !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      doc.setDrawColor(46, 77, 176);
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;
                      nbLignes++;
                      doc.setFontSize(10);
                    }
 
                  }
                  
                  if(evenement.libelle2 !== null && evenement.libelle2 !== "")
                  {
                    if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                    {
                      currentY+=3;
                      nbLignes++;
                    } 

                    doc.setFont("Helvetica", "bold");

                    var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.libelle2, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                    currentY=resultDecoup.nouvCurrentY;
                    nbLignes=resultDecoup.nouvNbLignes;
                    currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                    doc.setFont("Helvetica", "normal");

                    if(affichageCoordonnees === "O" 
                    && evenement.lat2 !== null && evenement.lat2 !== ""
                    && evenement.longi2 !== null && evenement.longi2 !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      doc.setDrawColor(46, 77, 176);
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat2, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi2, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight;
                      nbLignes++;
                      doc.setFontSize(10);
                    }

                  }

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.00")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "feuille", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      doc.setDrawColor(46, 77, 176);
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                    }

                    currentY=resultChgtCol.nouvCurrentY;
                    currentX=resultChgtCol.nouvCurrentX;
                    xOffset=resultChgtCol.nouvXOffset; 
                    currentColumn=resultChgtCol.nouvCurrentColumn;

                    if(resultChgtCol.newPage)
                    {
                      doc.addPage();
                    }

                    currentY+=lineHeight/2;
                    nbLignes++;

                    doc.text(evenement.prix+" "+monnaieSymb, xOffset, currentY, {align: 'center'});
                    currentY+=lineHeight/2;
                  }

                  if(affichageBlocs === "O" 
                    && evenement.bloc !== null && evenement.bloc !== "")
                    {

                      doc.addImage(blocImg, 'JPEG', xOffset-2, currentY, 4, 4);

                      currentY+=lineHeight/2;
                      currentY+=lineHeight;
                      nbLignes++;

                      doc.setFont("Helvetica", "italic");
                      doc.setFontSize(6);

                      var resultDecoup=decoupeLibelle('feuille', evenement, xOffset, currentY, evenement.bloc, doc, lineHeight-2, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                      doc.setFontSize(10);

                      currentY+=lineHeight/2;
                      nbLignes++;
                    }
                  
                  doc.setDrawColor(46, 77, 176);
                  // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                  doc.roundedRect(currentX, currentYInit, columnWidth, nbLignes*lineHeight+3, 5, 5);

                  if(chgtColonne)
                  {
                    doc.setFillColor(255, 255, 255);
                    doc.rect(currentX, currentYInit-1, columnWidth, 2, 'F');
                    doc.rect(currentX, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX, currentYInit+2, 2, 1, 'F');
                    doc.rect(currentX+columnWidth-2, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX+columnWidth-1, currentYInit+2, 2, 1, 'F');
                  }
                }
              }
    
            // Passer à la colonne suivante si la colonne actuelle est pleine
            if (currentY + lineHeight > doc.internal.pageSize.getHeight()) {
                currentColumn++;
                if (currentColumn > 4) {
                    // Si toutes les colonnes sont remplies, créer une nouvelle page
                    doc.addPage();
                   
                    currentColumn = 1; // Revenir à la première colonne sur la nouvelle page
                }
                currentX = (currentColumn - 1) * (columnWidth + 6);
                xOffset = (columnWidth / 2) + currentX;
                currentY = 20; // Réinitialiser la position verticale
            } else {
                if(!elementVide)
                {
                  // Sinon, passer à la ligne suivante dans la même colonne
                  currentY += lineHeight;
                }
            }

          //currentColumn++;
          index++; 
          });
        }

        const fetchPdfBytes = async (url: string): Promise<Uint8Array> => {
        const response = await Axios.get(url, { responseType: 'blob' });

        const arrayBuffer = await response.data.arrayBuffer();
        return new Uint8Array(arrayBuffer);
      };
        

      const addImagesToPdf = async () => {
      
        let nbImagesParPage = 0;
        let firstImage=true;
      
        for (const journee of tabJournees) {
          if (affichageConfirmations === "O") {
      
            for (const evenement of journee.evenements) {
              if (evenement.document && !evenement.document.endsWith("pdf")) {
                try {
                  if(firstImage)
                  {
                    doc.addPage();
                    firstImage=false;
                  } 
                    const img = await fetchImage(evenement.document);
                    const isLandscape = img.width > img.height;
                    
                    // Changement de page
                    if (nbImagesParPage === 2) {
                      doc.addPage();
                      currentColumn = 1; // Revenir à la première colonne sur la nouvelle page
                      currentX = (currentColumn - 1) * columnWidth + 4;
      
                      nbImagesParPage = 0;
                    }
      
                    // Ajustez les dimensions de l'image si nécessaire
                    let width = isLandscape ? (pageWidth / 2) - 10 : 180 * (img.width / img.height);
                    let height = isLandscape ? width * (img.height / img.width) : 180;
      
                    if (width > (pageWidth / 2) - 10) {
                      width = (pageWidth / 2) - 10;
                      height = width * (img.height / img.width);
                    }
      
                    const positionX = nbImagesParPage === 0 ? 5 : nbImagesParPage === 1 ? 5 + (pageWidth / 2) : 5;
                    const positionY = 20;
      
                    doc.addImage(img.src, 'JPEG', positionX, positionY, width, height);
      
                    nbImagesParPage++;
                    URL.revokeObjectURL(img.src);
                } catch (error) {
                  console.error('Erreur lors du chargement de l\'image', error);
                }
              }
            }
          }
        }
      };

        const addPdfToPdf = async (pdfDoc: any) => {
        
          let nbImagesParPage = 0;
        
          for (const journee of tabJournees) {
            if (affichageConfirmations === "O") {
        
              for (const evenement of journee.evenements) {
                if (evenement.document && evenement.document.endsWith("pdf")) {
                  try {
                    const existingPdfBytes = await fetchPdfBytes(evenement.document);
                    const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
                
                
                
                    // Copier les pages du PDF existant dans le nouveau PDF
                    const pages = await pdfDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
                    pages.forEach((page: any) => {
                      pdfDoc.addPage(page);
                    });
                  } catch (error) {
                    console.error('Erreur lors du chargement de l\'image', error);
                  }
                }
              }
            }
          }
        };

        if(affichageConfirmations === "O")
        {
          currentColumn = 1; // Revenir à la première colonne sur la nouvelle page
          currentX = (currentColumn - 1) * columnWidth + 4;

          await addImagesToPdf();
        const jsPDFDataUri = doc.output('datauristring');
        const jsPDFArray = dataURIToUint8Array(jsPDFDataUri);

        const jsPDFDoc = await PDFDocument.load(jsPDFArray);

          // Créer un nouveau document PDF
          const pdfDoc = await PDFDocument.create();
      
        // Copier les pages du PDF existant dans le nouveau PDF
        const pagesJspdf = await pdfDoc.copyPages(jsPDFDoc, jsPDFDoc.getPageIndices());
        pagesJspdf.forEach((page: any) => {
          pdfDoc.addPage(page);
        });

          await addPdfToPdf(pdfDoc);

          // Serializer le document PDF en bytes
          const pdfBytesFinal = await pdfDoc.save();

          // Sauvegarder le PDF avec FileSaver
          const blob = new Blob([pdfBytesFinal], { type: 'application/pdf' });
          saveAs(blob, 'tripkygo-feuille-route-'+keyVoyage+'.pdf');

        }
        else
        {
          doc.save('tripkygo-feuille-route-'+keyVoyage+'.pdf');
        }
        setIsLoading(false); // Arrêter le chargement
        closeModal();
      } catch (error: any) {
            setIsLoading(false); // Arrêter le chargement
            if (error.response?.status === 401) {
              alert("Erreur lors de l'édition, vous devez être connecté(e).");
              navigate("/login");
            } else {
              alert("Erreur lors de l'édition");
              closeModal();
            }
          }
      }
      else
      {
        /* PDF AVEC PHOTOS */

        try {

          const response = await voyageService.getVoyage(keyVoyage, "", "", langue);

            const tabJournees = response.data.voyage.groupedResults || [];


            var dateDeb=response.data.voyage.date_deb_formate;
            var dateFin=response.data.voyage.date_fin_formate;
            var titreVoyage=response.data.voyage.titre_voyage;
            var username=response.data.voyage.username;

            let imageCouv: { base64: string; width: number; height: number } | HTMLImageElement | undefined;

            if(response.data.voyage.couverture && response.data.voyage.couverture !== "")
            {
              imageCouv= await fetchImage(response.data.voyage.couverture);
            }
            else
            {
              var imageUrl = require(`../images/countries/${response.data.voyage.id_pays.split("_")[0]}.webp`);

              // Convertir l'image en base64
              imageCouv = await convertImageToBase64(imageUrl);

            }


            if(titreVoyage === null || titreVoyage === '')
            {
              titreVoyage = t('itineraireVoyage');
            }

            var datesVoyage=dateDeb+" - "+dateFin;

            var affichagePhotos="O";
            var affichagePrix=isAffichePrix ? "O" : "N";
            var affichageBlocs=isAfficheBlocNotes ? "O" : "N";
            var affichageConfirmations=isAfficheConfirmations ? "O" : "N";
            var affichageCoordonnees=isAfficheGPS ? "O" : "N";
            var numDroit=response.data.voyage.num_droit;

            if(numDroit === 2 && affichagePrix === "O" && (response.data.voyage.affichage_prix === null || response.data.voyage.affichage_prix !== "O"))
            {
              affichagePrix="N";
            }
            if(numDroit === 2 && affichageBlocs === "O" && (response.data.voyage.affichage_blocs === null || response.data.voyage.affichage_blocs !== "O"))
            {
              affichageBlocs="N";
            }
            if(numDroit === 2 && affichageConfirmations === "O" && (response.data.voyage.affichage_confirmations === null || response.data.voyage.affichage_confirmations !== "O"))
            {
              affichageConfirmations="N";
            }
            if(numDroit === 2 && (response.data.voyage.affichage_photos === null || response.data.voyage.affichage_photos !== "O"))
            {
              affichagePhotos="N";
            }

            var monnaie=response.data.voyage.monnaie;

            let monnaieSymb="";
   
     switch(monnaie) {
       case 'euro':
         monnaieSymb='€';
         break;
       case 'dollar':
        monnaieSymb='$';
         break;
       case 'pound':
        monnaieSymb='£';
           break;
       case 'yen':
        monnaieSymb='¥';
             break;
       case 'won':
        monnaieSymb='₩';
               break;
       case 'rupee':
        monnaieSymb='₹';
             break;
             case 'indian-rupee':
              monnaieSymb='₹';
                 break;
             case 'baht':
              monnaieSymb='฿';
                 break;
             case 'brazilian-real':
              monnaieSymb='R$';
                 break;
             case 'dong':
              monnaieSymb='₫';
                 break;
             case 'peso':
              monnaieSymb='₱';
                 break;
             case 'ruble':
              monnaieSymb='₽';
                 break;
                 case 'chf':
                  monnaieSymb='CHF';
                     break;
       default:
        monnaieSymb='€';
         
       }

            var doc = new jsPDF({  
              orientation: 'portrait', 
              unit: 'mm',         
              format: 'a4',   
          });
          const fontSize = 14; // Taille de police

          doc.addImage(couvertureCarnetImg, 'JPEG', 0, 0, 210, 297);
          
          if ('base64' in imageCouv) {
            doc.addImage(imageCouv.base64, 'JPEG', 0, 0, 210, 210 * (imageCouv.height / imageCouv.width));
          } else {
          doc.addImage(imageCouv, 'JPEG', 0, 0, 210, 210 * (imageCouv.height / imageCouv.width));
          }

          /*doc.setFontSize(42);
          doc.text("CARNET DE VOYAGE", 105, 200, {align: 'center'});
          doc.setFontSize(fontSize);*/

          doc.setFontSize(18);
          doc.text(titreVoyage, 105, 230, {align: 'center'});
          doc.setFontSize(fontSize);

          doc.text(datesVoyage, 105, 240, {align: 'center'});

          doc.addImage(logoPDFImg, 'JPEG', 90, 280, 30, 12);

          if(username !== null && username !== "")
          { 
            doc.setFontSize(11);
            doc.text("par "+username, 126, 288);
            doc.setFontSize(fontSize);
          }

          doc.addPage();

          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight()
          const columnWidth = pageWidth-6; // Largeur de chaque colonne
          const lineHeight = 4; // Hauteur de ligne

          let currentColumn = 1; // Colonnes actuelle (commence à la première colonne)
          let currentX = 3
          let currentY = 10; // Position verticale de départ
      
          let currentYInit=0;
          let nbLignes=0;

          // Calculer la largeur du texte
          const textWidth = doc.getTextWidth(titreVoyage);
      
          // Calculer la position horizontale centrée
          const titreX = (pageWidth - textWidth) / 2;
      
          // Ajouter le titre centré
          doc.setFontSize(fontSize);
          var xOffset = doc.internal.pageSize.width / 2

            var index=0;

           for (const journee of tabJournees) {

            // Ajouter un rectangle avec un fond gris derrière le texte
            doc.setFillColor("#f0f0f0"); // Gris clair
            doc.rect(currentX, currentY  - lineHeight, columnWidth, lineHeight+2, 'F');

            // Ajouter le texte à la colonne actuelle
            doc.setTextColor("#000000"); // Couleur de texte noir
            doc.setFontSize(11);

            const textWidth2 = doc.getTextWidth("Jour " + journee.num_jour + " - "+ journee.date_journee);

            // Ajouter le texte à la colonne actuelle
            //doc.text("Jour " + journee.num_jour + " - "+ journee.date_journee, textX, currentY);
            
            //xOffset = (columnWidth / 2)+ (columnWidth * (currentColumn - 1));
            xOffset = (columnWidth / 2);
            doc.text("Jour " + journee.num_jour + " - "+ journee.date_journee, xOffset, currentY, {align: 'center'});

            doc.setFontSize(10);

            currentY += lineHeight;

            for (const evenement of journee.evenements) {

              currentYInit=currentY;
              nbLignes=0;
              var chgtColonne=false;
              var elementVide=true;
              var hauteurImage=0;

              if(evenement.typeEv === "L")
              {

                if((evenement.libelle1 !== null && evenement.libelle1 !== "") 
                || (evenement.libelle2 !== null && evenement.libelle2 !== "")
                )
                {

                  if(affichagePhotos === "O" && evenement.image !== null && evenement.image !== "")
                  {
                    var resultImage=await addEventImage(doc, evenement.image, currentY-1, columnWidth, lineHeight, pageHeight);

                    hauteurImage=resultImage.height;

                    if(resultImage.changePage)
                    {
                      nbLignes=1;
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=10;
                    }
                  }

                  elementVide=false;
                  var resultChgtCol=controleChgtColonne(evenement, "carnet", true, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                     currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;
                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=10;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }


                  if(evenement.type !== null && evenement.type === "O")
                  {
                    doc.addImage(repasImg, 'JPEG', currentX+1, currentY+1, 5, 5);
                  }
                  else
                  {
                    doc.addImage(markerImg, 'JPEG', currentX+1, currentY+1, 5, 5);
                  }
                  nbLignes++;

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.0" && evenement.paiement !== null && evenement.paiement === "paye")
                  {
                    doc.addImage(payeImg, 'JPEG', currentX+columnWidth-7, currentY+1, 5, 5);
                  }

                  currentY+=4;


                  currentY+=lineHeight;
  
                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                      doc.setFont("Helvetica", "bold");

                      var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.libelle1, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                    

                    if(affichageCoordonnees === "O" 
                    && evenement.lat !== null && evenement.lat !== ""
                    && evenement.longi !== null && evenement.longi !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      if(evenement.type !== null && evenement.type === "O")
                      {
                        doc.setDrawColor(240, 226, 68);
                      }
                      else
                      {
                        doc.setDrawColor(198, 198, 198);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;
                      nbLignes++;
                      doc.setFontSize(10);
                    }
 
                  }
                  
                  if(evenement.libelle2 !== null && evenement.libelle2 !== "")
                  {
                    if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                    {
                      currentY+=3;
                      nbLignes++;
                    } 

                    var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.libelle2, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                    currentY=resultDecoup.nouvCurrentY;
                    nbLignes=resultDecoup.nouvNbLignes;
                    currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      currentY+=lineHeight/2;

                  }

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.00")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      if(evenement.type !== null && evenement.type === "O")
                      {
                        doc.setDrawColor(240, 226, 68);
                      }
                      else
                      {
                        doc.setDrawColor(198, 198, 198);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                    currentY+=lineHeight/2;
                    nbLignes++;

                    doc.text(evenement.prix+" "+monnaieSymb, xOffset, currentY, {align: 'center'});
                    currentY+=lineHeight;

                    nbLignes=nbLignes+0.5;
                  }

                  if(affichageBlocs === "O" 
                    && evenement.bloc !== null && evenement.bloc !== "")
                    {

                      doc.addImage(blocImg, 'JPEG', xOffset-2, currentY, 4, 4);

                      currentY+=lineHeight/2;
                      currentY+=lineHeight;
                      nbLignes++;

                      doc.setFont("Helvetica", "italic");
                      doc.setFontSize(6);

                      var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.bloc, doc, lineHeight-2, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                      doc.setFontSize(10);

                      currentY+=lineHeight/2;
                      nbLignes++;
                    }
                  if(evenement.type !== null && evenement.type === "O")
                  {
                    doc.setDrawColor(240, 226, 68);
                  }
                  else
                  {
                    doc.setDrawColor(198, 198, 198);
                  }

                  if(hauteurImage > nbLignes*lineHeight+3)
                  {
                    // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                    doc.roundedRect(currentX, currentYInit, columnWidth, hauteurImage+lineHeight+4, 5, 5);
                    currentY=currentYInit+hauteurImage+lineHeight+2;
                  }
                  else
                  {
                    // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                    doc.roundedRect(currentX, currentYInit, columnWidth, nbLignes*lineHeight+3, 5, 5);
                  }


                  if(chgtColonne)
                  {
                    doc.setFillColor(255, 255, 255);
                    doc.rect(currentX, currentYInit-1, columnWidth, 2, 'F');
                    doc.rect(currentX, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX, currentYInit+2, 2, 1, 'F');
                    doc.rect(currentX+columnWidth-2, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX+columnWidth-1, currentYInit+2, 2, 1, 'F');
                  }
                
                }

              }
              else if(evenement.typeEv === "T")
              {
                if((evenement.debut !== null && evenement.debut !== "")
                || (evenement.libelle1 !== null && evenement.libelle1 !== "") 
                || (evenement.fin !== null && evenement.fin !== "")
                || (evenement.libelle2 !== null && evenement.libelle2 !== "")
                )
                {
                  elementVide=false;
                  var resultChgtCol=controleChgtColonne(evenement, "carnet", true, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {                  
                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;
                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=columnWidth/2;
                      currentY=10;
                      currentYInit=10;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                  var decalageBarre=0;

                  if(affichageCoordonnees === "O" 
                  && evenement.lat !== null && evenement.lat !== ""
                  && evenement.longi !== null && evenement.longi !== "")
                  {
                    decalageBarre=4;
                  }

                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                    if(evenement.libelle1.length > 30)
                    {
                      decalageBarre+=lineHeight;
                    }
                  }  

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.0" && evenement.paiement !== null && evenement.paiement === "paye")
                  {
                    doc.addImage(payeImg, 'JPEG', currentX+columnWidth-7, currentY+1, 5, 5);
                  }

                  if(evenement.location === null || evenement.location !== "O")
                  {
                    if(((evenement.libelle1 != null && evenement.libelle1 !== "")
                    || (evenement.debut != null && evenement.debut !== ""))
                     && ((evenement.libelle2 != null && evenement.libelle2 !== "")
                    || (evenement.fin != null && evenement.fin !== "")))
                    {
                      if(((evenement.libelle1 != null && evenement.libelle1 !== "")
                      && (evenement.debut != null && evenement.debut !== ""))
                      && ((evenement.libelle2 != null && evenement.libelle2 !== "")
                      && (evenement.fin != null && evenement.fin !== "")))
                      {
                        decalageBarre+=lineHeight;
                      }
                      doc.addImage(barreImg, 'JPEG', columnWidth/2, currentY+1+lineHeight+decalageBarre, 4, 20);
                      
                    }
                    else if((evenement.libelle2 != null && evenement.libelle2 !== "")
                    || (evenement.fin != null && evenement.fin !== ""))
                    {
                      doc.addImage(barre2Img, 'JPEG', columnWidth/2, currentY+2+decalageBarre, 4, 18);
                    }
                    else
                    {       
                      decalageBarre+=lineHeight;
                      decalageBarre+=lineHeight;
                      doc.addImage(barre1Img, 'JPEG', columnWidth/2, currentY+2+decalageBarre, 4, 18);
                    }
  
                  }
                  
                  if(evenement.type !== null && evenement.type !== "")
                      {
                      switch (evenement.type) {
                        case 'plane':
                          doc.addImage(planeImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          break;
                        case 'ship':
                          doc.addImage(shipImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          break;
                        case 'train':
                          doc.addImage(trainImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          break;
                        case 'bus':
                          doc.addImage(busImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                           break;
                        case 'taxi':
                          doc.addImage(taxiImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          break;
                        case 'car':
                          if(evenement.location !== null && evenement.location === "O")
                          {
                            doc.addImage(carLocImg, 'JPEG', (columnWidth/2)-35, currentY+7+lineHeight+decalageBarre+3, 13, 13);
                          }
                          else
                          {
                            doc.addImage(carImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          }
                          break;
                        case 'motorcycle':
                          if(evenement.location !== null && evenement.location === "O")
                          {
                            doc.addImage(motorcycleLocImg, 'JPEG', (columnWidth/2)-35, currentY+7+lineHeight+decalageBarre+3, 13, 13);
                          }
                          else
                          {
                            doc.addImage(motorcycleImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          }
                          break; 
                        case 'bicycle':
                          if(evenement.location !== null && evenement.location === "O")
                          {
                            doc.addImage(bicycleLocImg, 'JPEG', (columnWidth/2)-35, currentY+7+lineHeight+decalageBarre+3, 13, 13);
                          }
                          else
                          {
                            doc.addImage(bicycleImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          }
                          break;
                        case 'walking':
                          doc.addImage(walkingImg, 'JPEG', (columnWidth/2)-35, currentY+lineHeight+decalageBarre+3, 13, 13);
                          break;
                        default:
                          break;
                      }

                      if(evenement.distance !== null && evenement.distance != "")
                      {
                        doc.setFontSize(8);
                        doc.text(evenement.distance+" km", (columnWidth/2)-29, currentY+lineHeight+decalageBarre+19, {align: 'center'});

                        if(evenement.duree !== null && evenement.duree != "")
                        {
                          doc.text(evenement.duree, (columnWidth/2)-29, currentY+lineHeight+decalageBarre+19+lineHeight, {align: 'center'});
                        }
                        doc.setFontSize(10);
                      }
                    } 
                  

                  nbLignes++;
                  currentY+=lineHeight;

                  if(evenement.location !== null && evenement.location === "O" && evenement.moment === "P")
                  {
                    doc.setFont("Helvetica", "italic");
                    doc.setFontSize(9);
                    doc.text("Prise en charge", 5, currentY, {align: 'left'});
                    doc.setFont("Helvetica", "normal");
                    doc.setFontSize(10);
                    currentY+=lineHeight;
                    nbLignes++;
                  }

                  if(evenement.location !== null && evenement.location === "O" && evenement.moment === "R")
                  {
                    doc.setFont("Helvetica", "italic");
                    doc.setFontSize(9);
                    doc.text("Restitution", 5, currentY, {align: 'left'});
                    doc.setFont("Helvetica", "normal");
                    doc.setFontSize(10);
                    currentY+=lineHeight;
                    nbLignes++;
                  }
    
                  if(evenement.debut !== null && evenement.debut !== "")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                    if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=columnWidth/2;
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                    doc.text(evenement.debut, xOffset, currentY, {align: 'center'});
                    currentY+=lineHeight;
                    nbLignes++;
                  }
                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                    doc.setFont("Helvetica", "bold");

                    var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.libelle1, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                    currentY=resultDecoup.nouvCurrentY;
                    nbLignes=resultDecoup.nouvNbLignes;
                    currentYInit=resultDecoup.nouvCurrentYInit;
                    currentX=resultDecoup.nouvCurrentX;
                    xOffset=resultDecoup.nouvXOffset; 
                    currentColumn=resultDecoup.nouvCurrentColumn;
                    if(!chgtColonne) {
                     chgtColonne=resultDecoup.nouvChgtColonne;
                    }

                    doc.setFont("Helvetica", "normal");

                    if(affichageCoordonnees === "O" 
                    && evenement.lat !== null && evenement.lat !== ""
                    && evenement.longi !== null && evenement.longi !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                      if(resultChgtCol.newColumn)
                      {
                        if(evenement.location !== null && evenement.location === "O")
                        {
                         doc.setDrawColor(107, 33, 168);
                        }
                        else
                        { 
                          doc.setDrawColor(34, 197, 95);
                        }
            
                        doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);
  
                        currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                        nbLignes=1;

                        if(!chgtColonne) {
                         chgtColonne=true;
                        }
  
                        doc.addPage();
                        currentColumn=1;
                        currentX=3;
                        xOffset=columnWidth/2;
                        currentY=10;
                        currentYInit=5;
                      }
                      else
                      {
                        currentY=resultChgtCol.nouvCurrentY;
                        xOffset=resultChgtCol.nouvXOffset; 
                        currentColumn=resultChgtCol.nouvCurrentColumn;
                        currentX=resultChgtCol.nouvCurrentX; 
                      }
  
                      doc.setFontSize(8);
                      doc.text("lat : "+evenement.lat+" lon : "+evenement.longi, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight;
                      nbLignes++;
                      doc.setFontSize(10);
                    }
                  }

                  if(evenement.location === null || evenement.location !== "O" || evenement.moment !== "R")
                  {
                    currentY+=lineHeight;
                    nbLignes++;
                  }

                  if(evenement.compagnie !== null && evenement.compagnie !== "")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);
  
                    if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=columnWidth/2;
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                    doc.text(evenement.compagnie, columnWidth -30, currentY, {align: 'right'});
                  }
                  currentY+=lineHeight;
                  nbLignes++;
                  if(evenement.ref !== null && evenement.ref !== "")
                  {
                    doc.text(evenement.ref, columnWidth -30, currentY, {align: 'right'});
                  }

                  
                  if(evenement.location === null || evenement.location !== "O")
                  {
                    currentY+=lineHeight;
                    nbLignes++;

                    currentY+=lineHeight;
                    nbLignes++;
                  }

                  currentY+=lineHeight;
                  nbLignes++;
                  if(evenement.fin !== null && evenement.fin !== "")
                  {

                    var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                    if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                       chgtColonne=true;
                      }
                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=columnWidth/2;
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                    doc.text(evenement.fin, xOffset, currentY, {align: 'center'});
                  }
                  currentY+=lineHeight;
                  nbLignes++;
                  if(evenement.libelle2 !== null && evenement.libelle2 !== "")
                  {
                     doc.setFont("Helvetica", "bold");

                      var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.libelle2, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      doc.setFont("Helvetica", "normal");

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                    if(affichageCoordonnees === "O" 
                    && evenement.lat2 !== null && evenement.lat2 !== ""
                    && evenement.longi2 !== null && evenement.longi2 !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                      if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }
                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=columnWidth/2;
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                      doc.setFontSize(8);
                      doc.text("lat : "+evenement.lat2+" lon : "+evenement.longi2, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight;
                      nbLignes++;
                      doc.setFontSize(10);

                    }
                  }

                  if(affichagePrix === "O" 
                    && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.00")
                    {
                      currentY+=lineHeight/2;
                      var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                     if(resultChgtCol.newColumn)
                    {
                      if(evenement.location !== null && evenement.location === "O")
                      {
                       doc.setDrawColor(107, 33, 168);
                      }
                      else
                      { 
                        doc.setDrawColor(34, 197, 95);
                      }
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }
                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=columnWidth/2;
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }
                      doc.text(evenement.prix+" "+monnaieSymb, xOffset, currentY, {align: 'center'});
                        currentY+=lineHeight/2;
                        nbLignes++;
                    }

                    if(affichageBlocs === "O" 
                    && evenement.bloc !== null && evenement.bloc !== "")
                    {

                      doc.addImage(blocImg, 'JPEG', xOffset-2, currentY, 4, 4);

                      currentY+=lineHeight/2;
                      currentY+=lineHeight;
                      nbLignes++;

                      doc.setFont("Helvetica", "italic");
                      doc.setFontSize(6);

                      var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.bloc, doc, lineHeight-2, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                      doc.setFontSize(10);

                      currentY+=lineHeight/2;
                      nbLignes++;
                    }

                  if(evenement.location !== null && evenement.location === "O")
                  {
                    doc.setDrawColor(107, 33, 168);
                  }
                  else
                  {
                    doc.setDrawColor(34, 197, 95);
                  }
                  // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                  doc.roundedRect(currentX, currentYInit, columnWidth, nbLignes*lineHeight, 5, 5);

                  if(chgtColonne)
                  {
                    doc.setFillColor(255, 255, 255);
                    doc.rect(currentX, currentYInit-1, columnWidth, 2, 'F');
                    doc.rect(currentX, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX, currentYInit+2, 2, 1, 'F');
                    doc.rect(currentX+columnWidth-2, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX+columnWidth-1, currentYInit+2, 2, 1, 'F');
                  }
  
                }          
              }
              else if(evenement.typeEv === "N")
              {
                if((evenement.libelle1 !== null && evenement.libelle1 !== "") 
                || (evenement.libelle2 !== null && evenement.libelle2 !== "")
                )
                {
                  if(affichagePhotos === "O" && evenement.image !== null && evenement.image !== "")
                  {
                    var resultImage=await addEventImage(doc, evenement.image, currentY-1, columnWidth, lineHeight, pageHeight);

                    hauteurImage=resultImage.height;

                    if(resultImage.changePage)
                    {
                      nbLignes=1;
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=10;
                    }
                  }

                  elementVide=false;
                  var resultChgtCol=controleChgtColonne(evenement, "carnet", true, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;
                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=10;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                  doc.addImage(bedImg, 'JPEG', currentX+1, currentY+1, 5, 5);
                  
                  nbLignes++;

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.0" && evenement.paiement !== null && evenement.paiement === "paye")
                  {
                    doc.addImage(payeImg, 'JPEG', currentX+columnWidth-7, currentY+1, 5, 5);
                  }

                  currentY+=4;


                  currentY+=lineHeight;
  
                  if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                  {
                      if(evenement.libelle2 === null || evenement.libelle2 === "")
                      {
                        doc.setFont("Helvetica", "bold");
                      }

                      var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.libelle1, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");

                    if(affichageCoordonnees === "O" 
                    && evenement.lat !== null && evenement.lat !== ""
                    && evenement.longi !== null && evenement.longi !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      doc.setDrawColor(46, 77, 176);
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;
                      nbLignes++;
                      doc.setFontSize(10);
                    }
 
                  }
                  
                  if(evenement.libelle2 !== null && evenement.libelle2 !== "")
                  {
                    if(evenement.libelle1 !== null && evenement.libelle1 !== "")
                    {
                      currentY+=3;
                      nbLignes++;
                    } 

                    doc.setFont("Helvetica", "bold");

                    var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.libelle2, doc, lineHeight, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                    currentY=resultDecoup.nouvCurrentY;
                    nbLignes=resultDecoup.nouvNbLignes;
                    currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                       chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                    doc.setFont("Helvetica", "normal");

                    if(affichageCoordonnees === "O" 
                    && evenement.lat2 !== null && evenement.lat2 !== ""
                    && evenement.longi2 !== null && evenement.longi2 !== "")
                    {
                      var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      doc.setDrawColor(46, 77, 176);
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                      doc.setFontSize(6);
                      doc.text("lat : "+evenement.lat2, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight/2;

                      doc.text("lon : "+evenement.longi2, xOffset, currentY, {align: 'center'});
                      currentY+=lineHeight;
                      nbLignes++;
                      doc.setFontSize(10);
                    }

                  }

                  if(affichagePrix === "O" 
                  && evenement.prix !== null && evenement.prix !== "" && evenement.prix !== "0" && evenement.prix !== "0.00")
                  {
                    var resultChgtCol=controleChgtColonne(evenement, "carnet", false, currentY, lineHeight, currentColumn, columnWidth, currentX, xOffset, pageHeight);

                  if(resultChgtCol.newColumn)
                    {
                      doc.setDrawColor(46, 77, 176);
          
                      doc.roundedRect(currentX, currentYInit, columnWidth, pageHeight, 5, 5);

                      currentYInit=resultChgtCol.nouvCurrentY-lineHeight;
                      nbLignes=1;

                      if(!chgtColonne) {
                        chgtColonne=true;
                      }

                      doc.addPage();
                      currentColumn=1;
                      currentX=3;
                      xOffset=(columnWidth/4)+(columnWidth/2);
                      currentY=10;
                      currentYInit=5;
                    }
                    else
                    {
                      currentY=resultChgtCol.nouvCurrentY;
                      xOffset=resultChgtCol.nouvXOffset; 
                      currentColumn=resultChgtCol.nouvCurrentColumn;
                      currentX=resultChgtCol.nouvCurrentX; 
                    }

                    currentY+=lineHeight/2;
                    nbLignes++;

                    doc.text(evenement.prix+" "+monnaieSymb, xOffset, currentY, {align: 'center'});
                    currentY+=lineHeight/2;
                  }

                  if(affichageBlocs === "O" 
                    && evenement.bloc !== null && evenement.bloc !== "")
                    {

                      doc.addImage(blocImg, 'JPEG', xOffset-2, currentY, 4, 4);

                      currentY+=lineHeight/2;
                      currentY+=lineHeight;
                      nbLignes++;

                      doc.setFont("Helvetica", "italic");
                      doc.setFontSize(6);

                      var resultDecoup=decoupeLibelle('carnet', evenement, xOffset, currentY, evenement.bloc, doc, lineHeight-2, nbLignes, false, currentColumn, columnWidth, currentX, pageHeight, currentYInit);

                      currentY=resultDecoup.nouvCurrentY;
                      nbLignes=resultDecoup.nouvNbLignes;
                      currentYInit=resultDecoup.nouvCurrentYInit;
                      currentX=resultDecoup.nouvCurrentX;
                      xOffset=resultDecoup.nouvXOffset; 
                      currentColumn=resultDecoup.nouvCurrentColumn;
                      if(!chgtColonne) {
                        chgtColonne=resultDecoup.nouvChgtColonne;
                      }

                      doc.setFont("Helvetica", "normal");
                      doc.setFontSize(10);

                      currentY+=lineHeight/2;
                      nbLignes++;
                    }
                  
                  doc.setDrawColor(46, 77, 176);

                  if(hauteurImage > nbLignes*lineHeight+3)
                  {
                    // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                    doc.roundedRect(currentX, currentYInit, columnWidth, hauteurImage+lineHeight+4, 5, 5);
                    currentY=currentYInit+hauteurImage+lineHeight+2;
                  }
                  else
                  {
                    // Dessiner un carré avec des coins arrondis (x, y, largeur, hauteur, rayon des coins)
                    doc.roundedRect(currentX, currentYInit, columnWidth, nbLignes*lineHeight+3, 5, 5);
                  }


                  if(chgtColonne)
                  {
                    doc.setFillColor(255, 255, 255);
                    doc.rect(currentX, currentYInit-1, columnWidth, 2, 'F');
                    doc.rect(currentX, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX, currentYInit+2, 2, 1, 'F');
                    doc.rect(currentX+columnWidth-2, currentYInit+1, 3, 1, 'F');
                    doc.rect(currentX+columnWidth-1, currentYInit+2, 2, 1, 'F');
                  }
                }
              }

          //currentColumn++;
          index++; 
          currentY+=5; 
          }
                    doc.addPage();
          currentColumn = 1;
          currentX = 3
          currentY = 10; 
      
          currentYInit=0;
          nbLignes=0;
        }

        const fetchPdfBytes = async (url: string): Promise<Uint8Array> => {
        const response = await Axios.get(url, { responseType: 'blob' });

        const arrayBuffer = await response.data.arrayBuffer();
        return new Uint8Array(arrayBuffer);
      };
        

      const addImagesToPdf = async () => {
      
        let nbImagesParPage = 0;
        let firstImage=true;
      
        for (const journee of tabJournees) {
          if (affichageConfirmations === "O") {
      
            for (const evenement of journee.evenements) {
              if (evenement.document && !evenement.document.endsWith("pdf")) {
                try {
                  if(firstImage)
                  {
                    doc.addPage();
                    firstImage=false;
                  } 
                    const img = await fetchImage(evenement.document);
                    const isLandscape = img.width > img.height;
                    
                    // Changement de page
                    if (nbImagesParPage === 2) {
                      doc.addPage();
                      currentColumn = 1; // Revenir à la première colonne sur la nouvelle page
                      currentX = (currentColumn - 1) * columnWidth + 4;
      
                      nbImagesParPage = 0;
                    }
      
                    // Ajustez les dimensions de l'image si nécessaire
                    let width = isLandscape ? (pageWidth / 2) - 10 : 180 * (img.width / img.height);
                    let height = isLandscape ? width * (img.height / img.width) : 180;
      
                    if (width > (pageWidth / 2) - 10) {
                      width = (pageWidth / 2) - 10;
                      height = width * (img.height / img.width);
                    }
      
                    const positionX = nbImagesParPage === 0 ? 5 : nbImagesParPage === 1 ? 5 + (pageWidth / 2) : 5;
                    const positionY = 20;
      
                    doc.addImage(img.src, 'JPEG', positionX, positionY, width, height);
      
                    nbImagesParPage++;
                    URL.revokeObjectURL(img.src);
                } catch (error) {
                  console.error('Erreur lors du chargement de l\'image', error);
                }
              }
            }
          }
        }
      };

        const addPdfToPdf = async (pdfDoc: any) => {
        
          let nbImagesParPage = 0;
        
          for (const journee of tabJournees) {
            if (affichageConfirmations === "O") {
        
              for (const evenement of journee.evenements) {
                if (evenement.document && evenement.document.endsWith("pdf")) {
                  try {
                    const existingPdfBytes = await fetchPdfBytes(evenement.document);
                    const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
                
                
                
                    // Copier les pages du PDF existant dans le nouveau PDF
                    const pages = await pdfDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
                    pages.forEach((page: any) => {
                      pdfDoc.addPage(page);
                    });
                  } catch (error) {
                    console.error('Erreur lors du chargement de l\'image', error);
                  }
                }
              }
            }
          }
        };

        //Au moins un page de notes
        ajouterPageNotes(doc); 

        var moduloPages=doc.getNumberOfPages()% 4;

        if(moduloPages > 0)
        {
            for(var i=1;i<=(4-moduloPages);i++)
            {
              ajouterPageNotes(doc); 
            }
        }

        
        doc.addImage(logoImg, 'JPEG', 90, 200, 40, 16);

        doc.setFontSize(14);
        doc.text("Planificateur de voyages gratuit pour particuliers et travel planners", 105, 227, {align: 'center'});
        doc.setFontSize(fontSize);

        doc.setFontSize(9);
        doc.setFont("Helvetica", "italic");
        doc.text("Edité le "+getCurrentDate(), 105, 290, {align: 'center'});
        doc.setFontSize(fontSize);
        doc.setFont("Helvetica", "normal");

        if(affichageConfirmations === "O")
        {

          currentColumn = 1; // Revenir à la première colonne sur la nouvelle page
          currentX = (currentColumn - 1) * columnWidth + 4;

          await addImagesToPdf();
        const jsPDFDataUri = doc.output('datauristring');
        const jsPDFArray = dataURIToUint8Array(jsPDFDataUri);

        const jsPDFDoc = await PDFDocument.load(jsPDFArray);

          // Créer un nouveau document PDF
          const pdfDoc = await PDFDocument.create();
      
        // Copier les pages du PDF existant dans le nouveau PDF
        const pagesJspdf = await pdfDoc.copyPages(jsPDFDoc, jsPDFDoc.getPageIndices());
        pagesJspdf.forEach((page: any) => {
          pdfDoc.addPage(page);
        });

          await addPdfToPdf(pdfDoc);

          // Serializer le document PDF en bytes
          const pdfBytesFinal = await pdfDoc.save();

          // Sauvegarder le PDF avec FileSaver
          const blob = new Blob([pdfBytesFinal], { type: 'application/pdf' });
          saveAs(blob, 'tripkygo-carnet-voyage-'+keyVoyage+'.pdf');

        }
        else
        {
          doc.save('tripkygo-carnet-voyage-'+keyVoyage+'.pdf');
        }
        setIsLoading(false); // Arrêter le chargement
        closeModal();
      } catch (error: any) {
            setIsLoading(false); // Arrêter le chargement
            if (error.response?.status === 401) {
              alert("Erreur lors de l'édition, vous devez être connecté(e).");
              navigate("/login");
            } else {
              alert("Erreur lors de l'édition");
              closeModal();
            }
          }
      }
      
  };


  return (
    <>
   <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 scale-95"
            >
             <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                    Edition PDF
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>

              <div className="px-8 text-neutral-700 dark:text-neutral-300"> 

                     { (isCreateur || isCoCreateur) ? (
                       <>
                         <div className="mt-5 flex justify-center"> 
                                           <p className="text-sm">Quel type de document souhaitez-vous créer ?</p>
                          </div>
             
                        <div className="mt-4 grid grid-cols-1 gap-5">
                         <div className="flex items-center">
                         <input
                           checked={typeEdition === "pdf"}
                           id="pdf"
                           name="pdf"
                           type="radio"
                           onChange={() => setTypeEdition("pdf")}
                           className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                         />
                         <label
                           htmlFor="pdf"
                           className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                         >
                           Feuille de route
                         </label>
                       </div>
                       <div className="flex items-center">
                         <input
                           checked={typeEdition === "pdfPhotos"}
                           id="pdfPhotos"
                           name="pdfPhotos"
                           type="radio"
                           onChange={() => setTypeEdition("pdfPhotos")}
                           className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                         />
                         <label
                           htmlFor="pdfPhotos"
                           className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                         >
                           Carnet de voyage avec photos
                         </label>
                       </div>
                       </div>
                       </>
                    )
                  :
                  (
                    <div className="mt-4 flex justify-center ">
                      <p>
                           Feuille de route
                         </p>
                    </div>
                  )
                  }
                             

                    
                    <div className="mt-5 flex justify-center"> 
                              <p className="text-sm">Souhaitez-vous inclure les informations suivantes ?</p>
                    </div>
                 <div className="mt-5 flex text-sm justify-center items-center text-right grid grid-cols-2 gap-5">
                   { (isCreateur || isCoCreateur || (partagePrix !== undefined && partagePrix === "O")) && (
                      <>
                      <span>Prix</span>
                      <button
                        onClick={togglePrix}
                        className={`w-8 h-4 rounded-full transition-colors ${
                          isAffichePrix ? 'bg-rosetrip-500' : 'bg-gray-300'
                        }`}
                      >
                        <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAffichePrix ? 'translate-x-full' : ''}`} />
                      </button>
                      </>
                   )}

                    { (isCreateur || isCoCreateur || (partageBlocNotes !== undefined && partageBlocNotes === "O")) && (
                      <>
                      <span>{t('blocNotes')}</span>
                      <button
                        onClick={toggleBlocNotes}
                        className={`w-8 h-4 rounded-full transition-colors ${
                          isAfficheBlocNotes ? 'bg-rosetrip-500' : 'bg-gray-300'
                        }`}
                      >
                        <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAfficheBlocNotes ? 'translate-x-full' : ''}`} />
                      </button>
                      </>
                   )}
                    
                    { (isCreateur || isCoCreateur || (partageConfirmations !== undefined && partageConfirmations === "O")) && (
                      <>
                      <span>Confirmations</span>
                      <button
                        onClick={toggleConfirmations}
                        className={`w-8 h-4 rounded-full transition-colors ${
                          isAfficheConfirmations ? 'bg-rosetrip-500' : 'bg-gray-300'
                        }`}
                      >
                        <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAfficheConfirmations ? 'translate-x-full' : ''}`} />
                      </button>
                      </>
                   )}
                            

                    <span>Coordonnées GPS</span>
                    <button
                      onClick={toggleGPS}
                      className={`w-8 h-4 rounded-full transition-colors ${
                        isAfficheGPS ? 'bg-rosetrip-500' : 'bg-gray-300'
                      }`}
                    >
                      <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAfficheGPS ? 'translate-x-full' : ''}`} />
                    </button>
                   
                </div>
                <div className="flex justify-center mt-5 mb-5 "> 
                  <ButtonPrimary onClick={handleEdition}>
                    Générer
                  </ButtonPrimary>
                </div>
                { /*
                <div className="mt-5 flex justify-center"> 
                              <p className="text-xxs pb-3"><i className="las la la-warning"></i> La personne avec qui vous partagez votre voyage ne pourra faire aucune modification.<br/>Elle pourra éditer la feuille de route en PDF mais pas le carnet de voyage.</p>
                    </div>
                */
                }

              </div>
            </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>

{isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              Edition en cours, veuillez patienter...
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}
</>
    
  );
};

export default ModalEditionPDF