import Axios from './caller.service';

let login = (credentials) => {
    return Axios.post('/auth/login', credentials);
}

let saveToken = (token) => {
    localStorage.setItem('token', token);
}

let saveXsrfToken = (token) => {
    localStorage.setItem('xsrfToken', token);
}

/*let logout = () => {
    localStorage.removeItem('token');
}*/


let logout = () => {
    localStorage.removeItem('xsrfToken');

    return Axios.post('/auth/logout');

}

/*let isLogged = () => {
    let token = localStorage.getItem('token');
    return !!token;
}*/

let isLogged = () => {
    let token = localStorage.getItem('xsrfToken');
    
    return !!token;
}

let getToken = () => {
    return localStorage.getItem('xsrfToken');
}

/*
let getToken = () => {
    return localStorage.getItem('token');
}*/

export const accountService = {
    login, saveToken, saveXsrfToken, logout, isLogged, getToken
}