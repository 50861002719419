import React, { FC, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

export interface TabFiltersPaysProps {
  setFiltres: (plage: boolean, nature: boolean, culture: boolean, ski: boolean) => void;
}

const TabFiltersPays: FC<TabFiltersPaysProps> = ({ setFiltres }) => {
  //
  const { t } = useTranslation();
  
  const [isPlage, setIsPlage] = useState(false);
  const [isNature, setIsNature] = useState(false);
  const [isCulture, setIsCulture] = useState(false);
  const [isSki, setIsSki] = useState(false);

  useEffect(() =>
  {
    setFiltres(isPlage, isNature, isCulture, isSki);
  },[isPlage, isNature, isCulture, isSki])

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabFiltres = () => {
    return (
      <>
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
          isPlage
            ? "border-primary-500 bg-primary-50 text-primary-700"
            : "border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={() => setIsPlage(!isPlage)}
      >
        <span><i className="las la-umbrella-beach text-lg"></i> {t('plage')}</span>
        {isPlage && renderXClear()}
      </div>
      <div
      className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
        isNature
          ? "border-primary-500 bg-primary-50 text-primary-700"
          : "border-neutral-300 dark:border-neutral-700"
      }`}
      onClick={() => setIsNature(!isNature)}
    >
      <span><i className="las la-tree text-lg"></i> Nature</span>
      {isNature && renderXClear()}
    </div>
    <div
    className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
      isCulture
        ? "border-primary-500 bg-primary-50 text-primary-700"
        : "border-neutral-300 dark:border-neutral-700"
    }`}
    onClick={() => setIsCulture(!isCulture)}
  >
    <span><i className="las la-gopuram text-lg"></i> Culture</span>
    {isCulture && renderXClear()}
  </div>
  <div
  className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
    isSki
      ? "border-primary-500 bg-primary-50 text-primary-700"
      : "border-neutral-300 dark:border-neutral-700"
  }`}
  onClick={() => setIsSki(!isSki)}
>
  <span><i className="las la-skiing text-lg"></i> Ski</span>
  {isSki && renderXClear()}
</div>
</>
    );
  };

  const renderTabFiltresMobile = () => {
    return (
      <>
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
          isPlage
            ? "border-primary-500 bg-primary-50 text-primary-700"
            : "border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={() => setIsPlage(!isPlage)}
      >
        <span><i className="las la-umbrella-beach text-lg"></i></span>
        {isPlage && renderXClear()}
      </div>
      <div
      className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
        isNature
          ? "border-primary-500 bg-primary-50 text-primary-700"
          : "border-neutral-300 dark:border-neutral-700"
      }`}
      onClick={() => setIsNature(!isNature)}
    >
      <span><i className="las la-tree text-lg"></i></span>
      {isNature && renderXClear()}
    </div>
    <div
    className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
      isCulture
        ? "border-primary-500 bg-primary-50 text-primary-700"
        : "border-neutral-300 dark:border-neutral-700"
    }`}
    onClick={() => setIsCulture(!isCulture)}
  >
    <span><i className="las la-gopuram text-lg"></i></span>
    {isCulture && renderXClear()}
  </div>
  <div
  className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
    isSki
      ? "border-primary-500 bg-primary-50 text-primary-700"
      : "border-neutral-300 dark:border-neutral-700"
  }`}
  onClick={() => setIsSki(!isSki)}
>
  <span><i className="las la-skiing text-lg"></i></span>
  {isSki && renderXClear()}
</div>
</>
    );
  };
  

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabFiltres()}
      </div>
      <div className="flex lg:hidden space-x-4">
        {renderTabFiltresMobile()}
      </div>
    </div>
  );
};

export default TabFiltersPays;
