import React, { FC } from "react";

export interface VoyageurRatingProps {
  className?: string;
  totalLikes?: number;
}

const VoyageurRating: FC<VoyageurRatingProps> = ({
  className = "",
  totalLikes = 0,
}) => {
  return (
    <div
      className={`nc-VoyageurRating flex items-center space-x-1 text-sm  ${className}`}
      data-nc-id="VoyageurRating"
    >
      <span className="font-medium ">{totalLikes}</span>
      <div className="pb-[2px]">
      <i className={`las la-heart text-rose-500`}></i>
      </div>
    </div>
  );
};

export default VoyageurRating;
