import React, { FC, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import HeadingGuides from "components/HeadingGuides/HeadingGuides";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import image from "images/imageAPropos.jpeg";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []); 

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>{t('aPropos')}</title>
      </Helmet>

      <div className="mb-24 lg:mb-32 mt-10 lg:mt-12 px-10 ml-10 mr-10">
        <div className={`nc-SectionGuides relative ${className}`}>
          <HeadingGuides desc="" isCenter={true}>
            {t('presentation')}
          </HeadingGuides>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
            <div className="prose dark:prose-invert prose-sm sm:prose lg:prose-lg dark:prose-dark">
              <p>{t('aPropos1')}<i className={`las la-heart`}></i><br/>
              {t('aPropos2')} <a href="https://blog.tripkygo.com" target="_blank" rel="noreferrer"><u>blog</u></a> <i className={`las la-globe`}></i><br/>
              <br/>
              {t('aPropos3')}<br/>
              <br/>
              {t('aPropos4')}<br/>
              {t('aPropos5')}<br/><br/>
              {t('aPropos6')}
              <br/>
              <br/>
              {t('aPropos7')}  <i className={`las la-smile`}></i><br/>
              Etienne </p>
            </div>
            <div>
              <img src={image} className="w-1/2 md:w-2/3" style={{ margin: "auto" }}/>
            </div>
        </div>
      </div>
      <SectionSubscribe2 />
    </div>
    </div>
  );
};

export default PageAbout;
