import React, { FC, useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import SectionGuide from "components/SectionGuide/SectionGuide";
import AppContext from 'AppContext';
import { guidesService } from '_services/guides.service'
import { GuidePaysDataType } from "data/types";
import { useLocation} from "react-router-dom";

export interface PageGuideProps {
  className?: string;
}

const PageGuide: FC<PageGuideProps> = ({ className = "" }) => {

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  const { codePays } = useParams<{ codePays: string }>();
  const [pays, setPays] = useState<GuidePaysDataType>();
  let navigate = useNavigate();
  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  useEffect( () => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []);

  useEffect( () => {
 
      guidesService
        .getGuidePays(codePays, langue)
        .then((response) => {
          setPays(response.data);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du pays:", error);
          navigate('/');
        });
  }, [codePays]);
 
  return (
    <div
      className={`nc-PageGuide ${className}`}
      data-nc-id="PageGuide"
    >
      <Helmet>
        <title>Guide {pays ? pays.nom : ""}</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 mt-10 lg:mt-12 px-3 md:px-6">
        <SectionGuide pays={pays} />
      </div>
</div>
  );
};

export default PageGuide;
