import React, { FC, useEffect, useState, useContext } from "react";
import { jwtDecode } from 'jwt-decode';
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { compteService } from '_services/compte.service';
import { accountService } from '_services/account.service';
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { voyageInfo, setAfficheMenuMobile } = useContext(AppContext);

  let navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    password2: '',
    numDroit: 99,
    etatVoyage: 99,
    idVoyage: 0
  });  

  useEffect(() => {

    setAfficheMenuMobile(false);

    if(voyageInfo !== undefined && voyageInfo !== null
      && voyageInfo.id_voyage !== undefined && voyageInfo.id_voyage !== null 
      && voyageInfo.num_droit !== undefined && voyageInfo.num_droit !== null && voyageInfo.num_droit === 0
      && voyageInfo.etatVoyage !== undefined && voyageInfo.etatVoyage !== null && voyageInfo.etatVoyage === 0)
    {
       setCredentials({
        ...credentials,
        numDroit: voyageInfo.num_droit,
        etatVoyage: voyageInfo.etatVoyage,
        idVoyage: voyageInfo.id_voyage
      });
    }
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = () => {
      if (window.google && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id: '112999122939-k944srcdkk8esjekjq6bqqagvi211i3i.apps.googleusercontent.com',
          callback: handleGoogleCallback
        });

        const googleSignUpElement = document.getElementById('googleSignUp');
        if (googleSignUpElement) {
          window.google.accounts.id.renderButton(googleSignUpElement, {
            theme: 'filled_blue',
            size: 'large',
          });
        }
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleGoogleCallback = async (response: any) => {
   
    // Decode JWT to get user information
    const decodedToken = jwtDecode(response.credential);

    // Send user data to your API
    await handleGoogleSignup(decodedToken);
  };

  const handleGoogleSignup = async (userData: any) => {
   
    try {

    var numDroit=99;
    var etatVoyage=99;
    var idVoyage=0;
    
      if(voyageInfo !== undefined && voyageInfo !== null
        && voyageInfo.id_voyage !== undefined && voyageInfo.id_voyage !== null 
        && voyageInfo.num_droit !== undefined && voyageInfo.num_droit !== null && voyageInfo.num_droit === 0
        && voyageInfo.etatVoyage !== undefined && voyageInfo.etatVoyage !== null && voyageInfo.etatVoyage === 0)
      {
        numDroit=voyageInfo.num_droit;
        etatVoyage=voyageInfo.etatVoyage;
        idVoyage=voyageInfo.id_voyage;
  
      }

      const response = await compteService.creerCompte({
        email: userData.email,
        familyName: userData.family_name || '',
        givenName: userData.given_name || '',
        googleId: userData.sub,
        numDroit,
        etatVoyage,
        idVoyage
      });
  
      if (response.data && response.data.xsrfToken) {
        accountService.saveXsrfToken(response.data.xsrfToken);
        if(response.data.message && response.data.message === 'CREATE')
        {
          navigate(t('lienCompte'));
        }
        else
        {
          navigate(t('lienMesVoyages'));
        }
      } 
    } catch (error: any) {
      console.error("Error during Google signup", error);
      if (error.response && error.response.status === 401 && error.response.data.message) {
        alert(error.response.data.message);
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (credentials.email === "" || !validateEmail(credentials.email)) {
      alert(t('alertEmail'));
    } else if (credentials.password === "" || !validateMdp(credentials.password)) {
      alert(t('alertMdpInvalide'));
    } else if (credentials.password !== credentials.password2) {
      alert(t('alertMdpDifferents'));
    } else {

      compteService.creerCompte(credentials)
        .then(res => {

          if (res.data !== undefined && res.data.xsrfToken !== undefined) {
            accountService.saveXsrfToken(res.data.xsrfToken);
            navigate(t('lienCompte'));
          } else if (res.data !== undefined && res.data.message !== undefined && res.data.message === 'KO') {
            alert(t('alertInscriptionImpossible'));
          }
        })
        .catch(error => {
          if (error.response.status === 401 && error.response.data.message !== undefined) {
            alert(error.response.data.message);
          }
        });
    }
  };

  const validateEmail = (mail: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(mail);
  };

  function validateMdp(mdp: string) {
    var re = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z]).{6,}$/;
    return re.test(mdp);
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{t('inscription')}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        {t('inscription')}
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3 justify-center">
            <div id="googleSignUp"></div>
          </div>
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
            {t('ou')}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={onSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
              {t('adresseEmail')}
              </span>
              <Input
                type="email"
                placeholder="exemple@exemple.com"
                className="mt-1"
                value={credentials.email}
                onChange={onChange}
                name="email"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              {t('motDePasse')}
              </span>
              <Input type="password" className="mt-1"
                onChange={onChange}
                value={credentials.password}
                name="password" />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('resaisirEmail')}
              </span>
              <Input type="password" className="mt-1"
                onChange={onChange}
                value={credentials.password2}
                name="password2" />
            </label>
            <span className="block text-center text-sm  text-neutral-700 dark:text-neutral-300">
            {t('dejaInscrit')} {` `}
            <Link to={t('lienLogin')} className="pointeurRose">{t('connexion')}</Link>
            </span>
            <ButtonPrimary type="submit">{t('inscrire')}</ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;