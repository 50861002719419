import React, { FC, useState, useRef, useEffect, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import MapboxGL from "components/MapboxGL/MapboxGL";
import ModalGetYourGuide from 'components/ModalGetYourGuide';
import ModalBooking from 'components/ModalBooking';
import ModalDiscoverCars from 'components/ModalDiscoverCars';
import ModalKiwi from 'components/ModalKiwiNew';
import { FeatureGeoapifyType, JourneeType, EventType, PecType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CardLieu from "components/CardLieu/CardLieu";
import CardLieuModif from "components/CardLieuModif/CardLieuModif";
import CardTrajet from "components/CardTrajet/CardTrajet";
import CardTrajetModif from "components/CardTrajetModif/CardTrajetModif";
import CardNuit from "components/CardNuit/CardNuit";
import CardNuitModif from "components/CardNuitModif/CardNuitModif";
import { Dialog, Transition } from "@headlessui/react";
import Textarea from "shared/Textarea/Textarea";
import {apiKeyGeoapify} from '_config/constantes'; 
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { voyageService } from '_services/voyage.service';
import ButtonCircleSmall from "shared/Button/ButtonCircleSmall";
import { useDrag, useDrop, DndProvider, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export interface SectionCarteProps {
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  eventCategoriesPage: JourneeType[];
  etatVoyage: number;
  id_pays: string;
  numDroit: number;   
  majEventCategoriesPage: (updatedCategories :any) => void;
  monnaie?: string;
  nb_voyageurs: number;
  isModif: boolean;
  onChangeModif: () => void;
  verrouillageModif: () => Promise<number>;
  isFini: boolean;
}

interface DraggableCardProps {
  index: number; // Use index instead of id
  id_journee:number;
  onDrop: (dragIndex: number, hoverIndex: number, draggedIdJournee:number) => void;
  children?: React.ReactNode;
  eventCategories: JourneeType[];
}

const DraggableCard: FC<DraggableCardProps> = ({ index, id_journee, onDrop, children, eventCategories }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { index, id_journee },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const ref = useRef<HTMLDivElement>(null);


  const [, drop] = useDrop({
    accept: 'CARD',
    drop: (item: any, monitor: DropTargetMonitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const draggedIdJournee = item.id_journee;

      // Check if the dropped item is not the last event of the day
      if (draggedIdJournee !== null && isNotLastEventOfDay(draggedIdJournee, dragIndex)) {
        onDrop(dragIndex, hoverIndex, draggedIdJournee);
      } else {
        alert("Vous ne pouvez pas déplacer cet élément de fin de journée !");
      }
    },
  });

  const isNotLastEventOfDay = (idJournee: number, eventIndex: number): boolean => {
    const day = eventCategories.find((day) => day.id_journee === idJournee);

    if (day) {
      const isLastEvent = eventIndex === day.evenements.length - 1;
      const isTrajetLendemain = (day.evenements[eventIndex].typeEv==="T" && day.evenements[eventIndex].invisible2 === "O");
        
          if (isLastEvent) {
            return false;
          }
        
          if (isTrajetLendemain) {
            const lastEvent = day.evenements[day.evenements.length - 1];
            // Ajouter des conditions supplémentaires ici pour vérifier si le dernier événement a le typeEv=N et invisible1=O
            if (lastEvent.typeEv === 'N' && lastEvent.invisible1 === 'O') {
              return false;
            }
          }
    }
    return true;
  };

  useEffect(() => {
    drag(drop(ref));
  }, []); // Effectuera cette opération une seule fois au montage initial

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </div>
  );
};



const SectionCarte: FC<SectionCarteProps> = ({
  voyageKey,
  emailPartage,
  codePartage,
  eventCategoriesPage,
  etatVoyage,
  id_pays,
  numDroit,
  majEventCategoriesPage,
  monnaie,
  nb_voyageurs,
  isModif,
  onChangeModif,
  verrouillageModif,
  isFini
}) => {
  
  const { t } = useTranslation();

  const [testCompteur, setTestCompteur] = useState<number>(0);

  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [compteurClick, setCompteurClick] = useState<number>(0);
  const [selectedCardID, setSelectedCardID] = useState<string | null>(null);
  const [selectedMarkerID, setSelectedMarkerID] = useState<string | null>(null);
  const [placementCardID, setPlacementCardID] = useState<string | null>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const listContainerRefSmall = useRef<HTMLDivElement>(null);

  const [typeEvenementForModal, setTypeEvenementForModal] = useState("");
  const [idEvenementForModal, setIdEvenementForModal] = useState<number>(0);
  const [idJourneeForModal, setIdJourneeForModal] = useState<number>(0);

  const [blocNotes, setBlocNotes] = useState("");
  const [isModalBlocNotesOpen, setIsModalBlocNotesOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEnoughPoints, setIsEnoughPoints] = useState(true);
  
  const [selectedEvent, setSelectedEvent] = useState<EventType | null>(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(0);
  const [selectedJournee, setSelectedJournee] = useState<number | null>(null);
  const [listePec, setListePec] = useState<PecType[]>([]);

  const [lien, setLien] = useState("");
  const [lienEnregistre, setLienEnregistre] = useState(true);
  const [etatLien, setEtatLien] = useState("");
  const [lienModifie, setLienModifie] = useState("");
  const [motifLien, setMotifLien] = useState("");
  const [isModalLienOpen, setIsModalLienOpen] = useState(false);

  const [lieu1, setLieu1] = useState("");
  const [lieu2, setLieu2] = useState("");
  const [latitude1, setLatitude1] = useState("");
  const [latitude2, setLatitude2] = useState("");
  const [longitude1, setLongitude1] = useState("");
  const [longitude2, setLongitude2] = useState("");
  const [isModalCoordOpen, setIsModalCoordOpen] = useState(false);

  const [isModalGetYourGuideOpen, setIsModalGetYourGuideOpen] = useState(false);
  const [isModalKiwiOpen, setIsModalKiwiOpen] = useState(false);
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false);
  const [isModalDiscoverCarsOpen, setIsModalDiscoverCarsOpen] = useState(false);

  
  const [placeNom, setPlaceNom] = useState("");
  const [infoLatitude, setInfoLatitude] = useState("");
  const [infoLongitude, setInfoLongitude] = useState("");
  const [infoAdresse, setInfoAdresse] = useState("");
  const [infoHoraires, setInfoHoraires] = useState("");
  const [infoEquipements, setInfoEquipements] = useState("");
  const [infoSite, setInfoSite] = useState("");
  const [infoTelephone, setInfoTelephone] = useState("");
  const [infoContact, setInfoContact] = useState("");
  const [infoSource, setInfoSource] = useState("");
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  
  const [confirm, setConfirm] = useState("");
  const [confirmLien, setConfirmLien] = useState("");
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  
  const [isUpdating, setIsUpdating] = useState(false);

  const [isAdding, setIsAdding] = useState(false);

  let navigate=useNavigate();

  const toggleModif = () => {
    onChangeModif();
    setSelectedCardID(null);
    setSelectedMarkerID(null);
    setPlacementCardID(null);
  };

  const openModal = (idJournee: number, event: EventType, eventIndex: number) => {
    setSelectedEvent(event);
    setSelectedEventIndex(eventIndex);
    setSelectedJournee(idJournee);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setSelectedEventIndex(0);
    setSelectedJournee(null);
    setIsModalOpen(false);
  };

  const openModalLien = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalLienOpen(true);

    eventCategories.forEach((day, index) => {
      day.evenements.forEach((event, eventIndex) => {
          if (((typeEvenement === "lieu" && event.typeEv === "L") || (typeEvenement === "trajet" && event.typeEv === "T") || (typeEvenement === "nuit" && event.typeEv === "N")) && event.id === idEvenement) {
              setIdJourneeForModal(day.id_journee);
          }
      });
  });
  };

  const closeModalLien = () => {
    setLien("");
    setEtatLien("");
    setLienModifie("");
    setMotifLien("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIdJourneeForModal(0);
    setIsModalLienOpen(false);
    setIdJourneeForModal(0);
  };

  const openModalBlocNotes = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalBlocNotesOpen(true);

    eventCategories.forEach((day, index) => {
      day.evenements.forEach((event, eventIndex) => {
          if (((typeEvenement === "lieu" && event.typeEv === "L") || (typeEvenement === "trajet" && event.typeEv === "T") || (typeEvenement === "nuit" && event.typeEv === "N")) && event.id === idEvenement) {
              setIdJourneeForModal(day.id_journee);
          }
      });
  });
  };

  const closeModalBlocNotes = () => {
    setBlocNotes("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIdJourneeForModal(0);
    setIsModalBlocNotesOpen(false);
  };

  const openModalCoord = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalCoordOpen(true);
  };

  const closeModalCoord = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIsModalCoordOpen(false);
  };

  const openModalConfirm = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalConfirmOpen(true);

    eventCategories.forEach((day, index) => {
      day.evenements.forEach((event, eventIndex) => {
          if (((typeEvenement === "lieu" && event.typeEv === "L") || (typeEvenement === "trajet" && event.typeEv === "T") || (typeEvenement === "nuit" && event.typeEv === "N")) && event.id === idEvenement) {
              setIdJourneeForModal(day.id_journee);
          }
      });
  });
  };

  const closeModalConfirm = () => {
    setConfirm("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIdJourneeForModal(0);
    setIsModalConfirmOpen(false);
  };

  const openModalInfo = () => {
    setIsModalInfoOpen(true);
  }

  const closeModalInfo = () => {
    setInfoAdresse("");
    setInfoHoraires("");
    setInfoEquipements("");
    setInfoSite("");
    setInfoTelephone("");
    setInfoContact("");
    setInfoSource("");
    setPlaceNom("");
    setInfoLatitude("");
    setInfoLongitude("");
    setIsModalInfoOpen(false);
  } 

  const openModalGetYourGuide = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalGetYourGuideOpen(true);
  };

  const closeModalGetYourGuide = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    
    setIsModalGetYourGuideOpen(false);
  };

  const openModalKiwi = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalKiwiOpen(true);
  };

  const closeModalKiwi = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalKiwiOpen(false);
  };

  const openModalBooking = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalBookingOpen(true);
  };

  const closeModalBooking = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalBookingOpen(false);
  };

  const openModalDiscoverCars = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalDiscoverCarsOpen(true);
  };

  const closeModalDiscoverCars = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalDiscoverCarsOpen(false);
  };

  const getCardID = (event: EventType): string => {
    let cardID = "";
    if (event.typeEv === "L") {
      cardID = "lieu-" + event.id;
    } else if (event.typeEv === "T") {
      cardID = "trajet-" + event.id;
    } else if (event.typeEv === "N") {
      cardID = "nuit-" + event.id;
    }
    return cardID;
  };

  const moveCard = async (dragIndex: number, hoverIndex: number, draggedIdJournee: number | null, id_journee: number) => {
    const modifVoyage = async () => {
      try {
    if (draggedIdJournee !== null) {

      const updatedCategories = [...eventCategories];
      const dragDayIndex = updatedCategories.findIndex((day) => day.id_journee === draggedIdJournee);
      const dropDayIndex = updatedCategories.findIndex((day) => day.id_journee === id_journee);
      const dragCard = updatedCategories[dragDayIndex]?.evenements[dragIndex];
      const dropCard = updatedCategories[dropDayIndex]?.evenements[hoverIndex];

      if (dragCard && dropCard) {
        const dropIdJournee = id_journee;

        let typeEvenement="";
        let idEvenement=dragCard.id;
        let typeEvenementSuivant="";
        let idEvenementSuivant=dropCard.id;

        switch (dragCard.typeEv) {
            case 'L':
                typeEvenement="lieu";
              break;
            case 'T':
                typeEvenement="trajet";
              break;
          }

          switch (dropCard.typeEv) {
            case 'L':
                typeEvenementSuivant="lieu";
              break;
            case 'T':
                typeEvenementSuivant="trajet";
              break;
              case 'N':
                typeEvenementSuivant="nuit";
              break;
          }

    const isNotLastEventOfDay = (idJournee: number, eventIndex: number): boolean => {
            const day = eventCategories.find((day) => day.id_journee === idJournee);
            return day ? eventIndex < day.evenements.length - 1 : false;
    };

    if(dropIdJournee===draggedIdJournee 
      && (typeEvenementSuivant === 'nuit' || !isNotLastEventOfDay(dropIdJournee, hoverIndex)))
    {
      alert('Vous ne pouvez pas déplacer cet élément en fin de journée !')
    }
    else
    {

    // Remove the card from its initial position
    updatedCategories[dragDayIndex].evenements.splice(dragIndex, 1);
     // Insert the card at the new position
    updatedCategories[dropDayIndex].evenements.splice(hoverIndex, 0, dragCard);

    setEventCategories(updatedCategories);
            
     // Effectuez la requête API pour déplacer un événement
      voyageService.deplacerEvenement(voyageKey, {
        type: typeEvenement,
          typeSuivant: typeEvenementSuivant,
          id: idEvenement,
          idSuivant: idEvenementSuivant
    })
  .then((response: any) => {

     if (response.ok) {
      const apiResult = response.data;

      // Vérifiez si la réponse de l'API est "Erreur"
      if (apiResult === "Erreur") {
          alert("Erreur : le déplacement n'a pas pu être enregistré. Veuillez actualiser la page.");   
            } 
            else {
              majEventCategoriesPage(updatedCategories);
            }
          } 
      })
      .catch((error: any) => {
        if (error.response?.status === 401) {
          alert("Erreur lors du deplacement de l'évenement, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors du deplacement de l'évenement");
        }
        console.error("Erreur lors de du deplacement de l'évenement:", error);
      });
     }          
    }
  }
  } catch (error) {
    console.error(error);
    alert("Erreur lors de la modification !");
  }
  };

  const processModifVoyage = async () => {

  const verrouillageResultat = await verrouillageModif();

  if (verrouillageResultat === 1) {
    modifVoyage();
  }
  };

  processModifVoyage();
};
  
const shouldCallSuppressionClick = (day: any, eventIndex: number) => {
  const isLastEvent = eventIndex === day.evenements.length - 1;

  if (isLastEvent) {
    return false;
  }

  return true;
};


const removeEventFromState = (journeeId: number, eventId: number, typeEvenement: string) => {
  setEventCategories((prevCategories) => {
    return prevCategories.map((day) => {
      if (day.id_journee === journeeId) {
        return {
          ...day, // Create a new day object
          evenements: day.evenements.filter((event) => {
            // Ajoutez la condition de filtrage basée sur le typeEvenement
            if (typeEvenement === 'lieu' && event.typeEv === 'L') {
              return event.id !== eventId;
            } else if (typeEvenement === 'trajet' && event.typeEv === 'T') {
              return event.id !== eventId;
            } else if (typeEvenement === 'nuit' && event.typeEv === 'N') {
              return event.id !== eventId;
            }
            return true; // Si le typeEvenement ne correspond pas, incluez l'événement
          })
        };
      }
      return day;
    });
  });
};
  const handleCoordClick = async (typeEvenement: string, idEvenement: number) => {
    voyageService.getCoordonnees(voyageKey, typeEvenement, idEvenement)
    .then((response: any) => {

      if(response.data.message === 'ok')
      {
        setIdJourneeForModal(response.data.idJournee);
        setLieu1(response.data.lieu1);
        setLieu2(response.data.lieu2);
        setLatitude1(response.data.latitude1);
        setLatitude2(response.data.latitude2);
        setLongitude1(response.data.longitude1);
        setLongitude2(response.data.longitude2);
        openModalCoord(typeEvenement, idEvenement);
      }
      else
      {
        alert("Erreur lors de la récupération des coordonées");
      }

    })
    .catch((error: any) => {

      if (error.response?.status === 401) {
        alert("Erreur lors de la récupération des coordonnées, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
        navigate('/');
      }
      else
      {
        alert("Erreur lors de la récupération des coordonnées");
      }

    })
};

const handleAddTrajetLendemain = async (idJournee: number) => {
  const modifVoyage = async () => {
    try {
  const day = eventCategories.find((day) => day.id_journee === idJournee);

  if(day)
  {
    var numJour = day.num_jour;
    const daySuivant= eventCategories.find((day) => day.num_jour === numJour+1);

    if(daySuivant)
    {
      var idJourneeSuiv=daySuivant.id_journee;
      var idEvenement=daySuivant.evenements[0].id;
      var typeEv=daySuivant.evenements[0].typeEv;

      addEvenementLendemain(typeEv, idJourneeSuiv, idEvenement);
    }

  }
  
} catch (error) {
  console.error(error);
  alert("Erreur lors de la modification !");
}
};

  const processModifVoyage = async () => {
    
    const verrouillageResultat = await verrouillageModif();

    if (verrouillageResultat === 1) {
      modifVoyage();
    }
  };

  processModifVoyage();

};  

const handleDeleteTrajetLendemain = async (idJournee: number, idTrajet: number) => {

const day = eventCategories.find((day) => day.id_journee === idJournee);

if(day)
{
  var numJour = day.num_jour;
  const daySuivant= eventCategories.find((day) => day.num_jour === numJour+1);

  if(daySuivant)
  {
    var idJourneeSuiv=daySuivant.id_journee;

    removeEventFromState(idJourneeSuiv, idTrajet, "trajet");
  }

}
};  

const handleSaveCoord = () => {

if(validationCoordonneesGPS(latitude1, longitude1) && validationCoordonneesGPS(latitude2, longitude2))
{

voyageService.enregistrerCoordonnees(voyageKey, {
latitude1,
latitude2,
longitude1,
longitude2,
typeEvenement: typeEvenementForModal,
idEvenement: idEvenementForModal 
})
.then((response: any) => {


if(response.data.message !== 'ok')
{
  alert("Erreur lors de l'enregistrement des coordonnées");
}
else
{
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude1, "lat");
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude1, "longi");
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude2, "lat2");
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude2, "longi2");
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude1, "latModif");
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude1, "longiModif");
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude2, "lat2Modif");
  handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude2, "longi2Modif");
  
  alert("Enregistrement effectué !");

}

})
.catch((error: any) => {

if (error.response?.status === 401) {
  alert("Erreur lors de l'enregistrement des coordonnées', vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
  navigate('/');
}
else
{
  alert("Erreur lors de l'enregistrement des coordonnées");
}

})
}
};

const handleSaveCoord2 = (lat1: any, longi1: any, lat2: any, longi2: any, typeEvenement: any, idEvenement: any, idJournee: any) => {

  if(validationCoordonneesGPS(lat1, longi1) && validationCoordonneesGPS(lat2, longi2))
  {
  
  voyageService.enregistrerCoordonnees(voyageKey, {
  latitude1: lat1,
  latitude2: lat2,
  longitude1: longi1,
  longitude2: longi2,
  typeEvenement: typeEvenement,
  idEvenement: idEvenement 
  })
  .then((response: any) => {
 
  
  if(response.data.message !== 'ok')
  {
    alert("Erreur lors de l'enregistrement des coordonnées");
  }
  else
  {

    handleElementChange(idJournee, idEvenement, typeEvenement, lat1, "lat");
    handleElementChange(idJournee, idEvenement, typeEvenement, longi1, "longi");
    handleElementChange(idJournee, idEvenement, typeEvenement, lat2, "lat2");
    handleElementChange(idJournee, idEvenement, typeEvenement, longi2, "longi2");
    handleElementChange(idJournee, idEvenement, typeEvenement, lat1, "latModif");
    handleElementChange(idJournee, idEvenement, typeEvenement, longi1, "longiModif");
    handleElementChange(idJournee, idEvenement, typeEvenement, lat2, "lat2Modif");
    handleElementChange(idJournee, idEvenement, typeEvenement, longi2, "longi2Modif");
    
    alert("Enregistrement effectué !");
  }
  
  })
  .catch((error: any) => {
  
  if (error.response?.status === 401) {
    alert("Erreur lors de l'enregistrement des coordonnées', vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
    navigate('/');
  }
  else
  {
    alert("Erreur lors de l'enregistrement des coordonnées");
  }
  
  })
  }
  };

  const addEvenement = async (type: string) => {

    
    const modifVoyage = async () => {
      try {

    setIsAdding(true);

    if (selectedEvent && selectedJournee) {
      
      // Effectuez la requête API pour ajouter un événement
        voyageService.ajouterEvenement(voyageKey, {
            type,
            journee: selectedJournee,
            idEvenement: selectedEvent.id,
            typeEvenement: selectedEvent.typeEv
        })
      .then((response: any) => {

        const apiResult = response.data;

        // Vérifiez si la réponse de l'API est "Erreur"
        if (apiResult !== "Erreur") {

          // Fermez la modale
          closeModal();

          setEventCategories((prevCategories) => {
            // Trouvez la journée correspondante dans la liste
            const updatedCategories = prevCategories.map((day) => {
              if (day.id_journee === selectedJournee) {
              
                // Créez une copie du tableau des événements
              const updatedEvenements = [...day.evenements];

                // Ajoutez la CardLieuModifie au-dessus du bon événement
                updatedEvenements.splice(selectedEventIndex, 0, {
                  typeEv: type === 'lieu' || type === 'repas' ? 'L' : type === 'trajet' || type === 'location' ? 'T' : '',
                  id: apiResult.message, // Utilisez le résultat de l'API comme ID de l'événement
                  ordre: selectedEvent.ordre,
              libelle1: "",
              libelle2: "",
              prix: "",
              paiement: "",
              lat: "",
              longi: "",
              place: "",
              bloc: "",
              type: type === 'repas' ? 'O' : '',
              lien: "",
              etat_lien: "",
              lien_modifie: "",
              ref: "",
              compagnie: "",
              debut: "",
              fin: "",
              lat2: "",
              longi2: "",
              distance: "",
              duree: "",
              location: type === 'location' ? 'O' : '',
              moment: type === 'location' ? 'P' : '',
              rattachement: "",
              invisible1: "",
              invisible2: "",
              date_paiement_hebergement: "",
              acompte_hebergement: "",
              image: "",
              document: ""
            });

             // Retourner une copie de l'objet avec les événements mis à jour
             return {
              ...day,
              evenements: updatedEvenements,
            };

                    }
                    return day;
                  });
        
                      return updatedCategories;
                });
                setIsAdding(false);
              } else {
                setIsAdding(false);
                console.error("Erreur lors de la requête API");
                // Gérez l'erreur, affichez un message, etc.
              }

      })
      .catch((error: any) => {
        setIsAdding(false);

        if (error.response?.status === 401) {
          alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'ajout d'évenement");
        }
        console.error("Erreur lors de l'ajout d'évenement:", error);
      });
  
      } 
      else
      {
        setIsAdding(false);
      }
    } catch (error) {
      console.error(error);
      alert("Erreur lors de la modification !");
    }
  };

  const processModifVoyage = async () => {
    
    const verrouillageResultat = await verrouillageModif();

    if (verrouillageResultat === 1) {
      modifVoyage();
    }
  };

  processModifVoyage();
  }

const addEvenementLendemain = async (type: string, idJournee: number, idEvenement: number) => {
  const modifVoyage = async () => {
    try {
      setIsAdding(true);

      // Effectuez la requête API pour ajouter un événement
      voyageService.ajouterEvenement(voyageKey, {
        type: "trajet",
        journee: idJournee,
        idEvenement,
        typeEvenement: type,
        lendemain: "O"
      })
        .then((response: any) => {
          const apiResult = response.data;

          // Vérifiez si la réponse de l'API est "Erreur"
          if (apiResult !== "Erreur") {
            setEventCategories((prevCategories) => {
              // Créer une copie des catégories pour éviter les mutations directes
              const updatedCategories = prevCategories.map((day) => {
                if (day.id_journee === idJournee) {
                  console.log("day.evenements", day.evenements);

                  // Créez une copie du tableau des événements
                  const updatedEvenements = [...day.evenements];

                  // Ajoutez la CardLieuModifie au-dessus du bon événement
                  updatedEvenements.splice(0, 0, {
                    typeEv: "T",
                    id: apiResult.message, // Utilisez le résultat de l'API comme ID de l'événement
                    ordre: 1,
                    libelle1: "",
                    libelle2: "",
                    prix: "",
                    paiement: "",
                    lat: "",
                    longi: "",
                    place: "",
                    bloc: "",
                    type: "",
                    lien: "",
                    etat_lien: "",
                    lien_modifie: "",
                    ref: "",
                    compagnie: "",
                    debut: "",
                    fin: "",
                    lat2: "",
                    longi2: "",
                    distance: "",
                    duree: "",
                    location: "",
                    moment: "",
                    rattachement: "",
                    invisible1: "O",
                    invisible2: "",
                    date_paiement_hebergement: "",
                    acompte_hebergement: "",
                    image: "",
                    document: ""
                  });

                  // Retourner une copie de l'objet avec les événements mis à jour
                  return {
                    ...day,
                    evenements: updatedEvenements,
                  };
                }
                return day;
              });

              return updatedCategories;
            });

            setIsAdding(false);
          } else {
            setIsAdding(false);
            console.error("Erreur lors de la requête API");
          }
        })
        .catch((error: any) => {
          setIsAdding(false);
          if (error.response?.status === 401) {
            alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).");
            localStorage.removeItem('xsrfToken');
            navigate('/login');
          } else {
            alert("Erreur lors de l'ajout d'évenement");
          }
          console.error("Erreur lors de l'ajout d'évenement:", error);
        });
    } catch (error) {
      console.error(error);
      alert("Erreur lors de la modification !");
    }
  };


const processModifVoyage = async () => {
  
  const verrouillageResultat = await verrouillageModif();

  if (verrouillageResultat === 1) {
    modifVoyage();
  }
};

processModifVoyage();
}

const handleDisplayLatitude1 = (valeur: string) => {
setLatitude1(valeur);
}

const handleDisplayLatitude2 = (valeur: string) => {
setLatitude2(valeur);
}

const handleDisplayLongitude1 = (valeur: string) => {
setLongitude1(valeur);
}

const handleDisplayLongitude2 = (valeur: string) => {
setLongitude2(valeur);
}

const handleDisplayLien = (valeur: string) => {
  setLienEnregistre(false);
setLien(valeur);
}

const handleSaveLien = () => {
const newLien=lien.replace(/ /g,'');
setLien(newLien);

if(newLien === '' || validationLien(newLien))
{

    voyageService.enregistrerLien(voyageKey, {
      lien: newLien,
      typeEvenement: typeEvenementForModal,
      idEvenement: idEvenementForModal 
    })
    .then((response: any) => {

      if(response.data.message === 'ok')
      {
        setLienEnregistre(true);
        if(newLien !== "")
        {
          alert("Lien enregistré");
        }
        else
        {
          alert("Lien supprimé");  
        }

        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, newLien, 'lien');
             
      }
      else
      {

        alert("Erreur lors de l'enregistrement du lien");
      }

    })
    .catch((error: any) => {

      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement du lien', vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
        navigate('/');
      }
      else
      {
        alert("Erreur lors de l'enregistrement du lien");
      }

    })
}
else
{
alert("Votre lien est incorrect, veuillez le vérifier !\n\nUn lien doit commencer par http:// ou https:// et correspondre au format attendu.");
}

};

const validationLien = (lienSaisi: string) => {
var expReg = new RegExp("^((http|https)://){1}(www[.])?([a-zA-Z0-9]|-)+");

if (expReg.test(lienSaisi.toLowerCase()))
{
return true;
}
else
{
return false;
}
};


const validationCoordonneesGPS = (lat: string, lon: string) => {


if(lat === '' && lon === '')
{
return true
}
else if((lat === '' && lon !== '') || (lat !== '' && lon === ''))
{
alert("Il faut remplir la latitude et la longitude");
return false;
}
else
{
const isLatitude = isFinite(Number(lat)) && Math.abs(Number(lat)) <= 90;
const isLongitude =isFinite(Number(lon)) && Math.abs(Number(lon)) <= 180;
if(!isLatitude)
{
alert("La latitude doit être comprise entre -90 et 90");
return false;
}
else if(!isLongitude)
{
alert("La longitude doit être comprise entre -180 et 180");
return false;
}
else
{
return true;
}
}
}

  const handleSuppressionClick = async (typeEvenement: string, idEvenement: number) => {
    const modifVoyage = async () => {
      try {
        
        const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir définitivement supprimer cet élément ?"
    );

    // Vérifiez si l'utilisateur a confirmé
    if (isConfirmed) {

    voyageService.supprimerEvenement(voyageKey, {
      typeEvenement,
      idEvenement
    })
    .then((response: any) => {
  
      if(response.data.message === 'ok')
      {
        removeEventFromState(response.data.idJournee, idEvenement, typeEvenement);

        if(response.data.idNuit !== '')
        {
          //on reaffiche la nuit
          handleElementChange(response.data.idJournee, response.data.idNuit, "nuit", "N", "invisible1");
  
        }
        if(typeEvenement === "trajet")
        {
          handleSuppLocation(idEvenement);
        }
      }
      else
      {
      
        alert("Erreur lors de la suppression d'évenement");
      }

    })
    .catch((error: any) => {

      if (error.response?.status === 401) {
        alert("Erreur lors de la suppression d'évenement, vous n'êtes pas le créateur du voyage.")
        navigate('/');
      }
      else
      {
        alert("Erreur lors de la suppression d'évenement");
      }

    })
  }
} catch (error) {
  console.error(error);
  alert("Erreur lors de la modification !");
}
};

const processModifVoyage = async () => {

const verrouillageResultat = await verrouillageModif();

if (verrouillageResultat === 1) {
  modifVoyage();
}
};

processModifVoyage();

}; 


  const handleBlocNotesClick = async (typeEvenement: string, idEvenement: number) => {

    voyageService.getBlocNotes(voyageKey, typeEvenement, idEvenement, emailPartage, codePartage)
    .then((response: any) => {
      if(response.data.message === 'ok')
      {
        setBlocNotes(response.data.bloc_notes);
        openModalBlocNotes(typeEvenement, idEvenement);
      }
      else
      {
        alert("Erreur lors de la récupération du bloc-notes");
      }

    })
    .catch((error: any) => {

      if (error.response?.status === 401) {
        alert("Erreur lors de la récupération du bloc-notes, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
        navigate('/');
      }
      else
      {
        alert("Erreur lors de la récupération du bloc-notes");
      }

    })
};



const handleSaveBlocNotes = (valeur: string) => {

  setBlocNotes(valeur);

  var newBlocNotes=valeur.replace(/\n/g, "<br>");
  newBlocNotes=valeur.replace(/\r/g, "<br/>");

  voyageService.enregistrerBlocNotes(voyageKey, {
    blocNotes: newBlocNotes,
    typeEvenement: typeEvenementForModal,
    idEvenement: idEvenementForModal 
  })
  .then((response: any) => {
    
   
    handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, newBlocNotes, 'bloc');

  
    if(response.data.message !== 'ok')
    {
      alert("Erreur lors de l'enregistrement du bloc-notes");
    }

  })
  .catch((error: any) => {

    if (error.response?.status === 401) {
      alert("Erreur lors de l'enregistrement du bloc-notes', vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
      navigate('/');
    }
    else
    {
      alert("Erreur lors de l'enregistrement du bloc-notes");
    }

  })
};

const handleDisplayBlocNotes = (valeur: string) => {
setBlocNotes(valeur);
}

function isFeatureGeoapifyType(data: unknown): data is FeatureGeoapifyType {
  return (
    typeof data === 'object' &&
    data !== null &&
    'features' in data &&
    Array.isArray((data as FeatureGeoapifyType).features)
  );
}

const downloadPDF = () => {
  const link = document.createElement('a');
  link.href = confirm; // L'URL blob
  link.download = 'document.pdf'; // Nom du fichier à télécharger
  link.click(); // Simuler le clic pour déclencher le téléchargement
};


const handleConfirmDelete = async () => {
  
  const fileName = confirmLien.split("/confirmation/")[1];

  // Handle file upload to the server or save it locally
  try {

    voyageService.supprimerConfirm(voyageKey, fileName)
  .then((response: any) => {
    setConfirm("");
    setConfirmLien("");

    handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, "", 'document');

 })
  .catch((error: any) => {
    if (error.response?.status === 401) {
      alert("Erreur lors de la suppression de la confirmation de réservation, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de la suppression de la confirmation de réservation");
    }
    console.error('Error maj image:', error);
  });

    // Optionally, update the user's profile data with the new avatar filename
    //await saveChanges("avatar", fileName);
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Erreur lors du téléchargement du fichier.");
  }
};

function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
  setNumPages(numPages);
  setPageNumber(1);
}

const changePage = (offset: number) => {
  setPageNumber((prevPageNumber) => prevPageNumber + offset);
};

const previousPage = () => {
  changePage(-1);
};

const nextPage = () => {
  changePage(1);
};  


const handleConfirmChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
const fileInput = event.target;
const file = fileInput.files?.[0];

if (file) {
  // Check file type
  if (!/\/(jpeg|jpg|pdf)$/.test(file.type)) {
    alert("Veuillez sélectionner un fichier JPEG, JPG ou PDF.");
    fileInput.value = ""; // Clear the file input
    return;
  }

  // Check file size
  const maxSize = 8 * 1024 * 1024; // 8MB
  if (file.size > maxSize) {
    alert("La taille du fichier ne doit pas dépasser 8 Mo.");
    fileInput.value = ""; // Clear the file input
    return;
  }

  // Assuming you have some way to get the user's identifiant
  const fileName = `document_${typeEvenementForModal}_${idEvenementForModal}.${file.type.split("/")[1]}`;

  // Handle file upload to the server or save it locally
  try {
    // Example using the Fetch API to upload the file (modify as needed)
    const formData = new FormData();
    formData.append("confirm", file, fileName);

    voyageService.uploadConfirm(voyageKey, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  .then((response: any) => {
    let imageURL: string | undefined;

    setConfirmLien(response.data.confirmInfo.url);

    handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, response.data.confirmInfo.url, 'document');

    if(response.data.confirmInfo.url !== "")
    {

      Axios.get(response.data.confirmInfo.url, { responseType: 'blob' })
      .then((response) => {

        const blob = new Blob([response.data], { type: 'image/jpeg' });
        imageURL = URL.createObjectURL(blob);

        setConfirm(imageURL);

      })
      .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
    }
    else
    {
      setConfirm("");
    }

    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
      }
    };

 })
  .catch((error: any) => {
    if (error.response?.status === 401) {
      alert("Erreur lors de l'enregistrement du document, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de l'enregistrement du document");
    }
    console.error('Error maj document:', error);
  });

    // Optionally, update the user's profile data with the new avatar filename
    //await saveChanges("avatar", fileName);
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Erreur lors du téléchargement du fichier.");
  }
}
};


const handleConfirmClick = async (typeEvenement: string, idEvenement: number) => {

  if(etatVoyage !== 0)
  {
  voyageService.getConfirm(voyageKey, typeEvenement, idEvenement, emailPartage, codePartage)
  .then((response: any) => {
   
    if(response.data.message === 'ok')
    {
      
      openModalConfirm(typeEvenement, idEvenement);

      let imageURL: string | undefined;

      setConfirmLien(response.data.confirm);
      if(response.data.confirm !== "")
      {
         Axios.get(response.data.confirm, { responseType: 'blob' })
        .then((response) => {
        
          const blob = new Blob([response.data], { type: 'image/jpeg' });
          imageURL = URL.createObjectURL(blob);

          setConfirm(imageURL);
  
        })
        .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
      }
      else
      {
        setConfirm("");
      }
  
      return () => {
        if (imageURL) {
          URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
        }
      };
    }
    else
    {
      alert("Erreur lors de la récupération de la confirmation");
    }

  })
  .catch((error: any) => {

    if (error.response?.status === 401) {
      alert("Erreur lors de la récupération du document, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
      navigate('/');
    }
    else
    {
      alert("Erreur lors de la récupération du document");
    }

  })
}
else
{
  alert("Vous devez être connecté(e) à un compte pour enregistrer une confirmation de réservation.");
}
};

const handleCopierClick = (idEvenement: number) => {

}

  const handleInfoClick = (placeId: string, placeNom: string, latitude: string, longitude: string) => {

  if(placeId !=null && placeId !== '' && placeId !== 'x')
  {
  /* Create a new promise and send geocoding request */
  let currentPromiseReject: ((reason?: any) => void) | null = null;

    /* Create a new promise and send geocoding request */
    var promise = new Promise((resolve, reject) => {
      currentPromiseReject = reject;

      var url = `https://api.geoapify.com/v2/place-details?id=${placeId}&lang=fr&apiKey=${apiKeyGeoapify}`;

      fetch(url)
        .then(response => {
          // check if the call was successful
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(data => reject(data));
          }
        });
    });

    promise.then((data: unknown) => {
      if (isFeatureGeoapifyType(data)) {
   
    /* For each item in the results */
    data.features.forEach((feature, index) => {

      if(feature.properties.address_line1 !== undefined && feature.properties.address_line1 !== null && feature.properties.address_line1 !== '')
      {
        if(placeNom === feature.properties.address_line1)
        {
          setInfoAdresse(feature.properties.address_line2);
        }
        else
        {
          setInfoAdresse(feature.properties.formatted);
        }
      }
      if(feature.properties.opening_hours !== undefined &&  feature.properties.opening_hours !== null && feature.properties.opening_hours !== '')
      {
        const regex = /;/gi;
        setInfoHoraires(feature.properties.opening_hours.replace(regex,"<br/>"));
        
      }
      if(feature.properties.facilities !== undefined &&  feature.properties.facilities !== null )
      {

        var equipements="";

        if( feature.properties.facilities.wheelchair)
        {
          equipements='<i class="las la-wheelchair"></i> ';
        }
        if( feature.properties.facilities.internet_access)
        {
          equipements+='<i class="las la-wifi"></i> ';
        }
        if( feature.properties.facilities.toilets)
        {
          equipements+='<i class="las la-restroom"></i> ';
        }
        if( feature.properties.facilities.swimming_pool)
        {
          equipements+='<i class="las la-swimming-pool"></i> ';
        }
        if( feature.properties.facilities.dogs)
        {
          equipements+='<i class="las la-wifi"></i> ';
        }
        if( feature.properties.facilities.air_conditioning)
        {
          equipements+='<i class="las la-snowflake"></i> ';
        }
        setInfoEquipements(equipements);
      }
      if(feature.properties.website !== undefined &&  feature.properties.website !== null && feature.properties.website !== '')
      {
        setInfoSite(feature.properties.website);
      }
      if(feature.properties.contact !== undefined && feature.properties.contact!== null)
      {
        if(feature.properties.contact.phone !== undefined && feature.properties.contact.phone !== null && feature.properties.contact.phone !== '')
        {
          var tabTel=feature.properties.contact.phone.split(";");
          setInfoTelephone(tabTel[0]);
          
        }
        if(feature.properties.contact.email !== undefined &&  feature.properties.contact.email !== null && feature.properties.contact.email !== '')
        {
          
          setInfoContact(feature.properties.contact.email);
        }
      }

      setInfoSource('Fourni par <a target="_blank" href="https://www.geoapify.com/">Geoapify</a> | <a target="_blank" href="'+feature.properties.datasource.url+'">'+feature.properties.datasource.attribution+'</a>');

      setPlaceNom(placeNom);
      setInfoLatitude(latitude);
      setInfoLongitude(longitude);
      openModalInfo();
    });
   }
  });
}
}

const handleRechercheClick = (typeEvenement: string, idEvenement: number) => {

  voyageService.getCoordonnees(voyageKey, typeEvenement, idEvenement)
      .then((response: any) => {
        
        if(response.data.message === 'ok')
        {
          setIdJourneeForModal(response.data.idJournee);
          setLieu1(response.data.lieu1);
          setLieu2(response.data.lieu2);
          setLatitude1(response.data.latitude1);
          setLatitude2(response.data.latitude2);
          setLongitude1(response.data.longitude1);
          setLongitude2(response.data.longitude2);

          if(typeEvenement === "lieu")
          {
            eventCategories.forEach((day) => {
              day.evenements.forEach((event) => {
                if(event.typeEv === "L" && event.id === idEvenement)
                {
                  if(event.type !== "O")
                  {
                    openModalGetYourGuide(typeEvenement, idEvenement);
                  }
                  else
                  {
                   //
                  }
                }
                 });
           });
          }
          else if(typeEvenement === "trajet")
          {
            eventCategories.forEach((day) => {
               day.evenements.forEach((event) => {
                 if(event.typeEv === "T" && event.id === idEvenement)
                 {
                   if(event.location === "O")
                   {
                    openModalDiscoverCars(typeEvenement, idEvenement);
                   }
                   else
                   {
                    openModalKiwi(typeEvenement, idEvenement);
                   }
                 }
                  });
            });
          }
          else if(typeEvenement === "nuit")
          {
            openModalBooking(typeEvenement, idEvenement);
          }
        }
        else
        {
          alert("Erreur lors de la récupération des coordonées");
        }

      })
      .catch((error: any) => {

        if (error.response?.status === 401) {
          alert("Erreur lors de la récupération des coordonnées, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
          navigate('/');
        }
        else
        {
          alert("Erreur lors de la récupération des coordonnées");
        }

      })

}

const handleElementChange = useCallback(
  (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement: string) => {

    setEventCategories((prevCategories) => {
      return prevCategories.map((day) => {
        if (day.id_journee === journeeId) {
          return {
            ...day,
            evenements: day.evenements.map((event) => {
              if (
                (typeEvenement === 'lieu' && event.typeEv === 'L' && event.id === eventId) ||
                (typeEvenement === 'trajet' && event.typeEv === 'T' && event.id === eventId) ||
                (typeEvenement === 'nuit' && event.typeEv === 'N' && event.id === eventId)
              ) {
                if (typeElement === 'libelle1') {
                  return { ...event, libelle1: valeur };
                } else if (typeElement === 'libelle2') {
                  return { ...event, libelle2: valeur };
                } else if (typeElement === 'prix') {
                  return { ...event, prix: valeur };
                } else if (typeElement === 'paiement') {
                  return { ...event, paiement: valeur };
                } else if (typeElement === 'acompte_hebergement') {
                  return { ...event, acompte_hebergement: valeur };
                } else if (typeElement === 'date_paiement_hebergement') {
                  return { ...event, date_paiement_hebergement: valeur };
                } else if (typeElement === 'debut') {
                  return { ...event, debut: valeur };
                } else if (typeElement === 'fin') {
                  return { ...event, fin: valeur };
                } else if (typeElement === 'compagnie') {
                  return { ...event, compagnie: valeur };
                } else if (typeElement === 'ref') {
                  return { ...event, ref: valeur };
                } else if (typeElement === 'distance') {
                  return { ...event, distance: valeur };
                } else if (typeElement === 'duree') {
                  return { ...event, duree: valeur };
                } else if (typeElement === 'invisible1') {
                  return { ...event, invisible1: valeur };
                } else if (typeElement === 'invisible2') {
                  return { ...event, invisible2: valeur };
                } else if (typeElement === 'type') {
                  return { ...event, type: valeur };
                } else if (typeElement === 'moment') {
                  return { ...event, moment: valeur };
                } else if (typeElement === 'rattachement') {
                  return { ...event, rattachement: valeur };
                } else if (typeElement === 'image') {
                  return { ...event, image: valeur };
                } else if (typeElement === 'lat') {
                  return { ...event, lat: valeur };
                } else if (typeElement === 'longi') {
                  return { ...event, longi: valeur };
                } else if (typeElement === 'lat2') {
                  return { ...event, lat2: valeur };
                } else if (typeElement === 'longi2') {
                  return { ...event, longi2: valeur };
                } else if (typeElement === 'latModif') {
                  return { ...event, latModif: valeur };
                } else if (typeElement === 'longiModif') {
                  return { ...event, longiModif: valeur };
                } else if (typeElement === 'lat2Modif') {
                  return { ...event, lat2Modif: valeur };
                } else if (typeElement === 'longi2Modif') {
                  return { ...event, longi2Modif: valeur };
                } else if (typeElement === 'document') {
                  return { ...event, document: valeur };
                } else if (typeElement === 'lien') {
                  return { ...event, lien: valeur };
                } else if (typeElement === 'bloc') {
                  return { ...event, bloc: valeur };
                } else if (typeElement === 'pays') {
                  return { ...event, pays: valeur };
                } else if (typeElement === 'nom_pays') {
                  return { ...event, nom_pays: valeur };
                }
              }
              return event;
            }),
          };
        }
        return day;
      });
    });

  },
  [] // dependencies, update if needed
);

const handlePecChange = useCallback(
  (idPec: string, idRest :string) => {

    setEventCategories((prevCategories) => {
      return prevCategories.map((day) => {
          return {
            ...day,
            evenements: day.evenements.map((event) => {
              if (event.typeEv === 'T' && event.location === "O" && event.moment === "P" && event.rattachement === idRest) 
              {
                  return { ...event, rattachement: "" };
              }
              else if(idPec !== '' && event.typeEv === 'T' && event.location === "O" && event.moment === "P" && event.id === Number(idPec)) 
              {
                return { ...event, rattachement: idRest };
              }
              return event;
            }),
          };
      });
    });
  },
  [] // dependencies, update if needed
);

const handleSuppLocation = useCallback(
  (idLocation: number) => {

    setEventCategories((prevCategories) => {
      return prevCategories.map((day) => {
          return {
            ...day,
            evenements: day.evenements.map((event) => {
              if (event.typeEv === 'T' && event.location === "O" && event.moment === "P" && event.rattachement === idLocation.toString()) 
              {
                  return { ...event, rattachement: "" };
              }
              else if(event.typeEv === 'T' && event.location === "O" && event.moment === "R" && event.rattachement === idLocation.toString()) 
              {
                return { ...event, rattachement: "", type: "" };
              }
              return event;
            }),
          };
      });
    });

  },
  [] // dependencies, update if needed
);

const handleChangeTypeLocation = useCallback(
  (idRattach: string, nouvType: string) => {

    setEventCategories((prevCategories) => {
      return prevCategories.map((day) => {
          return {
            ...day,
            evenements: day.evenements.map((event) => {
              if (event.typeEv === 'T' && event.location === "O" && event.moment === "P" && nouvType === "P" && event.id === Number(idRattach))
              {
                  return { ...event, rattachement: "" };
              }
              else if(event.typeEv === 'T' && event.location === "O" && event.moment === "R" && nouvType === "R" && event.id === Number(idRattach))
              {
                return { ...event, rattachement: "", type: "" };
              }
              return event;
            }),
          };
      });
    });

  },
  [] // dependencies, update if needed
);


useEffect(() => {

    if (!isUpdating && JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage)) {
       // Set loading state or other indicators here if needed
       setLoading(true);
       setIsUpdating(true);
 
       // Simulated async update (replace with actual async operation if needed)
       setTimeout(() => {

         setEventCategories(eventCategoriesPage);
         setLoading(false);
         setIsUpdating(false);
       }, 1000); // Adjust delay as per your application needs
    }
  }, [eventCategoriesPage]);

  useEffect(() => {

    if (!loading && JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage))
   {

      // Mettre à jour eventCategoriesPage lorsque eventCategories change
      majEventCategoriesPage(eventCategories);
    
    const nbPoints = eventCategories.reduce((count, jour) => {
      return count + jour.evenements.filter((evenement) => 
        (evenement.lat !== null && evenement.lat !== '' &&
        evenement.longi !== null && evenement.longi !== '') ||
        (evenement.lat2 !== null && evenement.lat2 !== '' &&
        evenement.longi2 !== null && evenement.longi2 !== '')
      ).length;
    }, 0);

    if(nbPoints>0)
    {
      setIsEnoughPoints(true);
    }
    else
    {
      setIsEnoughPoints(false);
    }
  }

  }, [eventCategories]);

useEffect(() => {

  const updatedListePec = eventCategories.flatMap((day) =>
  day.evenements
    .filter(
      (event) =>
        event.location === 'O' &&
        event.moment === 'P'
    )
    .map((event) => ({
      date_journee: day.date_journee,
      id_journee: day.id_journee,
      id_evenement: event.id, 
      type_transport: event.type || "",
      rattachement: event.rattachement || "",
      debut: event.debut || ""
    }))
);

setListePec(updatedListePec);

}, [eventCategories]);

const handleEffacePlacement = () => {
  setPlacementCardID(null);
}

const handleDeplacementMarker = async (lat1: any, longi1: any, lat2: any, longi2: any, typeEvenement: string, idEvenement: number, idJournee: number) => {
 
  setLatitude1(lat1);
  setLongitude1(longi1);
  setLatitude2(lat2);
  setLongitude2(longi2);
  setTypeEvenementForModal(typeEvenement);
  setIdEvenementForModal(idEvenement);
  setIdJourneeForModal(idJournee);
  handleSaveCoord2(lat1, longi1, lat2, longi2, typeEvenement, idEvenement, idJournee);
}

const handleLienClick = async (typeEvenement: string, idEvenement: number) => {

 
  voyageService.getLien(voyageKey, typeEvenement, idEvenement, emailPartage, codePartage)
  .then((response: any) => {
  
    if(response.data.message === 'ok')
    {
      setLien(response.data.lien);
      setEtatLien(response.data.etat_lien);
      setLienModifie(response.data.lien_modifie);
      setMotifLien(response.data.motif_lien);
      openModalLien(typeEvenement, idEvenement);
    }
    else
    {
      alert("Erreur lors de la récupération du lien");
    }

  })
  .catch((error: any) => {

    if (error.response?.status === 401) {
      alert("Erreur lors de la récupération du lien, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
      navigate('/');
    }
    else
    {
      alert("Erreur lors de la récupération du lien");
    }

  })
}; 


  const cleanMarkerID = (markerID: string): string => {
    if (markerID.startsWith("arrivee-")) {
      return markerID.substring("arrivee-".length);
    } else if (markerID.startsWith("depart-")) {
      return markerID.substring("depart-".length);
    } else if (markerID.startsWith("ville-")) {
      return markerID.substring("ville-".length);
    } else if (markerID.startsWith("hebergement-")) {
      return markerID.substring("hebergement-".length);
    } else {
      return markerID;
    }
  };

  // Fonction pour gérer le clic sur un marqueur et mettre à jour l'ID de la carte sélectionnée
const handleMarkerClick = (markerID: string) => { 
  setSelectedMarkerID(null);
  

    const isScreenLessThanLG = window.matchMedia("(max-width: 992px)").matches;

    if (isScreenLessThanLG) {
       if (markerID && listContainerRefSmall.current) {
        markerID=cleanMarkerID(markerID);
        setSelectedMarkerID(markerID);
       
        const selectedCardElement = listContainerRefSmall.current.querySelector(`#${markerID}-small`);


      if (selectedCardElement  instanceof HTMLElement) {
      const scrollOffset = (listContainerRefSmall.current.offsetWidth - selectedCardElement.offsetWidth) / 2;
     listContainerRefSmall.current.scrollLeft = selectedCardElement.offsetLeft - scrollOffset;

        }
      }
    } else {
      if (markerID && listContainerRef.current) {
        markerID=cleanMarkerID(markerID);
        setSelectedMarkerID(markerID);
        
        const selectedCardElement = listContainerRef.current.querySelector(`#${markerID}`);

      if (selectedCardElement  instanceof HTMLElement) {
      selectedCardElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }


};

const handlePlacementClick = (event: EventType, id: string, type?: String) => {

  if(type)
  {
    setPlacementCardID(type+"-"+id);  
  }
  else{
    setPlacementCardID(id);        
  }  

  alert("Lieu sélectionné.\n\nFaîtes un clic droit (ou appui long sur écran tactile) à l'emplacement souhaité sur la carte pour lui attribuer des coordonnées GPS.");
}

  // Fonction pour mettre à jour l'ID de la carte sélectionnée
  const handleCardClick = (event: EventType, id: string, type?: String) => {
    
    setSelectedCardID(null);
    setSelectedMarkerID(null);
    
    if(((type === undefined || type === null || type === "depart" || type === "ville") && event.lat !== null && event.lat !== '' && event.longi !== null && event.longi !== '')
    || ((type === "arrivee" || type === "hebergement") && event.lat2 !== null && event.lat2 !== '' && event.longi2 !== null && event.longi2 !== ''))
    {
      const newCompteurClick=compteurClick+1; 
        
      if(type)
      {
        setSelectedCardID(type+"-"+id);  
        setCompteurClick(newCompteurClick);  
      }
      else{
        setSelectedCardID(id);        
        setCompteurClick(newCompteurClick);
      }     
    }
    else
    {
      
      alert("Pas de coordonnée GPS enregistrée donc non localisable.")
    }
  };

  return (
    
    <DndProvider backend={HTML5Backend}>
    <>
    { isEnoughPoints && (
    <div>
      <div className="relative min-h-screen cardsMap md:grid-cols-1 md:grid-gap-1">
  
      <div className={`w-full lg:w-[320px] xl:w-[400px] 2xl:w-[580px] flex-shrink-0 lg:px-8 xl:px-8 md:w-full-map block lg:hidden overflow-x-auto`}>
     
     
      { (numDroit === 1 || numDroit === 0) && (
     <div className="mb-3 lg:mb-4 text-xs items-center text-center">
           <div>
             <button
               onClick={toggleModif}
               className={`w-10 h-6 rounded-full p-1 transition-colors ${isModif ? 'bg-rosetrip-500' : 'bg-gray-300'}`}
             >
               <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isModif ? 'translate-x-full' : ''}`} />
             </button>
           </div>
           <div className="pt-2">
             {isModif && (
               <span>{t('modeModif')}</span>
             )}
             {!isModif && (
               <span>{t('modeConsult')}</span>
             )}
             &nbsp;
           </div>
         </div>
         )}
</div>

        {/* MAPPPPP */}
        <div className={`flex-grow static block md:flex-grow md:static md:block lg:flex-grow lg:static lg:block xl:flex-grow xl:static xl:blockfixed inset-0 z-50`}>
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] fixed sticky top-0 top-[88px] md:top-[88px] lg:top-[88px] left-0 w-full h-map lg:h-[calc(100vh-88px)] rounded-md overflow-hidden w-map">
            <MapboxGL
              voyageKey={voyageKey}
              selectedCardID={selectedCardID}
              compteurClick={compteurClick}
              onMarkerClick={handleMarkerClick}
              eventCategories={eventCategories}
              isModif={isModif}
              onDeplacementMarker={handleDeplacementMarker}
              placementCardID={placementCardID}
              onEffacePlacement={handleEffacePlacement}
            />
          </div>
        </div>

        <div className={`w-full lg:w-[320px] xl:w-[400px] 2xl:w-[580px] flex-shrink-0 lg:px-8 xl:px-8 md:w-full-map md:block lg:hidden overflow-x-auto md:flex md:flex-wrap pt-4 lg:pt-0`}>
  <div ref={listContainerRefSmall} className="flex gap-2 md:flex md:flex-nowrap overflow-x-auto">
    {eventCategories &&
      eventCategories.map((day, index) => {
        let isNewDay = true;
        return day.evenements.map((event, eventIndex) => {

          // Afficher "Jour {day.num_jour}" uniquement lorsqu'il y a un changement de jour
          const dayHeader = isNewDay ? <div className="mb-1 pl-2 text-left"><h2 className="champJour" >{t('jour')} {day.num_jour}</h2>
          <h2 className="champDate">{day.date_journee}</h2></div> : <div className="mt-8"></div>;
          isNewDay = false;

          return (
            <>
              {isModif || (!isModif && ((
                (event.typeEv === "L" &&
                ((event.libelle1 !== null && event.libelle1 !== "") ||
                 (event.libelle2 !== null && event.libelle2 !== "") ||
                 (event.prix !== null && Number(event.prix.replace("", "0"))) > 0))) 
                || (event.typeEv === "T" &&
                ((event.libelle1 !== null && event.libelle1 !== "") ||
                  (event.libelle2 !== null && event.libelle2 !== "") ||
                  (event.prix !== null && Number(event.prix.replace("", "0")) > 0) ||
                  (event.type !== null && event.type !== "") ||
                  (event.debut !== null && event.debut !== "") ||
                  (event.fin !== null && event.fin !== "")))
                || (event.typeEv === 'N' && event.invisible1 !== "O" 
                && (( event.libelle1 !== null && event.libelle1 !== "") 
                  || ( event.libelle2 !== null && event.libelle2 !== "") 
                  || (event.prix !== null && Number(event.prix.replace("","0"))) >0 )
                )
                 )) ? (
              <div key={`${day.num_jour}-${eventIndex}-small`} id={`${getCardID(event)}-small`} className="py-1 text-center flex-shrink-0 cardMap"> 
                <div className="">
                {dayHeader}
                  {isModif && (
                    <DraggableCard
                      key={event.id}
                      index={eventIndex}
                      id_journee={day.id_journee}
                      eventCategories={eventCategories}
                      onDrop={(dragIndex, hoverIndex, draggedIdJournee) =>
                        moveCard(dragIndex, hoverIndex, draggedIdJournee, day.id_journee)
                      }
                    >
                      {event.typeEv !== "N" || (event.typeEv === "N" && event.invisible1 !== "O") ? (
                        <ButtonCircleSmall onClick={() => openModal(day.id_journee, event, eventIndex)}>+</ButtonCircleSmall>
                      ) : null}
                      {event.typeEv === "L" ? (
                        <CardLieuModif
                          id={`lieu-${event.id}-small`}
                          index={`lieu-${event.id}-small`}
                          key={`lieu-${event.id}-small`}
                          monnaie={monnaie || 'euro'}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || "",
                            email: "atruelock0@skype.com",
                            avatar: event.image || "",
                            bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                            count: 40,
                            href: "/author",
                            desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                            jobName: "Author Job",
                            libelle2: event.libelle2 || "",
                            prix: event.prix || "",
                            paiement: event.paiement || "",
                            type: event.type || "",
                            lien: event.lien || "",
                            lien_modifie: event.lien_modifie || "",
                            etat_lien: event.etat_lien || "",
                            lat: event.lat || "",
                            longi: event.longi || "",
                            document: event.document || "",
                            bloc: event.bloc || "",
                          }}
                          id_pays={id_pays}
                          idVoyage={voyageKey}
                          etatVoyage={etatVoyage}
                          idJournee={day.id_journee}
                          onSuppressionClick={handleSuppressionClick}
                          onLienClick={handleLienClick}
                          onDisplayElementChange={handleElementChange}
                          onBlocNotesClick={handleBlocNotesClick}
                          onCoordClick={handleCoordClick}
                          onConfirmClick={handleConfirmClick}
                          isMap={true}
                          onLocalisationClick={() => handleCardClick(event, getCardID(event))}
                          onPlacementClick={() => handlePlacementClick(event, getCardID(event))}
                          onRechercheClick={handleRechercheClick}
                          selected={selectedMarkerID === `lieu-${event.id}`}
                        />
                      ) : event.typeEv === "T" ? (
                        <CardTrajetModif
                          id={`trajet-${event.id}-small`}
                          index={`trajet-${event.id}-small`}
                          key={`trajet-${event.id}-small`}
                          monnaie={monnaie || 'euro'}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || "",
                            email: "atruelock0@skype.com",
                            avatar: event.image || "",
                            bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                            count: 40,
                            href: "/author",
                            desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                            jobName: "Author Job",
                            libelle2: event.libelle2 || "",
                            prix: event.prix || "",
                            type: event.type || "",
                            debut: event.debut || "",
                            fin: event.fin || "",
                            compagnie: event.compagnie || "",
                            ref: event.ref || "",
                            location: event.location || "",
                            paiement: event.paiement || "",
                            distance: event.distance || "",
                            duree: event.duree || "",
                            lat: event.lat || "",
                            longi: event.longi || "",
                            lat2: event.lat2 || "",
                            longi2: event.longi2 || "",
                            invisible1: event.invisible1 || "",
                            invisible2: event.invisible2 || "",
                            rattachement: event.rattachement || "",
                            moment: event.moment || "",
                            lien: event.lien || "",
                            lien_modifie: event.lien_modifie || "",
                            etat_lien: event.etat_lien || "",
                            latModif: event.latModif || "",
                            longiModif: event.longiModif || "",
                            lat2Modif: event.lat2Modif || "",
                            longi2Modif: event.longi2Modif || "",
                            document: event.document || "",
                            bloc: event.bloc || "",
                          }}
                          idJournee={day.id_journee}
                          id_pays={id_pays}
                          idVoyage={voyageKey}
                          onSuppressionClick={shouldCallSuppressionClick(day, eventIndex) ? handleSuppressionClick : undefined}
                          onDisplayElementChange={handleElementChange}
                          onAddTrajetLendemain={handleAddTrajetLendemain}
                          onDeleteTrajetLendemain={handleDeleteTrajetLendemain}
                          listePecPlanificateur={listePec}
                          onDisplayPecChange={handlePecChange}
                          onChangeTypeLocation={handleChangeTypeLocation}
                          onLienClick={handleLienClick}
                          onBlocNotesClick={handleBlocNotesClick}
                          onCoordClick={handleCoordClick}
                          onConfirmClick={handleConfirmClick}
                          isMap={true}
                          onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                          onPlacementClick={(type) => handlePlacementClick(event, getCardID(event), type)}
                          onRechercheClick={handleRechercheClick}
                          selected={selectedMarkerID === `trajet-${event.id}`}
                        />
                      ) : event.typeEv === "N" ? (
                        <CardNuitModif
                          index={`nuit-${event.id}-small`}
                          key={`nuit-${event.id}-small`}
                          monnaie={monnaie || 'euro'}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || "",
                            email: "atruelock0@skype.com",
                            avatar: event.image || "",
                            bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                            count: 40,
                            href: "/author",
                            desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                            jobName: "Author Job",
                            libelle2: event.libelle2 || "",
                            prix: event.prix || "",
                            paiement: event.paiement || "",
                            date_paiement_hebergement: event.date_paiement_hebergement || "",
                            acompte_hebergement: event.acompte_hebergement || "",
                            invisible1: event.invisible1 || "",
                            lien: event.lien || '',
                            lien_modifie: event.lien_modifie || '',
                            etat_lien: event.etat_lien || '',
                            lat: event.lat || '',
                            longi: event.longi || '',
                            lat2: event.lat2 || '',
                            longi2: event.longi2 || '',
                            document: event.document || '',
                            bloc: event.bloc || ''
                          }}
                          id_pays={id_pays}
                          idVoyage={voyageKey}
                          etatVoyage={etatVoyage}
                          idJournee={day.id_journee}
                          onDisplayElementChange={handleElementChange}
                          onLienClick={handleLienClick}
                          onBlocNotesClick={handleBlocNotesClick}
                          onCoordClick={handleCoordClick}
                          onConfirmClick={handleConfirmClick}
                          onCopierClick={handleCopierClick}
                          isMap={true}
                          onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                          onPlacementClick={(type) => handlePlacementClick(event, getCardID(event), type)}
                          onRechercheClick={handleRechercheClick}
                          selected={selectedMarkerID === `nuit-${event.id}`}
                        />
                      ) : null}
                    </DraggableCard>
                  )}
                  {!isModif && (
                    (event.typeEv === "L" &&
                      ((event.libelle1 !== null && event.libelle1 !== "") ||
                        (event.libelle2 !== null && event.libelle2 !== "") ||
                        (event.prix !== null && Number(event.prix.replace("", "0"))) > 0) ? (
                      <CardLieu
                        index={`lieu-${event.id}-small`}
                        key={`lieu-${event.id}-small`}
                        monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || "",
                          email: "atruelock0@skype.com",
                          avatar: event.image || "",
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || "",
                          prix: event.prix || "",
                          type: event.type || "",
                          paiement: event.paiement || "",
                          lien: event.lien || "",
                          lien_modifie: event.lien_modifie || "",
                          etat_lien: event.etat_lien || "",
                          document: event.document || "",
                          bloc: event.bloc || "",
                          lat: event.lat || "",
                          longi: event.longi || "",
                          place_id: event.place || "",
                        }}
                        onBlocNotesClick={handleBlocNotesClick}
                        onConfirmClick={handleConfirmClick}
                        onInfoClick={handleInfoClick}
                        selected={selectedMarkerID === `lieu-${event.id}`}
                        isMap={true}
                        onLocalisationClick={() => handleCardClick(event, getCardID(event))}
                        onRechercheClick={handleRechercheClick}
                        numDroit={numDroit}
                      />
                    ) : event.typeEv === "T" &&
                      ((event.libelle1 !== null && event.libelle1 !== "") ||
                        (event.libelle2 !== null && event.libelle2 !== "") ||
                        (event.prix !== null && Number(event.prix.replace("", "0")) > 0) ||
                        (event.type !== null && event.type !== "") ||
                        (event.debut !== null && event.debut !== "") ||
                        (event.fin !== null && event.fin !== "")) ? (
                      <CardTrajet
                        index={`trajet-${event.id}-small`}
                        key={`trajet-${event.id}-small`}
                        monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || "",
                          email: "atruelock0@skype.com",
                          avatar: event.image || "",
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || "",
                          prix: event.prix || "",
                          type: event.type || "",
                          debut: event.debut || "",
                          fin: event.fin || "",
                          compagnie: event.compagnie || "",
                          ref: event.ref || "",
                          location: event.location || "",
                          distance: event.distance || "",
                          duree: event.duree || "",
                          moment: event.moment || "",
                          paiement: event.paiement || "",
                          lien: event.lien || "",
                          lien_modifie: event.lien_modifie || "",
                          etat_lien: event.etat_lien || "",
                          document: event.document || "",
                          bloc: event.bloc || "",
                          lat: event.lat || "",
                          longi: event.longi || "",
                          lat2: event.lat2 || "",
                          longi2: event.longi2 || "",
                        }}
                        onBlocNotesClick={handleBlocNotesClick}
                        onConfirmClick={handleConfirmClick}
                        selected={selectedMarkerID === `trajet-${event.id}`}
                        isMap={true}
                        onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                        onRechercheClick={handleRechercheClick}
                        numDroit={numDroit}
                      />
                    ) : event.typeEv === 'N' && event.invisible1 !== "O" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ) ? (
                      <CardNuit
                        index={`nuit-${event.id}-small`}
                        key={`nuit-${event.id}-small`}
                        monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || "",
                          email: "atruelock0@skype.com",
                          avatar: event.image || "",
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || "",
                          prix: event.prix || "",
                          paiement: event.paiement || "",
                          date_paiement_hebergement: event.date_paiement_hebergement || "",
                          acompte_hebergement: event.acompte_hebergement || "",
                          lien: event.lien || "",
                          lien_modifie: event.lien_modifie || "",
                          etat_lien: event.etat_lien || "",
                          document: event.document || "",
                          bloc: event.bloc || "",
                          lat: event.lat || "",
                          longi: event.longi || "",
                          lat2: event.lat2 || "",
                          longi2: event.longi2 || "",
                          place_id: event.place || "",
                        }}
                        onBlocNotesClick={handleBlocNotesClick}
                        onConfirmClick={handleConfirmClick}
                        onInfoClick={handleInfoClick}
                        selected={selectedMarkerID === `nuit-${event.id}`}
                        isMap={true}
                        onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                        onRechercheClick={handleRechercheClick}
                        numDroit={numDroit}
                      />
                    ) : null)
                  )}
                 </div>
              </div>
                 ) : (null)}
            </>
          );
        });
      })}
  </div>
</div>

        <div className={`min-h-screen w-full lg:w-[320px] xl:w-[400px] 2xl:w-[400px] flex-shrink-0 lg:px-8 xl:px-8 md:w-full-map hidden lg:block`}>
          
          
      { (numDroit === 1 || numDroit === 0) && (
      <div className="mb-3 lg:mb-4 text-xs items-center text-center">
            <div>
              <button
                onClick={toggleModif}
                className={`w-10 h-6 rounded-full p-1 transition-colors ${isModif ? 'bg-rosetrip-500' : 'bg-gray-300'}`}
              >
                <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isModif ? 'translate-x-full' : ''}`} />
              </button>
            </div>
            <div className="pt-2">
              {isModif && (
                <span>{t('modeModif')}</span>
              )}
              {!isModif && (
                <span>{t('modeConsult')}</span>
              )}
              &nbsp;
            </div>
          </div>
      )}
          <div ref={listContainerRef} className="grid grid-cols-1 gap-2 md:grid-cols-none md:grid-rows-1 md:gap-0 md:overflow-x-auto">
            {eventCategories &&
              eventCategories.map((day, index) => (
                <>
                  <div key={`jour-${day.num_jour}`} className="text-center">
                    {day.num_jour > 1 && (
                      <hr className="mt-1"></hr>
                    )}
                    <h2 className="champJour mt-4">{t('jour')} {day.num_jour}</h2>
                    <h2 className="champDate mt-2 mb-2">{day.date_journee}</h2>
                  </div>

                  {  day.evenements.map((event, eventIndex) => (

                      <div key={`${day.num_jour}-${eventIndex}`} id={getCardID(event)} className="py-1 text-center">
                        { isModif ? (
                        <DraggableCard key={event.id} index={eventIndex} id_journee={day.id_journee} eventCategories={eventCategories} onDrop={(dragIndex, hoverIndex, draggedIdJournee) => moveCard(dragIndex, hoverIndex, draggedIdJournee, day.id_journee)}>
                         {event.typeEv !== "N" || (event.typeEv === "N" && event.invisible1 !== 'O') ? (
                            <ButtonCircleSmall onClick={() => openModal(day.id_journee, event, eventIndex)}>+</ButtonCircleSmall>
                         ) : null }
                        {event.typeEv === "L" ? (
                          <CardLieuModif
                          id="CARD"
                          index={`lieu-${event.id}`}
                          key={`lieu-${event.id}`}
                          monnaie={monnaie || 'euro'}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || "",
                                              email: "atruelock0@skype.com",
                                              avatar: event.image || '',
                                              bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                                              count: 40,
                                              href: "/author",
                                              desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                                              jobName: "Author Job",
                                              libelle2: event.libelle2 || '',
                                              prix: event.prix || '',
                                              paiement: event.paiement || '',
                                              type: event.type || '',
                                              lien: event.lien || '',
                                              lien_modifie: event.lien_modifie || '',
                                              etat_lien: event.etat_lien || '',
                                              lat: event.lat || '',
                                              longi: event.longi || '',
                                              document: event.document || '',
                                              bloc: event.bloc || ''
                                            }}
                                            id_pays={id_pays}
                                            idVoyage={voyageKey}
                                            etatVoyage={etatVoyage}
                                            idJournee={day.id_journee}
                                            onSuppressionClick={handleSuppressionClick}
                                            onLienClick={handleLienClick}                                     
                                            onDisplayElementChange={handleElementChange}
                                            onBlocNotesClick={handleBlocNotesClick}
                                            onCoordClick={handleCoordClick}
                                            onConfirmClick={handleConfirmClick}
                                        isMap={true}
                                        onLocalisationClick={() => handleCardClick(event, getCardID(event))}
                                        onPlacementClick={() => handlePlacementClick(event, getCardID(event))}
                                        onRechercheClick={handleRechercheClick}
                                        selected={selectedMarkerID === `lieu-${event.id}`}
                                            />
                        ) : event.typeEv === 'T' ? (
                        <CardTrajetModif
                        id="CARD"
                        index={`trajet-${event.id}`}
                          key={`trajet-${event.id}`}
                          monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || '',
                          email: "atruelock0@skype.com",
                          avatar:event.image || '',
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || '',
                          prix: event.prix || '',
                          type: event.type || '',
                          debut: event.debut || '',
                          fin: event.fin || '',
                          compagnie: event.compagnie || '',
                          ref: event.ref || '',
                          location: event.location || '',
                          paiement: event.paiement || '',
                          distance: event.distance || '',
                          duree: event.duree || '',
                          lat: event.lat || '',
                          longi: event.longi || '',
                          lat2: event.lat2 || '',
                          longi2: event.longi2 || '',
                          invisible1: event.invisible1 || '',
                          invisible2: event.invisible2 || '',
                          rattachement: event.rattachement || '',
                          moment: event.moment || '',
                          lien: event.lien || '',
                          lien_modifie: event.lien_modifie || '',
                          etat_lien: event.etat_lien || '',
                          latModif: event.latModif || '',
                          longiModif: event.longiModif || '',
                          lat2Modif: event.lat2Modif || '',
                          longi2Modif: event.longi2Modif || '',
                          document: event.document || '',
                          bloc: event.bloc || ''
                                            
                        }}
                        idJournee={day.id_journee}
                        id_pays={id_pays}
                        idVoyage={voyageKey}
                        onSuppressionClick={shouldCallSuppressionClick(day, eventIndex) ? handleSuppressionClick : undefined}
                        onDisplayElementChange={handleElementChange}
                        onAddTrajetLendemain={handleAddTrajetLendemain}
                        onDeleteTrajetLendemain={handleDeleteTrajetLendemain}
                        listePecPlanificateur={listePec}
                        onDisplayPecChange={handlePecChange}
                        onChangeTypeLocation={handleChangeTypeLocation}
                        onLienClick={handleLienClick}
                        onBlocNotesClick={handleBlocNotesClick}
                        onCoordClick={handleCoordClick}
                      onConfirmClick={handleConfirmClick}
                      isMap={true}
                      onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                      onPlacementClick={(type) => handlePlacementClick(event, getCardID(event), type)}
                      onRechercheClick={handleRechercheClick}
                      selected={selectedMarkerID === `trajet-${event.id}`}
                        />
                      ) : event.typeEv === 'N' ? (
                        <CardNuitModif
                        index={`nuit-${event.id}`}
                        key={`nuit-${event.id}`}
                        monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || '',
                          email: "atruelock0@skype.com",
                          avatar:  event.image || '',
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || '',
                          prix: event.prix || '',
                          paiement: event.paiement || '',
                          date_paiement_hebergement: event.date_paiement_hebergement || '',
                          acompte_hebergement: event.acompte_hebergement || '',
                          invisible1: event.invisible1 || '',
                          lien: event.lien || '',
                          lien_modifie: event.lien_modifie || '',
                          etat_lien: event.etat_lien || '',
                          lat: event.lat || '',
                          longi: event.longi || '',
                          lat2: event.lat2 || '',
                          longi2: event.longi2 || '',
                          document: event.document || '',
                          bloc: event.bloc || ''
                                            
                        }}
                        id_pays={id_pays}
                        idVoyage={voyageKey}
                        etatVoyage={etatVoyage}
                        idJournee={day.id_journee}
                        onDisplayElementChange={handleElementChange}
                        onLienClick={handleLienClick}
                        onBlocNotesClick={handleBlocNotesClick}
                        onCoordClick={handleCoordClick}
                        onConfirmClick={handleConfirmClick}
                        onCopierClick={handleCopierClick}
                        isMap={true}
                        onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                        onPlacementClick={(type) => handlePlacementClick(event, getCardID(event), type)}
                        onRechercheClick={handleRechercheClick}
                        selected={selectedMarkerID === `nuit-${event.id}`}
                        />
                      ) : null}
  </DraggableCard>
                      ) : null
                      }

                      { !isModif ? (
                      (event.typeEv === "L" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ) ? (
                      <CardLieu
                      index={`lieu-${event.id}`}
                      key={`lieu-${event.id}`}
                      monnaie={monnaie || 'euro'}
                      author={{
                        id: 1,
                        firstName: "Alric",
                        lastName: "Truelock",
                        displayName: event.libelle1 || "",
                                          email: "atruelock0@skype.com",
                                          avatar: event.image || '',
                                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                                          count: 40,
                                          href: "/author",
                                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                                          jobName: "Author Job",
                                          libelle2: event.libelle2 || '',
                                          prix: event.prix || '',
                                          type: event.type || '',
                                          paiement: event.paiement || '',
                                          lien: event.lien || '',
                                          lien_modifie: event.lien_modifie || '',
                                          etat_lien: event.etat_lien || '',
                                          document: event.document || '',
                                          bloc: event.bloc || '',
                                          lat: event.lat || '',
                                          longi: event.longi || '',
                                          place_id: event.place|| ''
                                          
                                        }}
                                        onBlocNotesClick={handleBlocNotesClick}
                                        onConfirmClick={handleConfirmClick}
                                        onInfoClick={handleInfoClick}
                                        selected={selectedMarkerID === `lieu-${event.id}`}
                                        isMap={true}
                                        onLocalisationClick={() => handleCardClick(event, getCardID(event))}
                                        onRechercheClick={handleRechercheClick}
                                        numDroit={numDroit}
                    
                      />
                    ) : event.typeEv === 'T' && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") 
                        || (event.prix !== null && Number(event.prix.replace("","0")) >0 ) || ( event.type !== null && event.type !== "")
                        || ( event.debut !== null && event.debut !== "")  || ( event.fin !== null && event.fin !== "")) ? (
                    <CardTrajet
                    index={`trajet-${event.id}`}
                    key={`trajet-${event.id}`}
                    monnaie={monnaie || 'euro'}
                    author={{
                      id: 1,
                      firstName: "Alric",
                      lastName: "Truelock",
                      displayName: event.libelle1 || '',
                      email: "atruelock0@skype.com",
                      avatar: event.image || '',
                      bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                      count: 40,
                      href: "/author",
                      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                      jobName: "Author Job",
                      libelle2: event.libelle2 || '',
                      prix: event.prix || '',
                      type: event.type || '',
                      debut: event.debut || '',
                      fin: event.fin || '',
                      compagnie: event.compagnie || '',
                      ref: event.ref || '',
                      location: event.location || '',
                      distance: event.distance || '',
                      duree: event.duree || '',
                      moment: event.moment || '',
                      paiement: event.paiement || '',
                      lien: event.lien || '',
                      lien_modifie: event.lien_modifie || '',
                      etat_lien: event.etat_lien || '',
                      document: event.document || '',
                      bloc: event.bloc || '',
                      lat: event.lat || '',
                      longi: event.longi || '',
                      lat2: event.lat2 || '',
                      longi2: event.longi2 || ''
                      
                                            
                    }}
                    //listePecPlanificateur={listePec}                 
                    onBlocNotesClick={handleBlocNotesClick}
                    onConfirmClick={handleConfirmClick}
                    selected={selectedMarkerID === `trajet-${event.id}`}
                    isMap={true}
                    onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                    onRechercheClick={handleRechercheClick}
                    numDroit={numDroit}
                    />
                    ) : event.typeEv === 'N' && event.invisible1 !== "O" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ) ? (
                    <CardNuit
                    index={`nuit-${event.id}`}
                    key={`nuit-${event.id}`}
                    monnaie={monnaie || 'euro'}
                    author={{
                      id: 1,
                      firstName: "Alric",
                      lastName: "Truelock",
                      displayName: event.libelle1 || '',
                      email: "atruelock0@skype.com",
                      avatar: event.image || '',
                      bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                      count: 40,
                      href: "/author",
                      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                      jobName: "Author Job",
                      libelle2: event.libelle2 || '',
                      prix: event.prix || '',
                      paiement: event.paiement || '',
                      date_paiement_hebergement: event.date_paiement_hebergement || '',
                      acompte_hebergement: event.acompte_hebergement || '',
                      lien: event.lien || '',
                      lien_modifie: event.lien_modifie || '',
                      etat_lien: event.etat_lien || '',
                      document: event.document || '',
                      bloc: event.bloc || '',
                      lat: event.lat || '',
                      longi: event.longi || '',
                      lat2: event.lat2 || '',
                      longi2: event.longi2 || '',
                      place_id: event.place|| ''
                                            
                    }}          
                    onBlocNotesClick={handleBlocNotesClick}
                    onConfirmClick={handleConfirmClick}
                    onInfoClick={handleInfoClick}
                    selected={selectedMarkerID === `nuit-${event.id}`}
                    isMap={true}
                    onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                    onRechercheClick={handleRechercheClick}
                    numDroit={numDroit}
                    />
                    ) : null )) : null}

                    </div>
                  

                  ))
                  }
                  </>
            ))}
</div>

            <div className="flex mt-16 justify-center items-center">
            {/* Pagination */}
          </div>
        </div>
      </div>
    </div>
      )}

    { (!isEnoughPoints && (numDroit === 1 || numDroit === 0)) && (
      <div className="sticky text-center text-sm">
        <p>Pour visualiser la carte vous devez d'abord saisir au moins un lieu géolocalisé sur le planning.</p>
      </div>
    )}

    { !isEnoughPoints && numDroit !== 1 && numDroit !== 0 && (
      <div className="sticky text-center text-sm">
        <p>Il n'y a pas de lieu géolocalisé sur le planning pour afficher la carte.</p>
      </div>
    )}
    </>

{ isModalBlocNotesOpen && (
  <Transition appear show={isModalBlocNotesOpen} as={Fragment}>
  <Dialog
    as="div"
    className="fixed inset-0 z-50 overflow-y-auto"
    onClose={closeModalBlocNotes}
  >
    <div className="min-h-screen px-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
      </Transition.Child>

      {/* This element is to trick the browser into centering the modal contents. */}
      <span
        className="inline-block h-screen align-middle"
        aria-hidden="true"
      >
        &#8203;
      </span>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
          <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
            <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
              <h3
                className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                id="headlessui-dialog-title-70"
              >
                  { isModif 
                  ? "Ajout/modification du bloc-notes"
                  : t('blocNotes')
                  }
              </h3>
              <span className="absolute left-3 top-3">
                <ButtonClose onClick={closeModalBlocNotes} />
              </span>
            </div>
            <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                <div className="mt-5 mb-3 w-full text-center">
                     <TextareaAutogrow
                      maxLength={800}
                      className="mt-1.5 text-sm text-center"
                      value={blocNotes}
                      onChange={(e) => {
                        if (isModif) {
                          handleDisplayBlocNotes(e.target.value);
                        }
                      }}
                      onBlur={(e) => {
                        if (isModif) {
                          handleSaveBlocNotes(e.target.value);
                        }
                      }}
                      readOnly={!isModif}
                      placeholder="Notez vos idées ou informations complémentaires pour cette étape..."
                    /> 
                </div>
                <div className="flex items-center py-2.5 sm:py-3 lg:py-3 space-x-5 lg:space-x-8">
                  <span className="text-xxs w-full text-left">
                  Les informations saisies dans les blocs-notes ne sont visibles que par le créateur du voyage et lors d'un partage de voyage si l'option a été sélectionnée.
                  </span>
                </div>

            </div>
          </div>
        </div>
      </Transition.Child>
    </div>
  </Dialog>
</Transition>
)}

{isModalInfoOpen && (
        <Transition appear show={isModalInfoOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalInfo}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Informations
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalInfo} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div
                      
                        className="text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        
                        <span>{placeNom}</span>
                      </div>
                      <div
                      
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >

                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir//${infoLatitude},${infoLongitude}/`}><i className={`text-3xl las la-map-marker`}></i></a>
                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir//${infoLatitude},${infoLongitude}/`} style={{ fontSize: '13px' }}
                        >{infoAdresse}</a>
                      </div>
            
                      { infoSite !== "" && (
                      <div
                       
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        
                        <a target="_blank" rel="noreferrer" href={infoSite}><i className={`text-3xl las la-globe`}></i></a>
                        <a target="_blank" rel="noreferrer" href={infoSite} style={{ fontSize: '13px' }}
                        >{infoSite}</a>
                      </div>
                      )}
                      { infoTelephone !== "" && (
                      <div
                      
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <a target="_blank" rel="noreferrer" href={`tel:${infoTelephone}`}><i className={`text-3xl las la-phone`}></i></a>
                        <a target="_blank" rel="noreferrer" href={`tel:${infoTelephone}`}>
                          {infoTelephone}
                        </a>
                      </div>
                      )}
                      { infoContact !== "" && (
                      <div
                        
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <a target="_blank" rel="noreferrer" href={`mailto:${infoContact}`}><i className={`text-3xl las la-envelope`}></i></a>
                        <a target="_blank" rel="noreferrer" href={`mailto:${infoContact}`} style={{ fontSize: '13px' }}>
                          {infoContact}
                        </a>
                      </div>
                      )}
                      { infoHoraires !== "" && (
                      <div
                        
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <p><i className={`text-3xl las la-clock`}></i></p>
                        <span className="text-sm" dangerouslySetInnerHTML={{ __html: infoHoraires }}></span>
                      
                      </div>
                      )}
                      { infoEquipements !== "" && (
                      <div
                      
                        className="text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
  
                        <span className="text-3xl" dangerouslySetInnerHTML={{ __html: infoEquipements }}></span>

                      </div>
                      )}
                      <div
                       
                        className="text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                       <span className="text-xxs" dangerouslySetInnerHTML={{ __html: infoSource }}></span>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

      
{isModalLienOpen && (
        <Transition appear show={isModalLienOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalLien}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Ajout/modification d'un lien
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalLien} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 w-full text-center">
                        <h2 className={`text-base font-medium`}>
                          <input
                            type="text"
                            value={lien}
                            onChange={(e) => handleDisplayLien(e.target.value)}
                            className="champAutocomplete"
                            placeholder="Saisir le lien"
                            maxLength={2000}
                            //onBlur={handleBlurVille}
                            //onFocus={handleFocusVille}
                          />
                        </h2>
                        <ButtonPrimary className="mt-3 mb-5" onClick={handleSaveLien}>Enregistrer</ButtonPrimary>
                        <div className="text-left mb-5">
                          {lienEnregistre && lien !== null && lien !== "" && (
                          <h3 className="text-sm mb-1"><span className="font-semibold">Etat : </span>{etatLien === "R" ? "lien refusé" : etatLien === "V" ? "lien validé" : etatLien === "M" ? "lien validé après modification par nos services" : "validation en cours"}</h3>
                          )}
                          {lienEnregistre && (etatLien === "R" || etatLien === "M") && motifLien !== null && motifLien !== "" && (
                          <h3 className="text-sm mb-1"><span className="font-semibold">Motif : </span>{motifLien}</h3>
                          )}
                           {lienEnregistre && etatLien === "M" && lienModifie !== null && lienModifie !== "" && (
                          <h3 className="text-sm mb-1"><span className="font-semibold">Lien pour les autres utilisateurs (voyages publics ou partagés) : </span>{lienModifie}</h3>
                           )}
                          <div className="text-xxs mt-2">Pour les voyages publics ou partagés, les liens ne seront visibles par les autres utilisateurs qu'après validation par nos services (sous 48h maximum).</div>
                        </div>
                        
                      </div>
                      <div className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8">
                         <div>
                           <div className="text-xs font-semibold">
                            <i className="las la la-warning"></i> Les identifiants d'affiliation présents dans les liens pourront être supprimés ou remplacés par nos services durant la phase de validation.
                           </div>
                          <div className="text-xxs text-center mt-3">
                            Affichez ou masquez les liens de vos voyages publics dans les paramètres de confidentialité de votre compte.
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

      
{isModalOpen && (
        <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      {t('questionAjout')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        onClick={() => addEvenement("lieu")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-map-marker`}
                        ></i>
                        <span>{t('lieu')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("repas")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl la la-pizza-slice`}
                        ></i>
                        <span>{t('repas')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("trajet")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-plane`}
                        ></i>
                        <span>{t('trajet')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("location")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-car-alt`}
                        ></i>
                        <span>{t('location')}</span>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isModalCoordOpen && (
        <Transition appear show={isModalCoordOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalCoord}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Ajout/modification des coordonnées GPS
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalCoord} />
                    </span>
                  </div>
                  
                  
                  { lieu1 !== "" && lieu1 !== null && (
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        className="text-center items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <span>{lieu1}</span>
                      </div>
                  </div>
                  )}

                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="flex justify-center items-center mb-2">
                        { lieu1 !== "" && lieu1 !== null && (
                          <>
                          
                          <input
                            type="text"
                            value={latitude1}
                            onChange={(e) => handleDisplayLatitude1(e.target.value)}
                            className="text-center"
                            placeholder="Latitude"
                            maxLength={20}
                            
                          />
                       
                        <input
                          type="text"
                          value={longitude1}
                          onChange={(e) => handleDisplayLongitude1(e.target.value)}
                          className="text-center"
                          placeholder="Longitude"
                          maxLength={20}
                          
                        />
                      
                      </>
                        )}
                        
                      </div>
                  </div>
                  
                  { lieu2 !== "" && lieu2 !== null && (
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        className="text-center items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <span>{lieu2}</span>
                      </div>
                  </div>
                  )}

                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="flex justify-center items-center mb-2">

                    { lieu2 !== "" && lieu2 !== null && (
                      <>
                        
                          <input
                            type="text"
                            value={latitude2}
                            onChange={(e) => handleDisplayLatitude2(e.target.value)}
                            className="text-center"
                            placeholder="Latitude"
                            maxLength={20}
                            
                          />
                      
                      
                        <input
                          type="text"
                          value={longitude2}
                          onChange={(e) => handleDisplayLongitude2(e.target.value)}
                          className="text-center"
                          placeholder="Longitude"
                          maxLength={20}
                          
                        />
                    
                      </>
                        )}
                        
                      </div>
                      
                  </div>

                  <div className="mt-2 px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        className="text-center items-center py-2 sm:py-2 lg:py-2 space-x-5 lg:space-x-8"
                      >
                        <ButtonPrimary onClick={handleSaveCoord}>Enregistrer</ButtonPrimary>
                      </div>
                  </div>
                  
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="flex justify-center items-center mb-6">

                      </div>

                      <div className="flex items-center text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8">
                         <div>
                         
                          <div className="text-xxs text-center mt-2">
                          Les coordonnées peuvent également être ajoutées et modifiées depuis la carte.
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isModalConfirmOpen && (
        <Transition appear show={isModalConfirmOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalConfirm}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      { isModif
                      ? "Ajout/modification d'une confirmation de réservation"
                      : "Confirmation de réservation"
                      }
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalConfirm} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      
                        
                      { !confirm && !isFini && (
                      <div className="mt-5 w-full text-center">
                        <form encType="multipart/form-data">
                        <input
                          name='confirm'
                          type="file"
                          className="cursor-pointer"
                          accept="image/jpeg, image/jpg, application/pdf"
                          onChange={handleConfirmChange}
                        />
                        </form>

                        <div className="text-xxs text-center mt-3">
                          .jpeg, .jpg et .pdf acceptés, 8 Mo max
                        </div>
                        <div className="mt-5 flex justify-center"> 
                              <p className="text-xxs pb-3"><i className="las la la-warning couleurRose text-sm texteGras"></i> Les documents seront supprimés 1 an après la date de fin du voyage.<br/>Si vous souhaitez les conserver, penser à générer une edition PDF de l'itinéraire depuis "Mes voyages".</p>
                        </div>
                      </div>
                        )}

                    { !confirm && isFini && (
                      <div className="mt-5 mb-5 w-full text-center">
                       <p>Vous ne pouvez pas ajouter de confirmation de réservation à un voyage dont la date de fin est passée.</p>
                      </div>
                        )}


                    {confirm && (
                          <div className="mt-2 w-full text-center">
                             <div className="mt-5 flex justify-center"> 
                              <p className="text-xxs pb-3"><i className="las la la-warning couleurRose text-sm texteGras"></i> Les documents seront supprimés 1 an après la date de fin du voyage.<br/>Si vous souhaitez les conserver, penser à générer une edition PDF de l'itinéraire depuis "Mes voyages".</p>
                        </div>
                            {confirmLien.endsWith('.pdf') ? (
                              <div>
                                <a onClick={downloadPDF} target="_blank" rel="noopener noreferrer">
                                <i  style={{ fontSize: '2em' }} className="pointeurRose las la-file-pdf"></i>
                              </a>
                              <div>
                              <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                  <i className="las la-arrow-left" style={{ fontSize: '1.5em' }}></i>
                                </button>
                                <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
                                <i className="las la-arrow-right" style={{ fontSize: '1.5em' }}></i>
                                </button>
                               </div>
                              <p style={{ fontSize: '0.8em' }}>
                                 Page {pageNumber} sur {numPages}
                               </p>
                               <div className="pdf-container">
                               
                               <Document file={confirm} onLoadSuccess={onDocumentLoadSuccess} className="pdf-document">
                                 <Page pageNumber={pageNumber} scale={0.5} /> 
                               </Document>
                               
                             </div>
                             
                              </div>
                             
                            ) : (
                              <img
                                src={`${confirm}`}
                                alt="Confirmation de réservation"
                              />
                            )}
                            { isModif && (
                            <ButtonPrimary className="mt-3 mb-5" onClick={handleConfirmDelete}>
                              Supprimer
                            </ButtonPrimary>
                            )}
                          
                          </div>
                        )}
                        
                      
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isModalGetYourGuideOpen && (
       <ModalGetYourGuide 
       onGetYourGuideCloseModal={closeModalGetYourGuide} 
       lieu={lieu1}
       latitude={latitude1}
       longitude={longitude1}
       />
       )}


{isModalBookingOpen && (
       <ModalBooking
       onBookingCloseModal={closeModalBooking} 
       ville={lieu1}
       latitude={latitude1}
       longitude={longitude1}
       hebergement={lieu2}
       latitudeHeb={latitude2}
       longitudeHeb={longitude2}
       />
       )}

{isModalDiscoverCarsOpen && (
       <ModalDiscoverCars
       onDiscoverCarsCloseModal={closeModalDiscoverCars} 
       ville={lieu1}
       latitude={latitude1}
       longitude={longitude1}
       />
       )}

{isModalKiwiOpen && (
       <ModalKiwi
       onKiwiCloseModal={closeModalKiwi} 
       idEvenement={idEvenementForModal}
       nbVoyageurs={nb_voyageurs}
       voyageKey={voyageKey}
       />
       )}

       
{isAdding && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('ajoutEnCours')}
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}

</DndProvider>
  
  );
};

const TextareaAutogrow: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight, 40)}px`; // Ajustez 60 en fonction de vos besoins pour définir une hauteur minimale
    }
  };

  useEffect(() => {
    handleInput();
  }, []); // Utilisation d'un tableau de dépendances vide pour que cela s'exécute une seule fois après le rendu initial


  useEffect(() => {

    // Si libelle2 est vide, définissez la hauteur initiale sur 2 lignes
    if (props.defaultValue === "" && textareaRef.current) {
      textareaRef.current.style.height = '40px'; // Ajustez la hauteur initiale selon vos besoins
    }
  }, [props.defaultValue]);
  
  return <Textarea ref={textareaRef} onInput={handleInput} {...props} />;
};


export default SectionCarte;
