import CardMois from "components/CardMois/CardMois";
import HeadingGuides from "components/HeadingGuides/HeadingGuides";
import { TaxonomyType } from "data/types";
import React, { useEffect, useState } from "react";
import { guidesService } from "_services/guides.service";
import { useTranslation } from 'react-i18next';
import { useLocation} from "react-router-dom";

export interface SectionOuPartirProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const SectionOuPartir: React.FC<SectionOuPartirProps> = ({
  headingCenter = true,
  className = "",
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  const [listePays, setListePays] = useState<any[]>([]);

  const tabMois = [
    { 
      name: t('janvier'),
      paysRef: "TH" 
    },
    { 
      name: t('fevrier'),
      paysRef: "PH" 
    },
    { 
      name: t('mars'),
      paysRef: "LK" 
    },
    { 
      name: t('avril'),
      paysRef: "JP" 
    },
    { 
      name: t('mai'),
      paysRef: "MA" 
    },
    { 
      name: t('juin'),
      paysRef: "CV" 
    },
    { 
      name: t('juillet'),
      paysRef: "GR" 
    },
    { 
      name: t('aout'),
      paysRef: "MT" 
    },
    { 
      name: t('septembre'),
      paysRef: "KE" 
    },
    { 
      name: t('octobre'),
      paysRef: "EG" 
    },
    { 
      name: t('novembre'),
      paysRef: "MX" 
    },
    { 
      name: t('decembre'),
      paysRef: "NZ" 
    },
  ];
  
  useEffect(() => {

    guidesService
      .getGuidesAnnee(langue)
      .then((response) => {

        setListePays(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des mois:", error);
      });
  }, []);

  return (
    <div className={`nc-SectionOuPartir relative ${className}`}>
      <HeadingGuides desc={t('trouverDestination')}isCenter={headingCenter}>
      {t('ouPartir')}
      </HeadingGuides>
      <h1 className="mb-7 text-xl texteGras">{t('souhaitPartir')}</h1>
      <div className={`grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5 sm:gap-6 md:gap-8`}>
        {tabMois.map((item: any, i: any) => (
          <CardMois key={i} taxonomy={item} numMois={i+1}
          nbPays={listePays.length > 0 ? listePays[i].continents.reduce((acc: any, continent: any) => acc + continent.pays.length, 0) : 0} 
          />
        ))}
      </div>
    </div>


  );
};

export default SectionOuPartir;
