import React, { FC, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';


export interface ModalGetYourGuideProps {
  onGetYourGuideCloseModal: () => void;
  lieu: string;
  latitude: string;
  longitude: string;
}

const ModalGetYourGuide: FC<ModalGetYourGuideProps> = ({ 
  onGetYourGuideCloseModal, 
  lieu, 
  latitude, 
  longitude 
}) => {
  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

    // Fonction pour obtenir la valeur d'un cookie
    const getCookie = (name: string) => {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(name))
        ?.split('=')[1];
      return cookieValue || '';
    };

  const handleCloseModalGetYourGuide = () => {
    onGetYourGuideCloseModal();
  };

  const handleInfoClick = () => {
    alert(
      'En cliquant sur une activité et en réservant chez notre partneaire GetYourGuide, tripkygo touchera une commission sur votre réservation sans modifier le prix que vous payerez.\n\nNous espérons que cela permettra, à terme, de maintenir notre site 100% gratuit !'
    );
  };

  useEffect(() => {
    const cookieValue = getCookie("tripkygoPartenaires");

    // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
    if (cookieValue && cookieValue === 'O') {
  
      setCookiePartenaires(true);
    }
    else
    {
      setCookiePartenaires(false);
    } 
  }, []);

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalGetYourGuide}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h2 className="font-medium leading-6 text-gray-900 dark:text-neutral-300">Réservez une activité {lieu && ` près de ${lieu}`} <sup><button type="button" onClick={handleInfoClick}><i className="las la-info"></i></button></sup></h2>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={handleCloseModalGetYourGuide} />
                </span>
              </div>
              {!cookiePartenaires ? (
            <InformationCookiesMarketing/>
          ) : (<>
                <div className="text-center">
                  <p className="text-xs pt-3">
                    <i className="las la-lightbulb"></i>&nbsp;Faites défiler vers le bas ou cliquez pour voir la totalité des prestations disponibles sur <a href='https://www.getyourguide.fr/?partner_id=SX74NSV' rel="noreferrer" target="_blank"><u>GetYourGuide</u></a>
                  </p>
                </div>
              <div className="px-8 mb-5 pt-2 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                <iframe
                  loading="lazy"
                  src={`https://widget.getyourguide.com/default/activities.frame?locale_code=fr-FR&widget=activities&number_of_items=20&partner_id=SX74NSV&q=${latitude},${longitude}&website=http://tripkygo.com`}
                  frameBorder="0"
                  scrolling="yes"
                  title="GetYourGuide Widget"
                  width="100%"
                  height="400px"
                ></iframe>
              </div>
              </>
              )}
            </div>

          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalGetYourGuide;
