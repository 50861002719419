import React, { FC } from "react";

export interface  CheckboxSmallProps {
  label?: string;
  className?: string;
  name: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const  CheckboxSmall: FC< CheckboxSmallProps> = ({
  label = "",
  name,
  className = "",
  checked,
  onChange,
}) => {
    return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className="text-xs flex flex-col flex-1 justify-center"
        >
          <span style={{ whiteSpace: 'nowrap' }} className=" text-neutral-500 dark:text-neutral-400">
            {label}
          </span>
        </label>
      )}
        <input
        id={name}
        name={name}
        type="checkbox"
        className="pointeur focus:ring-action-primary h-4 w-4 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
        checked={checked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
    </div>
  );
};

export default  CheckboxSmall;
