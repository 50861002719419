import React, { FC, useState, Fragment } from "react";
import { voyageService } from '_services/voyage.service'
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

export interface BtnSupprimeIconProps {
  className?: string;
  colorClass?: string;
  keyVoyage?: number;
}

const BtnSupprimeIcon: FC<BtnSupprimeIconProps> = ({
  className = "",
  colorClass = "text-white",
  keyVoyage=0
}) => {
  const [isLoading, setIsLoading] = useState(false); // Indique si la copie est en cours
  const navigate = useNavigate();

  const onClick = () => {
    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm("ATTENTION ! Etes-vous sûr(e) de vouloir définitivement supprimer ce voyage ?");
    if (isConfirmed) {
      setIsLoading(true);
      voyageService.supprimerVoyage(keyVoyage)
        .then((response: any) => {
          window.location.reload();
        })
        .catch((error: any) => {
          setIsLoading(false); // Arrêter le chargement
          if (error.response?.status === 401) {
            alert("Erreur lors de la suppression du voyage, vous devez être connecté(e).");
            navigate('/login'); // Redirection
          } else {
            alert("Erreur lors de la suppression.");
          }
          console.log('suppression KO', error);
        });

    }
  }

  return (
    <>
    <div
      className={`nc-voyagBtnSupprimeVoyageIcon flex items-center justify-center rounded-full cursor-pointer ${colorClass} ${className} `}
      data-nc-id="BtnSupprimeIcon"
      title="Suppression"
      onClick={onClick}
    ><i className={`p-1 text-xl las la-trash`}></i>
    </div>

{isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              Suppression en cours, veuillez patienter...
            </h3>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}
</>
  );
};

export default BtnSupprimeIcon;
