import React, { FC, useState, useEffect } from "react";

export interface BtnLikeIconProps {
  className?: string;
  taille?: string;
  classCoeur?: string;
  colorClass?: string;
  isLiked?: boolean;
  nbLikes: number;
  onLikeClick?: (event: any) => void; 
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  classCoeur= "text-xs",
  taille = "h-5 w-5",
  colorClass = "text-white boutonLike",
  isLiked = false,
  nbLikes=0,
  onLikeClick,
}) => {
  const [likedState, setLikedState] = useState(isLiked);

  useEffect(() => {
    setLikedState(isLiked);
  }, [isLiked]);

  return (
    <div
      className={`nc-BtnLikeVoyageIcon flex items-center justify-center rounded-full cursor-pointer ${
        likedState ? "nc-BtnLikeVoyageIcon--liked" : ""
      }  ${colorClass} ${className} `}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={onLikeClick}
    ><span className={classCoeur}>{nbLikes !== 0 ?  nbLikes : null}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={taille}
        fill={likedState ? "currentColor" : "none"}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
