import React, { FC } from "react";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  instagram?: string;
  facebook?: string;
  youtube?: string;
  twitter?: string;
  site?: string;
}

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  instagram = "",
  facebook = "",
  youtube = "",
  twitter = "",
  site = ""
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {site !== '' && (
        <a
          key="site"
          //className={`${itemClass}`}
          href={site.startsWith('http') ? site : `https://${site}`} 
          target="_blank"
          rel="noopener noreferrer"
          //title={item.name}
        >
          <i className="las la-globe"></i>
        </a>
      )}
      {instagram !== '' && (
        <a
          key="instagram"
          //className={`${itemClass}`}
          href={`https://www.instagram.com/${instagram}`}
          target="_blank"
          rel="noopener noreferrer"
          //title={item.name}
        >
          <i className="lab la-instagram"></i>
        </a>
      )}
      {facebook !== '' && (
        <a
          key="facebook"
          //className={`${itemClass}`}
          href={`https://www.facebook.com/${facebook}`}
          target="_blank"
          rel="noopener noreferrer"
          //title={item.name}
        >
          <i className="lab la-facebook"></i>
        </a>
      )}
      {youtube !== '' && (
        <a
          key="youtube"
          //className={`${itemClass}`}
          href={`https://www.youtube.com/@${youtube}`}
          target="_blank"
          rel="noopener noreferrer"
          //title={item.name}
        >
          <i className="lab la-youtube"></i>
        </a>
      )}
      {twitter !== '' && (
        <a
          key="twitter"
          //className={`${itemClass}`}
          href={`https://www.twitter.com/${twitter}`}
          target="_blank"
          rel="noopener noreferrer"
          //title={item.name}
        >
          <i className="lab la-twitter"></i>
        </a>
      )}
    </nav>
  );
};

export default SocialsList;
