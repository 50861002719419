import React, { FC, useState, useEffect } from "react";
import { TrajetType, PecType, MonnaieType } from "data/types";
import { currencyIcons } from '_config/constantes';
import Badge from "shared/Badge/Badge";
import EvenementBtns2 from "components/EvenementBtns2";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

export interface CardTrajetProps {
  className?: string;
  author: TrajetType;
  index: string;
  listePecPlanificateur?: PecType[];
  onBlocNotesClick: (typeEvenement: string, idEvenement: number) => void;
  onConfirmClick: (typeEvenement: string, idEvenement: number) => void;
  selected?: boolean;
  isMap?: boolean;
  onLocalisationClick?: (type: String) => void;
  onRechercheClick: (typeEvenement: string, idEvenement: number) => void;
  monnaie: string;
  numDroit: number;
}

const CardTrajet: FC<CardTrajetProps> = ({
  className = "",
  author,
  index,
  listePecPlanificateur,
  onBlocNotesClick,
  onConfirmClick,
  selected,
  isMap,
  onLocalisationClick,
  onRechercheClick,
  monnaie,
  numDroit
}) => {
  const { t } = useTranslation();

  const { displayName, libelle2, prix, paiement, type, debut, fin, compagnie, ref, location, moment, duree, distance, lat, longi, lat2, longi2 } = author;
  const [lienVisible, setLienVisible] = useState("");
  const [lienItineraire, setLienItineraire] = useState("");
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const CurrencyIcon = currencyIcons[monnaie as MonnaieType];

  useEffect(() => {
    //gestion lien lien_modifie si createur ou visiteur et etat=R pour créateur géré par l'api
    if(author.lien !== undefined && author.lien !== "" && numDroit ===1)
    {
      setLienVisible(author.lien);
    }
    else if(author.lien !== undefined && author.lien !== "" && author.etat_lien ==="V")
    {
      setLienVisible(author.lien);
    } 
    else if(author.lien !== undefined && author.lien !== "" && author.etat_lien === "M" && author.lien_modifie !== undefined && author.lien_modifie !== "")
    {
      setLienVisible(author.lien_modifie);
    }

    if(author.lat !== "" && author.longi !== "" && author.lat2 !== "" && author.longi2 !== "")
    {
      setLienItineraire("https://www.google.com/maps/dir/"+author.lat+","+author.longi+"/"+author.lat2+","+author.longi2+"/");
    }
    else if(author.lat !== "" && author.longi !== "")
    {
      setLienItineraire("https://www.google.com/maps/dir//"+author.lat+","+author.longi+"/");
    }
    else if(author.lat2 !== "" && author.longi2 !== "")
    {
      setLienItineraire("https://www.google.com/maps/dir//"+author.lat2+","+author.longi2+"/");
    }

  }, []);

  const afficheOptions = () => {
    setShowOptions(true);
  }

  const cacheOptions = () => {
    setShowOptions(false);
  }

  return (
    <div
      
      className={`${selected ? "selectedCard" : ""} nc-CardTrajet relative flex flex-col items-center justify-center text-center px-1 py-2 sm:px-3 sm:py-3  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardTrajet"
      onMouseOver={afficheOptions}
      onMouseLeave={cacheOptions}
    >
        <Badge
        taille="text-xxs py-1"
          className="absolute left-1 top-3"
          color={location === "" ? "green" : "purple"}
          name={location === "" ? t('trajet') : t('location')}
        />
        { (author.bloc !== "" || author.document !== "" || lienVisible || lienItineraire) && (
             <EvenementBtns2 optionsVisibles={!isMap ? showOptions : false}  onRechercheClick={onRechercheClick} onBlocNotesClick={ author.bloc !== "" ? onBlocNotesClick : undefined} onConfirmClick={author.document !== "" ? onConfirmClick : undefined} lienVisible={lienVisible}  lienItineraire={lienItineraire} index={index || '' }/>
        )}
      <div className={`${location === "O" && moment === "P" ? 'mt-4 w-full' : 'mt-3 w-full'}`}>
      { ( location === "O" && moment === "R") && (
        <span className="text-xs">{t('restitution')}</span>
      )}
      { ( location === "O" && moment === "P") && (
        <span className="text-xs">{t('priseEnCharge')}</span>
      )}
        
      </div>
      <div>
        <div className={`${ location !== "O" && displayName !== '' && debut === '' ? 'mt-2' : ''} flex w-full`}>
          <div className={`${ location !== "O" && (debut !== '' && displayName !== '' ) ? 'pt-7' : 'pt-1'} flex-shrink-0 flex flex-col items-center pb-2`}>
          {location !== "O" && (debut !== '' || displayName !== '') && (
            <span className="block w-3 h-3 rounded-full border border-neutral-400"></span>
           )}
           {location !== "O" && (
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
          )}
          {location !== "O" && (fin !== '' || libelle2 !== '') && (
            <span className="block w-3 h-3 rounded-full border border-neutral-400"></span>
          )}
          </div>
          <div className="space-y-2 text-sm pl-1">
            <div className="flex flex-col space-y-2">
            {debut !== '' && (
              <span className="text-xs text-neutral-500 dark:text-neutral-400">
              <i className={`las la-clock`}></i> {debut}
              </span>
            )} 
              {displayName !== '' && (
              <span className="champInput">
                {displayName} { isMap && displayName !== "" && (lat === "" || longi === "") && (
          <>
            <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                                data-tooltip-content={t('coordonneesGPSAbsentes')}
                                data-tooltip-place="top"
                                onClick={(numDroit === 1 || numDroit === 0) ? () => alert("Pour placer ce lieu, veuillez passer en mode modification.") : undefined}
                              ></i>
                              <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
          </>
          )}
        { isMap && displayName !== "" && lat !== "" && longi !== "" && (
          <>  <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLoc"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={() => onLocalisationClick && onLocalisationClick("depart")}
                              ></i>
                              <Tooltip id="myTooltipLoc" style={{ zIndex: 9999 }}></Tooltip>
          </>
          )}
              </span>
              )}
            </div>
            { ( type !== "" || distance !== "" ||  duree !== '' || ref !== "" ||  compagnie !== '') && (
            <div className="grid grid-cols-2 md:grid-cols-2">
          <div className="mr-2">
          <div>
          { ( location !== "O" ||  moment !== "R") && (
            <div className={`${location !== 'O' && displayName === '' && debut === '' ? 'mt-3' : ''}`}>
            {  type &&  type !== "" && ( 
              <>
            <i className={`las la-${type} text-xxl ${ location === 'O' ? 'text-purple-500' : 'text-green-500'} `}></i>
            <h2 className="champAnnexe" data-tooltip-id="myTooltipDistance"
            data-tooltip-content="Estimation à vol d'oiseau ou selon le mode de transport si la durée est affichée"
            data-tooltip-place="bottom">
              {  distance !== '' && <span>{ distance} km</span>}
            </h2>
            <Tooltip id="myTooltipDistance" style={{ zIndex: 9999 }}></Tooltip>  
            <div className="champAnnexe">
              {duree}
            </div>
            </>
            )}
            </div>
            )}
          </div>

          </div>
          <div className="text-left">
          {compagnie !== '' && (location !== "O" ||  moment === "P") && (
          <div className={`mt-1 text-xxs`}>
           {compagnie}
           </div>
          )}
          {ref !== '' && (location !== "O" ||  moment === "P") && (
           <div className={`mt-1 text-xxs`}>
           {ref}
           </div>
          )}
          </div>
        </div>
            )}

          {(libelle2 !== '' || fin !== '') && (
            <div className="flex flex-col space-y-2">
            {fin !== '' && (
              <span className="text-xs text-neutral-500 dark:text-neutral-400">
              <i className={`las la-clock`}></i> {fin}
              </span>
            )}
              {libelle2 !== '' && (
              <span className="champInput">
                {libelle2} { isMap && libelle2 !== "" && (lat2 === "" || longi2 === "") && (
          <>
            <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltipArr"
                                data-tooltip-content={t('coordonneesGPSAbsentes')}
                                data-tooltip-place="top"
                                onClick={(numDroit === 1 || numDroit === 0) ? () => alert("Pour placer ce lieu, veuillez passer en mode modification.") : undefined}
                              ></i>
                              <Tooltip id="myTooltipArr" style={{ zIndex: 9999 }}></Tooltip>
          </>
       )}
        { isMap && libelle2 !== "" && lat2 !== "" && longi2 !== "" && (
            <>
            <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLocArr"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={() => onLocalisationClick && onLocalisationClick("arrivee")}
                              ></i>
                              <Tooltip id="myTooltipLocArr" style={{ zIndex: 9999 }}></Tooltip>
          </>
          )}
              </span>
              )}
            </div>

            )}
          </div>
        </div>
      </div>
      {!isMap && prix !== '' && Number(prix) !== 0 ? (
      <div className="py-1 px-1 mt-2 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <span className="text-xs font-medium pt-[1px]">
          {prix}
        </span>
        {CurrencyIcon}
        <span className="text-xs font-medium pt-[1px]">
          {paiement === 'paye' && "Payé "}
          {paiement === 'paye' && <i className='las la-check'></i>}
        </span>
      </div>
      ): null}

    {listePecPlanificateur && location === "O" && index && moment === "R" && listePecPlanificateur.map((pec, pecIndex) => (
      <div className="text-xxs" key={`pec-${pecIndex}`}>
        {pec.rattachement === index.split('-')[1] && (
          <span>
          {pec.type_transport !== "" && <i className={`text-purple-500 text-sm las la-${pec.type_transport}`}>&nbsp;</i>}
          Début de location : {pec.date_journee.split(" ")[1]}
          {pec.debut !== "" && ` à ${pec.debut}`}</span>
        )}
      </div>
    ))}
    </div>
  );
};

export default CardTrajet;
