import React, { FC, useState } from "react";
import PaysInput from "../PaysInput";
import GuestsInput  from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import SelectMonnaie from "../SelectMonnaie";
import VilleInput from "../VilleInput";


const convertStringToDate = (dateString: string): Date | null => {

  if (!dateString) return null;

  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day); // Month is 0-indexed
};

type Pays = {
  code_pays: string;
  nom: string;
};

export interface VoyageSearchFormProps {
  idVoyage: number,
  titre: string;
  identifiant: string;
  username: string;
  id_pays: string;
  libelles_pays: string;
  ville: string;
  date_deb_formate: string;
  date_fin_formate: string;
  nb_voyageurs: number;
  numDroit: number;
  monnaie: string;
  nbJours?: number;
  verrouillageModif: () => Promise<number>;
}

const VoyageSearchForm: FC<VoyageSearchFormProps> = ({
  idVoyage,
  titre,
  identifiant = '',
  username = "",
  id_pays = "",
  libelles_pays = "",
  ville = "",
  date_deb_formate = "",
  date_fin_formate = "",
  nb_voyageurs = 0,
  numDroit,
  monnaie,
  nbJours,
  verrouillageModif
}) => {
  const [startDate, setStartDate] = useState<Date | null>(convertStringToDate(date_deb_formate));
  const [endDate, setEndDate] = useState<Date | null>(convertStringToDate(date_fin_formate));
  const [startDateInit, setStartDateInit] = useState<Date | null>(convertStringToDate(date_deb_formate));
  const [endDateInit, setEndDateInit] = useState<Date | null>(convertStringToDate(date_fin_formate));
  const [idPays, setIdPays] = useState<string>(id_pays);
  const [libellesPays, setLibellesPays] = useState<string>(libelles_pays);
  
  const [nbVoyageurs, setNbVoyageurs] = useState<number>(nb_voyageurs);


  const handleSelectDestination = (updatedDestinations: Pays[]) => {

    const idP = Array.isArray(updatedDestinations)
  ? updatedDestinations.map((destination) => destination.code_pays).join("_")
  : "";

  setIdPays(idP);

  const libellesP = Array.isArray(updatedDestinations)
  ? updatedDestinations.map((destination) => destination.nom).join("_")
  : "";
  setLibellesPays(libellesP);
  };

  const handleRemoveDestination = (updatedDestinations: Pays[]) => {

    const idP = Array.isArray(updatedDestinations)
  ? updatedDestinations.map((destination) => destination.code_pays).join("_")
  : "";

  setIdPays(idP);

  const libellesP = Array.isArray(updatedDestinations)
  ? updatedDestinations.map((destination) => destination.nom).join("_")
  : "";
  setLibellesPays(libellesP);


};

  const handleSelectDates = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleSelectVoyageurs = (nbVoy: number) => {
    setNbVoyageurs(nbVoy);
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        { ville === "" 
        ? (<PaysInput
          className="flex-[1.5]"
          onSelectDestination={handleSelectDestination}
          onRemoveDestination={handleRemoveDestination}
          isModifiable={ (numDroit === 1 || numDroit === 0) ? true : false }
          libellesPays={libelles_pays}
          idPays={id_pays}  
          nbJours={nbJours}
        />)
        : (<VilleInput ville={ville} numDroit={numDroit}/>)
        }
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

        { date_deb_formate !== "" && (
          <StayDatesRangeInput isModifiable={ (numDroit === 1 || numDroit === 0) ? true : false } className="flex-1" onSelectDates={handleSelectDates} startDate={startDateInit} endDate={endDateInit}/>
        )}

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput className="flex-1" isModifiable={ (numDroit === 1 || numDroit === 0) ? true : false } nbVoy={nb_voyageurs} onSelectVoyageurs={handleSelectVoyageurs}/>
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <SelectMonnaie nbVoyageurs={nbVoyageurs} startDate={startDate} endDate={endDate} idVoyage={idVoyage} titre={titre} id_pays={idPays} libelles_pays={libellesPays} startDateInit={startDateInit} endDateInit={endDateInit} hasButtonSubmit={ (numDroit === 1 || numDroit === 0) ? true : false } isModifiable={ (numDroit === 1 || numDroit === 0) ? true : false } monnaie={monnaie}
        verrouillageModif={verrouillageModif}>
        </SelectMonnaie>        
      </form>
    );
  };

  return renderForm();
};

export default VoyageSearchForm;