import React, { FC, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { useTranslation } from 'react-i18next';

export interface ModalIntegrationProps {
  idVoyage: string;
  onIntegrationCloseModal: () => void;
  affichageBudget: string;
}

const ModalIntegration: FC<ModalIntegrationProps> = ({
  idVoyage,
  onIntegrationCloseModal,
  affichageBudget
}) => {
  const { t } = useTranslation();

  const [vue, setVue] = useState<string>("planning");

  const handleCloseModalIntegration= () => {
    onIntegrationCloseModal();
  };

  const modalRef = useRef(null);

  const copierCode = () => {
    const code = `<iframe src="https://tripkygo.com${t('lienItineraireIntegre')}/${idVoyage}/${vue}" width="100%" height=500 scrolling="auto" frameborder=0 ></iframe>`;
  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code)
        .then(() => {
          alert("Code copié !");
        })
        .catch((err) => {
          console.error("Erreur lors de la copie du code :", err);
        });
    } else {
      console.error("L'API du presse-papiers n'est pas disponible.");
    }
  };

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalIntegration}
        ref={modalRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-2/3">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                    id="headlessui-dialog-title-70"
                  >
                    {t('integrationItineraire')}
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={handleCloseModalIntegration} />
                  </span>
                </div>
                <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300">
                  <div className="mt-5 w-full text-center text-md">
                    {t('quelleVue')}
                  </div>
                  <div className="flex justify-center mt-5 mb-5 space-x-5 lg:space-x-8 text-center">
                            <div className="grid grid-cols-1 gap-5">
                              <div className="flex items-center">
                                <input
                                  checked={vue === "planning"}
                                  id="planning"
                                  name="planning"
                                  type="radio"
                                  onChange={() => setVue("planning")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="planning"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Planning
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  checked={vue === "resume"}
                                  id="resume"
                                  name="resume"
                                  type="radio"
                                  onChange={() => setVue("resume")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="resume"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t('resume')}
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  checked={vue === "carte"}
                                  id="carte"
                                  name="carte"
                                  type="radio"
                                  onChange={() => setVue("carte")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="carte"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t('carte')}
                                </label>
                              </div>
                              { affichageBudget && affichageBudget === 'O' && (
                                <div className="flex items-center">
                                <input
                                  checked={vue === "budget"}
                                  id="budget"
                                  name="budget"
                                  type="radio"
                                  onChange={() => setVue("budget")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="budget"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Budget
                                </label>
                              </div>
                                )}
                          </div>
                  </div>
                  <div className="mt-5 w-full text-center text-xs">
                    <a href={`${t('lienItineraireIntegre')}/${idVoyage}/${vue}`} target="_blank" rel="noreferrer"><u><i>{t('previsualisezItineraire')}</i></u></a>
                  </div>
                 <div className="mt-5 w-full text-center text-md font-semibold" onClick={copierCode}>
                    <u>{t('codeAUtiliser')}</u>
                  </div>
                  <div className="mt-5 w-full text-center text-md couleurRose" onClick={copierCode}>
                              &lt;iframe src="https://tripkygo.com{t('lienItineraireIntegre')}/{idVoyage}/{vue}" width="100%" height=500 scrolling="auto" frameborder=0 &gt;&lt;/iframe&gt;
                  </div>
                  <div className="mt-5 mb-10 w-full text-center text-sm">
                    <i className="las la-info"></i>&nbsp;{t('pourAjuster')} <a href={`${t('lienAide')}#faq10`} target="_blank" rel="noreferrer"><u>{t('commentIntegrer')}</u></a>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalIntegration;
