import React from "react";
import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { compteService } from "_services/compte.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [userTripkygo, setUserTripkygo] = useState(false);

  useEffect(() => {

    // Appeler l'API pour obtenir les informations sur le voyageur
    compteService.getProfil()
      .then((response) => {
        if(response.data[0].identifiant !== 15)
        {
          setUserTripkygo(false);
        }
        else
        {
          setUserTripkygo(true);
        }
      })
      .catch((error: any) => {

        console.error("Error fetching data:", error);
        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
          navigate(t('lienLogin'));
        }
        else
        {
          alert(t('alertRecupProfil'));
          localStorage.removeItem('xsrfToken');
          navigate(t('lienHome'));
        }
      });
  }, []);

  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-2 bg-white dark:bg-neutral-800">
        <div className="container">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <NavLink
              to={t('lienCompte')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t('profil')}
            </NavLink>

            <NavLink
              to={t('lienCompteConfidentialite')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t('confidentialite')}
            </NavLink>

            <NavLink
              to={t('lienCompteMdp')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t('motDePasse')}
            </NavLink>

            <NavLink
              to={t('lienGestionCompte')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t('gestionCompte')}
            </NavLink>

            { userTripkygo && (
              <NavLink
              to="/validation-liens"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Validation Liens
            </NavLink>
            )}
          </div>
        </div>
      </div>
      <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;
