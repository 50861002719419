import React, { useEffect, useState, useRef } from 'react';
import Map, { Marker, Source, Layer, NavigationControl, FullscreenControl } from 'react-map-gl/maplibre';
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import {apiKeyGeoapify} from '_config/constantes';
import Supercluster from 'supercluster';
import markerRose from "../../images/markerRose.png";
import markerJaune from "../../images/markerJaune.png";
import markerVert from "../../images/markerVert.png";
import markerLoc from "../../images/markerViolet.png";
import markerBleu from "../../images/markerBleu.png";
import imageCurseur from "../../images/m4.png";
import { FeatureCollection, Feature, Properties, LineString, MultiLineString } from '@turf/helpers';
import * as turf from '@turf/turf';
import { LngLatLike } from 'mapbox-gl';
import { JourneeType } from "data/types";
import { useTranslation } from 'react-i18next';

interface MapboxGLProps {
  voyageKey: string;
  selectedCardID: string | null;
  compteurClick: number;
  onMarkerClick: (markerID: string) => void;
  eventCategories: JourneeType[],
  isModif: boolean,
  onDeplacementMarker: (lat1: any, longi1: any, lat2: any, longi2: any, typeEvenement: string, idEvenement: number, idJournee: number) => void;
  placementCardID: string | null;
  onEffacePlacement: () => void; 
}

function MapboxGL({ voyageKey, selectedCardID, compteurClick, onMarkerClick, eventCategories, 
                    isModif, onDeplacementMarker, placementCardID, onEffacePlacement }: MapboxGLProps) {

  const { t } = useTranslation();

  const [zoomTimeout, setZoomTimeout] = useState<NodeJS.Timeout | null>(null);
  const [supercluster, setSupercluster] = useState<Supercluster | null>(null);
  const [isMapLoad, setIsMapLoad] = useState<boolean>(false);
  const [isMajMap, setIsMajMap] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(0);
  const mapRef = useRef<any | null>(null);
  const [geojson, setGeojson] = useState<any>({
    type: 'FeatureCollection',
    features: [],
  });
  const [viewport, setViewport] = useState<any>({
    longitude: 0,
    latitude: 0,
    zoom: 99,
    maxZoom: 18
  });
  const [validCoordinates, setValidCoordinates] = useState<number[][]>([]);
  const [visibleClusters, setVisibleClusters] = useState<any[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<any>(null);
  const [petalMarkers, setPetalMarkers] = useState<any[]>([]);
  const placementCardIDRef = useRef<string | null>(null);
  const touchStartRef= useRef<any>(null);
  const touchMoveRef= useRef<any>(null);
  const [oldEventCategories, setOldEventCategories] = useState<any>(null);

  const zoomRef = useRef(zoom);

  useEffect(() => {
    zoomRef.current = zoom;
  }, [zoom]);


  const handleMarkerClick = (marker: any) => {
    onEffacePlacement();
    onMarkerClick(marker.properties?.id);
  };

  useEffect(() => {
    placementCardIDRef.current = placementCardID;
  }, [placementCardID]);

  const determineMarkerImage = (type: string) => {
    switch (type) {
      case 'L':
        return markerRose;
      case 'R':
        return markerJaune;
      case 'T1':
      case 'T2':
        return markerVert;
      case 'L1':
      case 'L2':
        return markerLoc;
      case 'N1':
      case 'N2':
        return markerBleu;
      default:
        return markerRose; // Image par défaut
    }
  };

  const getMap = () => {
    
    let etapes : any[] = [];
  
    let i = 0; // Index pour gérer les virgules.
    let oldJour = 0;
    let oldLibelle = '';
    let oldLat = '';
    let oldLong = '';
    let identique = false;
    let numOrdre = 1;

    let numJourAttente=0;
    let libelleAttente="";
    let latAttente="";
    let longiAttente="";
    let typeAttente="";
    let idAttente=0;

    eventCategories.forEach((journee) => {
      journee.evenements.forEach((evenement) => {
      
      let typeEtape=evenement.typeEv;
      
      if(evenement.typeEv === 'L' || evenement.typeEv === 'N')
      {
            let libelleEtape="";
            let latEtape="";
            let longiEtape="";
            let activitesEtape="";
  
            if (evenement.typeEv === 'L' && evenement.type === 'O') {
              typeEtape = 'R';
            }

            if (evenement.typeEv === 'L' && evenement.lat !== '' && evenement.lat !== null 
            && evenement.libelle1 !== "")
            {
              libelleEtape=evenement.libelle1 || "";
              latEtape=evenement.lat;
              longiEtape=evenement.longi || "";
              activitesEtape=evenement.libelle2 || "";  
            }
            else if (evenement.typeEv === 'N' && evenement.lat !== '' && evenement.lat !== null
                     && (evenement.lat2 === '' || evenement.lat2 === null)
                     && evenement.libelle1 !== "")
            {
              libelleEtape=evenement.libelle1 || "";
              latEtape=evenement.lat;
              longiEtape=evenement.longi || "";
              activitesEtape="";  
              typeEtape = 'N1';
            }
            else if(evenement.typeEv === 'N' && evenement.lat2 !== '' && evenement.lat2 !== null
            && evenement.libelle2 !== "") {
              libelleEtape=evenement.libelle2 || "";
              latEtape=evenement.lat2;
              longiEtape=evenement.longi2 || "";
              activitesEtape="";  
              typeEtape = 'N2';
            }
          
            if(libelleEtape !== '')
            { 
            if (i === 0) {
              if (latEtape !== "") {
                etapes.push({
                  numJour: journee.num_jour,
                  libelle: libelleEtape,
                  lat: latEtape,
                  longi: longiEtape,
                  ordre: numOrdre,
                  type: typeEtape,
                  id: evenement.id,
                  activites: activitesEtape,
                });
                numOrdre++;
                i++;
                oldLibelle = libelleEtape;
                oldLat = latEtape;
                oldLong = longiEtape;
              }
              oldJour = journee.num_jour;
            } else {
              if (latEtape !== '' && latEtape !== null) {
                if (oldJour === journee.num_jour) {
                  if (
                    oldLibelle === libelleEtape &&
                    oldLat === latEtape &&
                    oldLong === longiEtape
                  ) {
                    identique=true;
                    etapes.push({
                        numJour: journee.num_jour,
                        libelle: libelleEtape,
                        lat: latEtape,
                        longi: longiEtape,
                        ordre: numOrdre,
                        type: typeEtape ,
                        id: evenement.id,
                        activites: activitesEtape,
                      });
                    numOrdre++;

                    numJourAttente=0;
                    libelleAttente="";
                    latAttente="";
                    longiAttente="";
                    typeAttente="";
                    idAttente=0;  
                    }
                  }

                  if(!identique)
                  {
                    if(libelleAttente !== "")
                    {
                        etapes.push({
                          numJour: numJourAttente,
                          libelle: libelleAttente,
                          lat: latAttente,
                          longi: longiAttente,
                          ordre: numOrdre,
                          type: typeAttente,
                          id: idAttente,
                          activites: ""
                        });
                        numOrdre++;
                        i++;

                      numJourAttente=0;
                      libelleAttente="";
                      latAttente="";
                      longiAttente="";
                      typeAttente="";
                      idAttente=0;
                    }
  
                    etapes.push({
                    numJour: journee.num_jour,
                    libelle: libelleEtape,
                    lat: latEtape,
                    longi: longiEtape,
                    ordre: numOrdre,
                    type: typeEtape ,
                    id: evenement.id,
                    activites: activitesEtape,
                    });
                    numOrdre++;
                    i++;                  
                  }
            
                  identique=false;
                  oldLibelle = libelleEtape;
                  oldLat = latEtape;
                  oldLong = longiEtape;
                  oldJour = journee.num_jour;
              }
            }
           }
          }
          else
          {
            let libelleEtape1="";
            let latEtape1="";
            let longiEtape1="";
            let typeEtape1="T1";
  
            let libelleEtape2="";
            let latEtape2="";
            let longiEtape2="";
            let typeEtape2="T2";
  
            if (
              evenement.typeEv === 'T' &&
              evenement.location === 'O' &&
              evenement.moment === 'P'
            ) {
              typeEtape1 = 'L1';
              typeEtape2 = '';
            } else if (
              evenement.typeEv === 'T' &&
              evenement.location === 'O' &&
              evenement.moment === 'R'
            ) {
              typeEtape1='L2';
              typeEtape2 = '';
            }
            
            if ((typeEtape1 === 'T1' || typeEtape1 === 'L1' || typeEtape1 === 'L2') && evenement.lat !== '' && evenement.lat !== null
              && evenement.libelle1 !== "")
            {
              libelleEtape1=evenement.libelle1 || "";
              latEtape1=evenement.lat;
              longiEtape1=evenement.longi || "";  
            }
            if (typeEtape2 === 'T2' && evenement.lat2 !== '' && evenement.lat2 !== null
            && evenement.libelle2 !== ""
            && (libelleEtape1 !== evenement.libelle2 || latEtape1 !== evenement.lat2 || longiEtape1 !== evenement.longi2))
            {
              libelleEtape2=evenement.libelle2 || "";
              latEtape2=evenement.lat2;
              longiEtape2=evenement.longi2 || "";  
            }

            if(libelleEtape1 !== '' || libelleEtape2 !== '')
            { 
            if (i === 0) {
              if (latEtape1 !== "" && latEtape1 !== latEtape2) {
                if(latEtape2 !== "")
                {
                  etapes.push({
                    numJour: journee.num_jour,
                    libelle: libelleEtape1,
                    lat: latEtape1,
                    longi: longiEtape1,
                    ordre: numOrdre,
                    type: typeEtape1,
                    id: evenement.id,
                    activites: ""
                  });
                  numOrdre++;
                  i++;
                  oldLibelle = libelleEtape1;
                  oldLat = latEtape1;
                  oldLong = longiEtape1;
                }
                else
                {
                  numJourAttente=journee.num_jour;
                  libelleAttente=libelleEtape1;
                  latAttente=latEtape1;
                  longiAttente=longiEtape1;
                  typeAttente=typeEtape1;
                  idAttente=evenement.id;

                  i++;
                  oldLibelle = libelleEtape1;
                  oldLat = latEtape1;
                  oldLong = longiEtape1;
                }
              }
              if (latEtape2 !== "") {
                
                numJourAttente=journee.num_jour;
                libelleAttente=libelleEtape2;
                latAttente=latEtape2;
                longiAttente=longiEtape2;
                typeAttente=typeEtape2;
                idAttente=evenement.id;

                i++;
                oldLibelle = libelleEtape2;
                oldLat = latEtape2;
                oldLong = longiEtape2;
              }
              oldJour = journee.num_jour;
            } else {
              if (latEtape1 !== "") {
                if (oldJour === journee.num_jour) {
                  if (
                    oldLibelle === libelleEtape1 &&
                    oldLat === latEtape1 &&
                    oldLong === longiEtape1
                  ) {
                    identique = true;
                  }
                }
                if (!identique) {
                  if(libelleAttente !== "")
                  {
                    etapes.push({
                      numJour: numJourAttente,
                      libelle: libelleAttente,
                      lat: latAttente,
                      longi: longiAttente,
                      ordre: numOrdre,
                      type: typeAttente,
                      id: idAttente,
                      activites: ""
                    });
                    numOrdre++;
                    i++;
                  }
                  numJourAttente=journee.num_jour;
                  libelleAttente=libelleEtape1;
                  latAttente=latEtape1;
                  longiAttente=longiEtape1;
                  typeAttente=typeEtape1;
                  idAttente=evenement.id;
                }
                identique = false;
                i++;
                oldLibelle = libelleEtape1;
                oldLat = latEtape1;
                oldLong = longiEtape1;
                oldJour = journee.num_jour;
              }
              if (latEtape2 !== "") {
                if (oldJour === journee.num_jour) {
                  if (
                    oldLibelle === libelleEtape2 &&
                    oldLat === latEtape2 &&
                    oldLong === longiEtape2
                  ) {
                    identique = true;
                  }
                }
                if (!identique) {
                  if(libelleAttente !== "")
                  {
                    etapes.push({
                      numJour: numJourAttente,
                      libelle: libelleAttente,
                      lat: latAttente,
                      longi: longiAttente,
                      ordre: numOrdre,
                      type: typeAttente,
                      id: idAttente,
                      activites: ""
                    });
                    numOrdre++;
                    i++;    
                  }
                  numJourAttente=journee.num_jour;
                  libelleAttente=libelleEtape2;
                  latAttente=latEtape2;
                  longiAttente=longiEtape2;
                  typeAttente=typeEtape2;
                  idAttente=evenement.id;
                }
                identique = false;
                i++;
                oldLibelle = libelleEtape2;
                oldLat = latEtape2;
                oldLong = longiEtape2;
                oldJour = journee.num_jour;
              } 
              oldJour = journee.num_jour;
            }
           }
          }
        });
    });
  
    if(libelleAttente !== "")
                  {
                    etapes.push({
                      numJour: numJourAttente,
                      libelle: libelleAttente,
                      lat: latAttente,
                      longi: longiAttente,
                      ordre: numOrdre,
                      type: typeAttente,
                      id: idAttente,
                      activites: ""
                    });
                    numOrdre++;
                    i++;    
                  }

    return etapes;
  };

  useEffect(() => {

  /*  if(JSON.stringify(eventCategories) !== JSON.stringify(oldEventCategories))
    {*/

      const etapes = getMap();
      let sequenceNumber = 1;
      
      const features: GeoJSON.Feature<GeoJSON.Point, any>[] = etapes.map((emplacement: any) => {
        let idPrefix = '';
        if (emplacement.type === "L" || emplacement.type === "R") {
          idPrefix = "lieu-";
        } else if (emplacement.type === "T1" || emplacement.type === "L1" || emplacement.type === "L2") {
          idPrefix = "depart-trajet-";
        } else if (emplacement.type === "T2" ) {
          idPrefix = "arrivee-trajet-";
        } else if (emplacement.type === "N1") {
          idPrefix = "ville-nuit-";
        } else if (emplacement.type === "N2") {
          idPrefix = "hebergement-nuit-";
        }
      
        const markerImage = determineMarkerImage(emplacement.type);
      
        const feature: GeoJSON.Feature<GeoJSON.Point, any> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(emplacement.longi), parseFloat(emplacement.lat)]
          },
          properties: {
            id: idPrefix + emplacement.id,
            markerImage: markerImage,
            numJour: emplacement.numJour,
            sequenceNumber: sequenceNumber++,
            libelle: emplacement.libelle
          }
        };
      
        return feature;
      });

      setGeojson({
        type: 'FeatureCollection',
        features: features
      });

      const newSupercluster = new Supercluster({
        radius: 1,
        maxZoom: 22
      });
      newSupercluster.load(features);
      setSupercluster(newSupercluster);

      if (features.length > 0) {
        const coordinates = features.map((feature: any) => {
          const [long, lat] = feature.geometry.coordinates;
          return [parseFloat(long), parseFloat(lat)];
        });

        if (coordinates.length === 0) {
          console.error("Invalid coordinates.");
          return;
        }

        setValidCoordinates(coordinates);

        if(viewport.longitude === 0 && viewport.latitude === 0 && viewport.zoom === 99)
        {
          const { longitude, latitude, zoom } = calculateViewport(coordinates);

          setViewport({ longitude, latitude, zoom });
        }
      }
      setIsMajMap(true);

    /*  setOldEventCategories(eventCategories);
    }*/
        
  }, [eventCategories]);

  const zoomToMarker = (coordinates: [number, number]) => {

    var coordLat=coordinates[1];
    var coordLong=coordinates[0];
    if(viewport.latitude === coordLat && viewport.longitude === coordLong)
    {
      coordLong=coordLong+0.00001;
    }

     setViewport({
      ...viewport,
      longitude: coordLong,
      latitude: coordLat,
      zoom: 10
    });

    setZoom(10);
  };

  useEffect(() => {
    onEffacePlacement();
    setPetalMarkers([]);
    if(compteurClick > 0)
    {
      const clickedFeatureId = selectedCardID;

      if(clickedFeatureId)
      {

      const clickedFeature = geojson.features.find((feature: any) => feature.properties.id === clickedFeatureId);
      
      if (clickedFeature) {
        const coordinates = clickedFeature.geometry.coordinates;
        zoomToMarker(coordinates);
        setIsMajMap(true);
      }
      else
      {
        eventCategories.forEach((day) =>
        day.evenements.forEach((evenement) => {

          let newId="";
          let typeId="";

          if(clickedFeatureId.startsWith("arrivee-trajet-"))
          {
            newId=clickedFeatureId.split("arrivee-trajet-")[1];
            typeId="T";
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              zoomToMarker([Number(evenement.longi2), Number(evenement.lat2)]);
              setIsMajMap(true);
            }
          }
          else if(clickedFeatureId.startsWith("depart-trajet-"))
          {
            newId=clickedFeatureId.split("depart-trajet-")[1];
            typeId="T";
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              zoomToMarker([Number(evenement.longi), Number(evenement.lat)]);
              setIsMajMap(true);
            }
          }
          else if(clickedFeatureId.startsWith("ville-hebergement-"))
          {
            newId=clickedFeatureId.split("ville-hebergement-")[1];
            typeId="N";
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              zoomToMarker([Number(evenement.longi2), Number(evenement.lat2)]);
              setIsMajMap(true);
            }
          }
          else if(clickedFeatureId.startsWith("ville-nuit-"))
          {
            newId=clickedFeatureId.split("ville-nuit-")[1];
            typeId="N";
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              zoomToMarker([Number(evenement.longi), Number(evenement.lat)]);
              setIsMajMap(true);
            }
          }

        })
      );
      }
    }
    }
  }, [compteurClick]);

  const calculateViewport = (coordinates: number[][]) => {
   
    let minLongitude = Infinity;
    let maxLongitude = -Infinity;
    let minLatitude = Infinity;
    let maxLatitude = -Infinity;

    coordinates.forEach(([long, lat]) => {
      minLongitude = Math.min(minLongitude, long);
      maxLongitude = Math.max(maxLongitude, long);
      minLatitude = Math.min(minLatitude, lat);
      maxLatitude = Math.max(maxLatitude, lat);
    });

    const longitude = (minLongitude + maxLongitude) / 2;
    const latitude = (minLatitude + maxLatitude) / 2;

    const maxZoom = 18;
    const zoom = Math.min(
      maxZoom,
      Math.floor(Math.log2(360 / (maxLongitude - minLongitude)))
    );

    setZoom(zoom);

    return { longitude, latitude, zoom };
  };

  const handleMapLoad = () => {

    setIsMapLoad(true);

    const map = mapRef.current?.getMap();
    if (!map) return;

    const markerImages = [markerRose, markerJaune, markerVert, markerLoc, markerBleu];

    markerImages.forEach((markerImage, index) => {
      const image = new Image();
      image.src = markerImage;

      image.onload = function () {
        map.addImage(`marker${index}`, image);
      };
    });

        // Écouter l'événement de déplacement de la carte pour déclencher la mise à jour de isMajMap
    map.on('moveend', () => {
        setIsMajMap(true);
    });

    
      map.on('contextmenu', (e: any) => {
          // Récupérer les coordonnées du clic
          const coordinates = e.lngLat;
          placementMarker(coordinates);
      });  

      map.on('touchmove', () => {
        touchMoveRef.current = true;
    });

      map.on('touchstart', (e: any) => {
          // Enregistrer le timestamp lorsque l'appui commence
          touchStartRef.current = new Date().getTime();
      });

      map.on('touchend', (e: any) => {
          // Récupérer la valeur de touchStart à partir de la référence
          const touchStart = touchStartRef.current;

          // Vérifier si touchStart n'est pas null
          if (touchStart) {
              // Calculer la durée de l'appui
              const touchEnd = new Date().getTime();
              const duration = touchEnd - touchStart;

              // Si la durée de l'appui est supérieure à un certain seuil, considérez cela comme un appui prolongé
              if (duration > 1000 && !touchMoveRef.current) { // Exemple de seuil : 1000 ms (1 seconde)
                  // Récupérer les coordonnées du toucher
                  const coordinates = e.lngLat;
                  placementMarker(coordinates);
              }
          }
          touchMoveRef.current = false;
      });

    // Nettoyer les écouteurs d'événements lorsque le composant est démonté
    return () => {
        map.off('moveend');
        map.remove();
    };
  };

  const placementMarker = (coordinates: any) => {

          const currentPlacementCardID = placementCardIDRef.current;

          if (currentPlacementCardID && currentPlacementCardID !== "") {

            handleMarkerMove(currentPlacementCardID, coordinates); 
            onEffacePlacement();
          }
          else
          {
            alert("Pour ajouter des coordonnées par clic droit sur la carte (ou appui long pour les écrans tactiles), veuillez d'abord sélectionner le lieu sans coordonnée GPS à placer en cliquant sur l'icône rose à côté de son libellé.")
          }
    
  };

  useEffect(() => {

    setPetalMarkers([]);

    const updateVisibleClusters = () => {
  
    if (!supercluster || !mapRef.current || !isMapLoad) return;
  
    const map = mapRef.current.getMap();
    if (!map) return;
    
      const bounds = map.getBounds();
      if (!bounds) {
        console.error('Bounds are undefined.');
        return;
      }
      const sw = bounds.getSouthWest(); // Coin sud-ouest
      const ne = bounds.getNorthEast(); // Coin nord-est
      const boundsArray: [number, number, number, number] = [sw.lng, sw.lat, ne.lng, ne.lat]; // Ajouter des valeurs de profondeur arbitraires
      
      const clusters = supercluster.getClusters(boundsArray, zoom);
  
      setVisibleClusters(clusters);

    };

    updateVisibleClusters();
    
    setIsMajMap(false);



  }, [supercluster, zoom, isMapLoad, isMajMap]);

  const majZoom = () => {

    setPetalMarkers([]);

    const updateVisibleClusters = () => {
  
    if (!supercluster || !mapRef.current || !isMapLoad) return;
  
    const map = mapRef.current.getMap();
    if (!map) return;
    
      const bounds = map.getBounds();
      if (!bounds) {
        console.error('Bounds are undefined.');
        return;
      }
      const sw = bounds.getSouthWest(); // Coin sud-ouest
      const ne = bounds.getNorthEast(); // Coin nord-est
      const boundsArray: [number, number, number, number] = [sw.lng, sw.lat, ne.lng, ne.lat]; // Ajouter des valeurs de profondeur arbitraires
      
      const clusters = supercluster.getClusters(boundsArray, zoom);
  
      setVisibleClusters(clusters);

    };

    updateVisibleClusters();
    
    setIsMajMap(false);



  };

// Fonction pour afficher les marqueurs en pétale autour du cluster sélectionné
const renderPetalMarkers = (cluster: any) => {
  if (selectedCluster !== null && selectedCluster === cluster) {
    setPetalMarkers([]);
    setSelectedCluster(null);
  } else {
    setSelectedCluster(cluster);
    if (!supercluster) return null;

    const leaves = supercluster.getLeaves(cluster.id, Infinity);

    let distance = 0;

    const map = mapRef.current.getMap();
    if (!map) return;

    let newZoom = map.getZoom();

    switch (Math.round(newZoom)) {
      case 1:
        distance = 15;
        break;
      case 2:
        distance = 6;
        break;
      case 3:
        distance = 3;
        break;
      case 4:
        distance = 1.8;
        break;
      case 5:
        distance = 0.8;
        break;
      case 6:
        distance = 0.4;
        break;
      case 7:
        distance = 0.3;
        break;
      case 8:
        distance = 0.08;
        break;
      case 9:
        distance = 0.05;
        break;
      case 10:
        distance = 0.03;
        break;
      case 11:
        distance = 0.02;
        break;
      case 12:
        distance = 0.01;
        break;
      case 13:
        distance = 0.005;
        break;
      case 14:
        distance = 0.003;
        break;
      case 15:
        distance = 0.001;
        break;
      case 16:
        distance = 0.0006;
        break;
      case 17:
        distance = 0.0004;
        break;
      case 18:
        distance = 0.0002;
        break;
      case 19:
        distance = 0.00006;
        break;
      case 20:
        distance = 0.00008;
        break;
      case 21:
        distance = 0.00004;
        break;
      case 22:
        distance = 0.00002;
        break;
      case 23:
        distance = 0.00001;
        break;
      default:
        distance = 0.1;
    }

    const numMarkers = leaves.length;
    const angleIncrement = (2 * Math.PI) / numMarkers; // Angle entre chaque marqueur

    const petalMarkersCalc = leaves
      .map((marker: Feature, index: number) => {
        if (marker.properties !== null) {
          // Calcul de l'angle pour le marqueur actuel
          const angle = angleIncrement * index;

          // Calcul des coordonnées polaires pour le marqueur
          const markerPolarX = distance * Math.cos(angle);
          const markerPolarY = distance * Math.sin(angle);

          // Conversion des coordonnées polaires en coordonnées cartésiennes en ajoutant les coordonnées du cluster
          const markerLongitude = cluster.geometry.coordinates[0] + markerPolarX;
          const markerLatitude = cluster.geometry.coordinates[1] + markerPolarY;

          const sequenceNumber = marker.properties.sequenceNumber;
          const markerSize = 30; // Taille des marqueurs en pétale
          const markerImage = marker.properties.markerImage; // Image du marqueur en pétale

          return renderMarker(
            marker.properties.numJour,
            marker.properties.libelle,
            marker.properties.id,
            markerLongitude,
            markerLatitude,
            markerImage,
            sequenceNumber,
            () => handleMarkerClick(marker),
            markerSize,
            0
          );
        }
        return null;
      })
      .filter(Boolean); // Supprime les valeurs nulles

    setPetalMarkers(petalMarkersCalc);
  }
};



const handleMarkerMove = (id: string, newPosition: LngLatLike) => {

  onEffacePlacement();
  const { lat, lng } = newPosition as { lat: number, lng: number };

  eventCategories.forEach((journee) => {
    journee.evenements.forEach((evenement) => {
      let newId="";
          let typeId="";
          let latConserv='';
          let longiConserv='';

          if(id.startsWith("lieu-"))
          {
            newId=id.split("lieu-")[1];
            typeId="L";
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              onDeplacementMarker(lat, lng, '', '', 'lieu', Number(newId), journee.id_journee);
            }
          }
          else if(id.startsWith("hebergement-nuit-"))
          {
            newId=id.split("hebergement-nuit-")[1];
            typeId="N";
            
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              if(evenement.lat !== null && evenement.longi !== null)
              {
                latConserv=evenement.lat;
                longiConserv=evenement.longi;
              }
              onDeplacementMarker(latConserv, longiConserv, lat, lng, 'nuit', Number(newId), journee.id_journee);
            }
          }
          else if(id.startsWith("ville-nuit-"))
          {
            newId=id.split("ville-nuit-")[1];
            typeId="N";
            
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              if(evenement.lat2 !== null && evenement.longi2 !== null)
              {
                latConserv=evenement.lat2;
                longiConserv=evenement.longi2;
              }
              onDeplacementMarker( lat, lng, latConserv, longiConserv, 'nuit', Number(newId), journee.id_journee);
            }
          }
          else if(id.startsWith("arrivee-trajet-"))
          {
            newId=id.split("arrivee-trajet-")[1];
            typeId="T";
            
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              if(evenement.lat !== null && evenement.longi !== null)
              {
                latConserv=evenement.lat;
                longiConserv=evenement.longi;
              }
              onDeplacementMarker(latConserv, longiConserv, lat, lng, 'trajet', Number(newId), journee.id_journee);
            }
          }
          else if(id.startsWith("depart-trajet-"))
          {
            newId=id.split("depart-trajet-")[1];
            typeId="T";
            
            if (evenement.typeEv === typeId && evenement.id.toString() === newId) {
              if(evenement.lat2 !== null && evenement.longi2 !== null)
              {
                latConserv=evenement.lat2;
                longiConserv=evenement.longi2;
              }
              onDeplacementMarker( lat, lng, latConserv, longiConserv, 'trajet', Number(newId), journee.id_journee);
            }
          }
    });
  });

};

const handleZoom = (e: any) => {
  const zoomLevel = e.viewState.zoom;

  if (Number.isInteger(zoomLevel)) {
    setZoom(zoomLevel);
    if (zoomTimeout) {
      clearTimeout(zoomTimeout);
      setZoomTimeout(null);
    }
  } else {
    if (zoomTimeout) {
      clearTimeout(zoomTimeout);
    }
    const newTimeout = setTimeout(() => {
      setZoom(Math.round(zoomLevel));
      setZoomTimeout(null);
    }, 300);
    setZoomTimeout(newTimeout);
  }
};

useEffect(() => {
  return () => {
    // Cleanup timeout on unmount
    if (zoomTimeout) {
      clearTimeout(zoomTimeout);
    }
  };
}, [zoomTimeout]);

const renderMarker = (numJour: any, libelle: any, id: any, longitude: any, latitude: any, markerImage: any, sequenceNumber: any, onClick: any, markerSize: any, position: any) => (
  <Marker
    key={id}
    longitude={longitude}
    latitude={latitude}
    offset={[0, position]}
    onClick={onClick}
    draggable={isModif}
    onDragEnd={(event) => {
      if (isModif) {
        handleMarkerMove(id, event.lngLat);
      }
    }}
    
  >
    <div      data-tooltip-id={`tooltip-${id}`} 
     data-tooltip-content={`${libelle} (${t('jourMin')} ${numJour})`} 
     data-tooltip-place="top"
      style={{
        position: 'relative',
        width: `${markerSize}px`,
        height: `${markerSize}px`,
        cursor: 'pointer'
      }}
    >
      <img
        src={markerImage}
        alt="Marker"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '50%'
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '12px',
          color: '#000',
          fontWeight: 'bold'
        }}
      >
        {sequenceNumber}
      </div>
    </div>
    <Tooltip
     id={`tooltip-${id}`} style={{ zIndex: 99999 }}
  >
  </Tooltip>
  </Marker>
);

  
  
const renderClusters = () => {
  return (
    <>
      {visibleClusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              longitude={longitude}
              latitude={latitude}
              offset={[0, 0]}
              onClick={() => renderPetalMarkers(cluster)}
            >
              <div
      style={{
        position: 'relative',
        width: `40px`,
        height: `40px`,
        cursor: 'pointer'
      }}
    >
      <img
        src={imageCurseur}
        alt="Marker"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '50%'
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '12px',
          color: '#000',
          fontWeight: 'bold'
        }}
      >
        {pointCount}
      </div>
    </div>
            </Marker>
          );
        } else {
          const sequenceNumber = cluster.properties.sequenceNumber;
          const markerSize = 30;

          const markerImage = cluster.properties.markerImage;

          return renderMarker(cluster.properties.numJour, cluster.properties.libelle, cluster.properties.id, longitude, latitude, markerImage, sequenceNumber, () => handleMarkerClick(cluster), markerSize, -markerSize / 2);
       
        }
      })}
      {petalMarkers}
    </>
  );
};


  const calculateGeodesicLine = (coordinates: number[][]): FeatureCollection<LineString | MultiLineString, Properties> => {
    const lineFeatures: Feature<LineString | MultiLineString, Properties>[] = [];
    
    for (let i = 0; i < coordinates.length - 1; i++) {
      const start = turf.point(coordinates[i]);
      const end = turf.point(coordinates[i + 1]);
    
      const greatCircle = turf.greatCircle(start, end);
      lineFeatures.push(greatCircle);
    }
    
    return {
      type: 'FeatureCollection',
      features: lineFeatures
    };
  };
  
  return (
    <>
      {viewport.longitude !== 0 && viewport.latitude !== 0 && viewport.zoom !== 99 ? (
        <Map
          key={`${viewport.longitude},${viewport.latitude},${viewport.zoom},${selectedCardID}`}
          initialViewState={viewport}
          mapStyle={`https://maps.geoapify.com/v1/styles/osm-bright/style.json?apiKey=${apiKeyGeoapify}`}
          onLoad={handleMapLoad}    
          onZoom={handleZoom}
          ref={mapRef}
        >
          {validCoordinates.length > 1 && (
            <Source
              type="geojson"
              data={calculateGeodesicLine(validCoordinates)}
            >
              <Layer
                id="line-layer"
                type="line"
                paint={{
                  'line-color': '#FF0000',
                  'line-width': 2
                }}
              />
            </Source>
          )}
          <Source id="my-data" type="geojson" data={geojson} cluster={true} clusterRadius={10}>
            {/* Vos couches de cluster et de marqueurs */}
          </Source>
          {isMapLoad && visibleClusters.length > 0 && renderClusters()}
          <NavigationControl />
          <FullscreenControl />
        </Map>
      ) : null}
    </>
  );
}

export default MapboxGL;
