import React, { Fragment, useState, FC } from "react";
import { Dialog, Transition, Popover } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import DateInput from 'shared/DateInput/DateInput';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { voyageService } from '_services/voyage.service';
import { useNavigate } from "react-router-dom";

export interface ModalCopieProps {
  onClose: () => void;
  keyVoyage: number;
}

const ModalCopie: FC<ModalCopieProps> = ({ onClose, keyVoyage }) => {
  const [dateCopie, setDateCopie] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleCopie = () => {
    if (dateCopie === null) {
      alert("Vous devez sélectionner une date de début de voyage !");
    } else {
      setIsLoading(true); // Définir l'état de chargement
      voyageService.copierVoyage(keyVoyage, {
        nouvDate: dateCopie,
      })
        .then((response: any) => {
          setIsLoading(false); // Arrêter le chargement
          closeModal();
          if(window.location.pathname.includes("mes-voyages"))
          {
            window.location.reload();
          }
          else
          {
            navigate('/mes-voyages'); // Redirection
          }
        })
        .catch((error: any) => {
          setIsLoading(false); // Arrêter le chargement
          if (error.response?.status === 401) {
            alert("Erreur lors de la copie du voyage, vous devez être connecté(e).");
          } else {
            alert("Erreur lors de la copie.");
          }
        });
    }
  };

  const handleSelectDate = (dateSelected: Date | null) => {

    var dateSelectedString="";
  
    if(dateSelected !== null)
    {
      dateSelectedString=dateSelected.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    }

    setDateCopie(dateSelectedString);
  };

  return (
    <>
    <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto" 
        onClose={closeModal}
      >
        <div className="min-h-screen w-full px-4 text-center"> 
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block transition-all transform overflow-visible rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl" 
            >
              <div className="relative px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h3 className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200">
                  Copie
                </h3>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={closeModal} />
                </span>
              </div>

              <div className="px-8 text-neutral-700 dark:text-neutral-300"> 
                <div className="mt-5 flex justify-center"> 
               
                      <div className="z-[9999] py-2 px-3 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center">
                        <DateInput 
                          libelle={"Date de début du nouveau voyage"} 
                          onSelectDate={handleSelectDate} 
                        />
                      </div>
            
                </div>
                <div className="flex justify-center mt-3 mb-5"> 
                  <ButtonPrimary onClick={handleCopie}>
                    Lancer la copie
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>

{isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              Copie en cours, veuillez patienter...
            </h3>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}
</>
    
  );
};

export default ModalCopie;
