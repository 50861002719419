import CardGuides from "components/CardGuides/CardGuides";
import HeadingGuides from "components/HeadingGuides/HeadingGuides";
import { TaxonomyType } from "data/types";
import React, { useEffect, useState } from "react";
import ItemsSlider from "components/ItemsSlider";
import { guidesService } from "_services/guides.service";
import TabFiltersPays from "./TabFiltersPays";
import { useTranslation } from 'react-i18next';
import { useLocation} from "react-router-dom";

export interface SectionOuPartirMoisProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
  idMois: number;
  nomMois: string;
}

const SectionOuPartirMois: React.FC<SectionOuPartirMoisProps> = ({
  headingCenter = true,
  className = "",
  idMois,
  nomMois
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  const [listePays, setListePays] = useState<any[]>([]);
  const [filtrePlage, setFiltrePlage] = useState<boolean>(false);
  const [filtreNature, setFiltreNature] = useState<boolean>(false);
  const [filtreCulture, setFiltreCulture] = useState<boolean>(false);
  const [filtreSki, setFiltreSki] = useState<boolean>(false);

  useEffect(() => {
    guidesService
      .getGuidesMois(Number(idMois), langue)
      .then((response) => {
        setListePays(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des guides:", error);
      });
  }, []);

  const handleMajFiltres = (plage: boolean, nature: boolean, culture: boolean, ski: boolean) => {
    setFiltrePlage(plage);
    setFiltreNature(nature);
    setFiltreCulture(culture);
    setFiltreSki(ski);
  };

  const filtrerPays = (pays: any) => {
    if (filtrePlage && pays.plage !== 'O') return false;
    if (filtreNature && pays.nature !== 'O') return false;
    if (filtreCulture && pays.culture !== 'O') return false;
    if (filtreSki && pays.ski !== 'O') return false;
    return true;
  };

  return (
    <div className={`nc-SectionOuPartirMois relative ${className}`}>
      <HeadingGuides desc={`${t('selectionOuPartir')} ${nomMois} ${t('enFonction')}`} isCenter={headingCenter}>
       {t('ouPartirEn')} {nomMois} ?
      </HeadingGuides>
      <div className="relative flex justify-center items-center mb-6">
        <TabFiltersPays setFiltres={handleMajFiltres}/>
      </div>

      {listePays.map((continent, index) => (
        <div key={index}>
          {continent.pays.filter(filtrerPays).length > 0 ? (
            <>
           <h1 className="mb-5 text-xl texteGras">{continent.nom}</h1>
            <ItemsSlider title={continent.continentCode} barreHaute={false}>
            {continent.pays.filter(filtrerPays).map((item: any, i: any) => (
              <CardGuides key={continent.code + "-" + i} taxonomy={item} categories={item.plage+item.nature+item.culture+item.ski}/>
            ))}
            </ItemsSlider>
            </>
          ) : (
            null
          )}
        </div>
            ))}
    </div>
  );
};

export default SectionOuPartirMois;
