import React, { FC, ReactNode } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import type { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

interface DetailPagetLayoutProps {
    images: any;
    children: ReactNode;
  }
  
  const DetailPagetLayout: FC<DetailPagetLayoutProps> = ({ children, images }) => { 
   
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    let path=thisPathname;
    if(thisPathname.endsWith("/"))
    {
      path=thisPathname.substr(0,thisPathname.length-1);
    }
    navigate(`${path}/?${params.toString()}`);
  };


  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={images}
      />

      <div className="container ListingDetailPage__content">{children}</div>
    </div>
  );
};

export default DetailPagetLayout;
