import React, { useState, useEffect, Fragment } from "react";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import CarteMonde from "./CarteMonde";
import { Chart } from 'react-google-charts';
import Lottie from 'react-lottie';
import animationData from '../../shared/loading.json';
import { useTranslation } from 'react-i18next';

export interface StatistiquesProps {
  stats?: any;
}


const Statistiques: React.FC<StatistiquesProps> = ({ 
  stats
}) => {
  const { t } = useTranslation();

  const [filteredEntries, setFilteredEntries] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [tableauDiagramme, setTableauDiagramme] = useState<any[]>([]);

  const responsivite={
    resp: 1
  };
  
  useEffect(() => {
    if (stats?.totalBudgetMonnaie) {
      setFilteredEntries(Object.entries(stats?.totalBudgetMonnaie).filter(([currency, amount]) => {
        if (typeof amount === 'number' && amount > 0) {
          return true;
        }
        return false;
      }));


      const nomsPaysArray = stats.listeNomsPays.split('_').filter(Boolean);
      const paysArray = stats.listePays.split('_').filter(Boolean);

      const tabPays: any = {};

      for (let i = 0; i < paysArray.length; i++) {
        tabPays[paysArray[i]] = {
              numberOfThings: 100, 
              fillColor: '#FF4A52',
              nom: nomsPaysArray[i]
          };
      }

      setData(tabPays);

      const newTableauDiagramme = [
        ['Type', 'Total'],
        [t('avion')+' '+stats?.totalDistanceDetail.avion+" km", stats?.totalDistanceDetail.avion],
        [t('bateau')+' '+stats?.totalDistanceDetail.bateau+" km", stats?.totalDistanceDetail.bateau],
        ['Train '+stats?.totalDistanceDetail.train+" km", stats?.totalDistanceDetail.train],
        ['Bus '+stats?.totalDistanceDetail.bus+" km", stats?.totalDistanceDetail.bus],
        ['Taxi '+stats?.totalDistanceDetail.taxi+" km", stats?.totalDistanceDetail.taxi],
        [t('voiture')+' '+stats?.totalDistanceDetail.voiture+" km", stats?.totalDistanceDetail.voiture],
        ['Moto '+stats?.totalDistanceDetail.moto+" km", stats?.totalDistanceDetail.moto],
        [t('velo')+' '+stats?.totalDistanceDetail.velo+" km", stats?.totalDistanceDetail.velo],
        [t('aPied')+' '+stats?.totalDistanceDetail.pied+" km", stats?.totalDistanceDetail.pied],
        [t('nonRenseigne')+' '+stats?.totalDistanceDetail.autres+" km", stats?.totalDistanceDetail.autres]
      ];
      setTableauDiagramme(newTableauDiagramme);


      setIsLoading(true);
    }
  },[stats])

 
  const getCurrencySymbol = (currency: any) => {
    switch (currency) {
      case 'euro':
        return '€';
      case 'dollar':
        return '$';
      case 'pound':
        return '£';
      case 'yen':
        return '¥';
      case 'won':
        return '₩';
      case 'rupee':
      case 'indian-rupee':
        return '₹';
      case 'baht':
        return '฿';
      case 'brazilian-real':
        return 'R$';
      case 'dong':
        return '₫';
      case 'peso':
        return '₱';
      case 'ruble':
        return '₽';
      case 'chf':
        return 'CHF';
      default:
        return '€';
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const pieChart = () => {
    if (tableauDiagramme.length === 0) {
      return null; // Ne rend rien si tableauDiagramme est vide
    }  
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div>
          <Chart
            width={'auto'} 
            height={'auto'}
            chartType="PieChart"
            loader={<div>{t('chargementDiagramme')}</div>}
            data={tableauDiagramme}
            options={{
              title: t('repartition'),
              is3D: false,
              sliceVisibilityThreshold: 0.000000000000001,
              titleTextStyle:{fontSize: "18",
                              lineHeight: "2rem",
                              fontWeight: 600 },
              pieSliceTextStyle: {
                color: '#FFFFFF',fontSize: "11"
              },
              legend:{textStyle:{ color: '#040E27'},fontSize: "13",
                      bold: "true"},
              slices: [{color: '#FF4A52'}, {color: '#1EC6B6'}, {color: '#040E27'}, {color: '#FDAE5C'} , {color: '#D58CF5'}]
            }}
            rootProps={{ 'data-testid': '1' }}
          />
        </div>
      </div>
    );
  }

  const renderCarte = () => {
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{t('paysVisites')}</h2>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <CarteMonde data={data} responsivite={responsivite}/>

    </div>
    );
  };

  const renderGeneral = () => {
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] block lg:hidden">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{t('general')}</h2>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      
          <div className="flex justify-between text-neutral-800 dark:text-neutral-300 grid grid-cols-2 gap-5">
              <div><i className="las la-passport text-lg"></i>&nbsp;{stats?.nbVoyages} {t('voyage')}{stats?.nbVoyages > 1 ? "s" : ""}</div>
              <div><i className="las la-globe text-lg"></i>&nbsp;{stats?.nbPays} {t('paysVisiteMin')}{stats?.nbPays > 1 ? "s" : ""}</div>
              <div><i className="las la-calendar-alt text-lg"></i>&nbsp;{stats?.totalNbJours} {t('jourMin')}{stats?.totalNbJours > 1 ? "s" : ""}</div>
              <div><i className="las la-map-marker text-lg"></i>&nbsp;{stats?.totalNbEtapes} {t('etape')}{stats?.totalNbEtapes > 1 ? "s" : ""}</div>
              <div><i className="las la-heart text-lg"></i>&nbsp;{stats?.nbLikes} {t('jaimeRecu')}s</div>
          </div>

    </div>
    );
  };
  
  
  const renderDepenses = () => {
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{t('depenses')}</h2>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        {filteredEntries?.length > 0 ? (
        <div className="flex justify-between text-neutral-800 dark:text-neutral-300 grid grid-cols-2 gap-5">
          {filteredEntries.map(([currency, amount]: [string, number], index: any) => {
            const currencySymbol = getCurrencySymbol(currency);
            return (
            <>
              <div key={`depenses-${index}`}>
                <><i className="las la-wallet text-lg"></i>&nbsp;{Math.round(amount)} {currencySymbol} {t('depensesMin')}</>
              </div>
               <div key={`depensesJour-${index}`}>
               <><i className="las la-coins text-lg"></i>&nbsp;{stats?.totalBudgetParPersParJourMonnaie[currency]} {currencySymbol}{t('jourPersonne')}</>
             </div>
             </>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-between text-neutral-800 dark:text-neutral-300">
          <div>{t('aucuneDepense')}</div>
        </div>
      )}

    </div>
    );
  };

  const renderDeplacements = () => {
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{t('deplacements')}</h2>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

            <div className="flex justify-between text-neutral-800 dark:text-neutral-300 grid grid-cols-2 gap-5">
            {(stats?.totalDistance && stats?.totalDistance > 0) ? (
               <div>
                <><i className="las la-ruler text-lg"></i>&nbsp;{Math.round(stats.totalDistance)} km {t("parcourus")} 
                {stats?.totalDistanceDetail.avion > 0 ||
                stats?.totalDistanceDetail.bateau > 0 ||
                stats?.totalDistanceDetail.train > 0 ||
                stats?.totalDistanceDetail.taxi > 0 ||
                stats?.totalDistanceDetail.voiture > 0 ||
                stats?.totalDistanceDetail.moto > 0 ||
                stats?.totalDistanceDetail.velo > 0 ||
                stats?.totalDistanceDetail.pied > 0 ||
                stats?.totalDistanceDetail.bus > 0 ? (
                    <span> {t('dont')} </span>
                ) : null}
                </>
              </div>
              ) : <div>
              <>{t('aucunDeplacement')}</>
            </div>
            }
              <div>
              </div>
              {(stats?.totalDistanceDetail.avion && stats?.totalDistanceDetail.avion > 0) ? (
               <div>
               <><i className="las la-plane text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.avion)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.bateau && stats?.totalDistanceDetail.bateau > 0) ? (
               <div>
               <><i className="las la-ship text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.bateau)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.train && stats?.totalDistanceDetail.train > 0) ? (
               <div>
               <><i className="las la-train text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.train)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.bus && stats?.totalDistanceDetail.bus > 0) ? (
               <div>
               <><i className="las la-bus text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.bus)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.taxi && stats?.totalDistanceDetail.taxi > 0) ? (
               <div>
               <><i className="las la-taxi text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.taxi)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.voiture && stats?.totalDistanceDetail.voiture > 0) ? (
               <div>
               <><i className="las la-car text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.voiture)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.moto && stats?.totalDistanceDetail.moto > 0) ? (
               <div>
               <><i className="las la-motorcycle text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.moto)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.velo && stats?.totalDistanceDetail.velo > 0) ? (
               <div>
               <><i className="las la-bicycle text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.velo)} km</>
               </div>
              ) : null}
               {(stats?.totalDistanceDetail.pied && stats?.totalDistanceDetail.pied > 0) ? (
               <div>
               <><i className="las la-walking text-lg"></i>&nbsp;{Math.round(stats?.totalDistanceDetail.pied)} km</>
               </div>
              ) : null}
        </div>
        {(stats?.totalDistance && stats?.totalDistance > 0) ? (
        <div className="flex justify-between text-neutral-800 dark:text-neutral-300">
          {pieChart()}
        </div>
        ) : null}

    </div>
    );
  };
  const renderSidebar = () => {
    return (
      <div>
      <div className="listingSectionSidebar__wrap shadow-xl  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] mt-10">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-2xl font-semibold">
         {t('general')}
          </span>
        </div>

        {/* FORM */}

        {/* SUM */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      
          <div className="flex justify-between text-neutral-800 dark:text-neutral-300 grid grid-cols-2 gap-5">
              <div><i className="las la-passport text-lg"></i>&nbsp;{stats?.nbVoyages} {t('voyage')}{stats?.nbVoyages > 1 ? "s" : ""}</div>
              <div><i className="las la-globe text-lg"></i>&nbsp;{stats?.nbPays} {t('paysVisiteMin')}{stats?.nbPays > 1 ? "s" : ""}</div>
              <div><i className="las la-calendar-alt text-lg"></i>&nbsp;{stats?.totalNbJours} {t('jourMin')}{stats?.totalNbJours > 1 ? "s" : ""}</div>
              <div><i className="las la-map-marker text-lg"></i>&nbsp;{stats?.totalNbEtapes} {t('etape')}{stats?.totalNbEtapes > 1 ? "s" : ""}</div>
              <div><i className="las la-heart text-lg"></i>&nbsp;{stats?.nbLikes} {t('jaimeRecu')}{stats?.nbLikes > 1 ? "s" : ""}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    
    <DndProvider backend={HTML5Backend}>
  

        <div  className="nc-ListingStayDetailPage">

      {/* MAIN     <BackgroundSection /> */}
      <div className=" relative mt-11 flex flex-col lg:flex-row ">
  
        { isLoading ? (
          <>
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">      
              {renderGeneral()}
              {renderCarte()}
              {renderDepenses()}
              {renderDeplacements()}
            </div>
            <div className="hidden lg:block flex-grow mt-14 lg:mt-0 sideBarGuide">
            <div className="sticky top-28">{renderSidebar()}</div>
          </div>
        </>
        ) : (
          <div className="w-full space-y-2 lg:space-y-5 lg:pr-10 text-center">
            <Lottie options={defaultOptions} height={200} width={200} />
            <p className="text-center texteGras">{t('chargementStatistiques')}</p>
          </div>
        )}
      </div>
    </div>
   {/* <MobileFooterSticky />*/}
          
</DndProvider>
)};

export default Statistiques;