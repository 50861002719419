import React, { Fragment, useState, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { compteService } from "_services/compte.service";
import { useTranslation } from 'react-i18next';

export interface ModalReinitMdpProps {
  onClose: () => void;
}

const ModalReinitMdp: FC<ModalReinitMdpProps> = ({ onClose }) => {

  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleEnvoyer = () => {
   if (email === "" || !validateEmail(email)) {
      alert(t('alertEmail'));
    } else {
      compteService
      .reinitMdp(email)
      .then((response: any) => {
        if(response.data.message === 'OK')
        {
            if(email.toLowerCase().indexOf('orange.fr') === -1)
            {
              alert(t('alertMailRecup')+' '+email.toLowerCase()+t('alertMailRecupSuite'));
            }
            else
            {
              alert(t('alertMailRecup')+' '+email.toLowerCase()+t('alertMailRecupFin')); 
            }
            closeModal();
        }
        else if (response.data.message === 'INCONNU')
        {
            alert(t('alertPasCompte'));
        }
        else
        {
            alert(t('alertTechnique'));
        }
      })
      .catch((error: any) => {
       
        alert(t('alertEnvoiMail'))
        console.error('Error envoi message :', error);
      });
    }
  };

  const validateEmail = (mail: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(mail);
  }


  return (
    <>
   <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 scale-95"
            >
             <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                    {t('reinitMdp')}
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>

              <div className="px-8 text-neutral-700 dark:text-neutral-300"> 
                    <div className="mt-5 flex justify-center"> 
                        <h2 className={`text-base font-medium w-full`}>
                          <input
                            type="email"
                            onChange={handleEmailChange}
                            className="champAutocomplete"
                            placeholder={t('saisirEmail')}
                            maxLength={50}
                          />
                        </h2>
                    </div>
                  
                <div className="flex justify-center mt-5 mb-5 "> 
                  <ButtonPrimary onClick={handleEnvoyer}>
                    {t('envoyer')}
                  </ButtonPrimary>
                </div>
              </div>
            </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>


</>
    
  );
};

export default ModalReinitMdp;
