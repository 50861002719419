import React, { FC, useEffect } from "react";
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  onClick?: () => void;
  href?: string;
  isPlanif?: boolean;
}
const ButtonSubmit: FC<Props> = ({
  className = "",
  onClick = () => {},
  href = "/listing-stay",
  isPlanif
}) => {
  const { t } = useTranslation();

  return (
    <button
      type="submit"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={`flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none ${className} relative z-20`}
    >
      <span>{isPlanif ? t('modifier') : t('creer')}</span>
    </button>
  );
};

export default ButtonSubmit;
