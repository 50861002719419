import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Squares2X2Icon } from "@heroicons/react/24/outline";

interface PhotosProps {
  images: any;
  numDroit: number;
}

const Photos: FC<PhotosProps> = ({ images, numDroit }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;


  const handleOpenModalImageGallery = () => {
    let path=thisPathname;
    if(thisPathname.endsWith("/"))
    {
      path=thisPathname.substr(0,thisPathname.length-1);
    }
    navigate(`${path}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };


  return (
    <div className="nc-ListingStayDetailPage">
      <div className="relative mt-11 flex flex-col lg:flex-row">
        <div className="w-full space-y-8 lg:space-y-10 lg:pr-10">
        {!images && (
          <div className="text-center">       
              Récupération des photos en cours, veuillez patienter...
          </div>
        )}
        {images && images.length === 0 && (
          <div className="text-center">
            {(numDroit === 0 || numDroit === 1) ? (
              "Aucune photo n'a été ajoutée sur le planning"
            ) : (
              "Aucune photo"
            )}
          </div>
        )}
          { images && images.length > 1 && (
          <div className="rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={handleOpenModalImageGallery}
              >
                <img
                  alt="photos 1"
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={images[0].url  || ""} // Utilise la première image
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {images.slice(1, 4).map((item: any, index: number) => (
                <div key={index} className="relative rounded-md sm:rounded-xl overflow-hidden">
                  <div className="aspect-w-4 aspect-h-3">
                    <img
                      alt="photos"
                      className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={item.url || ""}
                      sizes="400px"
                    />
                  </div>
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}
              <div
                className="absolute flex items-center justify-center left-3 top-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="h-5 w-5" />
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Afficher tout
                </span>
              </div>
            </div>
          </div>
            )}

          { images && images.length === 1 && (
            <div className="rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-3 row-span-3 relative aspect-[4/3] rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={handleOpenModalImageGallery}
              >
                <img
                  alt="photos 1"
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={images[0].url  || ""} // Utilise la première image
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              
              <div
                className="absolute hidden flex items-center justify-center left-3 top-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="h-5 w-5" />
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Afficher tout
                </span>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Photos;
