import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';

export interface SectionCouvertureGuideProps {
  className?: string;
  codePays:string;
  nomPays:string;
}

const SectionCouvertureGuide: FC<SectionCouvertureGuideProps> = ({ className = "", codePays ="", nomPays ="" }) => {

  const { t } = useTranslation();

  const handleScrollToInformations = () => {
    const element = document.getElementById("informations");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={`nc-SectionCouvertureGuide relative ${className}`}
      data-nc-id="SectionCouvertureGuide"
    >
      <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
          Guide { nomPays ? nomPays : ""}
        </h2>
        <span className="sm:text-lg md:text-xl font-semibold text-neutral-50">
          {t('infoDestination')}
        </span>
        <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
          onClick={handleScrollToInformations}
        >
          {t('consulter')}
        </ButtonPrimary>
      </div>
      {codePays && (
      <div className="relative aspect-w-1 aspect-h-0-7 sm:aspect-w-4 sm:aspect-h-2 lg:aspect-w-16 lg:aspect-h-8 xl:aspect-h-7 ">
        <img
          className="absolute inset-0 object-cover rounded-xl"
          src={require(`../../images/guides/${codePays}.jpg`)}
          alt="hero"
        />
      </div>
      )}
    </div>
  );
};

export default SectionCouvertureGuide;
