import React, { FC, useState, useCallback, useEffect } from "react";
import ImageSlider from "components/ImageSlider/ImageSlider";
import { VoyageDataType } from "data/types";
import NbDays from "components/NbDays/NbDays";
import { Link } from "react-router-dom";
import BtnLikeVoyageIcon from "components/BtnLikeVoyageIcon/BtnLikeVoyageIcon";
import BtnConfidentialiteVoyageIcon from "components/BtnConfidentialiteVoyageIcon/BtnConfidentialiteVoyageIcon";
import BtnPartageRSVoyageIcon from "components/BtnPartageRSVoyageIcon/BtnPartageRSVoyageIcon";
import ModalPartageRS from 'components/ModalPartageRS';
import BtnPartageVoyageIcon from "components/BtnPartageVoyageIcon/BtnPartageVoyageIcon";
import BtnCreateursVoyageIcon from "components/BtnCreateursVoyageIcon/BtnCreateursVoyageIcon";
import ModalPartage from 'components/ModalPartage';
import ModalCreateurs from 'components/ModalCreateurs';
import ModalIntegration from 'components/ModalIntegration';
import BtnPDFVoyageIcon from "components/BtnPDFVoyageIcon/BtnPDFVoyageIcon";
import ModalEditionPDF from 'components/ModalEditionPDF';
import BtnCopieVoyageIcon from "components/BtnCopieVoyageIcon/BtnCopieVoyageIcon";
import ModalCopie from 'components/ModalCopie';
import BtnSupprimeVoyageIcon from "components/BtnSupprimeVoyageIcon/BtnSupprimeVoyageIcon";
import Badge from "shared/Badge/Badge";
import { voyageService } from '_services/voyage.service'
import { accountService } from '_services/account.service';
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';

export interface VoyageCardProps {
  className?: string;
  voyageKey: number;
  voyageData?: VoyageDataType;
  size?: "default" | "small";
  isAds?: false;
  isCreateur?: boolean;
  isCoCreateur?: boolean;
  isPartage?: boolean;
  partageConfirmations?: string;
  partagePrix?: string;
  partageBlocNotes?: string;
  onMajLike?: () => void;
  onMajDislike?: () => void;
  onMajLikeHome?: (id: number) => void;
  onMajDislikeHome?: (id: number) => void;
}

const VoyageCard: FC<VoyageCardProps> = ({ voyageKey, voyageData, size = "default", isAds = false, className, 
isCreateur = false, isCoCreateur = false, isPartage = false, partageConfirmations, partagePrix, partageBlocNotes, onMajLike, onMajDislike, onMajLikeHome, onMajDislikeHome }) => {
  const [nbLikes, setNbLikes] = useState<number>(voyageData?.nb_likes || 0);
  const [isModalPartageRSOpen, setIsModalPartageRSOpen] = useState(false);
  const [isModalCopieOpen, setIsModalCopieOpen] = useState(false);
  const [isModalPartageOpen, setIsModalPartageOpen] = useState(false);
  const [isModalCreateursOpen, setIsModalCreateursOpen] = useState(false);
  const [isModalEditionPDFOpen, setIsModalEditionPDFOpen] = useState(false);
  const [photo, setPhoto] = useState("");
  const [isModalIntegrationOpen, setIsModalIntegrationOpen] = useState(false);

  const { t } = useTranslation();

  const openModalEditionPDF = () => {
    setIsModalEditionPDFOpen(true);
  };

  const closeModalEditionPDF = () => {
    setIsModalEditionPDFOpen(false);
  };

  const openModalPartageRS = () => {
    setIsModalPartageRSOpen(true);
  };

  const closeModalPartageRS = () => {
    setIsModalPartageRSOpen(false);
  };

  const openModalCopie = () => {
    if(accountService.isLogged())
    {
      setIsModalCopieOpen(true);
    }
    else
    {
      alert(t('alertCopie'));
    }
  };

  const closeModalCopie = () => {
    setIsModalCopieOpen(false);
  };

  const openModalPartage = () => {
    if(accountService.isLogged())
    {
      setIsModalPartageOpen(true);
    }
    else
    {
      alert(t('alertPartage'));
    }
  };

  const closeModalPartage = () => {
    setIsModalPartageOpen(false);
  };

  const openModalCreateurs = () => {
    if(accountService.isLogged())
    {
      setIsModalCreateursOpen(true);
    }
    else
    {
      alert(t('alertCreateurs'));
    }
  };

  const closeModalCreateurs = () => {
    setIsModalCreateursOpen(false);
  };


  const closeModalIntegration = () => {
    setIsModalIntegrationOpen(false);
  };

  const openModalIntegration = () => {
    setIsModalIntegrationOpen(true);
  };

  const handleIntegrationClick = async () => {
      openModalIntegration(); 
  }

    // Fonction pour obtenir la valeur d'un cookie
    const getCookie = (name: string) => {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(name))
        ?.split('=')[1];
      return cookieValue || '';
    };

  const [isLiked, setIsLiked] = useState(() => {

    if(voyageData?.liked === 'O')
    {
      return true;
    }
    else
    {
      // Vérifie si le cookie existe
      const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
      const cookieValue = getCookie(cookieName);

      // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
      if (cookieValue === 'O') {
        return true;
      }
    }
    return false;
  });

  useEffect(() => {
    if(voyageData?.nb_likes)
    {
      setNbLikes(voyageData?.nb_likes);
    }

    if(voyageData?.liked && voyageData?.liked === 'O')
    {
      setIsLiked(true);
    }
     else
    {
       // Vérifie si le cookie existe
       const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
       const cookieValue = getCookie(cookieName);
 
       // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
       if (cookieValue === 'O') {
        setIsLiked(true);
       }
       else
       {
        setIsLiked(false);
       }
    }
  }, [voyageData?.nb_likes]);


    // Fonction de gestion du clic sur le bouton like
const handleLikeClick = useCallback(async () => {
  var etatLike=isLiked;

  if(!onMajLikeHome)
  {
    setNbLikes((prevNbLikes) => (etatLike ? prevNbLikes - 1 : prevNbLikes + 1));

    setIsLiked((prevIsLiked) => !prevIsLiked);
  }

  if(!etatLike)
  {
    // Ajout du cookie
    const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
    document.cookie = `${cookieName}=O; path=/`;

    const xsrfToken = localStorage.getItem('xsrfToken');

    if(onMajLike)
    {
      onMajLike();
    }
    if(onMajLikeHome)
    {
      onMajLikeHome(voyageKey);
    }

      try{
        const response = await voyageService.likeVoyage({ id_voyage: voyageData?.id_voyage }, {
          headers: {
            'X-XSRF-Token': xsrfToken,
          },
        })
        
      } catch (error: any) {
        console.error("Error like voyage :", error);
      } finally {
      
      }

  }
  else
  {
    // Suppression du cookie
  const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;

  const xsrfToken = localStorage.getItem('xsrfToken');

    if(onMajDislike)
    {
      onMajDislike();
    }
    if(onMajDislikeHome)
    {
      onMajDislikeHome(voyageKey);
    }

    try{
      const response = await voyageService.dislikeVoyage(voyageData?.id_voyage, {
        headers: {
          'X-XSRF-Token': xsrfToken,
        },
      })
      
    } catch (error: any) {
      console.error("Error dislike voyage:", error);
    } finally {
    
    }

  }

  
}, [isLiked, voyageData?.id_voyage]);

  // Gestionnaire de clic sur le username
  const handleUsernameClick = (index: number) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  
   // Ouvrir le lien dans un nouvel onglet
  if (voyageData && voyageData.identifiant) {
    const identifiants = voyageData.identifiant.split(';');
    const selectedIdentifiant = identifiants[index]?.trim();
    if (selectedIdentifiant) {
      window.open(`${t('lienVoyageur')}/${selectedIdentifiant}`, '_blank');
    }
  }
  };

useEffect(() => {
   
  let imageURL: string | undefined;

   if(voyageData?.couverture && voyageData?.couverture !== "")
   {
     Axios.get(voyageData?.couverture || "", { responseType: 'blob' })
     .then((response) => {

       const blob = new Blob([response.data], { type: 'image/jpeg' });
       imageURL = URL.createObjectURL(blob);
       setPhoto(imageURL);

     })
     .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
   }
   else
   {
    let imageCouv = require(`../../images/countries/${voyageData?.id_pays.split("_")[0]}.webp`);
    setPhoto(imageCouv);
   }

   return () => {
     if (imageURL) {
       URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
     }
   };
   
 }, [voyageData?.couverture]);

 const handleDeleteCoCreateur = (identifiant: any) => {

  if(voyageData)
  {
    const identifiants = voyageData.identifiant.split(';');
    const usernames = voyageData.username.split(';');
    
    // Find the index of the identifiant to delete
    const index = identifiants.indexOf(identifiant.toString());

    // Check if the identifiant exists
    if (index !== -1) {
      // Remove the identifiant and corresponding username
      identifiants.splice(index, 1);
      usernames.splice(index, 1);
      
      // Join the arrays back to strings
      voyageData.identifiant = identifiants.join(';');
      voyageData.username = usernames.join(';');
    }
  }

 }

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <ImageSlider
          uniqueID={`StayCard_${voyageKey}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={[photo]}
          href={`${t('lienPlanificateur')}/${voyageKey}`}
        />
        {voyageData?.visibilite !== null && voyageData?.visibilite !== '' && voyageData?.visibilite === 'public' && (
          <>
            <BtnLikeVoyageIcon nbLikes={nbLikes} isLiked={isLiked} className="absolute left-3 top-3 z-[1]" onLikeClick={handleLikeClick} />
            <BtnPartageRSVoyageIcon className={`boutonVoyage absolute right-${isCreateur ? 42 : isCoCreateur ? 26 : isPartage ? 18 : 10} top-3 z-[1]`} onClick={openModalPartageRS}/>
          </>
        )}
        {(isCreateur || isCoCreateur) && (voyageData?.visibilite === null || voyageData?.visibilite === '' || voyageData?.visibilite === 'prive') && (
          <>
            <BtnConfidentialiteVoyageIcon username={voyageData?.username} className="absolute left-3 top-3 z-[1]" keyVoyage={voyageKey} />
         </>
        )}
        {(isCreateur || isCoCreateur || isPartage) && (
          <BtnPDFVoyageIcon className={`boutonVoyage absolute right-${isCreateur ? 34 : isCoCreateur ? 18 : 10} top-3 z-[1]`} onClick={openModalEditionPDF}/>
        )}
        <BtnCopieVoyageIcon className={`boutonVoyage absolute right-${isCreateur ? 26 : isCoCreateur ? 10 : 3} top-3 z-[1]`} onClick={openModalCopie}/>
        { (isCreateur || isCoCreateur) && (
           <BtnPartageVoyageIcon className={`boutonVoyage absolute right-${isCreateur ? 18 : 3} top-3 z-[1]`} onClick={openModalPartage}/>
        )}
        { isCreateur && (
          <>
           <BtnCreateursVoyageIcon className="boutonVoyage absolute right-10 top-3 z-[1]" onClick={openModalCreateurs}/>
           <BtnSupprimeVoyageIcon keyVoyage={voyageKey} className="boutonVoyage absolute right-3 top-3 z-[1]"/>
           </>
        )}
       </div>
    );
  };

  const renderContent = () => {

    const handleLinkClick = (event: any) => {
      event.stopPropagation();
    };

    const paysList = voyageData?.libelles_pays?.split('_') || [];
    const idPaysList = voyageData?.id_pays?.split('_') || [];

    return (
      <div className={size === "default" ? "pt-2 pb-2 pl-4 pr-4 text-center" : "p-3 space-y-1"}>
        <div className="">
          <span className="font-medium titreVoyageCard">{voyageData?.titre_voyage}</span>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
          </div>
          <div className="flex items-center paysVoyageCard space-x-1 flex-wrap  space-y-1">
          {paysList.map((pays, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-rose-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ marginRight: '4px' }} // Ajouter un espace à droite de l'icône
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
              <a
                href={`${t('lienGuide')}/${idPaysList[index]}`}
                className="mr-1 pointeurRose"
                target="_blank"
                rel="noreferrer"
                onClick={handleLinkClick}
              >
                <u>{pays}</u>
              </a>
              {index < paysList.length - 1 && (
                <span className="mr-1"> - </span>
              )}
            </React.Fragment>
          ))}
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            { voyageData?.date_deb_formate !== '' ? (
              <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
              {voyageData?.date_deb_formate}
              <span className="text-rose-500">
                <i className={`las la-arrow-right`}></i>
              </span>
              {voyageData?.date_fin_formate}
            </span>
            ) : (
            <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
              Dates masquées
            </span>
            )}  
          </span>
          <NbDays nbJours={voyageData?.nb_jours || 0} />
        </div>
        <div className="flex justify-between items-center pt-1">
          <span className="text-xxs text-neutral-500 dark:text-neutral-400 font-normal">

             {voyageData?.username ? (
             <span className="text-left ">
             <i className="text-xs las la-user text-neutral-500 dark:text-neutral-400"></i>
             {voyageData.username.split(';').map((name, index, arr) => (
               <span key={index}>
                 <span className="text-xs font-medium username" onClick={handleUsernameClick(index)}> {name.trim()}</span>
                 {index < arr.length - 2 ? ', ' : index === arr.length - 2 ? ` ${t('et')} ` : ''}
               </span>
             ))}
            </span>
              ) : null} 
          </span>
   
        </div>
        <div className="flex justify-end items-center">
          <span className="text-xxs text-neutral-500 dark:text-neutral-400 font-normal">
            {t('cree')} {t('le')} {voyageData?.date_creation}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="VoyageCard"
    >
      {renderSliderGallery()}
      <Link to={`${t('lienPlanificateur')}/${voyageKey}`}>{renderContent()}</Link>
      { isModalPartageRSOpen && (
        <ModalPartageRS  onClose={closeModalPartageRS} keyVoyage={voyageKey}
        onIntegrationClick={voyageData?.visibilite !== null && voyageData?.visibilite !== '' && voyageData?.visibilite === 'public' ? handleIntegrationClick : () => {}}
        />
      )}
      {isModalIntegrationOpen && (
       <ModalIntegration 
       onIntegrationCloseModal={closeModalIntegration} 
       idVoyage={voyageKey.toString()}
       affichageBudget={voyageData?.affichage_budget || "N"}
        />
    )}
      { isModalCopieOpen && (
        <ModalCopie  onClose={closeModalCopie} keyVoyage={voyageKey}/>
      )}
      { isModalPartageOpen && (
        <ModalPartage  onClose={closeModalPartage} keyVoyage={voyageKey}/>
      )}
      { isModalCreateursOpen && (
        <ModalCreateurs onClose={closeModalCreateurs} keyVoyage={voyageKey} deleteCoCreateur={handleDeleteCoCreateur}/>
      )}
      { isModalEditionPDFOpen && (
        <ModalEditionPDF isCreateur={isCreateur} isCoCreateur={isCoCreateur} onClose={closeModalEditionPDF} keyVoyage={voyageKey}
        partageConfirmations={partageConfirmations}
        partagePrix={partagePrix}
        partageBlocNotes={partageBlocNotes}
        />
      )}
    </div>
  );
};

export default VoyageCard;
