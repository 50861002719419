import React, { FC, useState } from "react";
import VilleInput, { Destination } from "../VilleInput";
import GuestsInput, { GuestsInputProps }   from "../GuestsInput";
import StayDatesRangeInput, { StayDatesRangeInputProps } from "./StayDatesRangeInput";

export interface VoyageSedentaireSearchFormProps {
  onChangeLoading: () => void;
}

const VoyageSedentaireSearchForm: FC<VoyageSedentaireSearchFormProps> = ({onChangeLoading}) => {
 
  const [selectedDestinations, setSelectedDestinations] = useState<Destination[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);


  const handleSelectDestination = (codePays: string, pays: string, ville: string, place_id: string, latitude: string, longitude: string) => {
    const selectedDestination: Destination = {
      code_pays: codePays,
      pays: pays,
      ville: ville,
      place_id: place_id,
      latitude: latitude,
      longitude: longitude,
    };
  
    setSelectedDestinations([selectedDestination]);
    
  };

  const handleSelectDates = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

  };

  const handleChangeLoading = () => {
    onChangeLoading();
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <VilleInput className="flex-[1.5]"  onSelectDestination={handleSelectDestination}/>
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput className="flex-1" onSelectDates={handleSelectDates}/>
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput className="flex-1" selectedDestinations={selectedDestinations}  startDate={startDate} endDate={endDate} onChangeLoading={handleChangeLoading}/>
      </form>
    );
  };

  return renderForm();
};

export default VoyageSedentaireSearchForm;
