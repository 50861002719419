import React, { FC, useState, useRef } from "react";
import BtnOptionsIcon from "components/BtnOptionsIcon/BtnOptionsIcon";
import { useTranslation } from 'react-i18next';

export interface EvenementBtnsModifProps {
  index: string;
  onSuppressionClick?: (typeEvenement: string, idEvenement: number) => void;
  onLienClick?: (typeEvenement: string, idEvenement: number) => void;
  onBlocNotesClick?: (typeEvenement: string, idEvenement: number) => void;
  onCoordClick?: (typeEvenement: string, idEvenement: number) => void;
  onPhotoClick?: () => void;
  onConfirmClick?: (typeEvenement: string, idEvenement: number) => void;
  onCopierClick?: (idEvenement: number) => void;
  onPOIClick?: (idEvenement: number) => void;
  onRechercheClick?: (typeEvenement: string, idEvenement: number) => void;
  libelleSaisi: boolean;
  isMap?: boolean;
}

const EvenementBtnsModif: FC<EvenementBtnsModifProps> = ({
  index,
  onSuppressionClick,
  onLienClick,
  onBlocNotesClick,
  onCoordClick,
  onPhotoClick,
  onConfirmClick,
  onCopierClick,
  onPOIClick,
  onRechercheClick,
  libelleSaisi,
  isMap
}) => {
  const { t } = useTranslation();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const typeEvenement=index.split('-')[0];
  const idEvenement=Number(index.split('-')[1]);

  const showPopup = () => {
    setPopupVisible(true);
  };

  const handleSuppressionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (onSuppressionClick && index) {
      onSuppressionClick(typeEvenement, idEvenement);
    }

  };

  const handleLienClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (onLienClick && index) {
      onLienClick(typeEvenement, idEvenement);
    }

  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (!popupRef.current?.contains(e.relatedTarget as Node)) {
      setPopupVisible(false);
    }
  };

  const handleConfirmClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onConfirmClick && index) {
      onConfirmClick(typeEvenement, idEvenement);
    }
  };

  const handleBlocClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onBlocNotesClick && index) {
      onBlocNotesClick(typeEvenement, idEvenement);
    }
  };

  const handleCoordClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onCoordClick && index) {
      onCoordClick(typeEvenement, idEvenement);
    }
  };

  const handlePOIClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onPOIClick) {
      onPOIClick(idEvenement);
    }
  };
  
  const handlePhotoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onPhotoClick) {
      onPhotoClick();
    }
  };

  const handleCopierClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onCopierClick) {
      onCopierClick(idEvenement);
    }
  };

  const handleRechercheClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onRechercheClick) {
      onRechercheClick(typeEvenement, idEvenement);
    }
  };


  return (
    <div className="flow-root">

      <BtnOptionsIcon onMouseOver={showPopup}
        onMouseLeave={handleMouseLeave}/>

      {isPopupVisible && (
        <div
          ref={popupRef}
          className="absolute popup top-0 right-2 p-4 bg-white dark:bg-gray-800 rounded-md shadow-md mt-2 w-50"
          onMouseOver={showPopup}
          onMouseLeave={handleMouseLeave}
          style={{ zIndex: 20 }}
        >
           { onRechercheClick && (
           <div className="text-sm text-left pointeurRose" onClick={handleRechercheClick}>
            <i className={`las la-search couleurRose`}></i> {t('reservation')}
          </div>
           )}
          <div className="text-sm text-left pointeurRose" onClick={handleBlocClick}>
            <i className={`las la-pencil-alt`}></i> {t('blocNotes')}
          </div>
          {libelleSaisi && (
          <div className="text-sm text-left pointeurRose" onClick={handleConfirmClick}>
            <i className={`las la-file-alt`}></i> {t('confirmation')}
          </div>
          )}
          { libelleSaisi && (
          <div className="text-sm text-left pointeurRose" onClick={handleCoordClick}>
            <i className={`las la-location-arrow `}></i> {t('coordonnees')}
          </div>
          )}
           { !isMap && onCopierClick && libelleSaisi && (
           <div className="text-sm text-left pointeurRose" onClick={handleCopierClick}>
            <i className={`las la-copy`}></i> {t('copie')}
          </div>
           )}
          { libelleSaisi && (
          <div className="text-sm text-left pointeurRose" onClick={handleLienClick}>
            <i className={`las la-link`}></i> {t('lien')}
          </div>
          )}
           { onPhotoClick && libelleSaisi && (
           <div className="text-sm text-left pointeurRose" onClick={handlePhotoClick}>
            <i className={`las la-camera`}></i> Photo
          </div>
           )}
          { onPOIClick && (
           <div className="text-sm text-left pointeurRose" onClick={handlePOIClick}>
            <i className={`las la-map-marker`}></i> {t('pointsInteret')}
          </div>
           )}
          { onSuppressionClick && (
            <div className="text-sm text-left pointeurRose" onClick={handleSuppressionClick}>
              <i className={`las la-trash`}></i> {t('suppression')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EvenementBtnsModif;
