import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  
  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-2 bg-white dark:bg-neutral-800">
        <div className="container">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">

            <NavLink
              to={t('lienReservationHebergement')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t('hebergement')}
            </NavLink>
       
            <NavLink
              to={t('lienReservationTransport')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Transport
            </NavLink>
            
            <NavLink
              to={t('lienLocationVoiture')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t('voiture')}
            </NavLink>

            <NavLink
              to={t('lienReservationActivite')}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t('activite')}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container pt-8 sm:pt-14 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;
