import React, { FC, useState, useRef } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useTranslation } from 'react-i18next';

export interface BtnOutilsProps {
  onConvertisseurClick?: () => void;
  onStatistiquesClick: () => void;
  onContenuBagageClick: () => void;
  onImportClick: () => void;
  onExportClick: () => void;
  onIntegrationClick?: () => void;
  onPartageClick?: () => void;
  visibilite?: string;
}

const BtnOutils: FC<BtnOutilsProps> = ({
  onConvertisseurClick,
  onStatistiquesClick,
  onContenuBagageClick,
  onImportClick,
  onExportClick,
  onIntegrationClick,
  onPartageClick,
  visibilite=""
}) => {  
  const { t } = useTranslation();
  
  const [isPopupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  
  const showPopup = () => {
    setPopupVisible(true);
  };

  
  const handleConvertisseurClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onConvertisseurClick) {
      onConvertisseurClick();
    }
  };

  const handleImportClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onImportClick) {
      onImportClick();
    }
  };

  const handleExportClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onExportClick) {
      onExportClick();
    }
  };

  const handleStatistiquesClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onStatistiquesClick) {
      onStatistiquesClick();
    }
  };

  const handleIntegrationClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onIntegrationClick) {
      onIntegrationClick();
    }
  };

  const handlePartageClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onPartageClick) {
      onPartageClick();
    }
  };

  const handleContenuBagageClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onContenuBagageClick) {
      onContenuBagageClick();
    }
  };
  
  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (!popupRef.current?.contains(e.relatedTarget as Node)) {
      setPopupVisible(false);
    }
  };

  return (
    <div className="flow-root"  onMouseOver={showPopup}
    onMouseLeave={handleMouseLeave}>

        <ButtonSecondary
         sizeClass="px-2 py-2 sm:px-2"
         fontSize = "text-sm sm:text-sm font-medium">  
              <i className="las la-tools text-md"></i>
                <span className="ml-1">{t('outils')}</span>
        </ButtonSecondary>

      {isPopupVisible && (
        <div
          ref={popupRef}
          className="absolute popupOutils popup top-12 right-2 p-4 bg-white dark:bg-gray-800 rounded-md shadow-md mt-2 w-50 "
          onMouseOver={showPopup}
          onMouseLeave={handleMouseLeave}
          style={{ zIndex: 20 }}
        >
          <div className="text-sm text-left pointeurRose flex items-center justify-start" onClick={handleStatistiquesClick}
           >
            <i className={`las la-chart-bar text-lg`}></i>&nbsp;{t('afficherStatistiques')}
          </div>
          <div className="text-sm text-left pointeurRose flex items-center justify-start" onClick={handleConvertisseurClick}
           >
            <i className={`las la-calculator text-lg`}></i>&nbsp;{t('convertisseurMonnaies')}
          </div>
          <div className="text-sm text-left pointeurRose flex items-center justify-start" onClick={handleExportClick}
           >
            <i className={`las la-file-export text-lg`}></i>&nbsp;{t('exporterItineraire')}
          </div>
          <div className="text-sm text-left pointeurRose flex items-center justify-start" onClick={handleImportClick}
           >
            <i className={`las la-file-import text-lg`}></i>&nbsp;{t('importerItineraire')}
          </div>
          { handleIntegrationClick && visibilite === 'public' && (
            <div className="text-sm text-left pointeurRose flex items-center justify-start" onClick={handleIntegrationClick}
            >
            <i className={`las la-code text-lg`}></i>&nbsp;{t('integrerItineraire')}
          </div>
          )}
          { handlePartageClick && visibilite === 'public' && (
            <div className="text-sm text-left pointeurRose flex items-center justify-start" onClick={handlePartageClick}
            >
            <i className={`las la-share-alt text-lg`}></i>&nbsp;{t('partagerItineraire')}
          </div>
          )}
           <div className="text-sm text-left pointeurRose flex items-center justify-start" onClick={handleContenuBagageClick}
           >
            <i className={`las la-suitcase text-lg`}></i>&nbsp;{t('faireMaValise')}
          </div>
        </div>
      )}
    </div>
  );
};

export default BtnOutils;
