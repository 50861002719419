import Axios from './caller.service';

let creerCompte = (user) => {
  return Axios.post('/compte/', user);
}

let getUser = (id) => {
    return Axios.get('/compte/utilisateur/' + id);
  }
  
  let getProfil = () => {
    return Axios.get('/compte/profil');
  }
  
  let updateProfil = (profil) => {
    return Axios.put('/compte/profil', profil);
  }

  let uploadAvatar = (formData, config) => {
    return Axios.post('/upload/avatar', formData, config);
  }
  
  let changeMdp = (credentials) => {
    return Axios.put('/compte/change', credentials);
}

let supprimeCompte = () => {
    return Axios.delete('/compte/suppression');
}

let getVoyages = () => {
  return Axios.get('/compte/voyages/');
}

let reinitMdp= (mail) => {
  return Axios.put('/compte/reinitialisation/'+mail);
}

let verifReinitMdp = (mail, code) => {
  return Axios.get('/compte/reinitialisation/verif/'+mail+'/'+code);
}

let saveReinitMdp= (mail, code, mdp1) => {
  return Axios.put('/compte/reinitialisation/save/'+mail+'/'+code+'/'+mdp1);
}

let inscriptionNewsletter= (mail) => {
  return Axios.put('/compte/newsletter/'+mail);
}

let getStatistiques = () => {
  return Axios.get('/compte/statistiques/');
}

export const compteService = {
    creerCompte, getUser, getProfil, updateProfil, uploadAvatar, 
    changeMdp, supprimeCompte, getVoyages, reinitMdp,
    saveReinitMdp, verifReinitMdp, inscriptionNewsletter,
    getStatistiques
}