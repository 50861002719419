import { Tab } from "@headlessui/react";
import VoyageCard from "components/VoyageCard/VoyageCard";
import VoyageurRating from "components/VoyageurRating/VoyageurRating";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import React, { FC, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import SocialsList from "./SocialsList";
import { Helmet } from "react-helmet";
import { compteService } from '_services/compte.service'
import { VoyageDataType } from "data/types";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';

export interface VoyageurPageProps {
  className?: string;
}

const VoyageurPage: FC<VoyageurPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const [voyageurData, setVoyageurData] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const [imageAvatar, setImageAvatar] = useState("");
  const [nbLikesVoyageur, setNbLikesVoyageur] = useState(0);
  

  const navigate = useNavigate();

  const calculateTotalLikes = (resultData: any) => {
    if (resultData && resultData.voyages) {
      return resultData.voyages.reduce((totalLikes :number, voyage: VoyageDataType) => {
        return totalLikes + (voyage.nb_likes || 0);
      }, 0);
    }
    return 0;
  };

  useEffect(() => {
    // Appeler l'API pour obtenir les informations sur le voyageur
    compteService.getUser(id)
      .then((response) => {
        // Mettre à jour l'état avec les données reçues de l'API
        //setVoyageurData(response.data);
        if(response.data.groupedResults.length === 0)
        {
         navigate('/');
        }
        else
        {
         setVoyageurData(response.data.groupedResults[0]);
 
          setNbLikesVoyageur(calculateTotalLikes(response.data.groupedResults[0]));


        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des données du voyageur:", error);
        navigate('/');
      });
  }, []);

  
  useEffect(() => {
   
    let imageURL: string | undefined;
  
     if(voyageurData?.avatar && voyageurData?.avatar !== "")
     {

       Axios.get(voyageurData?.avatar, { responseType: 'blob' })
       .then((response) => {
         const blob = new Blob([response.data], { type: 'image/jpeg' });
         imageURL = URL.createObjectURL(blob);
         setImageAvatar(imageURL);
       })
       .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
     }
  
     return () => {
       if (imageURL) {
         URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
       }
     };
     
   }, [voyageurData?.avatar]);

   const handleMajLike = () => {
    setNbLikesVoyageur((prevNbLikes) => prevNbLikes + 1);
   }

   const handleMajDislike = () => {
    setNbLikesVoyageur((prevNbLikes) => prevNbLikes - 1);
  }


  const renderSidebar = () => {
    return (
      <div className=" [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8 pt-2">
       <Avatar
          //hasChecked
          //hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-32 h-32"
          imgUrl={imageAvatar || ''}
      />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-xl font-semibold">{ voyageurData?.username }</h2>
          <span 
          data-tooltip-id="myTooltip"
          data-tooltip-content={t('totalLikes')}
          data-tooltip-place="bottom"
          >
            <VoyageurRating className="!text-base" totalLikes={nbLikesVoyageur} />
          </span>
        </div>

        <Tooltip id="myTooltip" />

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">
        { voyageurData?.presentation || ''}
        </p>

        {/* ---- */}
        <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          instagram={voyageurData?.instagramUser || ''}
          facebook={voyageurData?.facebookUser || ''}
          youtube={voyageurData?.youtubeUser || ''}
          twitter={voyageurData?.twitterUser || ''}
          site={voyageurData?.siteUser || ''}
        />

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
 
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {t('inscritEn')} {voyageurData?.dateUser || ''}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        <div>
          <h2 className="text-2xl font-semibold">{t('sesVoyagesPublics')}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {voyageurData && voyageurData.voyages && voyageurData.voyages.length > 0
          ? t('infosMasquees')
          : t('aucunVoyagePublic')}
          </span>
          
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                {voyageurData && voyageurData.voyages && voyageurData.voyages.length > 0 ? (
                voyageurData.voyages.map((voyage : VoyageDataType) => (
                  // Utilisez le composant approprié (par exemple, StayCard) pour afficher chaque voyage
                  <VoyageCard key={voyage.id_voyage} voyageData={voyage} voyageKey={voyage.id_voyage}
                  onMajLike={handleMajLike} onMajDislike={handleMajDislike}/>
                ))
              ) : null }
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };



  return (
    <div className={`nc-VoyageurPage ${className}`} data-nc-id="VoyageurPage">
      <Helmet>
        <title>Voyageur</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default VoyageurPage;
