import { avatarColors } from "contains/contants";
import React, { FC, useState } from "react";
import ModalPhoto from 'components/ModalPhoto';

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
  onImageClick?: (boolImage :boolean) => void;
}

const Avatar: FC<AvatarProps> = ({
  containerClassName = "ring-1 ring-white dark:ring-neutral-900",
  sizeClass = "h-6 w-6 text-sm",
  radius = "rounded-full",
  imgUrl = "",
  userName,
  hasChecked,
  hasCheckedClass = "w-4 h-4 -top-0.5 -right-0.5",
  onImageClick
}) => {
  const url = imgUrl || "";
  const name = userName || "John Doe";
  const _setBgColor = (name: string) => {
    const backgroundIndex = Math.floor(
      name.charCodeAt(0) % avatarColors.length
    );
    return avatarColors[backgroundIndex];
  };

  // Déclarez un état pour suivre l'état d'affichage de la modale
  const [modalImageUrl, setModalImageUrl] = useState<string | null>(null);

  // Fonction pour ouvrir la modale avec une image spécifique
  const openModal = (imageUrl: string) => {
    setModalImageUrl(imageUrl);
    if(onImageClick)
    {
      onImageClick(true);
    }
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setModalImageUrl(null);
    if(onImageClick)
    {
      onImageClick(false);
    }
  };

    // Gestionnaire de clic sur l'avatar
    const handleAvatarClick = (e: React.MouseEvent<HTMLDivElement>) => {
      // Empêcher la propagation de l'événement au parent (le composant Link)
      e.preventDefault();
      e.stopPropagation();
  
      // Ouvrir la modale avec l'URL de l'image
      openModal(url);
    };

  return (
    <div
      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
      style={{ backgroundColor: url ? undefined : _setBgColor(name) }}
    >
      {url && (
        <img
          className={`pointeur absolute inset-0 w-full h-full object-cover ${radius}`}
          src={url}
          alt={name}
          onClick={handleAvatarClick}
        />
      )}
      <span className="wil-avatar__name">{name[0]}</span>

      {hasChecked && (
        <span
          className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute  ${hasCheckedClass}`}
        >
          <i className="las la-check"></i>
        </span>
      )}

      {/* Condition pour afficher la modale lorsque modalImageUrl est défini */}
      {modalImageUrl && (
        <ModalPhoto imageUrl={modalImageUrl} onClose={closeModal} />
      )}
    </div>
  );
};

export default Avatar;
