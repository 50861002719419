import React, { FC, useContext,useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';


export interface LocationVoitureProps {
  className?: string;
}

const LocationVoiture: FC<LocationVoitureProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

  
  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(false);
  }, []);

  const handleInfoClick = () => {
    alert(
     t('infoDiscoverCars')
    );
  };

    // Fonction pour obtenir la valeur d'un cookie
    const getCookie = (name: string) => {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(name))
        ?.split('=')[1];
      return cookieValue || '';
    };
  

  useEffect(() => {

    const cookieValue = getCookie("tripkygoPartenaires");

    if (cookieValue && cookieValue === 'O') {

    const existingScript = document.getElementById('dchwidget');
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = 'dchwidget';
    script.src = 'https://www.discovercars.com/wg.js';
    script.async = true;

    script.dataset.devEnv = 'com';
    script.dataset.location = `france/paris`;
    script.dataset.lang = 'fr';
    script.dataset.utmSource = 'tripkygo';
    script.dataset.utmMedium = 'widget';
    script.dataset.affCode = 'tripkygo';
    script.dataset.autocomplete = 'on';
    script.dataset.styleSubmitBgColor = '#FF4A52';
    script.dataset.styleSubmitFontColor = '#FFFFFF';
    script.dataset.styleFormBgColor = '#f2f2f2';
    script.dataset.styleFormFontColor = '#000000';
    script.dataset.styleSubmitText = 'Rechercher';

    const widgetContainer = document.getElementById('discoverCarsWidgetContainer');
    if (widgetContainer) {
      widgetContainer.appendChild(script);
    }

    return () => {
      if (widgetContainer) {
        widgetContainer.removeChild(script);
      }
    };
  }
  else
  {
    setCookiePartenaires(false);
  }
  }, []);


  return (
    <div className={`nc-LocationVoiture ${className}`} data-nc-id="LocationVoiture">
      <Helmet>
        <title>{t('reservation')}</title>
      </Helmet>
      <CommonLayout>
        <div className="text-center">
           <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6">
           {t('louerVoitureAvec')} <a href='https://www.discovercars.com/?a_aid=tripkygo' rel="noreferrer" target="_blank"><u>DiscoverCars</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h1>
            
                <div id="discoverCarsWidgetContainer"> </div> {/* Conteneur du widget */}

            { !cookiePartenaires && (
                <InformationCookiesMarketing/>
            )}

        
        </div>
      </CommonLayout>
    </div>
  );
};

export default LocationVoiture;
