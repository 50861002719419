import React, { FC, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';

declare var Booking: any;

export interface ModalBookingProps {
  onBookingCloseModal: () => void;
  ville: string;
  latitude: string;
  longitude: string;
  hebergement: string;
  latitudeHeb: string;
  longitudeHeb: string;
}

const ModalBooking: FC<ModalBookingProps> = ({
  onBookingCloseModal,
  ville,
  latitude,
  longitude,
  hebergement,
  latitudeHeb,
  longitudeHeb,
}) => {

  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

    // Fonction pour obtenir la valeur d'un cookie
    const getCookie = (name: string) => {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(name))
        ?.split('=')[1];
      return cookieValue || '';
    };

    
  const handleCloseModalBooking = () => {
    onBookingCloseModal();
  };

  const handleInfoClick = () => {
    alert(
      "En cliquant sur un hébergement et en réservant chez notre partenaire Booking, tripkygo touchera une commission sur votre réservation sans modifier le prix que vous paierez.\n\nNous espérons que cela permettra, à terme, de maintenir notre site 100% gratuit !"
    );
  };

  const getBookingLocation = () => {
    if (ville && latitude && longitude) {
      return ville;
    }

    if (hebergement && latitudeHeb && longitudeHeb) {
      return hebergement;
    }

    return "Paris, Île-de-France, France";
  };

  useEffect(() => {
    const cookieValue = getCookie("tripkygoPartenaires");

    // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
    if (cookieValue && cookieValue === 'O') {
  
      setCookiePartenaires(true);
    const script = document.createElement('script');
    script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
    script.onload = () => {
      if (typeof Booking !== 'undefined' && Booking.AffiliateWidget) {
        new Booking.AffiliateWidget({
          iframeSettings: {
            selector: 'bookingAffiliateWidget_755a63ab-c7b3-4279-a5c9-584b78bd4b18',
            responsive: true,
          },
          widgetSettings: {
            ss: getBookingLocation(),
            latitude: latitude || latitudeHeb || 48.8566,
            longitude: longitude || latitudeHeb || 2.3522,
            checkin: "2024-08-29",
            checkout: "2024-08-30",
            zoom: 9,
          },
        });
        
      } else {
        console.error("Le script du widget affilié Booking n'a pas été chargé ou est indéfini.");
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }
  else
  {
    setCookiePartenaires(false);
  }
  }, [ville, latitude, longitude, hebergement, latitudeHeb, longitudeHeb]);

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalBooking}
      >
        <div className="h-full px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-[90vh]" // Définir la hauteur à 90% du viewport
            >
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h2 className="font-medium leading-6 text-gray-900 dark:text-neutral-300">
                  Réservez un hébergement avec <a href='https://www.booking.com/index.html?aid=2040377' rel="noreferrer" target="_blank"><u>Booking</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h2>

                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={handleCloseModalBooking} />
                </span>
              </div>

              <div
                className="px-6 py-4 overflow-y-auto h-full text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200"
              >
                <div id="bookingAffiliateWidget_755a63ab-c7b3-4279-a5c9-584b78bd4b18"></div>
                {!cookiePartenaires && (
            <InformationCookiesMarketing/>
          )}

                <br/>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalBooking;
