import React, { FC, useState, useEffect, useContext } from "react";
import { jwtDecode } from 'jwt-decode';
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { accountService } from '_services/account.service';
import { compteService } from '_services/compte.service';
import AppContext from 'AppContext';
import ModalReinitMdp from "../../components/ModalReinitMdp";
import ModalReinitMdpSuite from "../../components/ModalReinitMdpSuite";
import { useTranslation } from 'react-i18next';

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { voyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  const { emailReinit } = useParams<{ emailReinit: string }>();
  const { codeReinit } = useParams<{ codeReinit: string }>();

  const [isModalReinitMdpOpen, setIsModalReinitMdpOpen] = useState(false);
  const [isModalReinitMdpSuiteOpen, setIsModalReinitMdpSuiteOpen] = useState(false);

  let navigate=useNavigate();

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    numDroit: 99,
    etatVoyage: 99,
    idVoyage: 0
  });  

  useEffect(() => {
    setAfficheMenuMobile(false);

    if(emailReinit && emailReinit !== "" && codeReinit  && codeReinit !== "")
    {
      compteService
      .verifReinitMdp(emailReinit, codeReinit)
      .then((response: any) => {

        if(response.data.message === 'OK')
        {
          openModalReinitMdpSuite();
        }
        else if (response.data.message === 'EXPIRE')
        {
            alert(t('alertDelaiReinit'));
            navigate(t('lienLogin'));
        }
        else if (response.data.message === 'KO')
        {
            alert(t('alertMailIncorrect'));
            navigate(t('lienLogin'));
        }
        else
        {
            alert(t('alertTechinque'));
            navigate(t('lienLogin'));
        }
      })
      .catch((error: any) => {
       
        alert(t('alertVerifReinit'));
        console.error('Error verif reinit :', error);
      });
    }
   
    if(voyageInfo !== undefined && voyageInfo !== null
      && voyageInfo.id_voyage !== undefined && voyageInfo.id_voyage !== null 
      && voyageInfo.num_droit !== undefined && voyageInfo.num_droit !== null && voyageInfo.num_droit === 0
      && voyageInfo.etatVoyage !== undefined && voyageInfo.etatVoyage !== null && voyageInfo.etatVoyage === 0)
    {
     
      setCredentials({
        ...credentials,
        numDroit: voyageInfo.num_droit,
        etatVoyage: voyageInfo.etatVoyage,
        idVoyage: voyageInfo.id_voyage
      });
    }
  }, []);

  const openModalReinitMdp = () => {
    setIsModalReinitMdpOpen(true);
  };

  const closeModalReinitMdp = () => {
    setIsModalReinitMdpOpen(false);
  };

  const openModalReinitMdpSuite = () => {
    setIsModalReinitMdpSuiteOpen(true);
  };

  const closeModalReinitMdpSuite = () => {
    setIsModalReinitMdpSuiteOpen(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });

  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
   
    if (credentials.email === "" || !validateEmail(credentials.email)) {
      alert(t('alertEmail'));
    } 
    else
    {
    
    accountService.login(credentials)
    .then(res => {

      if(res.data !== undefined && res.data.xsrfToken !== undefined)
      {
        accountService.saveXsrfToken(res.data.xsrfToken);
        navigate(t('lienMesVoyages'));
      }
    }
  )
    .catch(error => {

      if(error.response.status === 401 && error.response.data.message !== undefined)
      {
        alert(error.response.data.message);
      }
    })
  }
}

  const validateEmail = (mail: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(mail);
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = () => {
      if (window.google && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id: '112999122939-k944srcdkk8esjekjq6bqqagvi211i3i.apps.googleusercontent.com',
          callback: handleGoogleCallback
        });

        const googleSignUpElement = document.getElementById('googleSignIn');
        if (googleSignUpElement) {
          window.google.accounts.id.renderButton(googleSignUpElement, {
            theme: 'filled_blue',
            size: 'large',
          });
        }
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleGoogleCallback = async (response: any) => {
    // Decode JWT to get user information
    const decodedToken = jwtDecode(response.credential);

    // Send user data to your API
    await handleGoogleSignIn(decodedToken);
  };

  const handleGoogleSignIn = async (userData: any) => {
    try {
      
      var numDroit=99;
      var idVoyage=0;
      var etatVoyage=99;

      if(voyageInfo !== undefined && voyageInfo !== null
        && voyageInfo.id_voyage !== undefined && voyageInfo.id_voyage !== null 
        && voyageInfo.num_droit !== undefined && voyageInfo.num_droit !== null && voyageInfo.num_droit === 0
        && voyageInfo.etatVoyage !== undefined && voyageInfo.etatVoyage !== null && voyageInfo.etatVoyage === 0)
      {
        numDroit=voyageInfo.num_droit;
        etatVoyage=voyageInfo.etatVoyage;
        idVoyage=voyageInfo.id_voyage;
      }
      const response = await compteService.creerCompte({
        email: userData.email,
        familyName: userData.family_name || '',
        givenName: userData.given_name || '',
        googleId: userData.sub,
        numDroit,
        etatVoyage,
        idVoyage
      });
  
      if (response.data && response.data.xsrfToken) {
        accountService.saveXsrfToken(response.data.xsrfToken);
        if(response.data.message && response.data.message === 'CREATE')
        {
          navigate(t('lienCompte'));
        }
        else
        {
          navigate(t('lienMesVoyages'));
        }
      } 
    } catch (error: any) {
      console.error("Error during Google signin", error);
      if (error.response && error.response.status === 401 && error.response.data.message) {
        alert(error.response.data.message);
      }
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>{t('connexion')}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        {t('connexion')}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3 justify-center">
          <div id="googleSignIn"></div>
         
            {/*loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            )) */}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {t('ou')}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={onSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('adresseEmail')}
              </span>
              <Input
                type="email"
                placeholder="exemple@exemple.com"
                className="mt-1"
                value={credentials.email}
                onChange={onChange}
                name="email"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('motDePasse')}
                <Link to="#" className="text-sm" onClick={openModalReinitMdp}>
                {t('motDePasseOublie')}
                </Link>
              </span>
              <Input type="password" className="mt-1"
              name="password" 
              onChange={onChange}
              value={credentials.password}
              />
            </label>
            <span className="block text-center text-sm text-neutral-700 dark:text-neutral-300">
            {t('pasInscrit')} {` `}
            <Link to={t('lienSignUp')}className="pointeurRose">{t('creerCompte')}</Link>
          </span>
            <ButtonPrimary type="submit">{t('seConnecter')}</ButtonPrimary>
          </form>
        </div>
      </div>

      { isModalReinitMdpOpen && (
        <ModalReinitMdp onClose={closeModalReinitMdp}/>
      )}

      { isModalReinitMdpSuiteOpen && (
        <ModalReinitMdpSuite onClose={closeModalReinitMdpSuite} email={emailReinit || ""} code={codeReinit || ""}/>
      )}

    </div>
  );
};

export default PageLogin;
