import React, { useState, useRef, ReactNode, FC, useEffect } from 'react';
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import { Container } from 'react-bootstrap';

interface ItemsSliderProps {
  title: string;
  children: ReactNode;
  nomClass?: string;
  classDay?: string;
  classSlider?: string;
  barreHaute?: boolean;
  classBarreHaute?: string;
}

const ItemsSlider: FC<ItemsSliderProps> = ({
  title,
  children,
  nomClass,
  classDay = "day-item",
  classSlider = "item-slider",
  barreHaute = true,
  classBarreHaute = ""
}) => {
  const scrl = useRef<HTMLDivElement>(null);
  const topScrollbar = useRef<HTMLDivElement>(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [contentFits, setContentFits] = useState(false);
  const [mouseConnected, setMouseConnected] = useState(false);

  const slide = (shift: number) => {
    if (scrl.current) {
      scrl.current.scrollBy({
        left: shift,
        behavior: 'smooth',
      });

      const newScrollLeft = scrl.current.scrollLeft + shift;
      setScrollX(newScrollLeft);

      const canScrollLeft = newScrollLeft > 0;
      const canScrollRight = scrl.current.scrollWidth - newScrollLeft > scrl.current.offsetWidth;

      setScrollEnd(!(canScrollLeft && canScrollRight));

      // Synchronize top scrollbar
      if (topScrollbar.current) {
        topScrollbar.current.scrollLeft = newScrollLeft;
      }
    }
  };

  const scrollCheck = () => {
    if (scrl.current) {
      const newScrollLeft = scrl.current.scrollLeft;
      setScrollX(newScrollLeft);

      setScrollEnd(scrl.current.scrollWidth - newScrollLeft <= scrl.current.offsetWidth);

      // Synchronize top scrollbar
      if (topScrollbar.current) {
        topScrollbar.current.scrollLeft = newScrollLeft;
      }
    }
  };

  const handleWheel = (e: WheelEvent) => {
    if (scrl.current) {
      const isAtStart = scrl.current.scrollLeft === 0;
      const isAtEnd = scrl.current.scrollWidth - scrl.current.scrollLeft === scrl.current.clientWidth;

      if ((isAtStart && e.deltaX < 0) || (isAtEnd && e.deltaX > 0)) {
        e.preventDefault();
      }

      // Synchronize top scrollbar
      if (topScrollbar.current) {
        topScrollbar.current.scrollLeft = scrl.current.scrollLeft;
      }
    }
  };

  const syncScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (scrl.current && topScrollbar.current) {
      scrl.current.scrollLeft = topScrollbar.current.scrollLeft;
    }
  };

  const handleTouchStart = () => {
    // This function is called when the user touches the slider
    setMouseConnected(false); // Assume touch device if touch start is detected
  };

  useEffect(() => {
    const currentScrl = scrl.current;
    if (currentScrl) {
      currentScrl.addEventListener('wheel', handleWheel, { passive: false });
      currentScrl.addEventListener('touchstart', handleTouchStart);

      setContentFits(currentScrl.scrollWidth <= currentScrl.offsetWidth);

      return () => {
        currentScrl.removeEventListener('wheel', handleWheel);
        currentScrl.removeEventListener('touchstart', handleTouchStart);
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (scrl.current) {
        setContentFits(scrl.current.scrollWidth <= scrl.current.offsetWidth);
        setScrollEnd(scrl.current.scrollWidth - scrl.current.scrollLeft <= scrl.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = () => {
      setMouseConnected(true);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (scrl.current) {
      setContentFits(scrl.current.scrollWidth <= scrl.current.offsetWidth);
      setScrollEnd(scrl.current.scrollWidth - scrl.current.scrollLeft <= scrl.current.offsetWidth);
    }
  }, [children]);

  return (
    <Container fluid className={`${nomClass || ''} ${mouseConnected ? 'mouse-connected' : ''}`}>
      <div className={classSlider}>
        <div
          onClick={() => slide(-200)}
          className={`left-arrow-left ${(scrollX < 1) ? 'is-disabled-hide' : ''} ${!(scrollX > 0) ? 'is-hidden' : ''}`}
        >
          <MdArrowBackIos size='70px' />
        </div>

        <div
          ref={scrl}
          onScroll={scrollCheck}
          className='item-container'
        >
          <div className="days-container">
            {React.Children.map(children, (child, index) => (
              child ? (
                <div key={index} className={`${classDay} mb-5`}>
                  {child}
                </div>
              ) : null
            ))}
          </div>
        </div>

        {barreHaute && (
          <div
            ref={topScrollbar}
            onScroll={syncScroll}
            className={`top-scrollbar${classBarreHaute}`}
          >
            <div className="scroll-content" style={{ width: scrl.current ? `${scrl.current.scrollWidth}px` : '100%' }} />
          </div>
        )}

        {!contentFits && (
          <div
            className={`right-arrow-right ${scrollEnd ? 'is-hidden' : ''}`}
            onClick={() => slide(200)}
          >
            <MdArrowForwardIos size='70px' />
          </div>
        )}
      </div>
    </Container>
  );
};

export default ItemsSlider;
