import SectionHero from "components/SectionHero/SectionHero";
import SectionHeroHome from "components/SectionHero/SectionHeroHome";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import rightImg from "images/about-hero-right2.webp";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

function PageHome() {
  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  const [compteur, setCompteur] = useState<string>("0");
  const { ancre } = useParams<{ ancre: string }>();
  const { pays } = useParams<{ pays: string }>();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAfficheMenuMobile(true);
    setVoyageInfo(null);
  }, []);

  useEffect(() => {
    if ((ancre && ancre === 'publics') || (pays && pays !== '')) {
      const element = document.getElementById('voyagesPublics');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ancre]);

  const formatNumber = (num: number): string => {
    return num.toLocaleString('fr-FR');
  };

  const handleMajCompteur = (nbVoyages: number) => {
    setCompteur(formatNumber(nbVoyages));
  };

  return (
    <>
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-12 mb-5 lg:space-y-5 lg:mb-5">
        {/* SECTION HERO */}
        <SectionHero className="pt-8 lg:pt-8 lg:pb-12" />

        <SectionHeroHome
          rightImg={rightImg}
          heading={t('bienvenue')}
          btnText=""
          />
      { compteur !== "0" && (
            <div className="text-center lg:pt-2 lg:pb-2 text-md md:text-lg">
            <h2><b>🗺️ {compteur} {t('nbItineraires')}</b></h2>
          </div>
      )}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces majCompteur={handleMajCompteur} pays={pays} heading={t('voyagesPublics')} subHeading={t('infoVoyagesPublics')}/>
        </div>

      </div>
    </div>
    {isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
                        <br/>
            <br/>
            <br/>
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              Le nouveau site n'est pas encore tout à fait prêt, merci de revenir ultérieurement pour ne pas perturber la migration qui devrait se terminer avant la fin du week-end :)
            </h3>
            <br/>
            <p>Si vous avez un besoin urgent d'accéder à votre itinéraire en consultation, veuillez nous contacter à contact@tripkygo.com ou sur les réseaux sociaux et nous vous donnerons accès à la plateforme de test.</p>
            <br/>
            <br/>
            <br/>
            <span onClick={() => setIsLoading(false)}>.</span>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}
    </>
  );
}

export default PageHome;
