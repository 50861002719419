import React, { FC, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import VoyageNomadeSearchForm from "./(voyage-nomade-search-form)/VoyageNomadeSearchForm";
import VoyageSedentaireSearchForm from "./(voyage-sedentaire-search-form)/VoyageSedentaireSearchForm";
import Lottie from 'react-lottie';
import animationData from '../../shared/loading.json';
import { useTranslation } from 'react-i18next';

// Définir les clés de traduction
const TRANSLATION_KEYS = {
  voyageNomade: 'voyageNomade',
  voyageSedentaire: 'voyageSedentaire',
};

export type SearchTab = "voyageNomade" | "voyageSedentaire";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "voyageNomade",
}) => {
  const { t } = useTranslation();

  const tabs: SearchTab[] = ["voyageNomade", "voyageSedentaire"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeLoading = () => {
    setIsLoading(prevState => !prevState);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{t(TRANSLATION_KEYS[tab])}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "voyageNomade":
        return <VoyageNomadeSearchForm onChangeLoading={handleChangeLoading}/>;
      case "voyageSedentaire":
        return <VoyageSedentaireSearchForm onChangeLoading={handleChangeLoading}/>;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}>
        {renderTab()}
        {renderForm()}
      </div>

      {isLoading && (
        <Transition appear show={true} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-[100] overflow-y-auto"
            onClose={() => {}} // Pas de fermeture pour la modale d'attente
          >
            <div className="min-h-screen flex items-center justify-center px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
              </Transition.Child>
              
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block transition-all text-center transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4">
                  <Lottie options={defaultOptions} height={200} width={200} />
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
                    {t('creation')} {t('enCours')}
                  </h3>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default HeroSearchForm;
