import React, { HTMLAttributes, ReactNode } from "react";
import NextPrev from "shared/NextPrev/NextPrev";

export interface HeadingGuidesProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
}

const HeadingGuides: React.FC<HeadingGuidesProps> = ({
  children,
  desc = "Popular places to stay that Chisfis recommends for you",
  className = "mb-6 md:mb-12 lg:mb-12 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  hasNextPrev = false,
  ...args
}) => {
  return (
    <div
      className={`nc-Section-HeadingGuides relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className={
          isCenter ? "text-center w-full max-w-2xl mx-auto" : "max-w-2xl"
        }
      >
        <h2 className={`text-2xl md:text-4xl font-semibold`} {...args}>
          {children || `Section HeadingGuides`}
        </h2>
        {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base text-sm sm:text-lg text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
        </div>
      )}
    </div>
  );
};

export default HeadingGuides;
