import React, { Fragment, useState, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { voyageService } from '_services/voyage.service';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface ModalPartageProps {
  onClose: () => void;
  keyVoyage: number;
}

const ModalPartage: FC<ModalPartageProps> = ({ onClose, keyVoyage }) => {

  const { t } = useTranslation();
  
  const [isLoading, setIsLoading] = useState(false);
  const [isAffichePrix, setIsAffichePrix] = useState<boolean>(false);
  const [isAfficheBudget, setIsAfficheBudget] = useState<boolean>(false);
  const [isAfficheConfirmations, setIsAfficheConfirmations] = useState<boolean>(false);
  const [isAfficheLiens, setIsAfficheLiens] = useState<boolean>(false);
  const [isAffichePhotos, setIsAffichePhotos] = useState<boolean>(false);
  const [isAfficheBlocNotes, setIsAfficheBlocNotes] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const togglePrix = () => {
    setIsAffichePrix(prevState => !prevState);
  };
  
  const toggleBudget = () => {
    setIsAfficheBudget(prevState => !prevState);
  };

  const toggleConfirmations = () => {
    setIsAfficheConfirmations(prevState => !prevState);
  };

  const toggleLiens = () => {
    setIsAfficheLiens(prevState => !prevState);
  };

  const togglePhotos = () => {
    setIsAffichePhotos(prevState => !prevState);
  };

  const toggleBlocNotes = () => {
    setIsAfficheBlocNotes(prevState => !prevState);
  };

  let navigate = useNavigate();

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handlePartage = () => {
   if (email === "" || !validateEmail(email)) {
      alert("Vous devez saisir une adresse email valide !");
    } else {
      setIsLoading(true);
      voyageService.partagerVoyage(keyVoyage, {
        email: email,
        partagePrix: isAffichePrix ? "O" : "N" ,
        partageBudget: isAfficheBudget ? "O" : "N" ,
        partageLiens: isAfficheLiens ? "O" : "N" ,
        partagePhotos: isAffichePhotos ? "O" : "N" ,
        partageConfirmations: isAfficheConfirmations ? "O" : "N" ,
        partageBlocNotes: isAfficheBlocNotes ? "O" : "N" ,
      })
        .then((response: any) => {
          setIsLoading(false); // Arrêter le chargement
          closeModal();
          alert("L'email de partage a été envoyé !")
        })
        .catch((error: any) => {
          setIsLoading(false); // Arrêter le chargement
          if (error.response?.status === 401) {
            alert("Erreur lors du partage du voyage, vous devez être connecté.");
            navigate("/login");
          } else {
            alert("Erreur lors du partage du voyage.");
          }
          console.log('Copie KO', error);
        });
    }
  };

  const validateEmail = (mail: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(mail);
  }


  return (
    <>
   <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 scale-95"
            >
             <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                    Partage individuel
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>

              <div className="px-8 text-neutral-700 dark:text-neutral-300"> 
                    <div className="mt-5 flex justify-center"> 
                              <p className="text-sm">Avec qui souhaitez-vous partager votre voyage ?</p>
                    </div>
                    <div className="mt-5 flex justify-center"> 
                        <h2 className={`text-base font-medium w-full `}>
                          <input
                            type="email"
                            onChange={handleEmailChange}
                            className="champAutocomplete"
                            placeholder={t('adresseEmail')}
                            maxLength={50}
                          />
                        </h2>
                    </div>
                    <div className="mt-5 flex justify-center"> 
                              <p className="text-sm">Souhaitez-vous partager les informations suivantes ?</p>
                    </div>
                 <div className="mt-5 flex justify-center text-sm items-center text-right grid grid-cols-2 gap-5">
                    <span>Prix</span>
                    <button
                      onClick={togglePrix}
                      className={`w-8 h-4 rounded-full transition-colors ${
                        isAffichePrix ? 'bg-rosetrip-500' : 'bg-gray-300'
                      }`}
                    >
                      <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAffichePrix ? 'translate-x-full' : ''}`} />
                    </button>

                    <span>Vue Budget</span>
                    <button
                      onClick={toggleBudget}
                      className={`w-8 h-4 rounded-full transition-colors ${
                        isAfficheBudget ? 'bg-rosetrip-500' : 'bg-gray-300'
                      }`}
                    >
                      <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAfficheBudget ? 'translate-x-full' : ''}`} />
                    </button>

                    <span>Photos</span>
                    <button
                      onClick={togglePhotos}
                      className={`w-8 h-4 rounded-full transition-colors ${
                        isAffichePhotos ? 'bg-rosetrip-500' : 'bg-gray-300'
                      }`}
                    >
                      <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAffichePhotos ? 'translate-x-full' : ''}`} />
                    </button>
    
                    <span>Confirmations</span>
                    <button
                      onClick={toggleConfirmations}
                      className={`w-8 h-4 rounded-full transition-colors ${
                        isAfficheConfirmations ? 'bg-rosetrip-500' : 'bg-gray-300'
                      }`}
                    >
                      <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAfficheConfirmations ? 'translate-x-full' : ''}`} />
                    </button>

                    <span>{t('blocNotes')}</span>
                    <button
                      onClick={toggleBlocNotes}
                      className={`w-8 h-4 rounded-full transition-colors ${
                        isAfficheBlocNotes ? 'bg-rosetrip-500' : 'bg-gray-300'
                      }`}
                    >
                      <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAfficheBlocNotes ? 'translate-x-full' : ''}`} />
                    </button>
  
                    <span>Liens</span>
                    <button
                      onClick={toggleLiens}
                      className={`w-8 h-4 rounded-full transition-colors ${
                        isAfficheLiens ? 'bg-rosetrip-500' : 'bg-gray-300'
                      }`}
                    >
                      <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isAfficheLiens ? 'translate-x-full' : ''}`} />
                    </button>
                </div>
                <div className="flex justify-center mt-5 mb-5 "> 
                  <ButtonPrimary onClick={handlePartage}>
                    Partager
                  </ButtonPrimary>
                </div>
                <div className="mt-5 flex justify-center"> 
                              <p className="text-xxs pb-3"><i className="las la la-warning"></i> La personne avec qui vous partagez votre voyage ne pourra faire aucune modification.<br/>Elle pourra éditer la feuille de route en PDF mais pas le carnet de voyage.</p>
                    </div>
              </div>
            </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>

{isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              Partage en cours, veuillez patienter...
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}
</>
    
  );
};

export default ModalPartage;
