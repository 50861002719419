import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { autocompleteService } from '_services/autocomplete.service';
import {apiKeyGeoapify} from '_config/constantes'; 
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string; 
  autoFocus?: boolean;
  onSelectDestination?: (codePays: string, pays: string, ville: string, place_id: string, latitude: string, longitude: string) => void;
  destinationsEnregistrees: Destination[];
}

export type Destination = {
  code_pays: string;
  pays: string;
  ville: string;
  place_id: string,
  latitude: string,
  longitude: string,
};

type Ville = {
  country_code: string;
  country: string;
  city: string;
  name: string;
  state: string;
  county: string;
  address_line1: string;
  address_line2: string;
  place_id: string;
  latitude: string;
  longitude: string;
  result_type: string;
};

const VilleInput: FC<Props> = ({
  onChange = () => {},
  autoFocus = false,
  className = "",
  defaultValue = "United States",
  headingText = "Où allez-vous ?",
  onSelectDestination,
  destinationsEnregistrees
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [selectedDestinations, setSelectedDestinations] = useState<Ville[]>([]);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [searchResults, setSearchResults] = useState<Ville[]>([]);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && value && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
     // inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (value && value.length > 1) {
      const id = setTimeout(() => {
        fetchSearchResults();
      }, 600);

      setTimeoutId(id);
    } else {
      setSearchResults([]);
    }

    // Cleanup function to cancel the timeout if component unmounts or `value` changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [value]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
   /*if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }*/
    setShowPopover(false);
  };

  const handleSelectLocation = (item: Ville) => {
    var ville = '';
    if (onSelectDestination) {
      var codePays = item.country_code;
      var pays = item.country;
      var etat = '';

      if (item.city !== undefined && item.city !== null) {
        ville = item.city;
      }
      else if (item.name !== undefined && item.name !== null) {
        ville = item.name;
      }
      else if (item.result_type === 'state') {
        ville = item.state;
      }
      else if (item.result_type === 'county') {
        ville = item.county;
      }
      else {
        ville = 'Lieu inconnu';
      }

      if (codePays === 'gb') {
        if (item.state !== null) {
          etat = item.state;

          switch (etat) {
            case 'Angleterre':
              codePays = 'ww';
              pays = etat;
              break;
            case 'Écosse':
              codePays = 'xx';
              pays = etat;
              break;
            case 'Irlande du Nord':
              codePays = 'yy';
              pays = etat;
              break;
            case 'Pays de Galles':
              codePays = 'zz';
              pays = etat;
              break;
            default:
          }
        }
      }
      else if (codePays === 'fr') {
        if (item.state !== null) {
          etat = item.state;

          switch (etat) {
            case 'Guadeloupe':
              codePays = 'gp';
              pays = etat;
              break;
            case 'Martinique':
              codePays = 'mq';
              pays = etat;
              break;
            case 'Réunion':
              codePays = 're';
              pays = etat;
              break;
            case 'Mayotte':
              codePays = 'yt';
              pays = etat;
              break;
            case 'Guyane':
              codePays = 'gf';
              pays = etat;
              break;
            case 'Guyane Française':
              codePays = 'gf';
              pays = etat;
              break;
            case 'Polynésie Française':
              codePays = 'pf';
              pays = etat;
              break;
            case 'Terres Australes Françaises':
              codePays = 'tf';
              pays = etat;
              break;
            case 'Saint-Pierre-et-Miquelon':
              codePays = 'pm';
              pays = etat;
              break;
            case 'Wallis et Futuna':
              codePays = 'wf';
              pays = etat;
              break;
            default:
          }
        }
      }


      onSelectDestination(codePays, pays, ville, item.place_id, item.latitude, item.longitude);
    }
    setValue(ville); // Écrase la valeur précédente de `value` avec la nouvelle ville sélectionnée
    setSearchResults([]);
    setShowPopover(false);
  };

  const fetchSearchResults = async () => {
    try {


      const response = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&lang=fr&limit=20&apiKey=${apiKeyGeoapify}&type=city`);
      const data = await response.json();

      const formattedResults: Ville[] = data.features.map((feature: any) => ({

        country_code: feature.properties?.country_code,
        country: feature.properties?.country,
        city: feature.properties?.city,
        name: feature.properties?.name,
        state: feature.properties?.state,
        county: feature.properties?.county,
        address_line1: feature.properties?.address_line1,
        address_line2: feature.properties?.address_line2,
        place_id: feature.properties?.place_id,
        latitude: feature.properties?.lat,
        longitude: feature.properties?.lon,
        result_type: feature.properties?.result_type,
      }));
      setSearchResults(formattedResults);
    } catch (error) {
      console.error("Erreur lors de la récupération des résultats de recherche :", error);
    }
  };

  const renderSearchResults = () => {
    return (
      <>
        {searchResults.map((item) => {
          const address_line1 = item.address_line1 || "";
          const address_line2 = item.address_line2 || "";
          return (
            <span
              onClick={() => handleSelectLocation(item)}
              key={((item as unknown) as { code_pays: string }).code_pays}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200 self-end">
                {address_line1}
              </span>
              {address_line2 && (
                <span className="block text-sm text-neutral-400 dark:text-neutral-500 self-end">
                  {address_line2}
                </span>
              )}
            </span>
          );
        })}
      </>
    );
  };

 /* useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);*/

  /*const handleSelectLocation = (item: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item);
      onChange && onChange(item);
    }, 0);
  };*/

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: Ville[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || ""}
        </p>
        <div className="mt-3">
          {items.map((item, index: number) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(item)}
                key={"result-"+index}
              >
               <span className="block text-neutral-400">
                <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200 self-end">
                {item.address_line1}
              </span>
              {item.address_line2 && (
                <span className="block text-sm text-neutral-400 dark:text-neutral-500 self-end">
                  {item.address_line2}
                </span>
              )}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderDestSaved = ({
    heading,
    items,
  }: {
    heading: string;
    items: Destination[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || ""}
        </p>
        <div className="mt-3">
          {items.map((item, index: number) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                key={"saved-"+index}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                
                <span className="">{item.ville+", "+item.pays}
              </span>
              </div>
            );
          })}
        </div>
      </>
    );
  };


  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
        {t('ouAllezVous')}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={t('saisirVille')}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: searchResults.length === 0 && value.length < 5 ? "" : value.length > 4 && searchResults.length === 0 ? t('aucunResultat') : value.length > 2 && searchResults.length === 1 ? t('resultat') : t('resultat')+"s",
                items: searchResults,
              })
            : null }
            {/*renderSearchValues({
                heading: "Popular destinations",
                items: [
                  "Australia",
                  "Canada",
                  "Germany",
                  "United Kingdom",
                  "United Arab Emirates",
                ],
              })*/}
              {destinationsEnregistrees.length > 0
            ? renderDestSaved({
                heading: destinationsEnregistrees.length === 1 ? t('destinationEnregistree') : t('destinationsEnregistrees'),
                items: destinationsEnregistrees,
              })
            : null }
            {/*renderSearchValues({
                heading: "Popular destinations",
                items: [
                  "Australia",
                  "Canada",
                  "Germany",
                  "United Kingdom",
                  "United Arab Emirates",
                ],
              })*/}
        </div>
      </div>
    </div>
  );
};

export default VilleInput;
