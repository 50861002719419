import { Popover, Transition } from "@headlessui/react";
import React, { FC, useEffect, useState, useRef } from "react";
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  ShareIcon,
  GlobeEuropeAfricaIcon,
  ChartPieIcon,
  PlusIcon
} from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import SocialsList from "shared/SocialsList/SocialsList";
import AvatarMenu from "shared/AvatarMenu/AvatarMenu";
import { compteService } from "_services/compte.service";
import { accountService } from "_services/account.service";
import { useNavigate } from "react-router-dom";
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';



export default function AvatarDropdown() {
  const { t } = useTranslation();

  const [profilData, setProfilData] = useState<any>(null);
  const [imageAvatar, setImageAvatar] = useState("");

  let navigate=useNavigate();

  
const solutionsFoot = [
  /*{
    name: "Help",
    href: "##",
    icon: LifebuoyIcon,
  },*/

  {
    name: t('deconnexion'),
    href: "##",
    icon: ArrowRightOnRectangleIcon,
  },
];

  const solutions = [
    {
      name: t('monCompte'),
      href: t('lienCompte'),
      icon: UserCircleIcon,
    },
    {
      name: t('mesVoyages'),
      href: t('lienMesVoyages'),
      icon: GlobeEuropeAfricaIcon,
    },
    {
      name: t('voyagesPartages'),
      href: t('lienVoyagesPartages'),
      icon: ShareIcon,
    },
    {
      name: t('statistiques'),
      href: t('lienStatistiques'),
      icon: ChartPieIcon,
    },
    {
      name: t('nouveauVoyage'),
      href: t('lienHome'),
      icon: PlusIcon,
    }
  ];

  const logout = () => {
    accountService.logout();
    navigate(t('lienHome'));
  }
  
  useEffect(() => {

    // Appeler l'API pour obtenir les informations sur le voyageur
    compteService.getProfil()
      .then((response) => {

        setProfilData(response.data[0]);
      })
      .catch((error: any) => {

        console.error("Error fetching data:", error);
        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
          navigate(t('lienLogin'));
        }
        else
        {
          alert("Erreur lors de la récupération du profil");
          navigate(t('lienHome'));
        }
      });
  }, []);

  useEffect(() => {
   
    let imageURL: string | undefined;
  
     if(profilData?.avatar && profilData?.avatar !== "")
     {
  
       Axios.get(profilData?.avatar.url, { responseType: 'blob' })
       .then((response) => {
         const blob = new Blob([response.data], { type: 'image/jpeg' });
         imageURL = URL.createObjectURL(blob);
         setImageAvatar(imageURL);
       })
       .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
     }
  
     return () => {
       if (imageURL) {
         URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
       }
     };
     
   }, [profilData?.avatar]);

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <AvatarMenu sizeClass="w-8 h-8 sm:w-9 sm:h-9" 
                imgUrl={`${imageAvatar || ''}`}/>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1" 
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                   {solutions.map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link>
                    ))}
                    <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
                  </div>
                  
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutionsFoot.map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        onClick={item.name === 'Déconnexion' || item.name === 'Log out' ? logout : undefined}
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
