import React, { FC } from "react";
import { useTranslation } from 'react-i18next';

export interface SectionCouvertureStatistiquesProps {
  className?: string;
 
}

const SectionCouvertureStatistiques: FC<SectionCouvertureStatistiquesProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nc-SectionCouvertureStatistiques relative ${className}`}
      data-nc-id="SectionCouvertureStatistiques"
    >
      <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        <h2 className="font-bold text-neutral-900 dark:text-neutral-50 text-3xl lg:text-4xl !leading-[115%] ">
        {t('statistiques')} 
        </h2>
      </div>
    </div>
  );
};

export default SectionCouvertureStatistiques;
