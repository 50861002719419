import React, { FC, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import { useTranslation } from 'react-i18next';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';


export interface ModalDiscoverCarsProps {
  onDiscoverCarsCloseModal: () => void;
  ville: string;
  latitude: string;
  longitude: string;
}

const ModalDiscoverCars: FC<ModalDiscoverCarsProps> = ({
  onDiscoverCarsCloseModal,
  ville,
  latitude,
  longitude,
}) => {
  const { t } = useTranslation();

  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

    // Fonction pour obtenir la valeur d'un cookie
    const getCookie = (name: string) => {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(name))
        ?.split('=')[1];
      return cookieValue || '';
    };

  const handleCloseModalDiscoverCars = () => {
    onDiscoverCarsCloseModal();
  };

  const handleInfoClick = () => {
    alert(
      "En cliquant sur le bouton 'Rechercher' et en réservant chez notre partenaire DiscoverCars, tripkygo touchera une commission sur votre réservation sans modifier le prix que vous paierez. Nous espérons que cela permettra de maintenir notre site 100% gratuit !"
    );
  };

  useEffect(() => {
    const cookieValue = getCookie("tripkygoPartenaires");

    // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
    if (cookieValue && cookieValue === 'O') {
  
      setCookiePartenaires(true);

    const existingScript = document.getElementById('dchwidget');
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = 'dchwidget';
    script.src = 'https://www.discovercars.com/wg.js';
    script.async = true;

    script.dataset.devEnv = 'com';
    script.dataset.location = `france/${ville}`;
    script.dataset.lang = 'fr';
    script.dataset.utmSource = 'tripkygo';
    script.dataset.utmMedium = 'widget';
    script.dataset.affCode = 'tripkygo';
    script.dataset.autocomplete = 'on';
    script.dataset.styleSubmitBgColor = '#FF4A52';
    script.dataset.styleSubmitFontColor = '#FFFFFF';
    script.dataset.styleFormBgColor = '#f2f2f2';
    script.dataset.styleFormFontColor = '#000000';
    script.dataset.styleSubmitText = 'Rechercher';

    const widgetContainer = document.getElementById('discoverCarsWidgetContainer');
    if (widgetContainer) {
      widgetContainer.appendChild(script);
    }

    return () => {
      if (widgetContainer) {
        widgetContainer.removeChild(script);
      }
    }
  }
    else
    {
      setCookiePartenaires(false);
    }
  }, [ville]);

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex justify-center items-center overflow-y-auto"
        onClose={handleCloseModalDiscoverCars}
      >
        <div className="w-full flex flex-col justify-center items-center px-4">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl"
              style={{ maxHeight: '90vh', minHeight: '300px' }} // Ajout de la hauteur maximale et minimale
            >
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h2 className="font-medium leading-6 text-gray-900 dark:text-neutral-300">
                  {t('louerVoitureAvec')} <a href='https://www.discovercars.com/?a_aid=tripkygo' rel="noreferrer" target="_blank"><u>DiscoverCars</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h2>

                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={handleCloseModalDiscoverCars} />
                </span>
              </div>

              <div
                className="px-6 py-4 overflow-y-auto text-neutral-700 dark:text-neutral-300"
              >
                   {!cookiePartenaires && (
            <InformationCookiesMarketing/>
          )}
                <div id="discoverCarsWidgetContainer"> 
                  {/* Ici le widget sera ajouté */}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalDiscoverCars;
