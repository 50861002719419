import React, { FC, useEffect, useState } from "react";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import BadgeNuit from "shared/BadgeNuit/BadgeNuit";
import Avatar from "shared/Avatar/Avatar";
import convertNumbThousand from "utils/convertNumbThousand";
import Axios from '_services/caller.service';
import { useTranslation } from 'react-i18next';

export interface CardNuitResumeProps {
  onLieuxResumeClick: () => void;
  className?: string;
  libelle: string;
  jour: string;
  nbNuits: number;
  image: string;
  pays: string;
  isModif: boolean;
}

const CardNuitResume: FC<CardNuitResumeProps> = ({
  onLieuxResumeClick,
  className = "",
  libelle,
  jour,
  nbNuits,
  image,
  pays,
  isModif
}) => {
  const { t } = useTranslation();
 const [imageLoad, setImageLoad] = useState<string | null>(null);
 const [isModalImageOpen, setIsModalImageOpen] = useState(false);

 const handleImageClick = (boolImage: boolean) => {
  setIsModalImageOpen(boolImage);
}

 const handleLieuxResumeClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
   if(!isModalImageOpen && libelle !== 'Nuit en transport' && libelle !== 'Night in transport')
   {
    onLieuxResumeClick();
   }
};

 const fetchImage = async (url: string): Promise<HTMLImageElement> => {
  try {
    const response = await Axios.get(url, { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'image/jpeg' });
    const imageURL = URL.createObjectURL(blob);

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
        // Nous révoquerons l'URL blob après l'ajout de l'image au PDF
      };
      img.onerror = (event) => {
        console.error('Erreur lors du chargement de l\'image', event);
        reject(event);
      };
      img.src = imageURL;
    });
  } catch (error) {
    console.error('Erreur lors de la requête pour récupérer l\'image', error);
    throw error;
  }
};

useEffect(() => {
  if(image)
  {
    const fetchImageAsync = async () => {
      if (image) {
        const fetchedImage = await fetchImage(image);
        setImageLoad(fetchedImage.src);
      } else {
        setImageLoad(null);
      }
    };

    fetchImageAsync();
  }
  else
  {
    setImageLoad(null);
  }
}, [image]); 

  return (
    <div
      className={`nc-CardNuitResume relative flex items-center mt-2 p-2 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className} ${(libelle !== "Nuit en transport" && libelle !== "Night in transport") || !isModif ? "pointeurRose" : ""}`}
      data-nc-id="CardNuitResume"
      onClick={handleLieuxResumeClick}
    >
      <div className={`absolute inset-x-0 top-2 flex ${ imageLoad ? "left-10" : "left-2" }`}>
      <span
          className={`block mt-2 text-xxs text-neutral-500 dark:text-neutral-400`}
        >
          {jour}
        </span>
      </div>
      <BadgeNuit
        className="absolute right-2 top-2"
        color="gray"
        name={`${nbNuits} nuit${nbNuits > 1 ? 's' : ''}`}
      />

      { imageLoad && (
        <Avatar
        sizeClass={`w-8 h-8`}
        radius="rounded-full"
        imgUrl={imageLoad}
        onImageClick={handleImageClick} 
      />
     /*<div className="relative flex-shrink-0 w-8 h-8 rounded-full overflow-hidden">
        <NcImage src={imageLoad} containerClassName="absolute inset-0" />
      </div>*/
      )}

      <div className={`${libelle.length > 22 ? "mt-5" : "mt-4"} flex-grow overflow-hidden`}>
        <h2 className="text-nuit-integre font-medium">
          <span className={`${pays === t('nuitsIncompletes') ? "couleurRose" : ""}`}>{libelle}</span>
        </h2>
      </div>
    </div>
  );
};

export default CardNuitResume;
