import {
    CurrencyEuroIcon,
    CurrencyDollarIcon,
    CurrencyPoundIcon,
    CurrencyRupeeIcon,
    CurrencyYenIcon,
  } from "@heroicons/react/24/outline";
import { MonnaieType } from "data/types";
  
const apiKeyGeoapify = '8831c2ab3fa948dca0d543e9ebd88e1d' //prod
//const apiKeyGeoapify = '65bfaab7556346e991ef862f1c2bfc91'; //clé de test
const apiKeyKiwi = "Wm-sF399xZs3QJlCpj0ELm7Uo3_vwVHf";
//const urlAPI = "http://localhost:3001/tripkygo-api";
const urlAPI = "https://tripkygo.com/tripkygo-api";

const currencyIcons: Record<MonnaieType, JSX.Element> = {
  euro: <CurrencyEuroIcon className="w-5 h-5 text-amber-500 ml-2" />,
  dollar: <CurrencyDollarIcon className="w-5 h-5 text-amber-500 ml-2" />,
  pound: <CurrencyPoundIcon className="w-5 h-5 text-amber-500 ml-2" />,
  'indian-rupee': <CurrencyRupeeIcon className="w-5 h-5 text-amber-500 ml-2" />,
  yen: <CurrencyYenIcon className="w-5 h-5 text-amber-500 ml-2" />,
  chf: (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      <span className="text-chf">CHF</span>
    </span>
  ),
  'brazilian-real': (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      R$
    </span>
  ),
  baht: (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ฿
    </span>
  ),
  dong: (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₫
    </span>
  ),
  ruble: (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₽
    </span>
  ),
  won: (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₩
    </span>
  ),
  peso: (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₱
    </span>
  ),
};

const currencyIconsIntegre: Record<MonnaieType, JSX.Element> = {
  euro: <CurrencyEuroIcon className="w-4 h-4 text-amber-500 ml-2" />,
  dollar: <CurrencyDollarIcon className="w-4 h-4 text-amber-500 ml-2" />,
  pound: <CurrencyPoundIcon className="w-4 h-4 text-amber-500 ml-2" />,
  'indian-rupee': <CurrencyRupeeIcon className="w-4 h-4 text-amber-500 ml-2" />,
  yen: <CurrencyYenIcon className="w-4 h-4 text-amber-500 ml-2" />,
  chf: (
    <span className="w-4 h-4 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      <span className="text-chf">CHF</span>
    </span>
  ),
  'brazilian-real': (
    <span className="w-4 h-4 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      R$
    </span>
  ),
  baht: (
    <span className="w-4 h-4 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ฿
    </span>
  ),
  dong: (
    <span className="w-4 h-4 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₫
    </span>
  ),
  ruble: (
    <span className="w-4 h-4 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₽
    </span>
  ),
  won: (
    <span className="w-4 h-4 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₩
    </span>
  ),
  peso: (
    <span className="w-4 h-4 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
      ₱
    </span>
  ),
};

export { apiKeyGeoapify, apiKeyKiwi, urlAPI, currencyIcons, currencyIconsIntegre };