import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { useTranslation } from 'react-i18next';

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}


const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = "",
}) => {

  const { t } = useTranslation();

  socials= [
    { name: "Instagram", icon: "lab la-instagram", href: `https://www.instagram.com/tripkygo${t('_en')}` },
    { name: "Facebook", icon: "lab la-facebook-square", href: `https://www.facebook.com/tripkygo${t('En')}` },
    { name: "Youtube", icon: "lab la-youtube", href: "https://www.youtube.com/@tripkygo" },
    { name: "Twitter", icon: "lab la-twitter", href: `https://twitter.com/tripkygo${t('_en')}` },
  ];

  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <i className={item.icon}></i>
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
