import React, { FC, useState, useEffect } from "react";
import { voyageService } from '_services/voyage.service'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface BtnConfidentialiteIconProps {
  className?: string;
  colorClass?: string;
  keyVoyage?: number;
  username?: string;
}

const BtnConfidentialiteIcon: FC<BtnConfidentialiteIconProps> = ({
  className = "",
  colorClass = "text-white boutonConfidentialite",
  keyVoyage=0,
  username
}) => {

  let navigate=useNavigate();
  
  const { t } = useTranslation();
  
  const handlePriveClick = () => {
    if(username && username !== '' && username !== 'null')
    {
    if(window.confirm(
      t('alertVoyagePrive')
    ))
    {
      voyageService.modifierVoyage({
        id_voyage:   keyVoyage,
        visibilite: "public"
    })
        .then((response) => {
        if (response.data.message !== undefined && response.data.message !== null && response.data.message === 'ok') {
            window.location.reload();
        } else {
            // Gérer le cas où idVoyage est 0 ou null
        }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            alert("Erreur lors du changement de visibilité, vous n'êtes plus connecté(e).")
            localStorage.removeItem('xsrfToken');
            navigate('/login');
          }
          else
          {
            alert("Erreur lors du changement de visiblité");
          }
  
        });
  
    }
  }
  else
  {
    alert("Pour rendre un voyage public, vous devez remplir un nom d'utilisateur dans votre profil.");
  }
}
  
  return (
    <div
      className={`nc-BtnConfidentialiteVoyageIcon flex items-center justify-center rounded-full cursor-pointer ${colorClass} ${className} `}
      data-nc-id="BtnConfidentialiteIcon"
      title="Save"
      onClick={handlePriveClick}
    >
      <i className="p-1 text-xl las la-lock"></i>
    </div>
  );
};

export default BtnConfidentialiteIcon;
