import React, { FC, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { voyageService } from '_services/voyage.service';
import { BagageType, IdeeBagageType } from "data/types";
import { autocompleteService } from '_services/autocomplete.service'
import { useTranslation } from 'react-i18next';

export interface ModalContenuBagageProps {
  onContenuBagageCloseModal: () => void;
  idVoyage: string;
  autoFocus?: boolean;
  className?: string;
  divHideVerticalLineClass?: string;
}
const ModalContenuBagage: FC<ModalContenuBagageProps> = ({
  onContenuBagageCloseModal,
  idVoyage,
  autoFocus = false,
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [contenuBagage, setContenuBagage] = useState<BagageType[]>([]);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [nouvelObjet, setNouvelObjet] = useState<string>('');
  

  const [notification, setNotification] = useState(false);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [searchResults, setSearchResults] = useState<IdeeBagageType[]>([]);

  let navigate = useNavigate();

  const modalRef = useRef(null);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && nouvelObjet && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    if (nouvelObjet) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [nouvelObjet]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    setShowPopover(false);
  };

  const handleCloseModalContenuBagage = () => {
    onContenuBagageCloseModal();
  };

  const toggleCheckbox = async (id: number) => {
    // Mettre à jour l'état local
    setCheckedItems((prev) => ({
      ...prev,
      [String(id)]: !prev[String(id)] // Convertir id en chaîne
    }));

    try {
      // Appeler l'API pour mettre à jour l'état de l'objet
      const response = await voyageService.modifierContenuBagage(idVoyage, {
        idContenu: id
      });
  
    } catch (error: any) {
      // Gérer les erreurs d'appel API
      if (error.response?.status === 401) {
        alert("Erreur lors de la mise à jour de l'objet, vous n'êtes plus connecté(e).");
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      } else {
        alert("Erreur lors de la mise à jour de l'objet");
      }
      console.error("Erreur lors de la mise à jour de l'objet :", error);
    }
  };
  
  const handleChangeCheckbox = async () => {

    try {
      // Appeler l'API pour mettre à jour l'état de l'objet
      const response = await voyageService.modifierNotifBagage(idVoyage);
      setNotification(!notification);
  
    } catch (error: any) {
      // Gérer les erreurs d'appel API
      if (error.response?.status === 401) {
        alert("Erreur lors de la mise à jour de la notification, vous n'êtes plus connecté(e).");
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      } else {
        alert("Erreur lors de la mise à jour de la notification");
      }
      console.error("Erreur lors de la mise à jour de la notif :", error);
    }
  };
  
  const renderNoInclude = (bagage: BagageType) => {
    return (
      <div key={bagage.id} className="flex items-center justify-between py-2">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox h-4 w-4 text-primary"
            checked={checkedItems[String(bagage.id)]}
            onChange={() => toggleCheckbox(bagage.id)}
          />
          <span className="ml-2 text-neutral-6000 dark:text-neutral-400 font-medium">
            {bagage.libelle}
          </span>
        </label>
        <i
          className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"
          onClick={() => handleClickSuppression(bagage.id)}
        ></i>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await voyageService.getContenuBagage(idVoyage);

        if (response.data.contenuBagage !== 0) {
          if(response.data.alerteBagage === 'O' || response.data.alerteBagage === 'X')
          {  
            setNotification(true);
          }
          else
          {
            setNotification(false);
          }
          setContenuBagage(response.data.contenuBagage);
          const initialCheckedItems: Record<string, boolean> = {};
          response.data.contenuBagage.forEach((bagage: BagageType) => {
            initialCheckedItems[String(bagage.id)] = bagage.etat === "done";
          });
          setCheckedItems(initialCheckedItems);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
        }
        console.error("Error fetching data:", error);
        navigate('/');
      }
    };
  
    fetchData();
  }, []);

  const handleClickAjout = async () => {
    // Vérifier si la nouvelle entrée est vide ou composée uniquement d'espaces
    if (nouvelObjet.trim() === '') {
      alert("Vous devez saisir un objet !");
      return;
    }

    try {
      // Appeler l'API pour ajouter le nouvel objet
      const response = await voyageService.ajouterContenuBagage(idVoyage, {
        libelle: nouvelObjet
      });
  
      const apiResult = response.data;
  
      // Vérifier si la réponse de l'API est valide
      if (apiResult !== "Erreur") {
        // Ajouter le nouvel objet à la liste
        const nouvelObjetBagage: BagageType = {
          id: apiResult.idContenu,
          libelle: nouvelObjet,
          etat: "new",
          ordre: contenuBagage.length + 1 // Ajouter 1 à la longueur actuelle de la liste
        };
  
        setContenuBagage((prev) => [...prev, nouvelObjetBagage]);
        setNouvelObjet(''); // Réinitialiser la valeur de l'entrée
      } else {
        console.error("Erreur lors de la requête API");
        // Gérer l'erreur, afficher un message, etc.
      }
    } catch (error: any) {
      // Gérer les erreurs d'appel API
      if (error.response?.status === 401) {
        alert("Erreur lors de l'ajout de l'objet, vous n'êtes plus connecté(e).");
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      } else {
        alert("Erreur lors de l'ajout de l'objet");
      }
      console.error("Erreur lors de l'ajout de l'objet :", error);
    }
  };


 const handleClickSuppression = async (idSuppression: number) => {

    try {
      // Appeler l'API pour ajouter le nouvel objet
      const response = await voyageService.supprimerContenuBagage(idVoyage, {
        idSuppr: idSuppression
      });
  
      const apiResult = response.data;
  
      // Vérifier si la réponse de l'API est valide
      if (apiResult !== "Erreur") {
        setContenuBagage((prev) => prev.filter((bagage) => bagage.id !== idSuppression));
      
      } else {
        console.error("Erreur lors de la requête API");
        // Gérer l'erreur, afficher un message, etc.
      }
    } catch (error: any) {
      // Gérer les erreurs d'appel API
      if (error.response?.status === 401) {
        alert("Erreur lors de la suppression de l'objet, vous n'êtes plus connecté(e).");
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      } else {
        alert("Erreur lors de la suppression de l'objet");
      }
      console.error("Erreur lors de la suppression de l'objet :", error);
    }
  };

  
  const fetchSearchResults = async () => {
    if(nouvelObjet.trim().length > 0)
    {
      try {
        const response = await autocompleteService.getIdeesBagage(nouvelObjet);
        const data = await response.data;

        if (data && data.rows) {
          setSearchResults(data.rows);
          if(data.rows.length > 0)
          {
            setShowPopover(true);
          }
          else
          {
            setShowPopover(false);
          }

        }

      } catch (error) {
        console.error("Erreur lors de la récupération des résultats de recherche :", error);
      }
    }
    else
    {
      setShowPopover(false);
    }
  };

  const renderSearchResults = () => {
    return (
      <>
        {searchResults.map((item) => (
          <span
            key={item.id}
            onClick={() => {setNouvelObjet(item.libelle);}}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.libelle}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalContenuBagage}
        ref={modalRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="h-screen inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      {t('faireMaValise')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={handleCloseModalContenuBagage} />
                    </span>
                  </div>
                  <div className="px-8 mb-5 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="space-y-4">
                      <div className="text-xs text-center mt-5 sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                          <label className="inline-flex items-center">
                            
                          <span className="ml-2 text-neutral-6000 dark:text-neutral-400 font-medium">
                            <i className="las la-bell text-md"></i> {t('notificationEmail')}&nbsp;&nbsp;
                            </span>
                            <input
                              type="checkbox"
                              className="form-checkbox h-4 w-4 text-primary couleurRose curseurPointeur"
                              checked={notification}
                              onChange={() => handleChangeCheckbox()}
                            />
                          </label>
                        </div>              
                        <div className="flex flex-col mt-5 sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                          <Input
                            className="!h-full"
                            placeholder={`${t('objet')}...`}
                            value={nouvelObjet}
                            onChange={(e) => setNouvelObjet(e.target.value)}
                            autoFocus={showPopover}
                            maxLength={30}
                            ref={inputRef}
                          />  
                          <ButtonPrimary className="flex-shrink-0" onClick={handleClickAjout}>
                              <i className="text-xl las la-plus"></i>
                              <span className="ml-3">{t('ajouter')}</span>
                            </ButtonPrimary>  
                        </div>
                         
                        {showPopover && nouvelObjet && (
                            <div className="absolute autocompleteBagage left-0 z-40 bg-white dark:bg-neutral-800 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                              {nouvelObjet && renderSearchResults()}
                            </div>
                          )}
                       <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
                       <div className="flow-root">
                        <div className="-my-3 mb-5 divide-y divide-neutral-100 dark:divide-neutral-800">
                       {contenuBagage.map(renderNoInclude)}
                       </div>
                       </div>
                      </div>  

                  </div>
                </div>
              </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalContenuBagage;