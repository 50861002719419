import { NavItemType } from "shared/NavigationTripkygo/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import { useTranslation } from 'react-i18next';

const useTranslatedMenus = () => {
  const { t } = useTranslation();

  const PlanificateurChildMenus: NavItemType[] = [
    {
      id: ncNanoId(),
      href: t('lienHome'),
      name: t('creerVoyage'),
    },
    {
      id: ncNanoId(),
      href: t('lienVoyagesPublics'),
      name: t('voyagesPublics'),
    },
    {
      id: ncNanoId(),
      href: t('lienAide'),
      name: t('aide'),
    }
  ];

  const ContactChildMenus: NavItemType[] = [
    {
      id: ncNanoId(),
      href: t('lienContact'),
      name: "Contact",
    },
    {
      id: ncNanoId(),
      href: t('lienAPropos'),
      name: t('aPropos'),
    }
  ];

  const GuidesChildMenus: NavItemType[] = [
    {
      id: ncNanoId(),
      href: t('lienGuides'),
      name: t('guidesPays')
    },
    {
      id: ncNanoId(),
      href: t('lienOuPartir'),
      name: t('ouPartir')
    },
    {
      id: ncNanoId(),
      href: "https://blog.tripkygo.com",
      name: "Blog",
    }
  ];

  const ReservationsChildMenus: NavItemType[] = [
    {
      id: ncNanoId(),
      href: t('lienReservationHebergement'),
      name: t('hebergement'),
    },
    {
      id: ncNanoId(),
      href: t('lienReservationTransport'),
      name: t('transport'),
    },
    {
      id: ncNanoId(),
      href: t('lienLocationVoiture'),
      name: t('voiture'),
    },
    {
      id: ncNanoId(),
      href: t('lienReservationActivite'),
      name: t('activite'),
    }
  ];

  const VoyagesChildMenus: NavItemType[] = [
    {
      id: ncNanoId(),
      href: t('lienMesVoyages'),
      name: t('mesVoyages'),
    },
    {
      id: ncNanoId(),
      href: t('lienVoyagesPartages'),
      name: t('voyagesPartages'),
    }
    ,
    {
      id: ncNanoId(),
      href: t('lienStatistiques'),
      name: t('statistiques'),
    },
  ];

  const NAVIGATION_DEMO: NavItemType[] = [
    {
      id: ncNanoId(),
      href: t('lienHome'),
      name: t('planificateur2'),
      type: "dropdown",
      children: PlanificateurChildMenus,
    },
    {
      id: ncNanoId(),
      href: "/account",
      name: t('monCompte'),
    },
    {
      id: ncNanoId(),
      href: t('lienMesVoyages'),
      name: t('mesVoyages'),
      type: "dropdown",
      children: VoyagesChildMenus,
    },
    {
      id: ncNanoId(),
      href: t('lienGuides'),
      name: t('guides'),
      type: "dropdown",
      children: GuidesChildMenus,
    },
    {
      id: ncNanoId(),
      href: t('lienReservation'),
      name: t('reservation'),
      type: "dropdown",
      children: ReservationsChildMenus,
    },
    {
      id: ncNanoId(),
      href: t('lienContact'),
      name: "Contact",
      type: "dropdown",
      children: ContactChildMenus,
    }
  ];

  const optionsMenus: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: "<i className={`las la-info`}></i>",
    },
    {
      id: ncNanoId(),
      href: "/home-2",
      name: "<i className={`las la-pencil-alt`}></i>",
      isNew: true,
    },
    {
      id: ncNanoId(),
      href: "/home-3",
      name: "<i className={`las la-link`}></i>",
      isNew: true,
    },
  ];

  const OPTIONS_EVENEMENT: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: "Options",
      type: "dropdown",
      children: optionsMenus,
      isNew: true,
    }
  ];

  return {
    NAVIGATION_DEMO,
    OPTIONS_EVENEMENT
  };
};

export default useTranslatedMenus;
