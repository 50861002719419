import React from 'react';
import ReactDOM from 'react-dom';

interface ModalPhotoProps {
  imageUrl: string;
  onClose: () => void;
}

const ModalPhoto: React.FC<ModalPhotoProps> = ({ imageUrl, onClose }) => {
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Vérifiez si l'élément cliqué est l'image elle-même
    if (
      e.target === e.currentTarget ||
      (e.target as HTMLElement).tagName.toLowerCase() === 'img'
    ) {
      // Seulement si l'événement provient de l'élément de superposition ou de l'image, pas de ses enfants
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <img className="modal-image-full" src={imageUrl} alt="Large Avatar" />
      </div>
    </div>,
    document.body
  );
};

export default ModalPhoto;
