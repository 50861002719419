import Axios from './caller.service';

let getPays = (motif, langue) => {
    return Axios.get('/autocomplete/pays/'+langue+'/'+motif);
}

let getRecherche = (motif) => {
    return Axios.get('/autocomplete/recherche/'+motif);
}

let getListePays = () => {
    return Axios.get('/autocomplete/pays');
}

let getAeroport = (motif, langue) => {
    return Axios.get('/autocomplete/aeroport/'+langue+'/'+motif);
}


let getIdeesBagage = (motif) => {
    return Axios.get('/autocomplete/bagage/'+motif);
}

export const autocompleteService = {
    getPays,
    getAeroport,
    getIdeesBagage,
    getListePays,
    getRecherche
}