import React, { FC, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { JourneeType, EventType } from "data/types";
import JSZip from "jszip";
import { useTranslation } from 'react-i18next';


export interface ModalImportProps {
  onImportCloseModal: () => void;
  idVoyage: string;
  nbJours: number;
  eventCategories: JourneeType[];
  onAddEvenement: (idJournee : number, idEvenement : number, libelle : string, description : string, latitiude : string, longitude : string) => number;
}

const ModalImport: FC<ModalImportProps> = ({
  onImportCloseModal,
  idVoyage,
  nbJours,
  eventCategories,
  onAddEvenement
}) => {
  const { t } = useTranslation();
  const [importDone, setImportDone] = useState<boolean>(false);
  const [placesList, setPlacesList] = useState<
    Array<{
      name: string;
      description: string;
      coordinates: string;
      ajout: boolean;
    }>
  >([]);
  const [selectedJours, setSelectedJours] = useState<Array<number | null>>(
    new Array(placesList.length).fill(null)
  );

  const modalRef = useRef(null);

  const handleCloseModalImport = () => {
    onImportCloseModal();
  };

  const importerItineraire = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImportDone(false);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      var fileContent="";
      if (file.name.endsWith('.kmz')) {
        fileContent = await extractKMLFromKMZ(file);
      }
      else
      {
        fileContent = await readFileContent(file);
      }

      const parsedPlacesList = parseKML(fileContent);

      setPlacesList(parsedPlacesList);
      setImportDone(true);
    }
  };

  const extractKMLFromKMZ = async (kmzFile: File): Promise<string> => {
    try {
      const zip = new JSZip();
      const zipFile = await zip.loadAsync(kmzFile);
  
      // Recherche automatique du fichier KML dans le KMZ
      const kmlFileName = Object.keys(zipFile.files).find((fileName) => fileName.toLowerCase().endsWith('.kml'));
      if (!kmlFileName) {
        throw new Error('Fichier KML introuvable dans l\'archive KMZ');
      }
  
      const kmlFile = zipFile.file(kmlFileName);
      if (!kmlFile) {
        throw new Error('Fichier KML introuvable dans l\'archive KMZ');
      }
  
      const kmlContent = await kmlFile.async('text');
      return kmlContent;
    } catch (error) {
      console.error('Erreur lors de l\'extraction du KML depuis le KMZ :', error);
      throw error;
    }
  };
  
  const handleSelectJourChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const selectedJourValue = parseInt(event.target.value, 10);

    setSelectedJours((prevSelectedJours) => {
      const newSelectedJours = [...prevSelectedJours];
      newSelectedJours[index] = selectedJourValue;
      return newSelectedJours;
    });
  };
  const getEventTypeLabel = (evenement : EventType) => {
    var libelle="";
    if (evenement.typeEv === "L" && evenement.type !== "O") {
      if(evenement.libelle1 === null || evenement.libelle1 === "")
      {
        libelle="vide";
      }
      else
      {
        libelle=": "+evenement.libelle1;
      }
      return "le LIEU "+libelle;
    } else if (evenement.typeEv === "L" && evenement.type === "O") {
      if(evenement.libelle1 === null || evenement.libelle1 === "")
      {
        libelle="vide";
      }
      else
      {
        libelle=": "+evenement.libelle1;
      }
      return "le REPAS "+libelle;
    } else if (evenement.typeEv === "T" && evenement.location !== "O") {
      if(evenement.libelle1 !== null && evenement.libelle1 !== "" && evenement.libelle2 !== null && evenement.libelle2 !== "")
      {
        libelle=": "+evenement.libelle1+" -> "+evenement.libelle2;
      }
      else if((evenement.libelle1 === null || evenement.libelle1 === "") && (evenement.libelle2 === null || evenement.libelle2 === ""))
      {
        libelle="vide";
      }
      else if(evenement.libelle1 === null || evenement.libelle1 === "")
      {
        libelle=": -> "+evenement.libelle2 || "";
      }
      else if(evenement.libelle2 === null || evenement.libelle2 === "")
      {
        libelle=": "+evenement.libelle1+" ->";
      }
      return "le TRAJET "+libelle;
    } else if (evenement.typeEv === "T" && evenement.location === "O") {
      if(evenement.libelle1 === null || evenement.libelle1 === "")
      {
        libelle="vide";
      }
      else
      {
        libelle=evenement.libelle1;
      }
      return "la LOCATION : "+libelle;
    } else if (evenement.typeEv === "N") {
      if(evenement.libelle1 !== null && evenement.libelle1 !== "" && evenement.libelle2 !== null && evenement.libelle2 !== "")
      {
        libelle=": "+evenement.libelle1+" - "+evenement.libelle2;
      }
      else if((evenement.libelle1 === null || evenement.libelle1 === "") && (evenement.libelle2 === null || evenement.libelle2 === ""))
      {
        libelle="vide";
      }
      else if(evenement.libelle1 === null || evenement.libelle1 === "")
      {
        libelle=": "+evenement.libelle2 || "";
      }
      else if(evenement.libelle2 === null || evenement.libelle2 === "")
      {
        libelle=": "+evenement.libelle1;
      }
      return "la NUIT "+libelle;
    } else {
      return evenement.typeEv; // Valeur par défaut si aucune condition n'est satisfaite
    }
  };

  const readFileContent = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target) {
          resolve(event.target.result as string);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const parseKML = (
    kmlContent: string
  ): Array<{ name: string; description: string; coordinates: string, ajout: boolean }> => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(kmlContent, "text/xml");

    const placeNodes = xmlDoc.querySelectorAll("Placemark");
    const placesList: Array<{
      name: string;
      description: string;
      coordinates: string;
      ajout: boolean;
    }> = [];

    placeNodes.forEach((placeNode) => {
      const name = placeNode.querySelector("name")?.textContent;
      const description = placeNode.querySelector("description")?.textContent;
      const coordinates = placeNode.querySelector("coordinates")?.textContent;

      if (name && coordinates) {
        placesList.push({ name, description: description || "", coordinates, ajout: false });
      }
    });

    return placesList;
  };

  const handleSelectPositionnementChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const selectedPositionnementId = parseInt(event.target.value, 10);
  
    // Vérifier que le jour est sélectionné et que l'index est valide
    if (
      selectedJours[index] !== undefined &&
      selectedJours[index] !== 0 &&
      selectedJours[index] !== null
    ) {
      const numJour=selectedJours[index]
      if(numJour !== null)
      {
        var resultat = onAddEvenement(
          eventCategories[numJour - 1].id_journee,
          selectedPositionnementId,
          placesList[index].name,
          placesList[index].description,
          placesList[index].coordinates.split(',')[0],
          placesList[index].coordinates.split(',')[1]
        );

        if(resultat === 0)
        {
          placesList[index].ajout=true;
        }
      }
      
    }
  };

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalImport}
        ref={modalRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-2/3">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                    id="headlessui-dialog-title-70"
                  >
                    Import
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={handleCloseModalImport} />
                  </span>
                </div>
                <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="mt-5 w-full text-center text-xs">
                    {t('importezItineraire')}
                  </div>
                  <div className="items-center mt-5 py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8 text-center">
                    <form encType="multipart/form-data">
                      <input
                        name="confirm"
                        type="file"
                        className="cursor-pointer"
                        accept=".kml,.kmz"
                        onChange={importerItineraire}
                      />
                    </form>

                    <div className="text-xxs text-center mt-3">
                      {t('kmlKmzAcceptes')}
                    </div>
                  </div>
                  {importDone && (
                    <div className="items-center py-2.5 space-x-5 lg:space-x-8 text-center">
                      <div className="text-lg text-center mt-3">
                        {t('lieuxImportes')}
                      </div>
                      
                      <div className="grid grid-cols-2 text-left mt-5">
                        {/* Entête du tableau */}
                        <div className="mb-2 font-semibold">{t('lieu')}</div>
                        <div className="mb-2 font-semibold">Action</div>

                        {/* Afficher chaque lieu dans une ligne du tableau */}
                        {placesList.map((place, index) => (
                          <React.Fragment key={index}>
                            <div className="mt-2 py-1 border-b border-gray-300">
                              <span className="text-neutral-6000 dark:text-neutral-400 font-medium"><i className="las la-map-marker couleurRose"></i> {place.name}</span>
                              <br />
                              <span className="text-sm">{place.description}</span>
                            </div>

                            { !place.ajout && (
                              <div className="border-b border-gray-300">
                              <select className="mt-1 mb-1" onChange={(event) => handleSelectJourChange(event, index)}>
                                <option key={0} value ={0}>{t('selectionnezJour')}</option>
                                {[...Array(nbJours)].map((_, jourIndex) => (
                                  <option key={jourIndex + 1} value={jourIndex + 1}>
                                    {t('jour')} {jourIndex + 1}
                                  </option>
                                ))}
                              </select>

                              {/* Afficher le select "positionnement" seulement si un jour est sélectionné pour ce lieu */}
                              {selectedJours[index] !== null && selectedJours[index] !== undefined && selectedJours[index] !== 0 && (
                                <select className="mb-1" onChange={(event) => handleSelectPositionnementChange(event, index)}>
                                  <option>{t('selectionnezPositionnement')}</option>
                                  {eventCategories
                                    .find((journee) => journee.num_jour === selectedJours[index])
                                    ?.evenements.map((evenement) => (
                                      !(evenement.typeEv === "N" && evenement.invisible1 === "O") && (
                                      <option key={evenement.id} value={evenement.id}>
                                        avant {getEventTypeLabel(evenement)}
                                      </option>
                                      )
                                    ))}
                                </select>
                              )}
                            </div>
                            )}
                            { place.ajout && (
                              <div className="border-b border-gray-300">
                                <div className="mt-3 text-center text-sm couleurRose">{t('lieuAjoute')} <i className="las la-check"></i></div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalImport;
