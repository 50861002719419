import React from "react";
import NavigationItem from "./NavigationItem";
import { accountService } from "_services/account.service"
import useTranslatedMenus from 'hooks/useTranslatedMenus';

function Navigation() {
  const isUserLogged = accountService.isLogged();
  const { NAVIGATION_DEMO } = useTranslatedMenus();
  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO.map((item) => (
        (item.name === "Planificateur"
        || item.name === "Planning tool"
            || item.name === "Guides"
            || item.name === "Réservation"
            || item.name === "Book"
            || item.name === "Aide"
            || item.name === "Help"
            || item.name === "Contact"
            || item.name === "À propos"
            || item.name === "About us"
            || item.name === "Blog"
            || (item.name === "Mes voyages" && isUserLogged)
            || (item.name === "My travels" && isUserLogged))
        && (
        <NavigationItem key={item.id} menuItem={item} />)
      ))}
    </ul>
  );
}

export default Navigation;
