import CardGuides from "components/CardGuides/CardGuides";
import HeadingGuides from "components/HeadingGuides/HeadingGuides";
import { TaxonomyType } from "data/types";
import React, { useEffect, useState, useRef } from "react";
import ItemsSlider from "components/ItemsSlider";
import { guidesService } from "_services/guides.service";
import { autocompleteService } from "_services/autocomplete.service";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';


interface SectionGuidesProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const SectionGuides: React.FC<SectionGuidesProps> = ({
  headingCenter = true,
  className = "",
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [listePays, setListePays] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  useEffect(() => {
    let langue="FR";
    if (location.pathname.match(/^\/en.*$/)) {
      langue="EN";
    }
    guidesService
      .getGuides(langue)
      .then((response) => {
        setListePays(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des guides:", error);
      });
  }, []);

  const fetchSearchResults = async () => {
    try {

      const response = await autocompleteService.getPays(value, langue);
      const data = response.data;

      if (data && data.rows) {
        const formattedResults = data.rows.map((row: any) => ({
          code_pays: row.code_pays,
          nom: row.nom,
        }));
        setSearchResults(formattedResults);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des résultats de recherche :", error);
    }
  };

  useEffect(() => {
    if (value) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [value]);

  const renderSearchResults = () => {
    return (
      <>
        {searchResults.map((item: any) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item.code_pays}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.nom}
            </span>
          </span>
        ))}
      </>
    );
  };

  const handleSelectLocation = (item: any) => {
    navigate(`${t('lienGuide')}/${item.code_pays}`);
    setShowPopover(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`nc-SectionGuides relative ${className}`}>
      <HeadingGuides desc={t('infoDestinations')} isCenter={headingCenter}>
        {t('guidesVoyage')}
      </HeadingGuides>
      <div className="relative flex justify-center items-center mb-6">
        <i className="text-2xl las la-search"></i>
        <div className="relative">
          <input
            type="text"
            className="md:ml-2.5 rechercheGuides"
            maxLength={30}
            placeholder={t('chercherDestination')}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            onClick={() => setShowPopover(true)}
            ref={inputRef}
          />

          {showPopover && value && (
            <div
              ref={popoverRef}
              className="absolute z-40 left-0 right-0 mt-2 bg-white dark:bg-neutral-800 py-3 sm:py-6 rounded-3xl shadow-xl"
              style={{ top: inputRef.current?.offsetHeight }}
            >
              {renderSearchResults()}
            </div>
          )}
        </div>
      </div>

      {listePays.map((continent, index) => (
        <div key={index}>
          <h1 className="mb-5 text-xl texteGras">{continent.nom}</h1>
          <ItemsSlider title={continent.continentCode}  barreHaute={false}>
            {continent.pays.map((item: any, i: any) => (
              <CardGuides key={`${continent.code}-${i}`} taxonomy={item} />
            ))}
          </ItemsSlider>
        </div>
      ))}
    </div>
  );
};

export default SectionGuides;
