import React from "react";
import SectionCouvertureGuide from "./SectionCouvertureGuide";
import InformationsPays from "./InformationsPays";
import { GuidePaysDataType } from "data/types";

export interface SectionGuideProps {
  pays?: GuidePaysDataType;
}

const SectionGuide: React.FC<SectionGuideProps> = ({ pays }) => {
  return (
    <div className="nc-SectionGuide relative">
      <div >
        <div className="container px-1 sm:px-4 mb-5 lg:mb-5">
          <SectionCouvertureGuide
            codePays={pays ? pays.alpha2 : ""}
            nomPays={pays ? pays.nom : ""}
            className=""
          />
        </div>
        <div className="relative py-4" id="informations">
          <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
            <InformationsPays codePays={pays ? pays.alpha2 : "" } pays={pays}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGuide;
