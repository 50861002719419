import React, { FC, ReactNode, useState, useEffect, useCallback } from "react";
import HeroSearchFormPlanificateur from "components/HeroSearchFormPlanificateur/HeroSearchFormPlanificateur";
import ModalCreateurs from 'components/ModalCreateurs';
import ButtonCircleSmall from "shared/Button/ButtonCircleSmall";
import { voyageService } from "../../_services/voyage.service";
import { useNavigate } from "react-router-dom";
import AvatarMenu from "shared/AvatarMenu/AvatarMenu";
import Axios from '../../_services/caller.service';
import { accountService } from '_services/account.service';
import { useTranslation } from 'react-i18next';
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';

export interface SectionHeroPlanificateurProps {
  className?: string;
  listingType?: ReactNode;
  rightImage?: string;
  idVoyage: number;
  titre: string;
  identifiant: string;
  username: string;
  avatar: string;
  id_pays: string;
  libelles_pays: string;
  ville: string;
  date_deb_formate: string;
  date_fin_formate: string;
  nb_voyageurs: number;
  couverture: string;
  numDroit: number; 
  isCreateur: boolean;
  monnaie: string;
  visibilite: string;
  etatVoyage:number;
  nbJours?: number;
  verrouillageModif: () => Promise<number>;
}

const SectionHeroPlanificateur: FC<SectionHeroPlanificateurProps> = ({
  className = "",
  listingType,
  rightImage,
  idVoyage,
  titre = "",
  identifiant = "",
  username = "",
  avatar = "",
  id_pays = "",
  libelles_pays = "",
  ville = "",
  date_deb_formate = "",
  date_fin_formate = "",
  nb_voyageurs = 0,
  couverture,
  numDroit,
  isCreateur,
  monnaie,
  visibilite,
  etatVoyage,
  nbJours,
  verrouillageModif
}) => {

  const { t } = useTranslation();

  const [editedTitre, setEditedTitre] = useState<string>(titre);
  const [editedCouverture, setEditedCouverture] = useState("");
  const [editedCouvertureLien, setEditedCouvertureLien] = useState("");
  const [imagesAvatars, setImagesAvatars] = useState<string[]>([]);
  const [isModalCreateursOpen, setIsModalCreateursOpen] = useState(false);
  const [editedIdentifiant, setEditedIdentifiant] = useState("");
  const [editedUsername, setEditedUsername] = useState("");

  let navigate=useNavigate();
  
  useEffect(() => {
    setEditedIdentifiant(identifiant);
    setEditedUsername(username);
  }, []);

  useEffect(() => {
   

    let imageURL: string | undefined;
  
     if(avatar && avatar !== "")
     {

      const avatarList = avatar.split(";").map(av => av.trim());

      avatarList.forEach((av: any, index: number) => {
          Axios.get(av, { responseType: 'blob' })
              .then((response) => {
                  const blob = new Blob([response.data], { type: 'image/jpeg' });
                  const imageURL = URL.createObjectURL(blob);
                  
                  setImagesAvatars(prevImages => {
                    // Clone the array to avoid directly mutating state
                    const newImages = [...prevImages];
                    // Update the correct index with the new image URL
                    newImages[index] = imageURL;
                    return newImages;
                  });
              })
              .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
            });
     }
  
     return () => {
       if (imageURL) {
         URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
       }
     };
     
   }, [avatar]);

   const handleUsernameClick = (e: React.MouseEvent<HTMLSpanElement>, index: number) => {

      e.preventDefault();
      e.stopPropagation();
    
     // Ouvrir le lien dans un nouvel onglet
    if (editedIdentifiant) { 
      if (typeof editedIdentifiant === 'string') {
        const identifiants = editedIdentifiant.split(';');
        const selectedIdentifiant = identifiants[index]?.trim();
        if (selectedIdentifiant) {
          window.open(`/voyageur/${selectedIdentifiant}`, '_blank');
        }
      } else {
        window.open(`/voyageur/${editedIdentifiant}`, '_blank');
      }
      
    }
    };

  useEffect(() => {
    
    setEditedCouvertureLien(couverture || "");

    let imageURL: string | undefined;

    if(couverture !== "")
    {

      Axios.get(couverture, { responseType: 'blob' })
      .then((response) => {

        const blob = new Blob([response.data], { type: 'image/jpeg' });
        imageURL = URL.createObjectURL(blob);
        setEditedCouverture(imageURL);

      })
      .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
    }
    else
    {
      setEditedCouverture("");
    }
    
    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
      }
    };
    
  }, [couverture]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = fileInput.files?.[0];
  
    let imageURL: string | undefined;

    if (file) {
      // Check file type
      if (!/\/(jpeg|jpg|png)$/.test(file.type)) {
        alert("Veuillez sélectionner un fichier JPEG, JPG ou PNG.");
        fileInput.value = ""; // Clear the file input
        return;
      }
  
      // Check file size
      const maxSize = 7 * 1024 * 1024; // 7MB
      if (file.size > maxSize) {
        alert("La taille du fichier ne doit pas dépasser 7 Mo.");
        fileInput.value = ""; // Clear the file input
        return;
      }
  
      // Check image orientation
      const img = new Image();
      img.onload = async () => {
        if (img.width < img.height) {
          alert("L'image doit être au format paysage !");
          fileInput.value = ""; // Clear the file input
          return;
        }
  
        // Assuming you have some way to get the user's identifiant
        const fileName = `photo_couverture_${idVoyage}.${file.type.split("/")[1]}`;
  
        // Handle file upload to the server or save it locally
        try {
          // Example using the Fetch API to upload the file (modify as needed)
          const formData = new FormData();
          formData.append("couverture", file, fileName);
  
          voyageService.uploadCouverture(idVoyage, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then((response: any) => {

            setEditedCouvertureLien(response.data.couvertureInfo.url);
  
            Axios.get(response.data.couvertureInfo.url, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], { type: 'image/jpeg' });
              imageURL = URL.createObjectURL(blob);
              setEditedCouverture(imageURL);
            })
            .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
  
          })
          .catch((error: any) => {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement de l'image, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            } else {
              alert("Erreur lors de l'enregistrement de l'image");
            }
          });
  
        } catch (error) {
          alert("Erreur lors du téléchargement du fichier.");
        }
      };
  
      // Charger l'image pour vérifier ses dimensions
      img.src = URL.createObjectURL(file);
    }
  
    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
      }
    };
  };

    const handleCouvertureDelete = async () => {

      const fileName = editedCouvertureLien.split("?")[0].split("/fichiers/"+idVoyage+"/photo/")[1];
    
      // Handle file upload to the server or save it locally
      try {
    
        voyageService.supprimerCouverture(idVoyage, fileName)
      .then((response: any) => {

        setEditedCouverture("");
        setEditedCouvertureLien("");
    
     })
      .catch((error: any) => {
        if (error.response?.status === 401) {
          alert("Erreur lors de la suppression de l'image, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de la suppression de l'image");
        }
        console.error('Error maj image:', error);
      });
    
        // Optionally, update the user's profile data with the new avatar filename
        //await saveChanges("avatar", fileName);
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Erreur lors du téléchargement du fichier.");
      }
    };

    const modifierTitre = (valeurTitre: string) =>
    { 
  
      voyageService.modifierVoyage({
          id_voyage: idVoyage,
          titre: valeurTitre,
      })
          .then((response) => {
  
          }) 
          .catch((error) => {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement du titre, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'enregistrement du titre");
            }
          });
    }

    const handleImageClick = (event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (numDroit === 0) {
        event.preventDefault();
        alert("Vous devez être connecté(e) à un compte pour changer la couverture !");
        return;
      }
      document.getElementById("fileInput")?.click();
    };

    const openModalCreateurs = () => {
      if(accountService.isLogged())
      {
        setIsModalCreateursOpen(true);
      }
      else
      {
        alert(t('alertCreateurs'));
      }
    };
  
    const closeModalCreateurs = () => {
      setIsModalCreateursOpen(false);
    };

    const handleDeleteCoCreateur = (id: any) => {

     const identifiants = editedIdentifiant.split(';');
      const usernames = editedUsername.split(';');
      
      // Find the index of the identifiant to delete
      const index = identifiants.indexOf(id.toString());
      
      // Check if the identifiant exists
      if (index !== -1) {
        // Remove the identifiant and corresponding username
        identifiants.splice(index, 1);
        usernames.splice(index, 1);

        var copieImagesAvatars= [...imagesAvatars]; 

        setImagesAvatars(copieImagesAvatars);
        
        // Join the arrays back to strings
        setEditedIdentifiant(identifiants.join(';'));
        setEditedUsername(usernames.join(';'));

      }
     }
  

  return (
    <div
      className={`nc-SectionHeroPlanificateur flex flex-col relative ${className}`}
      data-nc-id="SectionHeroPlanificateur"
    >
      <div className="flex flex-row items-end">
      <div className="flex-shrink-0 xs:w-3/4 sm:w-2/3 flex flex-col items-start space-y-3 sm:space-y-3 sm:pb-3 lg:pb-36 xl:pb-48 xl:pr-14 xl:mr-0">
      <div className="flex items-center" >
      {numDroit !== 0 && (
        <>
          <i className="text-2xl las la-user ml-2.5 mr-2 text-neutral-500 dark:text-neutral-400"></i>
          {editedUsername.split(';').map((name, index) => (
           <span
           key={index}
           onClick={(e) => handleUsernameClick(e, index)}
           data-tooltip-id={`myTooltip${index}`} 
           data-tooltip-content={name}
           data-tooltip-place="top"
           style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
         >
              <AvatarMenu sizeClass="w-9 h-9 sm:w-11 sm:h-11 mr-2" imgUrl={imagesAvatars[index] || ''} />
              <Tooltip id={`myTooltip${index}`} style={{ zIndex: 9999 }}></Tooltip>
            </span>
          ))}
          { numDroit===1 && isCreateur && editedUsername.split(';').length < 5 && (
           <ButtonCircleSmall size="w-9 h-9 sm:w-11 sm:h-11" onClick={openModalCreateurs}><i className="text-2xl las la-user-plus"></i></ButtonCircleSmall>
          )}
        </>
      )}
        {((titre !== null && titre !== "") || (numDroit === 1 || numDroit === 0)) && (
          <i className="text-2xl las la-map-marked ml-2.5 text-neutral-500 dark:text-neutral-400 force-hidden lg:block"></i>
        )}
        {numDroit === 1 || numDroit === 0 ? (
          <input
            type="text"
            className="ml-2.5 titreVoyage hidden lg:block"
            onBlur={(e) => modifierTitre(e.target.value)}
            onChange={(e) => setEditedTitre(e.target.value)}
            value={editedTitre}
            maxLength={40}
            placeholder={t('titreItineraire')}
          />
        ) : (
          <span className="ml-2.5 text-xs md:text-sm hidden lg:block  text-neutral-500 dark:text-neutral-400">{titre}</span>
        )}
        </div>
        <div className="flex items-center text-base md:text-md text-neutral-500 dark:text-neutral-400 block lg:hidden ">
            { ((titre !== null && titre !== "") || (numDroit === 1 || numDroit === 0)) && (
              <i className="text-2xl las la-map-marked ml-2.5"></i>
            )}
            { (numDroit === 1 || numDroit === 0) 
            ? <input type="text" className="ml-2.5 titreVoyage" onBlur={(e) => modifierTitre(e.target.value)} onChange={(e) => {setEditedTitre(e.target.value)} } value={editedTitre} maxLength={40} placeholder={t('titreItineraire')}/>
            :  <span className="ml-2.5 text-xs md:text-sm">{titre}</span>
            }
            
           
          </div>
       
        </div>
        <div className="flex-shrink-0 xs:w-1/4 sm:w-1/3 flex flex-col items-end space-y-8 sm:space-y-10 sm:pb-3 lg:pb-28 xl:pb-40 xl:pr-14 xl:mr-0">
          
          <div className="relative arrondiCouverture overflow-hidden flex">
            <img className="couverture" 
            src={
              editedCouverture && editedCouverture !== ''
              ? editedCouverture
              : editedCouverture === ''
              ? require(`../../images/countries/${id_pays.split("_")[0]}.webp`)
              : ''
            }
            alt="hero" />
            { (numDroit === 1 || numDroit === 0) && (
            <>
            { editedCouverture && editedCouverture !== '' && (
                <div
                    className="absolute top-0 left-0 m-2 cursor-pointer z-10 text-xl texteBlanc"
                    onClick={handleCouvertureDelete}
                  >
                    <i className={`las la-trash`}></i>
                </div>
            )}
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleImageClick}
                    className="cursor-pointer"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xxs md:text-xs lg:md:text-xs xl:md:text-xs cursor-pointer" onClick={handleImageClick}>{t('changerImage')}</span>
                </div>
                </>
            )}
              </div>
              
        </div>
        
        <form encType="multipart/form-data">
                <input
                  id="fileInput"
                  name='couverture'
                  type="file"
                  className="hidden"
                  //className="absolute inset-0 opacity-0 cursor-pointer"
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={handleFileChange}
                />
                </form>
      </div>

      <div className="hidden lg:flow-root w-full">
        <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
          <HeroSearchFormPlanificateur 
          idVoyage={idVoyage}
          titre={editedTitre}
          identifiant={editedIdentifiant || '' }
          username={editedUsername || ''}
          id_pays={id_pays || ''}
          libelles_pays={libelles_pays || ''}
          ville={ville || ''}
          date_deb_formate={date_deb_formate || ''}
          date_fin_formate={date_fin_formate || ''}
          nb_voyageurs={nb_voyageurs || 0 }
          numDroit={numDroit}
          monnaie={monnaie}
          nbJours={nbJours}
          verrouillageModif={verrouillageModif}
          />
        </div>
      </div>
      {
      isModalCreateursOpen && (
      <ModalCreateurs onClose={closeModalCreateurs} keyVoyage={idVoyage} deleteCoCreateur={handleDeleteCoCreateur}/>
    )}
    </div>
    
  );
};

export default SectionHeroPlanificateur;
