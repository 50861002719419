import React, { FC, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import SectionGuides from "components/SectionGuides/SectionGuides";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';

export interface PageGuidesProps {
  className?: string;
}


const PageGuides: FC<PageGuidesProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { setVoyageInfo,  setAfficheMenuMobile } = useContext(AppContext);

  useEffect( () => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []);

 
  return (
    <div
      className={`nc-PageGuides overflow-hidden ${className}`}
      data-nc-id="PageGuides"
    >
      <Helmet>
        <title>{t('guidesVoyage')}</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 mt-10 lg:mt-12 px-10">
        <SectionGuides />
      </div>
</div>
  );
};

export default PageGuides;
