import React, { FC, useState, useRef, useEffect, Fragment } from "react";
import { JourneeType, EventType} from "data/types";
import { useNavigate } from "react-router-dom";
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { Chart } from 'react-google-charts';
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Label from "components/Label/Label";
import CheckboxSmall from "shared/CheckboxSmall/CheckboxSmall";
import Textarea from "shared/Textarea/Textarea";
import { voyageService } from "../../_services/voyage.service";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export interface BudgetPageProps {
  eventCategoriesPage: JourneeType[];
  setEventCategoriesPage: (updatedCategories :any) => void;
  setFraisPage: (index: number, montantFrais: number) => void;
  setEtatFraisPage: (index: number, etatFrais: string) => void;
  setFraisPersoPage: (tabFrais: any[]) => void;
  setInfosFraisPage: (infos: string) => void;
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  monnaie: string;
  nb_jours: number;
  nb_voyageurs: number;
  numDroit: number;
  fraisVisa: number;
  fraisVaccins: number;
  fraisMateriel: number;
  fraisNourriture: number;
  fraisCarburant: number;
  fraisVehicule: number;
  fraisAutre: number;
  infosFrais: string;
  etatPaiementVisa: string;
  etatPaiementVaccins: string;
  etatPaiementMateriel: string;
  etatPaiementNourriture: string;
  etatPaiementCarburant: string;
  etatPaiementVehicule: string;
  etatPaiementAutre: string;
  fraisPerso: any[];
  isModif: boolean;
  onChangeModif: () => void;
  verrouillageModif: () => Promise<number>;
}




const BudgetPage: FC<BudgetPageProps> = ({
  eventCategoriesPage,
  setEventCategoriesPage,
  setFraisPage,
  setEtatFraisPage,
  setInfosFraisPage,
  voyageKey,
  emailPartage,
  codePartage,
  monnaie,
  nb_jours,
  nb_voyageurs,
  numDroit,
  fraisVisa,
  fraisVaccins,
  fraisMateriel,
  fraisNourriture,
  fraisCarburant,
  fraisVehicule,
  fraisAutre,
  infosFrais,
  etatPaiementVisa,
  etatPaiementVaccins,
  etatPaiementMateriel,
  etatPaiementNourriture,
  etatPaiementCarburant,
  etatPaiementVehicule,
  etatPaiementAutre,
  fraisPerso,
  setFraisPersoPage,
  isModif,
  onChangeModif,
  verrouillageModif
}) => {
  const { t } = useTranslation();
  
  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [loading, setLoading] = useState(false);;
  const [tableauDiagramme, setTableauDiagramme] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalFrais, setTotalFrais] = useState<number>(0);
  const [totalDejaPaye, setTotalDejaPaye] = useState<number>(0);
  const [symboleMonnaie, setSymboleMonnaie] = useState<string>("");
  const [fraisVisaEdited, setFraisVisaEdited] = useState<number>(0);
  const [fraisVaccinsEdited, setFraisVaccinsEdited] = useState<number>(0);
  const [fraisMaterielEdited, setFraisMaterielEdited] = useState<number>(0);
  const [fraisNourritureEdited, setFraisNourritureEdited] = useState<number>(0);
  const [fraisCarburantEdited, setFraisCarburantEdited] = useState<number>(0);
  const [fraisVehiculeEdited, setFraisVehiculeEdited] = useState<number>(0);
  const [fraisAutreEdited, setFraisAutreEdited] = useState<number>(0);
  const [etatPaiementVisaEdited, setEtatPaiementVisaEdited] = useState<string>("");
  const [etatPaiementVaccinsEdited, setEtatPaiementVaccinsEdited] = useState<string>("");
  const [etatPaiementMaterielEdited, setEtatPaiementMaterielEdited] = useState<string>("");
  const [etatPaiementNourritureEdited, setEtatPaiementNourritureEdited] = useState<string>("");
  const [etatPaiementCarburantEdited, setEtatPaiementCarburantEdited] = useState<string>("");
  const [etatPaiementVehiculeEdited, setEtatPaiementVehiculeEdited] = useState<string>("");
  const [etatPaiementAutreEdited, setEtatPaiementAutreEdited] = useState<string>("");
  const [fraisPersoEdited, setFraisPersoEdited] = useState<any[]>([]);
  const [infosFraisEdited, setInfosFraisEdited] = useState<string>("")
  const [nouvTypeFraisPerso, setNouvTypeFraisPerso] = useState<string>("")

  let navigate=useNavigate();

  const toggleModif = () => {
    onChangeModif();
  };


  
useEffect(() => {

    if (JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage)) {

      setEventCategories(eventCategoriesPage);

      var totalDejaPayeTemp=0;
      var totalL=0;
      var totalT=0;
      var totalN=0;
      var totalA=0;
      var totalR=0;

      var now = new Date();
      var Onow = new Date(now.getFullYear(),now.getMonth(),now.getDate());
 
      eventCategoriesPage.forEach((journee: JourneeType) => {
       journee.evenements.forEach((evenement: EventType) => {
         if(evenement.typeEv === "L" && evenement.type !== "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalL+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
         }
         else if(evenement.typeEv === "L" && evenement.type === "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalR+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
         }
         else if(evenement.typeEv === "T" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalT+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
         }
         else if(evenement.typeEv === "N" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalN+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
           else if(evenement.paiement === "sur_place")
           {

                const dateJournee = journee.date_journee.split(' ')[1].split('/');
                const OdatePaiementYear = parseInt(dateJournee[2], 10);
                const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
                const OdatePaiementDay = parseInt(dateJournee[0], 10);
                var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);

                if(OdatePaiement < Onow) {
                  totalDejaPayeTemp+=parseFloat(evenement.prix);
                }
           }
           else if(evenement.paiement === "acompte")
           {
            const dateJournee = journee.date_journee.split(' ')[1].split('/');
            const OdatePaiementYear = parseInt(dateJournee[2], 10);
            const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
            const OdatePaiementDay = parseInt(dateJournee[0], 10);
            var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);

            if(OdatePaiement < Onow) {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
            }
            else if(evenement.acompte_hebergement !== null)
            {
              totalDejaPayeTemp+=parseFloat(evenement.acompte_hebergement);
            }
           }
           else if(evenement.paiement === "prelev")
           {
        
              if(evenement.date_paiement_hebergement !== null)
              {
              
                var OdatePaiement = new Date(parseFloat(evenement.date_paiement_hebergement.split("/")[2]),parseFloat(evenement.date_paiement_hebergement.split("/")[1]),parseFloat(evenement.date_paiement_hebergement.split("/")[0]));
                if(OdatePaiement < Onow) {
             
                  totalDejaPayeTemp+=parseFloat(evenement.prix);
                }
              }
           }
         }
       });
      });

    let monnaieSymb="";
   
     switch(monnaie) {
       case 'euro':
         monnaieSymb='€';
         break;
       case 'dollar':
        monnaieSymb='$';
         break;
       case 'pound':
        monnaieSymb='£';
           break;
       case 'yen':
        monnaieSymb='¥';
             break;
       case 'won':
        monnaieSymb='₩';
               break;
       case 'rupee':
        monnaieSymb='₹';
             break;
             case 'indian-rupee':
              monnaieSymb='₹';
                 break;
             case 'baht':
              monnaieSymb='฿';
                 break;
             case 'brazilian-real':
              monnaieSymb='R$';
                 break;
             case 'dong':
              monnaieSymb='₫';
                 break;
             case 'peso':
              monnaieSymb='₱';
                 break;
             case 'ruble':
              monnaieSymb='₽';
                 break;
                 case 'chf':
                  monnaieSymb='CHF';
                     break;
       default:
        monnaieSymb='€';
         
       }

       setSymboleMonnaie(monnaieSymb);

       [[fraisVehicule, etatPaiementVehicule], [fraisCarburant, etatPaiementCarburant]].forEach((frais: any, index) => {
          if(frais[0] >0)
          {
            totalT+=parseFloat(frais[0]);
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
          }
       });
       
       if(fraisNourriture >0)
       {
        totalR+=fraisNourriture;
        if(etatPaiementNourriture === "paye")
        {
          totalDejaPayeTemp += fraisNourriture;
        }
       }
       
       [[fraisVisa, etatPaiementVisa], [fraisVaccins, etatPaiementVaccins], [fraisMateriel, etatPaiementMateriel], [fraisAutre, etatPaiementAutre]].forEach((frais: any, index) => {
        if(frais[0] > 0) {
            totalA += parseFloat(frais[0]); // Concaténation des dépenses au lieu de les additionner
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
        }
      });

      fraisPerso.forEach((frais, index) => {
          if(frais.montant_frais > 0) {
              totalA += parseFloat(frais.montant_frais); // Concaténation des dépenses au lieu de les additionner
              if(frais.etat_paiement_frais === "paye")
              {
                totalDejaPayeTemp += parseFloat(frais.montant_frais);
              }
          }
      });
     
       if(totalL >0 || totalT >0 || totalN >0 || totalA >0 || totalR >0){
     
        const newTableauDiagramme = [
          ['Type', 'Total'],
          [t('activites')+' '+totalL+monnaieSymb, totalL],
          [t('deplacements')+' '+totalT+monnaieSymb, totalT],
          [t('hebergements')+' '+totalN+monnaieSymb, totalN],
          [t('repas')+' '+totalR+monnaieSymb, totalR],
          [t('autresFrais')+' '+totalA+monnaieSymb, totalA]
        ];
        setTableauDiagramme(newTableauDiagramme);

        setTotal(totalL+totalT+totalN+totalR+totalA);
        setTotalDejaPaye(totalDejaPayeTemp);
     }
    }
  }, [eventCategoriesPage]);

  useEffect(() => {
  
        var totalDejaPayeTemp=0;
        var totalL=0;
        var totalT=0;
        var totalN=0;
        var totalA=0;
        var totalR=0;

        var now = new Date();
        var Onow = new Date(now.getFullYear(),now.getMonth(),now.getDate());
   
        eventCategoriesPage.forEach((journee: JourneeType) => {
         journee.evenements.forEach((evenement: EventType) => {
           if(evenement.typeEv === "L" && evenement.type !== "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalL+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
           }
           else if(evenement.typeEv === "L" && evenement.type === "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalR+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
           }
           else if(evenement.typeEv === "T" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalT+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
           }
           else if(evenement.typeEv === "N" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalN+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
             else if(evenement.paiement === "sur_place")
             {

                  const dateJournee = journee.date_journee.split(' ')[1].split('/');
                  const OdatePaiementYear = parseInt(dateJournee[2], 10);
                  const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
                  const OdatePaiementDay = parseInt(dateJournee[0], 10);
                  var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);
  
                  if(OdatePaiement < Onow) {
        
                    totalDejaPayeTemp+=parseFloat(evenement.prix);
                  }
             }
             else if(evenement.paiement === "acompte")
             {

              const dateJournee = journee.date_journee.split(' ')[1].split('/');
              const OdatePaiementYear = parseInt(dateJournee[2], 10);
              const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
              const OdatePaiementDay = parseInt(dateJournee[0], 10);
              var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);
              
  
              if(OdatePaiement < Onow) {
                totalDejaPayeTemp+=parseFloat(evenement.prix);
              }
              else if(evenement.acompte_hebergement !== null)
              {
                totalDejaPayeTemp+=parseFloat(evenement.acompte_hebergement);
              }
             }
             else if(evenement.paiement === "prelev")
             {
        
                if(evenement.date_paiement_hebergement !== null)
                {
 
                  var OdatePaiement = new Date(parseFloat(evenement.date_paiement_hebergement.split("/")[2]),parseFloat(evenement.date_paiement_hebergement.split("/")[1]),parseFloat(evenement.date_paiement_hebergement.split("/")[0]));
                  if(OdatePaiement < Onow) {

                    totalDejaPayeTemp+=parseFloat(evenement.prix);
                  }
                }
             }
           }
         });
        });
  
      let monnaieSymb="";
     
       switch(monnaie) {
         case 'euro':
           monnaieSymb='€';
           break;
         case 'dollar':
          monnaieSymb='$';
           break;
         case 'pound':
          monnaieSymb='£';
             break;
         case 'yen':
          monnaieSymb='¥';
               break;
         case 'won':
          monnaieSymb='₩';
                 break;
         case 'rupee':
          monnaieSymb='₹';
               break;
               case 'indian-rupee':
                monnaieSymb='₹';
                   break;
               case 'baht':
                monnaieSymb='฿';
                   break;
               case 'brazilian-real':
                monnaieSymb='R$';
                   break;
               case 'dong':
                monnaieSymb='₫';
                   break;
               case 'peso':
                monnaieSymb='₱';
                   break;
               case 'ruble':
                monnaieSymb='₽';
                   break;
                   case 'chf':
                    monnaieSymb='CHF';
                       break;
         default:
          monnaieSymb='€';
           
         }
  
         setSymboleMonnaie(monnaieSymb);

         let totalFraisTemp=0;
  
         [[fraisVehiculeEdited, etatPaiementVehiculeEdited], [fraisCarburantEdited, etatPaiementCarburantEdited]].forEach((frais: any, index) => {
          if(frais[0] >0)
          {
            totalT+=parseFloat(frais[0]);
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
            totalFraisTemp+=parseFloat(frais[0]);
          }
       });
       
       if(fraisNourritureEdited >0)
       {
        totalR+=fraisNourritureEdited;
        if(etatPaiementNourritureEdited === "paye")
        {
          totalDejaPayeTemp += fraisNourritureEdited;
        }
        totalFraisTemp+=fraisNourritureEdited;
       }
       
       [[fraisVisaEdited, etatPaiementVisaEdited], [fraisVaccinsEdited, etatPaiementVaccinsEdited], [fraisMaterielEdited, etatPaiementMaterielEdited], [fraisAutreEdited, etatPaiementAutreEdited]].forEach((frais: any, index) => {
        if(frais[0] > 0) {
            totalA += parseFloat(frais[0]); // Concaténation des dépenses au lieu de les additionner
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
            totalFraisTemp+=parseFloat(frais[0]);
        }
      });

      fraisPersoEdited.forEach((frais, index) => {
          if(frais.montant_frais > 0) {
              totalA += parseFloat(frais.montant_frais); // Concaténation des dépenses au lieu de les additionner
              if(frais.etat_paiement_frais === "paye")
              {
                totalDejaPayeTemp += parseFloat(frais.montant_frais);
              }
              totalFraisTemp+=parseFloat(frais.montant_frais);
          }
      });
         setTotalFrais(totalFraisTemp);

         if(totalL >0 || totalT >0 || totalN >0 || totalA >0 || totalR >0){
       
          const newTableauDiagramme = [
            ['Type', 'Total'],
            [t('activites')+' '+totalL+monnaieSymb, totalL],
            [t('deplacements')+' '+totalT+monnaieSymb, totalT],
            [t('hebergements')+' '+totalN+monnaieSymb, totalN],
            [t('repas')+' '+totalR+monnaieSymb, totalR],
            [t('autresFrais')+' '+totalA+monnaieSymb, totalA]
          ];
          setTableauDiagramme(newTableauDiagramme);
  
          setTotal(totalL+totalT+totalN+totalR+totalA);
          setTotalDejaPaye(totalDejaPayeTemp);
      }

}, [fraisVisaEdited, fraisVaccinsEdited, fraisMaterielEdited, fraisNourritureEdited, fraisCarburantEdited, fraisVehiculeEdited, fraisAutreEdited, fraisPersoEdited,
    etatPaiementVisaEdited, etatPaiementVaccinsEdited, etatPaiementMaterielEdited, etatPaiementNourritureEdited, etatPaiementCarburantEdited, etatPaiementVehiculeEdited, etatPaiementAutreEdited]);


  const getFraisLabel = (index: number) => {
    switch (index){
      case 0 :
        return t('visas'); 
        case 1 :
        return t('vaccins'); 
        case 2 :
        return t('materiel'); 
        case 3 :
        return t('vehicule'); 
        case 4 :
        return t('carburant'); 
        case 5 :
        return t('nourriture'); 
        case 6 :
        return t('autres'); 
    }

  }

  useEffect(() => {
    if(fraisVisa !== fraisVisaEdited)
    {
      setFraisVisaEdited(fraisVisa);
    }
    if(fraisVaccins !== fraisVaccinsEdited)
    {
      setFraisVaccinsEdited(fraisVaccins);
    }
    if(fraisMateriel !== fraisMaterielEdited)
    {
      setFraisMaterielEdited(fraisMateriel);
    }
    if(fraisNourriture !== fraisNourritureEdited)
    {
      setFraisNourritureEdited(fraisNourriture);
    }
    if(fraisCarburant !== fraisCarburantEdited)
    {
      setFraisCarburantEdited(fraisCarburant);
    }
    if(fraisVehicule !== fraisVehiculeEdited)
    {
      setFraisVehiculeEdited(fraisVehicule);
    }
    if(fraisAutre !== fraisAutreEdited)
    {
      setFraisAutreEdited(fraisAutre);
    }
    if(fraisPerso !== fraisPersoEdited)
    {
      setFraisPersoEdited(fraisPerso);
    }
    if(etatPaiementVisa !== etatPaiementVisaEdited)
    {
      setEtatPaiementVisaEdited(etatPaiementVisa);
    }
    if(etatPaiementVaccins !== etatPaiementVaccinsEdited)
    {
      setEtatPaiementVaccinsEdited(etatPaiementVaccins);
    }
    if(etatPaiementMateriel !== etatPaiementMaterielEdited)
    {
      setEtatPaiementMaterielEdited(etatPaiementMateriel);
    }
    if(etatPaiementNourriture !== etatPaiementNourritureEdited)
    {
      setEtatPaiementNourritureEdited(etatPaiementNourriture);
    }
    if(etatPaiementCarburant !== etatPaiementCarburantEdited)
    {
      setEtatPaiementCarburantEdited(etatPaiementCarburant);
    }
    if(etatPaiementVehicule !== etatPaiementVehiculeEdited)
    {
      setEtatPaiementVehiculeEdited(etatPaiementVehicule);
    }
    if(etatPaiementAutre !== etatPaiementAutreEdited)
    {
      setEtatPaiementAutreEdited(etatPaiementAutre);
    }
    if(infosFrais !== infosFraisEdited)
    {
      setInfosFraisEdited(infosFrais);
    }

  }, [fraisVisa, fraisVaccins, fraisMateriel, fraisNourriture, fraisCarburant, fraisVehicule, fraisAutre, fraisPerso, infosFrais,
      etatPaiementVisa, etatPaiementVaccins, etatPaiementMateriel, etatPaiementNourriture, etatPaiementCarburant, etatPaiementVehicule, etatPaiementAutre])

  useEffect(() => {
    if (tableauDiagramme.length > 0) {
      setLoading(true);
    }
 
  }, [tableauDiagramme]);

  const handleFraisChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let newValueFrais=0.00;
    try
    {
      if(event.target.value.trim() !== "")
      {
        newValueFrais=Number(event.target.value.trim().replace(",","."));

        if(isNaN(Number(newValueFrais)))
        {
          alert('Cette zone ne peut contenir que des chiffres.');
  
          event.target.focus();
          return false;
        }
        else if(Number(newValueFrais) > 99999999.99)
        {
          alert('Le prix ne peut pas être supérieur à 99999999.99');
          event.target.value='';
          event.target.focus();
          return false;
        }
      }
    }
    catch(error)
    {
      alert('Cette zone ne peut contenir que des chiffres.');

      event.target.focus();
      return false;
    }

    switch (index){
      case 0 :
        setFraisVisaEdited(Number(newValueFrais)); 
        break;
        case 1 :
          setFraisVaccinsEdited(Number(newValueFrais));
          break;
        case 2 :
          setFraisMaterielEdited(Number(newValueFrais));
          break;
        case 3 :
          setFraisVehiculeEdited(Number(newValueFrais));
          break;
        case 4 :
          setFraisCarburantEdited(Number(newValueFrais));
          break;
        case 5 :
          setFraisNourritureEdited(Number(newValueFrais));
          break;
        case 6 :
          setFraisAutreEdited(Number(newValueFrais));
          break;
    } 
    

    //enregistrement de la modification
    voyageService.modifierVoyage({
      id_voyage: voyageKey,
      type_frais: index,
      montant_frais: newValueFrais
    })
    .then((response: any) => {
      setFraisPage(index, newValueFrais);
    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification du frais");
      }
    });
  };

  const handleFraisPersoChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let newValueFrais=0.00;
    
    try {
      if(event.target.value.trim() !== "") {
        newValueFrais=Number(event.target.value.trim().replace(",","."));

        if(isNaN(Number(newValueFrais))) {
          alert('Cette zone ne peut contenir que des chiffres.');

          return false;
        }
        else if(Number(newValueFrais) > 99999999.99)
        {
          alert('Le prix ne peut pas être supérieur à 99999999.99');
          event.target.value='';
          event.target.focus();
          return false;
        }
      }
    }
    catch(error) {
      alert('Cette zone ne peut contenir que des chiffres.');
  
      
      return false;
    }

    setFraisPersoEdited((prevFraisPersoEdited) => {
      // Créez une copie de l'état actuel
      const updatedFraisPersoEdited = [...prevFraisPersoEdited];

      // Trouvez et mettez à jour l'élément correspondant
      const indexToUpdate = updatedFraisPersoEdited.findIndex((frais) => frais.id === id);
      if (indexToUpdate !== -1) {
       updatedFraisPersoEdited[indexToUpdate] = { ...updatedFraisPersoEdited[indexToUpdate], montant_frais: newValueFrais };
      }
    
      setFraisPersoPage(updatedFraisPersoEdited);
      return updatedFraisPersoEdited;
    });

    // Enregistrement de la modification
    voyageService.modifierVoyage({
      id_voyage: voyageKey,
      id_frais: id,
      montant_frais: newValueFrais
    })
    .then((response: any) => {

    })
    .catch((error: any) => {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      } else {
        alert("Erreur lors de l'enregistrement de la modification du frais");
      }
    });
};



  const handleChangeCheckboxFrais = (checked: boolean, index: number) => {

    
    var newValue = "";  
  
    if(checked)
    {
      newValue = "paye"
    }
  
    switch (index){
      case 0 :
          setEtatPaiementVisaEdited(newValue); 
          break;
        case 1 :
          setEtatPaiementVaccinsEdited(newValue);
          break;
        case 2 :
          setEtatPaiementMaterielEdited(newValue);
          break;
        case 3 :
          setEtatPaiementVehiculeEdited(newValue);
          break;
        case 4 :
          setEtatPaiementCarburantEdited(newValue);
          break;
        case 5 :
          setEtatPaiementNourritureEdited(newValue);
          break;
        case 6 :
          setEtatPaiementAutreEdited(newValue);
          break;
    } 
  
    voyageService.modifierVoyage({
      id_voyage: voyageKey,
      type_frais: index,
      etat_frais: newValue
    })
    .then((response: any) => {
      setEtatFraisPage(index, newValue);
    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification du frais");
      }
    });
  };

  const handleChangeCheckboxFraisPerso = (checked: boolean, id: number) => {

    
    var newValue = "";  
  
    if(checked)
    {
      newValue = "paye"
    }
  
    setFraisPersoEdited((prevFraisPersoEdited) => {
      // Créez une copie de l'état actuel
      const updatedFraisPersoEdited = [...prevFraisPersoEdited];

      // Trouvez et mettez à jour l'élément correspondant
      const indexToUpdate = updatedFraisPersoEdited.findIndex((frais) => frais.id === id);
      if (indexToUpdate !== -1) {
       updatedFraisPersoEdited[indexToUpdate] = { ...updatedFraisPersoEdited[indexToUpdate], etat_paiement_frais: newValue };
      }
    
      setFraisPersoPage(updatedFraisPersoEdited);
      return updatedFraisPersoEdited;
    });
    
  
    voyageService.modifierVoyage({
      id_voyage: voyageKey,
      id_frais: id,
      etat_frais: newValue
    })
    .then((response: any) => {

    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification du frais");
      }
    });
  };

  const handleBlurTextarea = (event: React.FocusEvent<HTMLTextAreaElement>) => {

    var newValue = event.target.value;  

      newValue = newValue.replaceAll('#', '%23');
      newValue = newValue.replaceAll('&', '%26');
      newValue = newValue.replaceAll('"', '');

      //enregistrement de la modification
      voyageService.modifierVoyage({
        id_voyage: voyageKey,
        infos_frais: newValue
      })
      .then((response: any) => {
        setInfosFraisPage(newValue);
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification des informations sur les frais");
        }
      });
};

const ajouterFraisPerso = () => {
  const modifVoyage = async () => {
    try {
if(nouvTypeFraisPerso.trim() !== "")
{
  voyageService.ajouterFraisPerso(voyageKey, {
    libelle_frais: nouvTypeFraisPerso.trim()
  })
  .then((response: any) => {
      
      const nouveauFrais = {
        id: response.data.idFrais,
        libelle: nouvTypeFraisPerso.trim(),
        ordre: response.data.ordreFrais,
        montant_frais: 0.00,
        etat_paiement_frais: ""
    };
    let tabFrais = [...fraisPersoEdited];
    tabFrais.push(nouveauFrais);
    setFraisPersoEdited(prevFraisPersoEdited => [...prevFraisPersoEdited, nouveauFrais]);
    setFraisPersoPage(tabFrais);
    setNouvTypeFraisPerso("");
  })
  .catch((error: any) =>
  {
    if (error.response?.status === 401) {
      alert("Erreur lors de l'enregistrement de l'ajout du frais, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de l'enregistrement de l'ajout du frais'");
    }
  });
}
else
{
  alert("Vous devez saisir le nom du nouveau type de frais.");
}
} catch (error) {
  console.error(error);
  alert("Erreur lors de la modification !");
}
};

const processModifVoyage = async () => {

const verrouillageResultat = await verrouillageModif();

if (verrouillageResultat === 1) {
  modifVoyage();
}
};

processModifVoyage();
}

const suppressionFraisPerso = (id: number) => {
  const modifVoyage = async () => {
    try {
    voyageService.supprimerFraisPerso(voyageKey, {
      idSuppr: id
    })
    .then((response: any) => {
      
      const nouveauFraisPersoEdited = fraisPersoEdited.filter(frais => frais.id !== id);
      setFraisPersoEdited(nouveauFraisPersoEdited);
      setFraisPersoPage(nouveauFraisPersoEdited);
    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la suppression du frais, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur de la suppression du frais");
      }
    });
  } catch (error) {
    console.error(error);
    alert("Erreur lors de la modification !");
  }
};

const processModifVoyage = async () => {
  
  const verrouillageResultat = await verrouillageModif();

  if (verrouillageResultat === 1) {
    modifVoyage();
  }
};

processModifVoyage();
  }


  const pieChart = () => {
    if (tableauDiagramme.length === 0) {
      return null; // Ne rend rien si tableauDiagramme est vide
    }  
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div>
          <Chart
            width={'auto'} 
            height={'auto'}
            chartType="PieChart"
            loader={<div>{t('chargementDiagramme')}</div>}
            data={tableauDiagramme}
            options={{
              title: t('repartitionBudget'),
              is3D: false,
              sliceVisibilityThreshold: 0.001,
              titleTextStyle:{fontSize: "18",
                              lineHeight: "2rem",
                              fontWeight: 600 },
              pieSliceTextStyle: {
                color: '#FFFFFF',fontSize: "11"
              },
              legend:{textStyle:{ color: '#040E27'},fontSize: "13",
                      bold: "true"},
              slices: [{color: '#FF4A52'}, {color: '#1EC6B6'}, {color: '#040E27'}, {color: '#FDAE5C'} , {color: '#D58CF5'}]
            }}
            rootProps={{ 'data-testid': '1' }}
          />
        </div>
      </div>
    );
  }
  
  const renderFraisConsult = () => {
    let renderedLines = 0;
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">Frais </h2>
          </div>
        {/*   <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>*/}
          { (numDroit === 1 || numDroit === 0) && (
         <div>
            <div className="flex items-center justify-end text-xs">
              
              {isModif && (
                <span className="ml-1">{t('modeModif')}</span> 
              )}
              {!isModif && (
                <span className="ml-1">{t('modeConsult')}</span>
              )}
              &nbsp;
              &nbsp;
              <button
                onClick={toggleModif}
                className={`w-10 h-6 rounded-full p-1 transition-colors ${isModif ? 'bg-rosetrip-500' : 'bg-gray-300'}`}
              >
                <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isModif ? 'translate-x-full' : ''}`} />
              </button>
            </div>
         </div>
         )}
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">

          {[[fraisVisa, etatPaiementVisa], [fraisVaccins, etatPaiementVaccins], [fraisMateriel, etatPaiementMateriel], [fraisVehicule, etatPaiementVehicule], [fraisCarburant, etatPaiementCarburant], [fraisNourriture, etatPaiementNourriture], [fraisAutre, etatPaiementAutre]].map((frais, index) => (
            frais[0] > 0 && (
              <div
                key={index}
                className={`p-4 ${renderedLines++ % 2 % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""} flex justify-between items-center space-x-4 rounded-lg`}
              >
                {/* Affichage du nom du frais et de sa valeur */}
                <span>{getFraisLabel(index)}</span>
                <span>
                  {frais[0]} {symboleMonnaie}{" "}
                  {(numDroit === 1 || numDroit === 0 || numDroit === 2) && frais[1] === "paye" ? (
                    <span>
                      <i className="las la-check text-green-500" />
                    </span>
                  ) : (
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )}
                </span>         
              </div>
            )
          ))}

          {fraisPerso.map((frais, index) => (
            frais.montant_frais > 0 && (
              <>
                <div
                  key={index + renderedLines}
                  className={`p-4 ${renderedLines++ % 2 % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""} flex justify-between items-center space-x-4 rounded-lg`}
                >
                  {/* Affichage du nom du frais et de sa valeur */}
                  <span>{frais.libelle}</span>
                  <span>
                    {frais.montant_frais} {symboleMonnaie}{" "}
                    {(numDroit === 1 || numDroit === 0 || numDroit === 2) && frais.etat_paiement_frais === "paye" ? (
                      <span>
                        <i className="las la-check text-green-500" />
                      </span>
                    ) : (
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    )}
                  </span>
                </div>
              </>
            )
          ))}

          </div>
        </div>
        <div>
        {infosFraisEdited && infosFraisEdited !== "" && (
            <>
              <h1 className="mt-5 mb-2 texteGras">{t('informationsfrais')}</h1>
              <div className={`p-4 ${renderedLines++ % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""} items-center rounded-lg`}>
                  {infosFraisEdited.split('\n').map((line, index) => (  
                      <div>{line}</div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderFraisModif = () => {
    return (
      <div className="listingSection__wrap [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-2xl font-semibold">{t('frais')} </h2>
          </div>
        {/*   <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>*/}
         { (numDroit === 1  || numDroit === 0) && (
            <div>
            <div className="flex items-center justify-end text-xs">
              
              {isModif && (
                <span className="ml-1">{t('modeModif')}</span> 
              )}
              {!isModif && (
                <span className="ml-1">{t('modeConsult')}</span>
              )}
              &nbsp;
              &nbsp;
              <button
                onClick={toggleModif}
                className={`w-10 h-6 rounded-full p-1 transition-colors ${isModif ? 'bg-rosetrip-500' : 'bg-gray-300'}`}
              >
                <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isModif ? 'translate-x-full' : ''}`} />
              </button>
            </div>
         </div>
         )}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {[[fraisVisaEdited, etatPaiementVisa], [fraisVaccinsEdited, etatPaiementVaccins], [fraisMaterielEdited, etatPaiementMateriel], [fraisVehiculeEdited, etatPaiementVehicule], [fraisCarburantEdited, etatPaiementCarburant], [fraisNourritureEdited, etatPaiementNourriture], [fraisAutreEdited, etatPaiementAutre]].map((frais, index) => (
          ((index !== 3 && index !== 2 && index !== 6) || frais[0] > 0) && (
            <React.Fragment key={index}>
              <div className="fraisSection">
                <Label>{getFraisLabel(index)}</Label>
              </div>
              <div className="flex flex-col mt-1 sm:flex-row sm:justify-between space-y-3 sm:space-y-0 space-x-5 fraisSection">
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">{symboleMonnaie}</span>
                  </div>
                  <Input
                    className="!pl-8 !pr-10"
                    placeholder="0.00"
                    value={frais[0]}
                    onChange={(e) => handleFraisChange(index, e)}
                    maxLength={11}
                  />
                </div>
                <CheckboxSmall
                  className="items-center"
                  label={t('paye?')}
                  name="paye"
                  onChange={(checked) => handleChangeCheckboxFrais(checked, index)} 
                  checked={frais[1] === 'paye'}
                />
              </div>
            </React.Fragment>
          )
        ))}

          <h1 className="texteGras texteNoir mb-5">{t('fraisPersonnalises')}</h1>
          {fraisPersoEdited.map((frais, index) => (
            <>
            <div className="fraisSection">
                  {<Label className="flex items-center">{frais.libelle}&nbsp;&nbsp;<i onClick={() => suppressionFraisPerso(frais.id)} className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i></Label>}
            </div>
            <div className="flex flex-col mt-1 sm:flex-row sm:justify-between space-y-3 sm:space-y-0 space-x-5 fraisSection">
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">{symboleMonnaie}</span>
                </div>
                <Input className="!pl-8 !pr-10" placeholder="0.00" 
                value={frais.montant_frais}
                onChange={(e) => handleFraisPersoChange(frais.id, e)}
                maxLength={11} />
              </div>
              < CheckboxSmall className="items-center" label={t('paye?')} name="paye" 
              onChange={(checked) => handleChangeCheckboxFraisPerso(checked, frais.id)} 
              checked={frais.etat_paiement_frais === 'paye'} />
            </div>
            </>
          ))}
          <div className="flex flex-col mt-5 sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                          <Input
                            className="!h-full"
                            placeholder={t('nouveauTypeFrais')}
                            value={nouvTypeFraisPerso}
                            maxLength={30}
                            onChange={(e) => setNouvTypeFraisPerso(e.target.value)}
                          />  
                          <ButtonPrimary className="flex-shrink-0" onClick={ajouterFraisPerso}>
                              <i className="text-xl las la-plus"></i>
                              <span className="ml-3">Ajouter</span>
                            </ButtonPrimary>  
           </div>

           <h1 className="mt-5 mb-2 texteGras">{t('informationsFrais')}</h1>
          <TextareaAutogrow
          maxLength={200}
          className="mt-1.5 text-xs text-left"
          defaultValue={infosFraisEdited}
          placeholder={"Ajoutez des informations..."}
          onBlur={(event) => handleBlurTextarea(event)}
          //onFocus={(event) => handleFocusTextarea(event)}

        />
      </div>
    );
  };

  const renderTotal = () => {
    return (
      <div className={`listingSection__wrap block ${(isModif || tableauDiagramme.length !== 0  || totalFrais > 0 || (infosFraisEdited && infosFraisEdited !== "") || numDroit === 1 || numDroit === 0) ? "lg:hidden" : "" } [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}>
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-xl font-semibold">
          {Math.round(total/nb_jours/nb_voyageurs)} {symboleMonnaie}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              {t('jourPersonne')}
            </span>
          </span>
        </div>

        {/* FORM */}

        {/* SUM */}
        <div className="flex flex-col space-y-4">
        
        {(numDroit === 1 || numDroit === 0 || numDroit === 2) && (
          <>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300" data-tooltip-id="myTooltipDejaPaye"
            data-tooltip-content={`${(parseFloat(totalDejaPaye.toFixed(2)) / nb_voyageurs).toFixed(2)} ${symboleMonnaie} par personne`}
            data-tooltip-place="top" >
            <Tooltip id="myTooltipDejaPaye" style={{ zIndex: 9999 }}></Tooltip> 
            <span>{t('dejaPaye')}</span>
            <span>{totalDejaPaye.toFixed(2)} {symboleMonnaie}</span> 
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300" data-tooltip-id="myTooltipResteAPayer"
            data-tooltip-content={`${((parseFloat(total.toFixed(2)) - parseFloat(totalDejaPaye.toFixed(2))) / nb_voyageurs).toFixed(2)} ${symboleMonnaie} par personne`}
            data-tooltip-place="top">
              <Tooltip id="myTooltipResteAPayer" style={{ zIndex: 9999 }}></Tooltip> 
            <span>{t('resteAPayer')}</span>
            <span>{(parseFloat(total.toFixed(2)) - parseFloat(totalDejaPaye.toFixed(2))).toFixed(2)} {symboleMonnaie}</span>
          </div>
          </>
        )}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>TOTAL</span>
            <span>{total.toFixed(2)} {symboleMonnaie}</span>
          </div>
          <div className="flex justify-between font-semibold" >
            <span className="text-xs md:text-sm">{t('parPersonne')}</span>
            <span>{(total/nb_voyageurs).toFixed(2)} {symboleMonnaie}</span>
          </div>
        </div>
      </div>
    );
  };


  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] mt-10">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-xl font-semibold">
          {Math.round(total/nb_jours/nb_voyageurs)} {symboleMonnaie}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
            {t('jourPersonne')}
            </span>
          </span>
        </div>

        {/* FORM */}

        {/* SUM */}
        <div className="flex flex-col space-y-4"> 
        {(numDroit === 1 || numDroit === 0 || numDroit === 2) && (
          <>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300" data-tooltip-id="myTooltipDejaPaye"
            data-tooltip-content={`${(parseFloat(totalDejaPaye.toFixed(2)) / nb_voyageurs).toFixed(2)} ${symboleMonnaie} par personne`}
            data-tooltip-place="top">
            <Tooltip id="myTooltipDejaPaye" style={{ zIndex: 9999 }}></Tooltip>
            <span>{t('dejaPaye')}</span>
            <span>{totalDejaPaye.toFixed(2)} {symboleMonnaie}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300" data-tooltip-id="myTooltipResteAPayer"
            data-tooltip-content={`${((parseFloat(total.toFixed(2)) - parseFloat(totalDejaPaye.toFixed(2))) / nb_voyageurs).toFixed(2)} ${symboleMonnaie} par personne`}
            data-tooltip-place="top">
            <Tooltip id="myTooltipResteAPayer" style={{ zIndex: 9999 }}></Tooltip>
            <span>{t('resteAPayer')}</span>
            <span>{(parseFloat(total.toFixed(2)) - parseFloat(totalDejaPaye.toFixed(2))).toFixed(2)} {symboleMonnaie}</span>
          </div>
          </>
        )}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>TOTAL</span>
            <span>{total.toFixed(2)} {symboleMonnaie}</span>
          </div>
          { nb_voyageurs > 1 && (
          <div className="flex justify-between font-semibold items-center" >
            <span className="text-sm">{t('parPersonne')}</span>
            <span>{(total/nb_voyageurs).toFixed(2)} {symboleMonnaie}</span>
          </div>
          )}
        </div>
      </div>
    );
  };

  return (
    
    <DndProvider backend={HTML5Backend}>
   

        <div className="nc-ListingStayDetailPage">

      {/* MAIN     <BackgroundSection /> */}
      <div className=" relative mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        

        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderTotal()}
          {loading && pieChart()}
          {!isModif && (totalFrais > 0 || (infosFraisEdited && infosFraisEdited !== "") || numDroit === 1 || numDroit === 0) && renderFraisConsult()}
          {isModif && renderFraisModif()}
        </div>

        {/* SIDEBAR */}
        {(isModif || tableauDiagramme.length !== 0 || totalFrais > 0 || (infosFraisEdited && infosFraisEdited !== "") || numDroit === 1 || numDroit === 0) && (
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
        )}
      </div>
    </div>
   {/* <MobileFooterSticky />*/}
          
</DndProvider>
);
};

const TextareaAutogrow: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight, 40)}px`; // Ajustez 60 en fonction de vos besoins pour définir une hauteur minimale
    }
  };

  useEffect(() => {
    handleInput();
  }, [props.value, props.defaultValue]); // Utilisation d'un tableau de dépendances vide pour que cela s'exécute une seule fois après le rendu initial

  useEffect(() => {

    if (props.defaultValue === "" && textareaRef.current) {
      textareaRef.current.style.height = '40px'; // Ajustez la hauteur initiale selon vos besoins
    }
  }, [props.defaultValue]);
  
  return <Textarea ref={textareaRef} onInput={handleInput} {...props} />;
};



export default BudgetPage;
