import React, { FC, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { voyageService } from '_services/voyage.service';
import { saveAs } from 'file-saver';
import JSZip from "jszip";
import { useTranslation } from 'react-i18next';


export interface ModalExportProps {
  onExportCloseModal: () => void;
  idVoyage: string;
}

const ModalExport: FC<ModalExportProps> = ({
  onExportCloseModal,
  idVoyage
}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [format, setFormat] = useState<string>("kml");
  const [type, setType] = useState<string>("tous");

  const modalRef = useRef(null);

  const handleCloseModalExport = () => {
      onExportCloseModal();

  };

  const exporterItineraire = async () => {
    try {
      const response = await voyageService.exporterVoyage(idVoyage, format, type);

      // Obtenir la date actuelle
      const currentDate = new Date();

      // Formater la date avec le format "JJMMAAAA_HHMISS"
      var formattedDate = new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(currentDate);

      formattedDate = formattedDate.replace(/[^0-9]/g, ''); 

      if (response.data !== 0) {
        const fileName = `itineraire_tripkygo_${idVoyage}_${formattedDate}.kml`;
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
        if(format === "kml")
        {
          saveAs(blob, fileName);
        }
        else
        {
           // Créer une archive ZIP (KMZ) contenant le fichier KML
          const zip = new JSZip();
          zip.file(fileName, blob);

          // Générer le contenu du fichier ZIP
          const zipContent = await zip.generateAsync({ type: "blob" });

          // Utiliser saveAs pour déclencher le téléchargement du fichier ZIP
          saveAs(zipContent, `itineraire_tripkygo_${idVoyage}_${formattedDate}.kmz`);
        
        } 
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        localStorage.removeItem('xsrfToken');
      }
      console.error("Error fetching data:", error);
      navigate('/');
    }
  }

    return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalExport}
        ref={modalRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Export
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={handleCloseModalExport} />
                    </span>
                  </div>
                  <div className="px-8 text-lg overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 w-full text-center text-xs">
                       {t('exportezItineraire')}
                       </div>
                      <div className="items-center mt-5 py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8">
                         <div>
                            <label className="text-sm font-semibold" htmlFor="">
                              Format
                            </label>
                            <div className="mt-4 grid grid-cols-1 gap-5">
                              <div className="flex items-center">
                                <input
                                  checked={format === "kml"}
                                  id="kml"
                                  name="kml"
                                  type="radio"
                                  onChange={() => setFormat("kml")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="kml"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  KML
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  checked={format === "kmz"}
                                  id="kmz"
                                  name="kmz"
                                  type="radio"
                                  onChange={() => setFormat("kmz")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="kmz"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  KMZ
                                </label>
                              </div>
                          </div>
                          <div className="mt-5">
                            <label className="text-sm font-semibold" htmlFor="">
                            {t('elementsExporter')}
                            </label>
                            <div className="mt-4 grid grid-cols-1 gap-5">
                              <div className="flex items-center">
                                <input
                                  checked={type === "tous"}
                                  id="tous"
                                  name="tous"
                                  type="radio"
                                  onChange={() => setType("tous")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="tous"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t('tousExporter')}
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  checked={type === "lieux"}
                                  id="lieux"
                                  name="lieux"
                                  type="radio"
                                  onChange={() => setType("lieux")}
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                                />
                                <label
                                  htmlFor="lieux"
                                  className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                 {t('seulementExporter')}
                                </label>
                              </div>
                          </div>
                          <div className="mt-6 text-center">
                            <ButtonPrimary onClick={exporterItineraire}>{t('exporter')}</ButtonPrimary>
                          </div>
                        </div>
                        </div>  
                      </div>
                  </div>
                </div>
              </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalExport;
