import ButtonSmall, { ButtonSmallProps } from "shared/Button/ButtonSmall";
import React from "react";

export interface ButtonPrimarySmallProps extends ButtonSmallProps {
  className: string;
}

const ButtonPrimarySmall: React.FC<ButtonPrimarySmallProps> = ({
  className = "",
  ...args
}) => {
  return (
    <ButtonSmall
      className={`ttnc-ButtonPrimarySmall disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimarySmall;
