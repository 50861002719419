import React, { FC,useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface CardMoisProps {
  className?: string;
  taxonomy: any;
  nbPays: number;
  numMois: number;
}

const CardMois: FC<CardMoisProps> = ({
  className = "",
  taxonomy,
  nbPays,
  numMois
}) => {
  const { t } = useTranslation();

  const { count, name, href = "/", thumbnail, paysRef } = taxonomy;

  return (
    <Link
      to={`${t('lienOuPartir')}-${t('en')}-${name.replace("é","e").replace("û","u")}/${numMois}`}
      className={`nc-CardMois flex flex-col ${className}`}
      data-nc-id="CardMois"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-3 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={require(`../../images/countries/${paysRef}.webp`)}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 px-3 truncate">
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {name}
        </h2>
        <span
          className={`block text-xs text-neutral-6000 dark:text-neutral-400`}
        >
          {nbPays} {t('paysS')}
        </span>
      </div>
    </Link>
  );
};

export default CardMois;
