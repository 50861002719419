import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';

export interface SectionHeroHomeProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  btnText: string;
}

const SectionHeroHome: FC<SectionHeroHomeProps> = ({
  className = "",
  rightImg,
  heading,
  btnText,
}) => {
  const { t, i18n } = useTranslation();

  const links = {
    en: {
      whereToGo: '/en/where-to-go',
      countryGuides: '/en/country-guides',
      reservationTool: '/en/reservation',
    },
    fr: {
      whereToGo: '/ou-partir',
      countryGuides: '/guides-pays',
      reservationTool: '/reservation',
    }
  };
  const currentLanguageLinks = links[i18n.language as 'en' | 'fr'];

  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-2xl !leading-tight font-semibold text-neutral-900 md:text-3xl xl:text-4xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
             {t('description1')}
          </span>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          {t('description2')}<a href={currentLanguageLinks.whereToGo} rel="noreferrer" target="_blank"><u>{t('ouPartir')}</u></a>{t('description3')}<u><a href={currentLanguageLinks.countryGuides} rel="noreferrer" target="_blank">{t('guidesPays')}</a></u>{t('description4')}<u><a href={currentLanguageLinks.reservationTool} rel="noreferrer" target="_blank">{t('outilReservation')}</a></u>.
          </span>
          {!!btnText && <ButtonPrimary href={t('lienLogin')}>{btnText}</ButtonPrimary>}
        </div>
        <div className="flex-grow flex justify-center sm:items-center">
          <img className="w-[50%] sm:w-[50%] md:w-[50%] lg:w-full xl:w-full imageAccueil" src={rightImg} alt="" />
        </div>
      </div>
    </div>
    
  );
};

export default SectionHeroHome;
