import React, { Fragment, useState, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useNavigate } from "react-router-dom";
import { compteService } from "_services/compte.service";
import Input from "shared/Input/Input";
import { useTranslation } from 'react-i18next';

export interface ModalReinitMdpSuiteProps {
  onClose: () => void;
  email: string;
  code: string;
}

const ModalReinitMdpSuite: FC<ModalReinitMdpSuiteProps> = ({ onClose, email, code }) => {
  const { t } = useTranslation();

  const [mdp1, setMdp1] = useState<string>("");
  const [mdp2, setMdp2] = useState<string>("");

  let navigate = useNavigate();

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const onChangeMdp1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMdp1(event.target.value);
  }

  const onChangeMdp2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMdp2(event.target.value);
  }

  function validateMdp(mdp: string) {
    var re = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z]).{6,}$/;
    return re.test(mdp);
  }

  const handleEnvoyer = () => {
    if (mdp1 === "" || mdp2 === ""){
      alert(t('alertDoubleMdp'));
    } else if (!validateMdp(mdp1)) {
      alert(t('alertMdpinvalide'));
    } else if (mdp1 !== mdp2) {
      alert(t('alertMdpDifferents'));
    } else {
      compteService
      .saveReinitMdp(email, code, mdp1)
      .then((response: any) => {
        if(response.data.message === 'OK')
        {
            alert(t('alertMdpModifie'));
            closeModal();
        }
        else if (response.data.message === 'EXPIRE')
        {
            alert(t('alertDelaiReinit'));
            navigate(t('lienLogin'));
        }
        else if (response.data.message === 'KO')
        {
            alert(t('alertMailIncorrect'));
            navigate(t('lienLogin'));
        }
        else
        {
           alert(t('alertMdpModifie'));
            navigate(t('lienLogin'));
        }
      })
      .catch((error: any) => {
       
        alert(t('alertErreurReinit'));
        console.error('Error envoi lors de la réinitialisation du mot de passe  :', error);
      });
    }
  };

  return (
    <>
   <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 scale-95"
            >
             <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                    {t('reinitMdp')}
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>

              <div className="px-8 text-neutral-700 dark:text-neutral-300 w-full"> 
                    <div className="mt-5 flex justify-center"> 
                    <label className="block w-full">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t('dejaInscrit')}
                    </span>
                    <Input type="password" className="mt-1"
                      onChange={(e) => onChangeMdp1(e)}
                      value={mdp1}
                      name="password" />
                  </label>
                    </div>
                    <div className="mt-5 flex justify-center w-full"> 
                    <label className="block w-full">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    {t('resaisirNouveauMdp')}
                    </span>
                    <Input type="password" className="mt-1"
                      onChange={(e) => onChangeMdp2(e)}
                      value={mdp2}
                      name="password2" />
                  </label>
                    </div>
                  
                <div className="flex justify-center mt-5 mb-5 "> 
                  <ButtonPrimary onClick={handleEnvoyer}>
                    {t('enregistrer')}
                  </ButtonPrimary>
                </div>
              </div>
            </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>


</>
    
  );
};

export default ModalReinitMdpSuite;
