import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import { useTranslation } from 'react-i18next';

export interface ModalPartageRSProps {
  onClose: () => void;
  keyVoyage: number;
  onIntegrationClick?: () => void;
}

const ModalPartageRS: FC<ModalPartageRSProps> = ({ 
  onClose,
  keyVoyage,
  onIntegrationClick
}) => {
  const { t } = useTranslation();
  const closeModal = () => {
    onClose();
  };

  const partageFacebook = () => {
    const url = `http://www.facebook.com/sharer.php?u=https://tripkygo.com${t('lienPlanificateur')}/${keyVoyage}&t=${t('itineraireVoyage')}&quote=${t('messageRs')}`;
    
    window.open(url, '_blank');

  };

  const partageX = () => {
    const url = `http://twitter.com/share?url=https://tripkygo.com${t('lienPlanificateur')}/${keyVoyage}&text=${t('messageRs')} :`;
    
    window.open(url, '_blank');

  };

  const partageMail = () => {
    const url = `mailto:?subject=${t('messageRs2')}&body=${t('messageRs2')} : https://tripkygo.com${t('lienPlanificateur')}/${keyVoyage}`;
    
    window.open(url, '_blank');

  };

  const partageSms = () => {
    const url = `sms:?body=${t('messageRs2')}&body=${t('messageRs2')} : https://tripkygo.com${t('lienPlanificateur')}/${keyVoyage}`;
    
    window.open(url, '_blank');

  };

  const copierLien = () => {
  const lien = `https://tripkygo.com${t('lienPlanificateur')}/${keyVoyage}`;

  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(lien)
      .then(() => {
        alert(t('lienCopie'));
      })
      .catch((err) => {
        console.error("Erreur lors de la copie du lien :", err);
      });
  } else {
    console.error("L'API du presse-papiers n'est pas disponible.");
  }
};

const handleIntegrationClick = (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  if (onIntegrationClick) {
    onIntegrationClick();
  }
};

  return (
    <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      {t('partage')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        onClick={copierLien}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-link`}
                        ></i>
                        <span>{t('copierLien')}</span>
                      </div>
                      <div
                        onClick={partageFacebook}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl la la-facebook`}
                        ></i>
                        <span>{t('partagerFacebook')}</span>
                      </div>
                      <div
                        onClick={partageX}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl la la-twitter`}
                        ></i>
                        <span>{t('partagerX')}</span>
                      </div>
                      <div
                       onClick={partageMail}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-envelope`}
                        ></i>
                        <span>{t('envoyerParMail')}</span>
                      </div>
                      <div
                       onClick={partageSms}
                       className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                     >
                       <i
                         className={`text-4xl las la-sms`}
                       ></i>
                       <span>{t('envoyerParSms')}</span>
                     </div>
                     <div
                       onClick={handleIntegrationClick}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-code`}
                        ></i>
                        <span>{t('integrerBlog')}</span>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
  );
};

export default ModalPartageRS;
