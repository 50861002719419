import React, { FC, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

export interface NcSelectMonnaieProps {
  className?: string;
  defaultValue?: string;
  min?: number;
  max?: number;
  onChange: (value: string) => void;
  label?: string;
  desc?: string;
}

const NcSelectMonnaie: FC<NcSelectMonnaieProps> = ({
  className = "w-full",
  defaultValue,
  min = 0,
  max,
  onChange,
  label,
  desc,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}
      data-nc-id="NcInputNumber"
    >
      {label && renderLabel()}

      <div
        className={`nc-NcInputNumber flex items-center justify-between w-28`}
      >
        <select className="monnaie"
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={value}
            /*disabled={!isModifiable}*/
            >
            <option value="euro">€</option><option value="dollar">$</option><option value="pound">£</option><option value="chf">CHF</option><option value="baht">฿</option><option value="brazilian-real">R$</option><option value="dong">₫</option><option value="peso">₱</option><option value="ruble">₽</option><option value="indian-rupee">₹</option><option value="won">₩</option><option value="yen">¥</option>
            </select>
      </div>
    </div>
  );
};

export default NcSelectMonnaie;
