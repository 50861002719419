import React, { useState } from "react";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { compteService } from "_services/compte.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AccountPass = () => {
  const { t } = useTranslation();

  // Utilisez le state pour gérer les valeurs des champs
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  let navigate=useNavigate();


  // Utilisez le state pour gérer les messages d'erreur
  const [error, setError] = useState("");
  const [updateOK, setUpdateOK] = useState("");

  // Fonction pour gérer le clic sur le bouton
  const handleUpdatePassword = () => {
    setUpdateOK("");

    // Vérifiez si tous les champs sont remplis
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      setError(t('veuillezRemplirChamps'));
      return;
    }

     // Vérifiez si les champs de nouveau mot de passe correspondent
     if (newPassword === oldPassword) {
      setError(t('mdpDifferentAncien'));
      return;
    }

    // Vérifiez si les champs de nouveau mot de passe correspondent
    if (newPassword !== confirmNewPassword) {
      setError(t('nouveauxMdpDifferents'));
      return;
    }

    // Vérifiez si le nouveau mot de passe correspond au pattern
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      setError(
        t('formatMdp')
      );
      return;
    }

    // Réinitialisez les messages d'erreur
    setError("");

      // Envoyer une requête PUT avec les données modifiées
        compteService
        .changeMdp({
          oldPassword,
          newPassword,
        })
        .then((response: any) => {
          setOldPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setUpdateOK(
           response?.data.message
          );
        })
        .catch((error: any) => {
          if (error.response?.status === 401) {
            alert(t('alertEnregMdp'))
            localStorage.removeItem('xsrfToken');
            navigate(t('lienLogin'));
          }
          else if (error.response?.status === 406) {
            if(error.response?.data.message === 'Ancien mot de passe incorrect')
            {
              setError(
                t('ancienMdpIncorrect')
              );
            }
            else
            {
              setError(
                error.response?.data.message
              );
            }
          }
          else
          {
            alert(t('alertEnregMdp'));
          }
          console.error('Error maj paswword:', error);
        });

  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t('changementMdp')}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-xl space-y-6">
            <div>
              <Label>{t('ancienMdp')} {t('messageInscriptionGoogle')}</Label>
              <Input
                type="password"
                className="mt-1.5"
                size={60}
                maxLength={60}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div>
              <Label>{t('nouveauMdp')}</Label>
              <Input
                type="password"
                className="mt-1.5"
                size={60}
                maxLength={60}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div>
              <Label>{t('resaisirNouveauMdp')}</Label>
              <Input
                type="password"
                className="mt-1.5"
                size={60}
                maxLength={60}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
            {/* Affiche les messages d'erreur */}
            {error && <p className="text-red-500">{error}</p>}
            {/* Affiche le message de confirmation */}
            {updateOK && <p className="text-green-500">{updateOK}</p>}
            <div className="pt-2">
              <ButtonPrimary onClick={handleUpdatePassword}>
                {t('majMdp')}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
