import React, { FC, MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface Props {
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  
}

const ButtonSubmit: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Link
      to=""
      type="button"
      className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
      onClick={onClick}
    >

      <span className="font-semibold">{t('creer')}</span>
    </Link>
  );
};

export default ButtonSubmit;