import React, { FC, useEffect, useState } from "react";
import { TaxonomyType } from "data/types";
import CardLieuIntegre from "components/CardLieuIntegre/CardLieuIntegre";
import CardTrajetIntegre from "components/CardTrajetIntegre/CardTrajetIntegre";
import CardNuitIntegre from "components/CardNuitIntegre/CardNuitIntegre";
import ItemsSlider from 'components/ItemsSlider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import { JourneeType, PecType } from "data/types";
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

export interface PlanificateurIntegreProps {
  className?: string;
  itemClassName?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  mode: string;
  eventCategoriesPage: JourneeType[];
  id_pays: string;
  nbJours: number;
  visibilite: string;
  etatVoyage: number;
  nb_voyageurs: number;
  monnaie: string;
  numDroit: number; 
  username: string;
}


const PlanificateurIntegre: FC<PlanificateurIntegreProps> = ({
  categoryCardType = "card3",
  voyageKey,
  emailPartage,
  codePartage,
  mode,
  eventCategoriesPage,
  id_pays,
  nbJours,
  visibilite,
  etatVoyage,
  nb_voyageurs,
  monnaie,
  numDroit,
  username,
}) => {
  
  const { t } = useTranslation();
  
  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [loading, setLoading] = useState(true);

  const [listePec, setListePec] = useState<PecType[]>([]);

  const [journeesLoc, setJourneesLoc] = useState<any[]>([]);
  const [locationTrouve, setLocationTrouve] = useState<boolean>(false);

  let navigate=useNavigate();



  useEffect(() => {
    
    if (JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage)) {
      setEventCategories(eventCategoriesPage);
      setLoading(false); 
    }
  }, [eventCategoriesPage]);
  
    
  useEffect(() => {

    const MajPec = async () => {

    const updatedListePec = eventCategories.flatMap((day) =>
    day.evenements
      .filter(
        (event) =>
          event.location === 'O' &&
          event.moment === 'P'
      )
      .map((event) => ({
        date_journee: day.date_journee,
        id_journee: day.id_journee,
        id_evenement: event.id, 
        type_transport: event.type || "",
        rattachement: event.rattachement || "",
        debut: event.debut || ""
      }))
  );
  setListePec(updatedListePec);

  const listeJourneesLoc = eventCategories.map((day) => ({
    date_journee: day.date_journee,
    id_journee: day.id_journee,
    car: false,
    motorcycle: false,
    bicycle: false,
  }))

  setLocationTrouve(false);

  await updatedListePec.forEach((pec: any, index: number) => {
   
    if(pec.rattachement && pec.rattachement !== "" &&
       pec.type_transport && pec.type_transport !== "")
       {

          var dayDeb = eventCategories.find((day) => day.id_journee === pec.id_journee);

          if(dayDeb)
          {
            var i=dayDeb.num_jour-1;
            var trouve=false;
            var dayFin = null;
            while(i<eventCategories.length && !trouve)
            {
  
              for (let k = 0; !trouve && k < eventCategories[i].evenements.length; k++)
              {
                if(eventCategories[i].evenements[k].typeEv === "T" && eventCategories[i].evenements[k].moment && eventCategories[i].evenements[k].moment === "R" 
                && eventCategories[i].evenements[k].id.toString() === pec.rattachement)
                {
                  dayFin = eventCategories[i];
                  trouve=true;
                }
              }
              i++;
            }

            if(dayFin !== null)
            {
              for (let j = dayDeb.num_jour-1; dayFin && j <= dayFin.num_jour - 1; j++)
              {
                setLocationTrouve(true);
                switch(pec.type_transport) {
                  case 'car':
                    listeJourneesLoc[j].car=true;
                    break;
                    case 'bicycle':
                      listeJourneesLoc[j].bicycle=true;
                      break;
                      case 'motorcycle':
                        listeJourneesLoc[j].motorcycle=true;
                        break;
                }
              }
            }
          }   
       }
  })

  setJourneesLoc(listeJourneesLoc);
  }

  MajPec();
}, [eventCategories]);


if (loading) {
  return <div className="text-center mt-20 mb-20"><h1 className="text-xl">{t('chargementVoyage')}</h1></div>;
}

  return (
    <DndProvider backend={HTML5Backend}>

    <ItemsSlider title="" nomClass="item-slider-container" 
    classDay="day-item-integre" classSlider="item-slider-integre"
    classBarreHaute="-integre">
        
    {eventCategories &&
              eventCategories.map((day, index) => (
                <div key={index}>
                  <>
                    <div className="text-center mb-2">
                      <h2 className="champJourIntegre">{t('jour')} {day.num_jour}</h2>
                      <h2 className={`champDateIntegre mt-1 ${ locationTrouve ? "mb-0" : "mb-2"}`}>{day.date_journee}</h2>
                      { locationTrouve && (<span>
                        { journeesLoc[index] && journeesLoc[index].car && (
                          <i className={`las la-car text-md text-purple-500`}></i>
                        )}
                        { journeesLoc[index] && journeesLoc[index].motorcycle && (
                          <i className={`las la-motorcycle text-md text-purple-500`}></i>
                        )}
                        { journeesLoc[index] && journeesLoc[index].bicycle && (
                          <i className={`las la-bicycle text-md text-purple-500`}></i>
                        )}
                      </span>
                      )}
                    </div>
                    {day.evenements.map((event, eventIndex) => (

                      <div key={eventIndex} 
                      className={`${ ((event.typeEv === "L" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ))
                        || (event.typeEv === 'T' && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") 
                           || (event.prix !== null && Number(event.prix.replace("","0")) >0 ) || ( event.type !== null && event.type !== "")
                           || ( event.debut !== null && event.debut !== "")  || ( event.fin !== null && event.fin !== "")))
                       || (event.typeEv === 'N' && event.invisible1 !== "O" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 )))
                       ? "py-1" : ""} text-center`}>

                      {(event.typeEv === "L" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) > 0)) ? (
                        <CardLieuIntegre
                          index={`lieu-${event.id}`}
                          key={`lieu-${event.id}`}
                          monnaie={monnaie}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || "",
                            email: "atruelock0@skype.com",
                            avatar: event.image || '',
                            bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                            count: 40,
                            href: "/author",
                            desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                            jobName: "Author Job",
                            libelle2: event.libelle2 || '',
                            prix: event.prix || '',
                            type: event.type || '',
                            paiement: event.paiement || '',
                            lien: event.lien || '',
                            lien_modifie: event.lien_modifie || '',
                            etat_lien: event.etat_lien || '',
                            document: event.document || '',
                            bloc: event.bloc || '',
                            lat: event.lat || '',
                            longi: event.longi || '',
                            place_id: event.place || ''
                          }}
                          numDroit={numDroit}
                        />
                      ) : event.typeEv === 'T' && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") 
                      || (event.prix !== null && Number(event.prix.replace("","0")) > 0) || ( event.type !== null && event.type !== "")
                      || ( event.debut !== null && event.debut !== "")  || ( event.fin !== null && event.fin !== "")) ? (
                        <CardTrajetIntegre
                          index={`trajet-${event.id}`}
                          key={`trajet-${event.id}`}
                          monnaie={monnaie}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || '',
                            email: "atruelock0@skype.com",
                            avatar: event.image || '',
                            bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                            count: 40,
                            href: "/author",
                            desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                            jobName: "Author Job",
                            libelle2: event.libelle2 || '',
                            prix: event.prix || '',
                            type: event.type || '',
                            debut: event.debut || '',
                            fin: event.fin || '',
                            compagnie: event.compagnie || '',
                            ref: event.ref || '',
                            location: event.location || '',
                            distance: event.distance || '',
                            duree: event.duree || '',
                            moment: event.moment || '',
                            paiement: event.paiement || '',
                            lien: event.lien || '',
                            lien_modifie: event.lien_modifie || '',
                            etat_lien: event.etat_lien || '',
                            document: event.document || '',
                            bloc: event.bloc || '',
                            lat: event.lat || '',
                            longi: event.longi || '',
                            lat2: event.lat2 || '',
                            longi2: event.longi2 || ''
                          }}
                          listePecPlanificateur={listePec}
                          numDroit={numDroit}
                        />
                      ) : event.typeEv === 'N' && event.invisible1 !== "O" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) > 0) ? (
                        <CardNuitIntegre
                          index={`nuit-${event.id}`}
                          key={`nuit-${event.id}`}
                          monnaie={monnaie}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || '',
                            email: "atruelock0@skype.com",
                            avatar: event.image || '',
                            bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                            count: 40,
                            href: "/author",
                            desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                            jobName: "Author Job",
                            libelle2: event.libelle2 || '',
                            prix: event.prix || '',
                            paiement: event.paiement || '',
                            date_paiement_hebergement: event.date_paiement_hebergement || '',
                            acompte_hebergement: event.acompte_hebergement || '',
                            lien: event.lien || '',
                            lien_modifie: event.lien_modifie || '',
                            etat_lien: event.etat_lien || '',
                            document: event.document || '',
                            bloc: event.bloc || '',
                            lat: event.lat || '',
                            longi: event.longi || '',
                            lat2: event.lat2 || '',
                            longi2: event.longi2 || '',
                            place_id: event.place || ''
                          }}
                          numDroit={numDroit}
                        />
                      ) : null}
                    </div>
                  

                  ))}
                </>
              </div>
            ))}


  </ItemsSlider>
  </DndProvider>
  );
};

export default PlanificateurIntegre;
