import React, { createContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [voyageInfo, setVoyageInfo] = useState(null);
  const [afficheMenuMobile, setAfficheMenuMobile] = useState(null);
  const [ajoutIncontournable, setAjoutIncontournable] = useState(null);

  return (
    <AppContext.Provider value={{ voyageInfo, setVoyageInfo, afficheMenuMobile, setAfficheMenuMobile, ajoutIncontournable, setAjoutIncontournable }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;