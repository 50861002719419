import React, { FC, useContext, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import AppContext from 'AppContext';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';


export interface ReservationActiviteProps {
  className?: string;
}

const ReservationActivite: FC<ReservationActiviteProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);

  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

  // Fonction pour obtenir la valeur d'un cookie
  const getCookie = (name: string) => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(name))
      ?.split('=')[1];
    return cookieValue || '';
  };

  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(false);
    const cookieValue = getCookie("tripkygoPartenaires");

    if (cookieValue && cookieValue === 'O') {

      setCookiePartenaires(true);
    }
    else
    {
      setCookiePartenaires(false);
    }
    
  }, []);

  const handleInfoClick = () => {
    alert(
      t('infoGetYourGuide')
    );
  };

  return (
    <div className={`nc-ReservationActivite ${className}`} data-nc-id="ReservationActivite">
      <Helmet>
        <title>{t('reservation')}</title>
      </Helmet>
      <CommonLayout>
      <div className="text-center">
           <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6">
              {t('cliquerActivite')} <a href='https://www.getyourguide.fr/?partner_id=SX74NSV' rel="noreferrer" target="_blank"><u>GetYourGuide</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h1>
                           
                {cookiePartenaires ? (
           <iframe
           loading="lazy"
           src={`https://widget.getyourguide.com/default/activities.frame?locale_code=fr-FR&widget=activities&number_of_items=20&partner_id=SX74NSV&website=http://tripkygo.com`}
           frameBorder="0"
           scrolling="yes"
           title="GetYourGuide Widget"
           width="100%"
           height="800px"
         ></iframe>
        
          ) : (
           <InformationCookiesMarketing/>
          
          
          )}
            
        
        </div>
      </CommonLayout>
    </div>
  );
};

export default ReservationActivite;
