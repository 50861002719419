import React, { FC, Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";

export interface TabFiltersGuideProps {
  onSectionChange: (section: number) => void;
  tabSections?: any[];
  presenceItineraires: boolean;
  chargementInfos: boolean;
}


const TabFiltersGuide: FC<TabFiltersGuideProps> = ({ onSectionChange = () => {}, tabSections = [], presenceItineraires, chargementInfos}) => {


  const [isSectionSelected, setIsSectionSelected] = useState(false);

  const rendertabSections = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }
              ${
                isSectionSelected
                  ? "border-primary-500 bg-primary-50 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              }`}
            >
              <span>Sélection</span>
              <i className="las la-angle-down ml-2"></i>
              {isSectionSelected && renderXClearSection(close)}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    {tabSections.map((section: any) => (
                       ((section.numSection !== 15 && section.numSection !== 1) || (chargementInfos && section.numSection === 1) || (presenceItineraires && section.numSection === 15)) && (
                        <div
                        key={section.numSection}
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={() => { 
                          onSectionChange(section.numSection === 0 ? null : section.numSection);
                        setIsSectionSelected(true);
                          close(); // Close the popover after selecting a section
                        }}
                      >
                        {section.nomSection}
                      </div>
                      )
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  

  

  const renderXClearSection = (close: () => void) => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer" 
      onClick={(e) => {
        e.stopPropagation();
        onSectionChange(0);
        setIsSectionSelected(false);
       close();
      }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {rendertabSections()}
      </div>
      <div className="flex lg:hidden space-x-4">
        {rendertabSections()}
      </div>
    </div>
  );
};

export default TabFiltersGuide;
