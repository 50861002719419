import React, { FC, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { useTranslation } from 'react-i18next';

export interface ModalLieuxResumeProps {
  onLieuxResumeCloseModal: () => void;
  lieux: string[];
}

const ModalLieuxResume: FC<ModalLieuxResumeProps> = ({
  onLieuxResumeCloseModal,
  lieux
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);

  const handleCloseModalLieuxResume = () => {
    onLieuxResumeCloseModal();
  };
    return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalLieuxResume}
        ref={modalRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      {t('lieuxVisites')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={handleCloseModalLieuxResume} />
                    </span>
                  </div>
                  <div className="px-8 text-lg overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mb-4 pt-5 w-full text-left text-base prose dark:prose-invert dark:prose-dark">
                        <ul>
                          {lieux.map((lieu, index) => (
                            <li key={index}>{lieu}</li>
                          ))}
                        </ul>
                      </div>
                  </div>
                </div>
              </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalLieuxResume;
