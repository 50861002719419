import { Tab } from "@headlessui/react";
import VoyageCard from "components/VoyageCard/VoyageCard";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { compteService } from "_services/compte.service";
import { VoyageDataType } from "data/types";
import 'react-tooltip/dist/react-tooltip.css'
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';

export interface VoyagesPartagesProps {
  className?: string;
}

const VoyagesPartages: FC<VoyagesPartagesProps> = ({ className = "" }) => {
  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  const [voyageurData, setVoyageurData] = useState<any>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);

         // Appeler l'API pour obtenir les voyages
        compteService.getVoyages()
        .then((response) => {

            setVoyageurData(response.data);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des données:", error);
          navigate('/');
        });
      
  }, []);

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cf.bstatic.com/static/affiliate_base/js/flexiproduct.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        <div>
          <h2 className="text-2xl font-semibold">{t('titreVoyagesPartages')}</h2>
          
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-2 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {voyageurData && voyageurData.voyagesPartages && voyageurData.voyagesPartages.length > 0 ? (
                voyageurData.voyagesPartages.map((voyage : VoyageDataType) => (
                  <VoyageCard key={voyage.id_voyage} voyageData={voyage} voyageKey={voyage.id_voyage} isPartage={true} 
                  partageConfirmations={voyage.partage_confirmations_reservation}
                  partagePrix={voyage.partage_prix}
                  partageBlocNotes={voyage.partage_bloc_notes}
                  />
                ))
              ) : null }
              <span className="block text-neutral-500 dark:text-neutral-400">
          {voyageurData && voyageurData.voyagesPartages && voyageurData.voyagesPartages.length === 0
          ? t('aucunVoyagePartage')
          : "" }
          </span>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };



  return (
    <div className={`nc-VoyagesPartages ${className}`} data-nc-id="VoyagesPartages">
      <Helmet>
        <title>Voyageur</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="w-full  space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          <div className=" justify-center mb-4 md:mb-0 hidden md:flex">
            <ins className="bookingaff" data-aid="2423294" data-target_aid="2423294" data-prod="banner" data-width="728" data-height="90" data-lang="fr">
              <iframe 
                src="//www.booking.com/flexiproduct.html?product=banner&amp;w=728&amp;h=90&amp;lang=fr&amp;aid=2423294&amp;target_aid=2423294&amp;tmpl=affiliate_banner&amp;fid=1719069401328&amp;" 
                width="728" 
                height="90" 
                scrolling="no" 
                style={{ order: "none", padding: 0, margin: 0, overflow: "hidden", width: "728px", height: "90px" }}
                marginHeight={0} 
                marginWidth={0} 
                frameBorder={0} 
                allowTransparency={true} 
                id="booking_widget__2423294__1719069401328" 
                data-responsive={false}
              ></iframe>
            </ins>
          </div>
          {voyageurData && voyageurData.voyagesPartages &&
          renderSection2()}
        </div>
      </main>
    </div>
  );
};

export default VoyagesPartages;
