"use client";

import React, { FC, useState } from "react";
import VoyageSearchForm from "./(voyage-search-form)/VoyageSearchForm";


export interface HeroSearchFormPlanificateurProps {
  className?: string;
  idVoyage: number;
  titre: string;
  identifiant: string;
  username: string;
  id_pays: string;
  libelles_pays: string;
  ville: string;
  date_deb_formate: string;
  date_fin_formate: string;
  nb_voyageurs: number;
  numDroit: number;
  monnaie: string;
  nbJours?: number;
  verrouillageModif: () => Promise<number>;
}

const HeroSearchFormPlanificateur: FC<HeroSearchFormPlanificateurProps> = ({
  className = "",
  idVoyage,
  titre = "",
  identifiant = "",
  username = "",
  id_pays = "",
  libelles_pays = "",
  ville= "",
  date_deb_formate = "",
  date_fin_formate = "",
  nb_voyageurs = 0,
  numDroit,
  monnaie,
  nbJours,
  verrouillageModif
}) => {

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      <VoyageSearchForm 
      idVoyage={idVoyage}
      titre={titre || ''}
      identifiant={identifiant || '' }
      username={username || ''}
      id_pays={id_pays || ''}
      libelles_pays={libelles_pays || ''}
      ville={ville || ''}
      date_deb_formate={date_deb_formate || ''}
      date_fin_formate={date_fin_formate || ''}
      nb_voyageurs={nb_voyageurs || 0 }
      numDroit={numDroit}
      monnaie={monnaie}
      nbJours={nbJours}
      verrouillageModif={verrouillageModif}/>
    </div>
  );
};

export default HeroSearchFormPlanificateur;
