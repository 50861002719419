import React from "react";
import iconeOsano from "images/iconeOsano.png";
import iconeMarketing from "images/iconeMarketingOsano.png";
import { useTranslation } from 'react-i18next';

export interface InformationCookiesMarketingProps {

}

export const InformationCookiesMarketing: React.FC<InformationCookiesMarketingProps> = ({

}) => {
  const { t } = useTranslation();

  return (
    <div className="row informationCookies">
    <p className="paragrapheInformationCookies">
      <i className="las la la-warning couleurRose"></i>&nbsp;{t('informationCookies1')}<a href="aide#faq12" target="_blank"><u>{t('informationCookies2')}</u></a>)
      <br/><br/><b>{t('informationCookies3')}</b>{t('informationCookies4')}
      <br/>{t('informationCookies5')}<span><img src={iconeOsano} width="30px" alt="Osano icon" className="imageOsano" />{t('informationCookies6')}</span>
      <br/>{t('informationCookies7')}<span><img src={iconeMarketing} height="20px" alt="Marketing cookies icon" className="imageMarketing" /></span>
      <br/>{t('informationCookies8')}
      <br/>{t('informationCookies9')}
      <br/><br/>{t('informationCookies10')}
      <br/><br/><b>{t('informationCookies11')}</b>
      <br/><br/>
    </p>
  </div>
  );
};
