import DatePicker from "react-datepicker";
import React, { FC, useState, useEffect } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useTranslation } from 'react-i18next';

export interface StayDatesRangeInputProps {
  className?: string;
  onSelectDates?: (dates: [Date | null, Date | null]) => void;
  initDeb?: Date | null;  
  initFin?: Date | null;
  majDeb?: Date | null;  
  majFin?: Date | null;  
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
  onSelectDates,
  initDeb, 
  initFin,
  majDeb,
  majFin
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(
    null
  );
  const [endDate, setEndDate] = useState<Date | null>( null);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if(onSelectDates)
    {
      onSelectDates(dates);
    }
  };

  useEffect(() =>{
    if(initDeb === majDeb && initFin === majFin)
    {
      setStartDate(initDeb || new Date());
      setEndDate(initFin || new Date());
    }
    else
    {
      setStartDate(majDeb || new Date());
      setEndDate(majFin || new Date());
    }
  }, [] );

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {t('quandPartezVous')}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
