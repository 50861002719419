import React, { FC, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { VoyageDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ItemsSlider from "components/ItemsSlider";
import HeaderFilterVoyages from "./HeaderFilterVoyages";
import VoyageCard from "components/VoyageCard/VoyageCard";
import CardVoyageFavori from "components/CardVoyageFavori/CardVoyageFavori";
import { voyageService } from '_services/voyage.service'
import TabFilters from "./TabFilters";
import Lottie from 'react-lottie';
import animationData from '../../shared/loading.json';
import { useTranslation } from 'react-i18next';

export interface SectionGridFeaturePlacesProps {
  stayListings?: VoyageDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  majCompteur?: (compteur: number) => void;
  pays?: string;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "Voyages publics",
  subHeading = "Seuls les itinéraires déclarés publics par leur créateur sont visibles. Certaines informations peuvent être masquées.",
  majCompteur,
  pays
}) => {
  const [stayListings, setStayListings] = useState<VoyageDataType[]>([]);
  const [stayListingsFavoris, setStayListingsFavoris] = useState<VoyageDataType[]>([]);
  const [visibleStayListings, setVisibleStayListings] = useState<VoyageDataType[]>([]);
  const [visibleCount, setVisibleCount] = useState(12);
  const [totalVoyages, setTotalVoyages] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [userList, setUserList] = useState<string[]>([]);
  const [uniqueCountries, setUniqueCountries] = useState<string[]>([]);
  const [allCountries, setAllCountries] = useState<string[]>([]);
  const [selectedTri, setSelectedTri] = useState<string | null>(null);
  const [paysLoaded, setPaysLoaded] = useState<boolean>(false);

  const { t } = useTranslation();
  const location = useLocation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const fetchStayListings = async () => {
      try {
        let debutApi = 99;
       /* if(!pays || pays === '')
        {
          debutApi=0;
        }*/
        let langue="FR";
        if (location.pathname.match(/^\/en.*$/)) {
          langue="EN";
        }

        const response = await voyageService.getVoyagesPublics(langue, debutApi);
        const modifiedData = response.data.rows.map((voyage: VoyageDataType) => ({
          ...voyage,
          nb_likes: voyage.nb_likes ?? null,
          visibilite: 'public'
        }));
        setStayListings(modifiedData);

        if(majCompteur)
        {
          majCompteur(response.data.nbVoyages);
        }

        const users: string[] = modifiedData.flatMap((voyage: VoyageDataType) => 
        voyage.username.split(';').map(user => user.trim())
      );
      
      const uniqueUsers: string[] = Array.from(new Set(users)).sort((a, b) => 
        a.toUpperCase().localeCompare(b.toUpperCase())
      );

      setUserList(uniqueUsers);

        const uniqueCountriesSet = new Set<string>();
        modifiedData.forEach((stay: VoyageDataType) => {
          const countries = stay.libelles_pays.split('_');
          countries.forEach((country: string) => uniqueCountriesSet.add(country.trim().replace(/^É/, "E")));
        });
        const uniqueAllCountries = Array.from(uniqueCountriesSet).sort();
        setUniqueCountries(uniqueAllCountries);
        setAllCountries(uniqueAllCountries);

        setTotalVoyages(modifiedData.length);
        setVisibleStayListings(modifiedData.slice(0, visibleCount));

        const modifiedDataCopie = [...modifiedData];
        modifiedDataCopie.sort((a: any, b: any) => {
              return b.nb_likes - a.nb_likes;
        });
      
        setStayListingsFavoris(modifiedDataCopie.slice(0, 20));

       /* if(debutApi === 0)
        {
          debutApi=40;
          const response = await voyageService.getVoyagesPublics('FR', debutApi);
          const modifiedData = response.data.rows.map((voyage: VoyageDataType) => ({
            ...voyage,
            nb_likes: voyage.nb_likes ?? null,
            visibilite: 'public'
          }));
         
          setStayListings(prevStayListings => {
            const updatedStayListings = [...prevStayListings, ...modifiedData];
    
            // Utiliser updatedStayListings pour mettre à jour la liste des utilisateurs
            const users: string[] = updatedStayListings.map((voyage: VoyageDataType) => voyage.username);
            const uniqueUsers: string[] = Array.from(new Set(users)).sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
            setUserList(uniqueUsers);

            const uniqueCountriesSet = new Set<string>();
            updatedStayListings.forEach((stay: VoyageDataType) => {
              const countries = stay.libelles_pays.split('_');
              countries.forEach((country: string) => uniqueCountriesSet.add(country.trim().replace(/^É/, "E")));
            });
            const uniqueAllCountries = Array.from(uniqueCountriesSet).sort();
            setUniqueCountries(uniqueAllCountries);
            setAllCountries(uniqueAllCountries);

            setTotalVoyages(modifiedData.length);
            setVisibleStayListings(updatedStayListings.slice(0, visibleCount));

            const modifiedDataCopie = [...updatedStayListings];
            modifiedDataCopie.sort((a: any, b: any) => {
                  return b.nb_likes - a.nb_likes;
            });
          
            setStayListingsFavoris(modifiedDataCopie.slice(0, 20));
    
            return updatedStayListings;
          });

        }*/
      } catch (error: any) {
        console.error("Erreur lors de la récupération des données :", error);

        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
        }
        console.error("Error fetching data:", error);
      }
    };

    fetchStayListings();
  }, []);

  useEffect(() => {
    let filteredStayListings = stayListings;
  
    if (selectedCountry) {
      filteredStayListings = filteredStayListings.filter((stay) => {
        const countries = stay.libelles_pays.split('_').map(country => country.replace(/^É/, "E"));
        return countries.includes(selectedCountry);
      });
  
      // Fonction pour extraire et nettoyer les usernames
      const extractUsernames = (username: string) => {
        return username
          .split(';')                    // Séparer les usernames par ';'
          .map(user => user.trim())      // Nettoyer les espaces autour de chaque username
          .filter(user => user.length > 0); // Filtrer les usernames vides
      };

      // Extraire, nettoyer, et filtrer les usernames de chaque voyage dans filteredStayListings
      const allUsers = filteredStayListings.flatMap((voyage) => 
        extractUsernames(voyage.username)
      );

      // Suppression des doublons
      const uniqueUsers: string[] = Array.from(new Set(allUsers));

      // Tri des utilisateurs de manière insensible à la casse
      const sortedUsers: string[] = uniqueUsers.sort((a, b) => 
        a.toUpperCase().localeCompare(b.toUpperCase())
      );

      setUserList(sortedUsers); // Met à jour la liste des utilisateurs
    } else {
      // If no country is selected, reset the user list to include all users

      const extractUsernames = (username: string) => {
        return username
          .split(';')                     // Séparer les usernames par ';'
          .map(user => user.trim())       // Nettoyer les espaces autour de chaque username
          .filter(user => user.length > 0); // Filtrer les usernames vides
      };
      
      // Extraire et nettoyer les usernames de chaque voyage
      const allUsers = stayListings.flatMap((voyage) => 
        extractUsernames(voyage.username)
      );
      
      // Suppression des doublons
      const uniqueUsers: string[] = Array.from(new Set(allUsers));
      
      // Tri des utilisateurs de manière insensible à la casse
      const sortedUsers: string[] = uniqueUsers.sort((a, b) => 
        a.toUpperCase().localeCompare(b.toUpperCase())
      );

      setUserList(sortedUsers); // Met à jour la liste des utilisateurs

      //const allUsers = stayListings.map((voyage) => voyage.username);
      //const uniqueUsers: string[] = Array.from(new Set(allUsers)).sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
      //setUserList(uniqueUsers);
    }
  
    if (selectedUser) {
      // Filtrer les stays en vérifiant si selectedUser est dans la liste des usernames séparés par ';'
      filteredStayListings = filteredStayListings.filter((stay) => {
        // Diviser les usernames en utilisant ';' comme délimiteur
        const usernames = stay.username.split(';').map(user => user.trim());
        return usernames.includes(selectedUser);
      });
    
      // Filter les pays en fonction du user sélectionné
      const countriesForSelectedUser = stayListings
        .filter((stay) => {
          // Diviser les usernames et vérifier si selectedUser est inclus
          const usernames = stay.username.split(';').map(user => user.trim());
          return usernames.includes(selectedUser);
        })
        .flatMap((stay) => stay.libelles_pays.split('_'))
        .map((country) => country.trim());
    
      // Obtenir les pays uniques et les trier
      const uniqueCountriesForSelectedUser = Array.from(new Set(countriesForSelectedUser)).sort();
      setUniqueCountries(uniqueCountriesForSelectedUser);
    } else {
      setUniqueCountries(allCountries);
    }

if (selectedTri) {
  filteredStayListings.sort((a, b) => {
    const getDate = (dateString: string): Date => {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day); // Month is 0-indexed
    };

    switch (selectedTri) {
      case "triPubCroi":
        return getDate(b.date_maj_visibilite).getTime() - getDate(a.date_maj_visibilite).getTime();
      case "triPubDecroi":
        return getDate(a.date_maj_visibilite).getTime() - getDate(b.date_maj_visibilite).getTime();
      case "triCreaCroi":
        return getDate(b.date_creation).getTime() - getDate(a.date_creation).getTime();
      case "triCreaDecroi":
        return getDate(a.date_creation).getTime() - getDate(b.date_creation).getTime();
      case "triNbJCroi":
        return b.nb_jours - a.nb_jours;
      case "triNbJDecroi":
        return a.nb_jours - b.nb_jours;
      case "triNbLCroi":
        return b.nb_likes - a.nb_likes;
      case "triNbLDecroi":
        return a.nb_likes - b.nb_likes;
      default:
        return 0;
    }
  });
}
  setVisibleStayListings(filteredStayListings.slice(0, visibleCount));
  setTotalVoyages(filteredStayListings.length);

  const modifiedDataCopie = [...stayListings];
  modifiedDataCopie.sort((a: any, b: any) => {
        return b.nb_likes - a.nb_likes;
  });

  console.log("modifiedDataCopie.slice(0, 20)",modifiedDataCopie.slice(0, 20));
  setStayListingsFavoris(modifiedDataCopie.slice(0, 20));

  if(pays && pays !== '' && !paysLoaded)
  {
    setSelectedCountry(pays);
    setPaysLoaded(true);
  }

}, [selectedCountry, selectedUser, stayListings, visibleCount, selectedTri, allCountries]);

  const loadMore = () => {
    const nextCount = visibleCount +12;
    setVisibleCount(nextCount);
    setVisibleStayListings(stayListings.slice(0, nextCount));
  };

  const handleMajLike = (idVoyage: number) => {
    setStayListings(prevStayListings => {
      const updatedStayListings = prevStayListings.map(stay => {
        if (stay.id_voyage === idVoyage) {
          return {
            ...stay,
            nb_likes: stay.nb_likes + 1,
            liked: 'O'
          };
        }
        return stay;
      });
      return updatedStayListings;
    });
  };
  
  const handleMajDislike = (idVoyage: number) => {
    setStayListings(prevStayListings => {
      const updatedStayListings = prevStayListings.map(stay => {
        if (stay.id_voyage === idVoyage) {
          return {
            ...stay,
            nb_likes: stay.nb_likes - 1,
            liked: ''
          };
        }
        return stay;
      });
      return updatedStayListings;
    });
  };
  

  const renderCard = (stay: VoyageDataType) => {
    return <VoyageCard key={stay.id_voyage} voyageKey={stay.id_voyage} voyageData={stay} 
            onMajLikeHome={handleMajLike}
            onMajDislikeHome={handleMajDislike}/>;
  };

  return (
    <div id="voyagesPublics" className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilterVoyages
        subHeading={subHeading}
        heading={heading}
      />
       {stayListingsFavoris && stayListingsFavoris.length > 0 && (
          <div >
            <h1 className="mb-5 text-xl texteGras">{t('plusAimes')}</h1>
            <ItemsSlider title={"Les itinériares préférés"}  barreHaute={false}>
              {stayListingsFavoris.map((stay, index) => (
                <CardVoyageFavori key={"itinerairefavori-" + index} voyageData={stay} 
                onMajLike={handleMajLike}
                onMajDislike={handleMajDislike}/>
              ))}
            </ItemsSlider>
          </div>
      )}
      <div className="mt-4 mb-8 lg:mb-11">
        <TabFilters
          tabs={uniqueCountries}
          onCountryChange={setSelectedCountry}
          tabsUsers={userList}
          onUserChange={setSelectedUser}
          onTriChange={setSelectedTri}
          pays={pays}
         />
      </div>
      { visibleStayListings && visibleStayListings.length > 0 ? (
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {visibleStayListings.map((stay) => renderCard(stay))}
      </div>
      ) : (
        <div className="text-center mt-20 mb-20">
          <Lottie options={defaultOptions} height={150} width={150} />
          <h1 className="text-lg">{t('chargementVoyagesPublics')}</h1>
        </div>
      )}

      {visibleCount < totalVoyages && (
        <div className="flex mt-16 justify-center items-center">
          <ButtonPrimary loading={false} onClick={loadMore}>
          {t('plusVoyagesPublics')}
          </ButtonPrimary>
        </div>
      )}
    </div>
  );
};

export default SectionGridFeaturePlaces;
