import {apiKeyGeoapify} from '_config/constantes'; 

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit==="K") { dist = dist * 1.609344 }
		if (unit==="N") { dist = dist * 0.8684 }
		return dist;
	}
}

function secondsToHm(d) {

  d = Number(d);

  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);

  var hDisplay = h > 0 ? h + "h" : "0h";
  var mDisplay = m > 9 ? m : "0"+m;
  return hDisplay + mDisplay; 
}

function apiRoute(modeTransport,latitudeDep,longitudeDep,latitiudeArr,longitudeArr) {

    return new Promise(function(resolve, reject) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Origin", "*");

    var raw = JSON.stringify({"mode":modeTransport,"sources":[{"location":[longitudeDep,latitudeDep]},{"location":[longitudeArr,latitiudeArr]}],"targets":[{"location":[longitudeDep,latitudeDep]},{"location":[longitudeArr,latitiudeArr]}]});
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    mode: 'cors'
    };

    fetch("https://api.geoapify.com/v1/routematrix?apiKey="+apiKeyGeoapify, requestOptions)
    .then(response => {
        // check if the call was successful
        if (response.ok) {
          response.json().then(data => resolve(data));
        } else {
          response.json().then(data => reject(data));
        }
      })
    .catch(error => {
       console.log("error fetch routematrix", error);
    });
    });
  }

export const distanceService = {
    distance,
    secondsToHm,
    apiRoute
}