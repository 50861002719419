import React from "react";
import SectionCouvertureStatistiques from "./SectionCouvertureStatistiques";
import Statistiques from "./Statistiques";

export interface SectionStatistiquesProps {
  stats?: any;
}

const SectionStatistiques: React.FC<SectionStatistiquesProps> = ({ stats }) => {

  return (
    <div className="nc-SectionStatistiques relative">
      <div >
        <div className="container px-1 sm:px-4 mb-5 lg:mb-5">
          <SectionCouvertureStatistiques
          />
        </div>
        <div className="relative py-4" id="informations">
          <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
            <Statistiques stats={stats}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionStatistiques;
