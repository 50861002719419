import React, { FC, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import SectionOuPartirMois from "components/SectionOuPartirMois/SectionOuPartirMois";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';

export interface PageOuPartirMoisProps {
  className?: string;
}

const PageOuPartirMois: FC<PageOuPartirMoisProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { numMois } = useParams<{ numMois: string }>();
  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);

  
const tabMois = [
  { 
    name: t('janvier'),
    paysRef: "TH" 
  },
  { 
    name: t('fevrier'),
    paysRef: "PH" 
  },
  { 
    name: t('mars'),
    paysRef: "LK" 
  },
  { 
    name: t('avril'),
    paysRef: "JP" 
  },
  { 
    name: t('mai'),
    paysRef: "MA" 
  },
  { 
    name: t('juin'),
    paysRef: "CV" 
  },
  { 
    name: t('juillet'),
    paysRef: "GR" 
  },
  { 
    name: t('aout'),
    paysRef: "MT" 
  },
  { 
    name: t('septembre'),
    paysRef: "KE" 
  },
  { 
    name: t('octobre'),
    paysRef: "EG" 
  },
  { 
    name: t('novembre'),
    paysRef: "MX" 
  },
  { 
    name: t('decembre'),
    paysRef: "NZ" 
  },
];

  useEffect( () => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []);

  return (
    <div
      className={`nc-PageOuPartirMois overflow-hidden ${className}`}
      data-nc-id="PageOuPartirMois"
    >
      <Helmet>
        <title>{t('ouPartirEn')}{tabMois[Number(numMois) - 1]?.name.charAt(0).toLowerCase() + tabMois[Number(numMois) - 1]?.name.slice(1)} ?</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 mt-10 lg:mt-12 px-10">
        <SectionOuPartirMois idMois={Number(numMois)} nomMois={tabMois[Number(numMois) - 1]?.name.charAt(0).toLowerCase() + tabMois[Number(numMois) - 1]?.name.slice(1)}/>
      </div>
</div>
  );
};

export default PageOuPartirMois;
