import React, { FC, useState, useRef, useEffect } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import MapboxGL from "components/MapboxGL/MapboxGL";
import { JourneeType, EventType } from "data/types";
import CardLieuIntegre from "components/CardLieuIntegre/CardLieuIntegre";
import CardTrajetIntegre from "components/CardTrajetIntegre/CardTrajetIntegre";
import CardNuitIntegre from "components/CardNuitIntegre/CardNuitIntegre";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from 'react-i18next';

export interface SectionCarteIntegreProps {
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  eventCategoriesPage: JourneeType[]; 
  etatVoyage: number;
  id_pays: string;
  numDroit: number;   
  monnaie?: string;
  nb_voyageurs: number;
}

const SectionCarteIntegre: FC<SectionCarteIntegreProps> = ({
  voyageKey,
  emailPartage,
  codePartage,
  eventCategoriesPage,
  etatVoyage,
  id_pays,
  numDroit,
  monnaie,
  nb_voyageurs
}) => {
  
  const { t } = useTranslation();
  
  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [compteurClick, setCompteurClick] = useState<number>(0);
  const [selectedCardID, setSelectedCardID] = useState<string | null>(null);
  const [selectedMarkerID, setSelectedMarkerID] = useState<string | null>(null);
  const [placementCardID, setPlacementCardID] = useState<string | null>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const listContainerRefSmall = useRef<HTMLDivElement>(null);

  const [isEnoughPoints, setIsEnoughPoints] = useState(false);

  const getCardID = (event: EventType): string => {
    let cardID = "";
    if (event.typeEv === "L") {
      cardID = "lieu-" + event.id;
    } else if (event.typeEv === "T") {
      cardID = "trajet-" + event.id;
    } else if (event.typeEv === "N") {
      cardID = "nuit-" + event.id;
    }
    return cardID;
  };

  const cleanMarkerID = (markerID: string): string => {
    if (markerID.startsWith("arrivee-")) {
      return markerID.substring("arrivee-".length);
    } else if (markerID.startsWith("depart-")) {
      return markerID.substring("depart-".length);
    } else if (markerID.startsWith("ville-")) {
      return markerID.substring("ville-".length);
    } else if (markerID.startsWith("hebergement-")) {
      return markerID.substring("hebergement-".length);
    } else {
      return markerID;
    }
  };

  // Fonction pour gérer le clic sur un marqueur et mettre à jour l'ID de la carte sélectionnée
const handleMarkerClick = (markerID: string) => { 
  setSelectedMarkerID(null);
  

    const isScreenLessThanLG = window.matchMedia("(max-width: 992px)").matches;

    if (isScreenLessThanLG) {
      if (markerID && listContainerRefSmall.current) {
        markerID=cleanMarkerID(markerID);
        setSelectedMarkerID(markerID);
       
        const selectedCardElement = listContainerRefSmall.current.querySelector(`#${markerID}-small`);

      if (selectedCardElement  instanceof HTMLElement) {
      const scrollOffset = (listContainerRefSmall.current.offsetWidth - selectedCardElement.offsetWidth) / 2;
      listContainerRefSmall.current.scrollLeft = selectedCardElement.offsetLeft - scrollOffset;

        }
      }
    } else {
     if (markerID && listContainerRef.current) {
        markerID=cleanMarkerID(markerID);
        setSelectedMarkerID(markerID);
        const selectedCardElement = listContainerRef.current.querySelector(`#${markerID}`);

      if (selectedCardElement  instanceof HTMLElement) {
      selectedCardElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }


};

  // Fonction pour mettre à jour l'ID de la carte sélectionnée
  const handleCardClick = (event: EventType, id: string, type?: String) => {
    
    setSelectedCardID(null);
    setSelectedMarkerID(null);
    
    if(((type === undefined || type === null || type === "depart" || type === "ville") && event.lat !== null && event.lat !== '' && event.longi !== null && event.longi !== '')
    || ((type === "arrivee" || type === "hebergement") && event.lat2 !== null && event.lat2 !== '' && event.longi2 !== null && event.longi2 !== ''))
    {
      const newCompteurClick=compteurClick+1; 
        
      if(type)
      {
        setSelectedCardID(type+"-"+id);  
        setCompteurClick(newCompteurClick);  
      }
      else{
        setSelectedCardID(id);        
        setCompteurClick(newCompteurClick);
      }     
    }
    else
    {
      
      alert("Pas de coordonnée GPS enregistrée donc non localisable.")
    }
  };

  const handleDeplacementMarker = async (lat1: any, longi1: any, lat2: any, longi2: any, typeEvenement: string, idEvenement: number, idJournee: number) => {

  }

  const handleEffacePlacement = () => {

  }

  
  useEffect(() => {
    
    const nbPoints = eventCategoriesPage.reduce((count, jour) => {
      return count + jour.evenements.filter((evenement) => 
        (evenement.lat !== null && evenement.lat !== '' &&
        evenement.longi !== null && evenement.longi !== '') ||
        (evenement.lat2 !== null && evenement.lat2 !== '' &&
        evenement.longi2 !== null && evenement.longi2 !== '')
      ).length;
    }, 0);


    if(nbPoints>0)
    {
      setIsEnoughPoints(true);
    }
    else
    {
      setIsEnoughPoints(false);
    }


  }, [eventCategoriesPage]);

  return (
    
    <DndProvider backend={HTML5Backend}>
    <>
    { isEnoughPoints && (
    <div>
      <div className="relative cardsMap md:grid-cols-1 md:grid-gap-1">
  

        {/* MAPPPPP */}
        <div className={`flex-grow static block md:flex-grow md:static md:block lg:flex-grow lg:static lg:block xl:flex-grow xl:static xl:blockfixed inset-0 z-50`}>
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] fixed sticky top-0 top-[88px] md:top-[88px] lg:top-[88px] left-0 w-full h-map-integre lg:h-[calc(100vh-88px)] rounded-md overflow-hidden w-map">
            <MapboxGL
              voyageKey={voyageKey}
              selectedCardID={selectedCardID}
              compteurClick={compteurClick}
              onMarkerClick={handleMarkerClick}
              eventCategories={eventCategoriesPage}
              isModif={false}
              onDeplacementMarker={handleDeplacementMarker}
              placementCardID={placementCardID}
              onEffacePlacement={handleEffacePlacement}
            />
          </div>
        </div>

        <div className={`w-full lg:w-[320px] xl:w-[400px] 2xl:w-[580px] flex-shrink-0 lg:px-8 xl:px-8 md:w-full-map md:block lg:hidden overflow-x-auto md:flex md:flex-wrap pt-8 lg:pt-0`}>
  <div ref={listContainerRefSmall} className="flex gap-2 md:flex md:flex-nowrap overflow-x-auto">
    {eventCategoriesPage &&
      eventCategoriesPage.map((day, index) => {
        let isNewDay = true;
        return day.evenements.map((event, eventIndex) => {

          // Afficher "Jour {day.num_jour}" uniquement lorsqu'il y a un changement de jour
          const dayHeader = isNewDay ? <div className="text-center"><h2 className="champJourIntegre mb-2">{t('jour')} {day.num_jour}</h2>
          <h2 className="champDateIntegre">{day.date_journee}</h2></div> : null;
          isNewDay = false;

          return (
            <>
              {dayHeader}
              <div key={`${day.num_jour}-${eventIndex}-small`} id={`${getCardID(event)}-small`} className="py-1 text-center flex-shrink-0 cardMap-integre"> 
                <div className="">
                {event.typeEv === "L" &&
                      ((event.libelle1 !== null && event.libelle1 !== "") ||
                        (event.libelle2 !== null && event.libelle2 !== "") ||
                        (event.prix !== null && Number(event.prix.replace("", "0"))) > 0) ? ( 
                      <CardLieuIntegre
                        index={`lieu-${event.id}-small`}
                        key={`lieu-${event.id}-small`}
                        monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || "",
                          email: "atruelock0@skype.com",
                          avatar: event.image || "",
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || "",
                          prix: event.prix || "",
                          type: event.type || "",
                          paiement: event.paiement || "",
                          lien: event.lien || "",
                          lien_modifie: event.lien_modifie || "",
                          etat_lien: event.etat_lien || "",
                          document: event.document || "",
                          bloc: event.bloc || "",
                          lat: event.lat || "",
                          longi: event.longi || "",
                          place_id: event.place || "",
                        }}
                        selected={selectedMarkerID === `lieu-${event.id}`}
                        isMap={true}
                        onLocalisationClick={() => handleCardClick(event, getCardID(event))}
                        numDroit={numDroit}
                      />
                    ) : event.typeEv === "T" &&
                      ((event.libelle1 !== null && event.libelle1 !== "") ||
                        (event.libelle2 !== null && event.libelle2 !== "") ||
                        (event.prix !== null && Number(event.prix.replace("", "0")) > 0) ||
                        (event.type !== null && event.type !== "") ||
                        (event.debut !== null && event.debut !== "") ||
                        (event.fin !== null && event.fin !== "")) ? (
                      <CardTrajetIntegre
                        index={`trajet-${event.id}-small`}
                        key={`trajet-${event.id}-small`}
                        monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || "",
                          email: "atruelock0@skype.com",
                          avatar: event.image || "",
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || "",
                          prix: event.prix || "",
                          type: event.type || "",
                          debut: event.debut || "",
                          fin: event.fin || "",
                          compagnie: event.compagnie || "",
                          ref: event.ref || "",
                          location: event.location || "",
                          distance: event.distance || "",
                          duree: event.duree || "",
                          moment: event.moment || "",
                          paiement: event.paiement || "",
                          lien: event.lien || "",
                          lien_modifie: event.lien_modifie || "",
                          etat_lien: event.etat_lien || "",
                          document: event.document || "",
                          bloc: event.bloc || "",
                          lat: event.lat || "",
                          longi: event.longi || "",
                          lat2: event.lat2 || "",
                          longi2: event.longi2 || "",
                        }}
                        selected={selectedMarkerID === `trajet-${event.id}`}
                        isMap={true}
                        onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                        numDroit={numDroit}
                      />
                    ) : event.typeEv === "N" &&
                      event.invisible1 !== "O" &&
                      ((event.libelle1 !== null && event.libelle1 !== "") ||
                        (event.libelle2 !== null && event.libelle2 !== "") ||
                        (event.prix !== null && Number(event.prix.replace("", "0"))) > 0) ? (
                      <CardNuitIntegre
                        index={`nuit-${event.id}-small`}
                        key={`nuit-${event.id}-small`}
                        monnaie={monnaie || 'euro'}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || "",
                          email: "atruelock0@skype.com",
                          avatar: event.image || "",
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || "",
                          prix: event.prix || "",
                          paiement: event.paiement || "",
                          date_paiement_hebergement: event.date_paiement_hebergement || "",
                          acompte_hebergement: event.acompte_hebergement || "",
                          lien: event.lien || "",
                          lien_modifie: event.lien_modifie || "",
                          etat_lien: event.etat_lien || "",
                          document: event.document || "",
                          bloc: event.bloc || "",
                          lat: event.lat || "",
                          longi: event.longi || "",
                          lat2: event.lat2 || "",
                          longi2: event.longi2 || "",
                          place_id: event.place || "",
                        }}
                        selected={selectedMarkerID === `nuit-${event.id}`}
                        isMap={true}
                        onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                        numDroit={numDroit}
                      />
                    ) : null}
                 </div>
              </div>
            </>
          );
        });
      })}
  </div>
</div>

        <div className={`min-h-screen w-full lg:w-[320px] xl:w-[400px] 2xl:w-[400px] flex-shrink-0 lg:px-8 xl:px-8 md:w-full-map hidden lg:block`}>
          
          <div ref={listContainerRef} className="grid grid-cols-1 gap-2 md:grid-cols-none md:grid-rows-1 md:gap-0 md:overflow-x-auto">
            {eventCategoriesPage &&
              eventCategoriesPage.map((day, index) => (
                <>
                  <div key={`jour-${day.num_jour}`} className="text-center">
                    {day.num_jour > 1 && (
                      <hr className="mt-1"></hr>
                    )}
                    <h2 className="champJourIntegre mt-2">{t('jour')} {day.num_jour}</h2>
                    <h2 className="champDateIntegre mt-1 mb-2">{day.date_journee}</h2>
                  </div>

                  {  day.evenements.map((event, eventIndex) => (

                      <div key={`${day.num_jour}-${eventIndex}`} id={getCardID(event)} className="py-1 text-center">

                    {event.typeEv === "L" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ) ? (
                      <CardLieuIntegre
                      index={`lieu-${event.id}`}
                      key={`lieu-${event.id}`}
                      monnaie={monnaie || 'euro'}
                      author={{
                        id: 1,
                        firstName: "Alric",
                        lastName: "Truelock",
                        displayName: event.libelle1 || "",
                                          email: "atruelock0@skype.com",
                                          avatar: event.image || '',
                                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                                          count: 40,
                                          href: "/author",
                                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                                          jobName: "Author Job",
                                          libelle2: event.libelle2 || '',
                                          prix: event.prix || '',
                                          type: event.type || '',
                                          paiement: event.paiement || '',
                                          lien: event.lien || '',
                                          lien_modifie: event.lien_modifie || '',
                                          etat_lien: event.etat_lien || '',
                                          document: event.document || '',
                                          bloc: event.bloc || '',
                                          lat: event.lat || '',
                                          longi: event.longi || '',
                                          place_id: event.place|| ''
                                          
                                        }}
                           
                                        selected={selectedMarkerID === `lieu-${event.id}`}
                                        isMap={true}
                                        onLocalisationClick={() => handleCardClick(event, getCardID(event))}
                                        numDroit={numDroit}
                    
                      />
                    ) : event.typeEv === 'T' && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") 
                        || (event.prix !== null && Number(event.prix.replace("","0")) >0 ) || ( event.type !== null && event.type !== "")
                        || ( event.debut !== null && event.debut !== "")  || ( event.fin !== null && event.fin !== "")) ? (
                    <CardTrajetIntegre
                    index={`trajet-${event.id}`}
                    key={`trajet-${event.id}`}
                    monnaie={monnaie || 'euro'}
                    author={{
                      id: 1,
                      firstName: "Alric",
                      lastName: "Truelock",
                      displayName: event.libelle1 || '',
                      email: "atruelock0@skype.com",
                      avatar: event.image || '',
                      bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                      count: 40,
                      href: "/author",
                      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                      jobName: "Author Job",
                      libelle2: event.libelle2 || '',
                      prix: event.prix || '',
                      type: event.type || '',
                      debut: event.debut || '',
                      fin: event.fin || '',
                      compagnie: event.compagnie || '',
                      ref: event.ref || '',
                      location: event.location || '',
                      distance: event.distance || '',
                      duree: event.duree || '',
                      moment: event.moment || '',
                      paiement: event.paiement || '',
                      lien: event.lien || '',
                      lien_modifie: event.lien_modifie || '',
                      etat_lien: event.etat_lien || '',
                      document: event.document || '',
                      bloc: event.bloc || '',
                      lat: event.lat || '',
                      longi: event.longi || '',
                      lat2: event.lat2 || '',
                      longi2: event.longi2 || ''
                      
                                            
                    }}
                    //listePecPlanificateur={listePec}                 
                    selected={selectedMarkerID === `trajet-${event.id}`}
                    isMap={true}
                    onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                    numDroit={numDroit}
                    />
                    ) : event.typeEv === 'N' && event.invisible1 !== "O" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ) ? (
                    <CardNuitIntegre
                    index={`nuit-${event.id}`}
                    key={`nuit-${event.id}`}
                    monnaie={monnaie || 'euro'}
                    author={{
                      id: 1,
                      firstName: "Alric",
                      lastName: "Truelock",
                      displayName: event.libelle1 || '',
                      email: "atruelock0@skype.com",
                      avatar: event.image || '',
                      bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                      count: 40,
                      href: "/author",
                      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                      jobName: "Author Job",
                      libelle2: event.libelle2 || '',
                      prix: event.prix || '',
                      paiement: event.paiement || '',
                      date_paiement_hebergement: event.date_paiement_hebergement || '',
                      acompte_hebergement: event.acompte_hebergement || '',
                      lien: event.lien || '',
                      lien_modifie: event.lien_modifie || '',
                      etat_lien: event.etat_lien || '',
                      document: event.document || '',
                      bloc: event.bloc || '',
                      lat: event.lat || '',
                      longi: event.longi || '',
                      lat2: event.lat2 || '',
                      longi2: event.longi2 || '',
                      place_id: event.place|| ''
                                            
                    }}          
                    selected={selectedMarkerID === `nuit-${event.id}`}
                    isMap={true}
                    onLocalisationClick={(type) => handleCardClick(event, getCardID(event), type)}
                    numDroit={numDroit}
                    />
                    ) : null }

                    </div>
                  

                  ))
                  }
                  </>
            ))}
</div>

            <div className="flex mt-16 justify-center items-center">
            {/* Pagination */}
          </div>
        </div>
      </div>
    </div>
      )}

    { !isEnoughPoints && (
         <div>
         <div className="relative">
        <p>Il n'y a pas de lieu géolocalisé sur le planning pour afficher la carte.</p>
      </div>
      </div>
    )}
    </>
</DndProvider>
  
  );
};


export default SectionCarteIntegre;
