import React, { FC, Fragment, useContext, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import SelectPaiementHebergement from "shared/SelectPaiementHebergement/SelectPaiementHebergement";
import { GeoapifyType } from "data/types";
import ButtonPrimarySmall from "shared/Button/ButtonPrimarySmall";
import ButtonSquare from "shared/Button/ButtonSquare";
import { guidesService } from "../_services/guides.service";
import { IncontournablesType, WikipediaType } from "data/types";
import AppContext from 'AppContext';
import { useLocation} from "react-router-dom";


export interface ModalIncontournablesProps {
  onIncontournablesCloseModal: () => void;
  codePays?: string;
  isModif?: boolean;
  nbJours?: number;
 }

const ModalIncontournables: FC<ModalIncontournablesProps> = ({
  onIncontournablesCloseModal,
  codePays,
  isModif,
  nbJours
}) => {
  const { setAjoutIncontournable } = useContext(AppContext);
  const [incontournables, setIncontournables] = useState<IncontournablesType[]>([]);
  const [sanitizedHTML, setSanitizedHTML] = useState("");
  const [ajoutLieuIncontournables, setAjoutLieuIncontournables] = useState<boolean>(false);
  const [libelleLieuIncontournable, setLibelleLieuIncontournable] = useState("");
  const [latitudeIncontournable, setLatitudeIncontournable] = useState("");
  const [longitudeIncontournable, setLongitudeIncontournable] = useState("");
  const [placeIdIncontournable, setPlaceIdIncontournable] = useState("");
  const [numJourIncontournable, setNumJourIncontournable] = useState<number>(1);
  const [selectedJour, setSelectedJour] = useState<number>(1);

  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  useEffect(() => {
   if( codePays)
    {
      guidesService.getIncontournables(codePays, langue)
      .then((response: any) => {
        if(response.data.length > 0)
        {
          setIncontournables(response.data);
        }
        else
        {
          handleCloseModalIncontournables();
          alert("Nous n'avons pas d'incontournables à vous proposer pour cette destination.")
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des incontournables:", error);
      });
    }
  }, [])

  const handleCloseModalIncontournables = () => {
    setAjoutIncontournable(null);
    onIncontournablesCloseModal();
  };

  function apiWikiData(idWikidata: string): Promise<GeoapifyType> {
    return new Promise(function(resolve, reject) {
        var url='https://www.wikidata.org/w/api.php?action=wbgetentities&ids='+idWikidata+'&format=json&origin=*';
      fetch(url)
      .then(response => {
        // check if the call was successful
        if (response.ok) {
          response.json().then(data => resolve(data as GeoapifyType));
        } else {
          response.json().then(data => reject(data));
        }
      });
    });
  }

  function affichageIncontournable(libelleInc: any, placeIdInc: any, latitudeInc: any, longitudeInc: any, data: any,idWikidata: any, countryCode: any) {
    setLibelleLieuIncontournable(libelleInc);
    setLatitudeIncontournable(latitudeInc);
    setLongitudeIncontournable(longitudeInc);
    setPlaceIdIncontournable(placeIdInc);

    setAjoutLieuIncontournables(false); 
    var wikiPays="";
    var titreWiki="";
    var langue="";
    var wikiTrouve=false;

    if(idWikidata !== '')
    {

        wikiPays=countryCode+"wiki";
        if(data.entities[idWikidata].sitelinks.frwiki !== undefined && data.entities[idWikidata].sitelinks.frwiki !== null)
        {
            titreWiki=data.entities[idWikidata].sitelinks.frwiki.title;
            langue='fr';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].sitelinks.enwiki !== undefined && data.entities[idWikidata].sitelinks.enwiki !== null)
        {
            titreWiki=data.entities[idWikidata].sitelinks.enwiki.title;
            langue='en';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].sitelinks.eswiki !== undefined && data.entities[idWikidata].sitelinks.eswiki !== null)
        {
            titreWiki=data.entities[idWikidata].sitelinks.eswiki.title;
            langue='es';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].sitelinks[wikiPays] !== undefined && data.entities[idWikidata].sitelinks[wikiPays] !== null)
        {
            titreWiki=data.entities[idWikidata].sitelinks[wikiPays].title;
            langue=countryCode;
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].sitelinks.ptwiki !== undefined && data.entities[idWikidata].sitelinks.ptwiki !== null)
        {
            titreWiki=data.entities[idWikidata].sitelinks.ptwiki.title;
            langue='pt';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].sitelinks.itwiki !== undefined && data.entities[idWikidata].sitelinks.itwiki !== null)
        {
            titreWiki=data.entities[idWikidata].sitelinks.itwiki.title;
            langue='it';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].sitelinks.dewiki !== undefined && data.entities[idWikidata].sitelinks.dewiki !== null)
        {
            titreWiki=data.entities[idWikidata].sitelinks.dewiki.title;
            langue='de';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].labels.fr !== undefined && data.entities[idWikidata].labels.fr !== null)
        {
            titreWiki=data.entities[idWikidata].labels.fr.value;
            langue='fr';
        }
        else if(data.entities[idWikidata].labels.en !== undefined && data.entities[idWikidata].labels.en !== null)
        {
            titreWiki=data.entities[idWikidata].labels.en.value;
            langue='en';
        }
        else if(data.entities[idWikidata].labels[wikiPays] !== undefined && data.entities[idWikidata].labels[wikiPays] !== null)
        {
            titreWiki=data.entities[idWikidata].labels[wikiPays].value;
            langue=countryCode;
        }
        else if(data.entities[idWikidata].labels.es !== undefined && data.entities[idWikidata].labels.es !== null)
        {
            titreWiki=data.entities[idWikidata].labels.es.value;
            langue='es';
        }
        else if(data.entities[idWikidata].labels.pt !== undefined && data.entities[idWikidata].labels.pt !== null)
        {
            titreWiki=data.entities[idWikidata].labels.pt.value;
            langue='pt';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].labels.it !== undefined && data.entities[idWikidata].labels.it !== null)
        {
            titreWiki=data.entities[idWikidata].labels.it.value;
            langue='it';
            wikiTrouve=true;
        }
        else if(data.entities[idWikidata].labels.de !== undefined && data.entities[idWikidata].labels.de !== null)
        {
            titreWiki=data.entities[idWikidata].labels.de.value;
            langue='de';
            wikiTrouve=true;
        }
    }



    if(wikiTrouve)
    {
        apiWikipediaPhoto(langue, titreWiki).then(dataWikipediaPhoto => {
        
            if(dataWikipediaPhoto.query.pages[0].thumbnail !== undefined && dataWikipediaPhoto.query.pages[0].thumbnail !== null)
            {
                var largeur=200;
                if(dataWikipediaPhoto.query.pages[0].thumbnail.height > dataWikipediaPhoto.query.pages[0].thumbnail.width)
                {
                    largeur=100;
                }
                var tabImage=dataWikipediaPhoto.query.pages[0].thumbnail.source.split('/');
                var nomImage="";
                    if(tabImage[tabImage.length-2].length > 4 && tabImage[tabImage.length-1].indexOf(tabImage[tabImage.length-2]))
                    {
                        nomImage=tabImage[tabImage.length-2];
                    }
                    else
                    {
                        nomImage=tabImage[tabImage.length-1];
                    }
                    var creditsImage="https://commons.wikimedia.org/wiki/File:"+nomImage;
                    apiWikipediaPhotoCredits(nomImage).then(dataPhotoCredits => {
                      
                        var credits='';
                        var keys = Object.keys(dataPhotoCredits.query.pages);
                        const pageId = Number(keys[0]);
  
                        if(pageId !== -1 && dataPhotoCredits.query.pages[pageId].imageinfo !== undefined)
                        {
                            credits='';
                            if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.AttributionRequired !== undefined && dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.AttributionRequired.value === 'true')
                            {

                                if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Credit !== undefined)
                                {
                                    credits+="Source : ";
                                    const regex = /, includes:\n<\/p>\n<ul class="gallery mw-gallery-traditional">.*<\/ul>/gis;
                                    const regex2 = /<ul class="gallery mw-gallery-traditional">.*<\/ul>/gis;
                                    credits=dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Credit.value.replace(regex,'</p>');
                                    credits=credits.replace(regex2,'');
                                }
                                if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Artist !== undefined)
                                {
                                    if(credits !== '')
                                    {
                                        credits+=" - "
                                    }
                                    credits+="Auteur : "+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Artist.value+" ";
                                }
                                if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms !== undefined)
                                {
                                    
                                    if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.LicenseUrl !== undefined)
                                    {
                                        if(credits !== '')
                                        {
                                            credits+=" - "
                                        }
                                        credits+="Licence : <a target=_blank href="+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.LicenseUrl.value+">"+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms.value+"</a>";
                                    }
                                    else
                                    {
                                        if(credits !== '')
                                        {
                                            credits+=" - "
                                        }
                                        credits+="Licence : "+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms.value;
                                    }

                                }   
                            }
                            var elementsImage = '<a href='+creditsImage+' target="_blank" title="Crédits photo"><img width='+largeur+' style="padding-bottom:6px;" src='+dataWikipediaPhoto.query.pages[0].thumbnail.source+' /></a><div class="sourcePhotoWiki" style="font-size:5px;line-height:8px;padding-bottom:9px;">'+credits+'</div>';
                            
                            apiWikipedia(langue, titreWiki).then(dataWikipedia => {
                            var contenuWikipedia="";
                            if(dataWikipedia.query.pages[0].extract !== undefined)
                            {
                                contenuWikipedia=dataWikipedia.query.pages[0].extract;
                            }
                            setSanitizedHTML('<h4>'+dataWikipedia.query.pages[0].title+'</h4>'+elementsImage+'<p style="font-size:11px;line-height:15px;">'+contenuWikipedia+' (...) <a style="font-size:11px;line-height:15px;" target="_blank" href="https://'+langue+'.wikipedia.org/wiki/'+titreWiki+'"><u className="pointeurRose">Lire la suite sur Wikipedia</u></a>.</p><p style="font-size:8px;line-height:20px;">Données issues des projets collaboratifs <a style="font-size:8px;line-height:20px;" href="https://www.wikidata.org/wiki/Wikidata:Introduction/fr" target="_blank">Wikidata</a> et <a style="font-size:8px;line-height:20px;" href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia:Accueil_principal" target="_blank">Wikipedia</a>.</p>');
                            });
                        }
                        else
                        {   
                            elementsImage = '<a href='+creditsImage+' target="_blank" title="Crédits photo"><img width='+largeur+' style="padding-bottom:6px;" src='+dataWikipediaPhoto.query.pages[0].thumbnail.source+' /></a>';
                            
                            apiWikipedia(langue, titreWiki).then(dataWikipedia => {
                            var contenuWikipedia="";
                            if(dataWikipedia.query.pages[0].extract !== undefined)
                            {
                                contenuWikipedia=dataWikipedia.query.pages[0].extract;
                            }
                            setSanitizedHTML('<h4>'+dataWikipedia.query.pages[0].title+'</h4>'+elementsImage+'<p style="font-size:11px;line-height:15px;">'+contenuWikipedia+' (...) <a style="font-size:11px;line-height:15px;" target="_blank" href="https://'+langue+'.wikipedia.org/wiki/'+titreWiki+'"><u className="pointeurRose">Lire la suite sur Wikipedia</u></a>.</p><p style="font-size:8px;line-height:20px;">Données issues des projets collaboratifs <a style="font-size:8px;line-height:20px;" href="https://www.wikidata.org/wiki/Wikidata:Introduction/fr" target="_blank">Wikidata</a> et <a style="font-size:8px;line-height:20px;" href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia:Accueil_principal" target="_blank">Wikipedia</a>.</p>');
                            
                            }); 
                        }
                    
                
                    });
            }
            else
            {
                apiWikipedia(langue, titreWiki).then(dataWikipedia => {
                    var contenuWikipedia="";
                    if(dataWikipedia.query.pages[0].extract !== undefined)
                    {
                        contenuWikipedia=dataWikipedia.query.pages[0].extract;
                        setSanitizedHTML('<h4>'+dataWikipedia.query.pages[0].title+'</h4><p style="font-size:11px;line-height:15px;">'+contenuWikipedia+' (...) <a style="font-size:11px;line-height:15px;" target="_blank" href="https://'+langue+'.wikipedia.org/wiki/'+titreWiki+'"><u className="pointeurRose">Lire la suite sur Wikipedia</u></a>.</p><p style="font-size:8px;line-height:20px;">Données issues des projets collaboratifs <a style="font-size:8px;line-height:20px;" href="https://www.wikidata.org/wiki/Wikidata:Introduction/fr" target="_blank">Wikidata</a> et <a style="font-size:8px;line-height:20px;" href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia:Accueil_principal" target="_blank">Wikipedia</a>.</p>');
                    }
                    else
                    {
                        titreWiki=titreWiki.charAt(0).toUpperCase() + titreWiki.substring(1);
                        titreWiki=titreWiki.replace(/_/g, " ");
                        setSanitizedHTML('<h4>'+titreWiki+'</h4><p style="font-size:11px">Aucune information disponible.</p>');         
                    
                    }
                    
                });}
            
            }
        )
        .catch( error => {
            titreWiki=titreWiki.charAt(0).toUpperCase() + titreWiki.substring(1);
            titreWiki=titreWiki.replace(/_/g, " ");
            setSanitizedHTML('<h4>'+titreWiki+'</h4><p style="font-size:11px">Aucune information disponible.</p>');
            
        })
    }
    else
    {
        if(idWikidata !== '')
        {
            var description='';
            if(data.entities[idWikidata].descriptions !== undefined && data.entities[idWikidata].descriptions !== null)
            {
                if(data.entities[idWikidata].descriptions.fr !== undefined && data.entities[idWikidata].descriptions.fr !== null)
                {
                    description=data.entities[idWikidata].descriptions.fr.value;
                }
                else if(data.entities[idWikidata].descriptions.en !== undefined && data.entities[idWikidata].descriptions.en !== null)
                {
                    description=data.entities[idWikidata].descriptions.en.value;
                }
                else if(data.entities[idWikidata].descriptions[countryCode] !== undefined && data.entities[idWikidata].descriptions[countryCode] !== null)
                {
                    description=data.entities[idWikidata].descriptions[countryCode].value;
                } 
            }
            titreWiki=titreWiki.charAt(0).toUpperCase() + titreWiki.substring(1);
            titreWiki=titreWiki.replace(/_/g, " ");
            if(description !== '')
            {
                description=description.charAt(0).toUpperCase() + description.substring(1);
                setSanitizedHTML('<h4>'+titreWiki+'</h4><p style="font-size:11px">'+description+'</p>'); 
            }
            else
            {
              setSanitizedHTML('<h4>'+titreWiki+'</h4><p style="font-size:11px">Aucune information disponible.</p>'); 
            }        
        }
        else
        {
          setSanitizedHTML('<h4>'+libelleInc+'</h4><p style="font-size:11px">Aucune information disponible.</p>'); 
           
        }
    }
    
}
  
  function apiWikipediaPhoto(langue: string, titreWikipedia: string) {
    return new Promise<WikipediaType>(function(resolve, reject) {
        var url='https://'+langue+'.wikipedia.org/w/api.php?action=query&format=json&formatversion=2&prop=pageimages|pageterms&piprop=thumbnail&pithumbsize=600&titles='+titreWikipedia+'&origin=*';
        var myHeaders = new Headers();
        myHeaders.append("Origin", "*");
        fetch(url, {mode: 'cors', headers: myHeaders})
          .then(response => {
            // check if the call was successful
            if (response.ok) {
              response.json().then(data => resolve(data));
            } else {
              response.json().then(data => reject(data));
            }
          })
          .catch(error => console.log('Authorization failed : ' + error.message));
          
    });
  }
  
  function apiWikipediaPhotoCredits(nomPhoto: string) {
    return new Promise<WikipediaType>(function(resolve, reject) {
        var url='https://commons.wikimedia.org/w/api.php?action=query&format=json&origin=*&withCredentials=false&titles=File:'+nomPhoto+'&prop=imageinfo&iiprop=metadata%7Ccommonmetadata%7Cextmetadata';
        fetch(url)
          .then(response => {
            // check if the call was successful
            if (response.ok) {
              response.json().then(data => resolve(data));
            } else {
              response.json().then(data => reject(data));
              
            }
            
          });
    });
  }
  
  
  function apiWikipedia(langue: string, titreWikipedia: string) {
    return new Promise<WikipediaType>(function(resolve, reject) {
        var url='https://'+langue+'.wikipedia.org/w/api.php?action=query&prop=extracts&exintro=true&exsentences=2&exlimit=1&titles='+titreWikipedia+'&explaintext=1&formatversion=2&format=json&origin=*';
        fetch(url)
          .then(response => {
            // check if the call was successful
            if (response.ok) {
              response.json().then(data => resolve(data));
            } else {
              response.json().then(data => reject(data));
            }
          });
    });
  }
  
const handleInfosIncontournablesClick = (incontournable: IncontournablesType) => {
  setSanitizedHTML("Recherche en cours, veuillez patienter....");
  setAjoutIncontournable(null);
  let idWikidata = incontournable.id_wikidata;
  let libelleInc = incontournable.libelle_incontournable;
  let placeIdInc = incontournable.place_id;
  let latitudeInc = incontournable.latitude;
  let longitudeInc = incontournable.longitude;
  apiWikiData(idWikidata).then(dataWiki => {
    affichageIncontournable(libelleInc, placeIdInc, latitudeInc, longitudeInc, dataWiki, idWikidata, codePays);
  });
}


const handleJourSelected = (numJour: number) => {
  setSelectedJour(numJour);
  setNumJourIncontournable(numJour);
};

const handleAddLieuIncontournables = async () =>
  {
    setAjoutIncontournable({numJourIncontournable, libelleLieuIncontournable, placeIdIncontournable, latitudeIncontournable, longitudeIncontournable});
  }  

    return (
      <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalIncontournables}
      >
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block py-8 w-full md:w-4/5 lg:w-3/4 xl:w-2/3">
            <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                  id="headlessui-dialog-title-70"
                >
                  Les Incontournables
                </h3>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={handleCloseModalIncontournables} />
                </span>
              </div>
              <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
            
                  <div className="items-center py-2.5 space-x-5 lg:space-x-8 text-center">
                  
                    <div className="grid grid-cols-2 gap-3 text-left mt-5">
                    <div>
                      {incontournables && incontournables.map((item, index) => (
                        <ButtonSquare
                          key={index}
                          onClick={() => handleInfosIncontournablesClick(item)}
                        >
                          <span className="text-sm font-medium texteGras">{item.num_ordre} - {item.libelle_incontournable}</span>
                        </ButtonSquare>
                      ))}
                    </div>
                      <div>
                     
                      <div className="text-center imagePOI" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}>

                      </div>
                     { isModif && sanitizedHTML !== "" && !ajoutLieuIncontournables && (
                          <div className="text-center mt-3">
                            <SelectPaiementHebergement
                            value={selectedJour}
                            onChange={(event) => handleJourSelected(Number(event.target.value))}
                            className="pointeur"
                            >
                           {[...Array(nbJours)].map((_, index) => (
                              <option key={`jour-${index}`} value={index + 1}>Jour {index + 1}</option>
                            ))}
                            </SelectPaiementHebergement>
                            <ButtonPrimarySmall className="w-full" 
                            onClick={handleAddLieuIncontournables}
                            >Créer une nouvelle étape</ButtonPrimarySmall>
                          </div>
                        )}
                        { sanitizedHTML !== "" && ajoutLieuIncontournables && (
                          <div className="text-center mt-5">
                            <span className="couleurRose">Lieu créé !</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
  );
};

export default ModalIncontournables;
