import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { compteService } from "_services/compte.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const CompteGestion = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    const isConfirmed = window.confirm(
      t('confirmSuppressionCompte')
    );

    // Vérifiez si l'utilisateur a confirmé
    if (isConfirmed) {
     
      setLoading(true);

      // Envoyer une requête PUT avec les données modifiées
      compteService
        .supprimeCompte()
        .then((response: any) => {

          localStorage.removeItem("xsrfToken");
          // Reset loading state after successful response
          setLoading(false);
          navigate(t('lienHome'));
        })
        .catch((error: any) => {
          // Reset loading state after API call, regardless of success or failure
          setLoading(false);

          if (error.response?.status === 401) {
            alert(t('alertErreurSuppCompte')+t('alertPlusConnecte'));
            localStorage.removeItem("xsrfToken");
            navigate(t('lienLogin'));
          } else if (error.response?.status === 406) {
            //
          } else {
            alert(t('alertErreurSuppCompte'));
            localStorage.removeItem("xsrfToken");
            navigate(t('lienLogin'));
          }
          console.error("Error suppression compte:", error);
          localStorage.removeItem("xsrfToken");
            navigate(t('lienLogin'));
        });
    }
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t('gestionCompte')}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
            <div className="pt-0">
              <ButtonPrimary onClick={handleDeleteAccount} disabled={loading}>
                {loading ? t('suppressionEnCours') : t('supprimerCompte')}
              </ButtonPrimary>
            </div>
            <br />
            <span className="text-neutral-700 dark:text-neutral-300 block">
              {t('avertissementSuppression')}
            </span>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default CompteGestion;
